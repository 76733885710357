// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@media all and (min-width: 980px){.dCNlDuJs3vKOjReteyGX{max-width:762px;padding-top:20px;margin-top:28px;margin:auto;min-width:40%}}", "",{"version":3,"sources":["webpack://./src/modules/UserProfile/pages/publicProfile.module.scss"],"names":[],"mappings":"AAAA,kCACI,sBACI,eAAA,CACA,gBAAA,CACA,eAAA,CACA,WAAA,CACA,aAAA,CAAA","sourcesContent":["@media all and (min-width : 980px) {\n    .profilePage {\n        max-width: 762px;\n        padding-top: 20px;\n        margin-top: 28px;\n        margin: auto;\n        min-width: 40%;\n    }\n  }\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"profilePage": "dCNlDuJs3vKOjReteyGX"
};
export default ___CSS_LOADER_EXPORT___;
