import React, { useContext } from 'react'

import { Link, matchPath } from 'react-router-dom'
import UIAvatar from 'react-ui-avatars'

import {
  UikNavSection,
  UikNavLink,
  UikNavUser,
  UikDivider,
} from 'uikit/components'
import { StoreContext } from '../../../../contexts/Store'

import { UikNavPanel } from 'uikit/containers'

// import menuLinks from './menuLinks';
// import menuLinksRecruiters from './menuLinksRecruiters';
// import candidateLinks from './candidateLinks';
// import menuFavourites from './menuFavourites';

const getParams = (pathname) => {
  const matchAppender = matchPath(pathname, {
    path: '/org/(@[a-zA-Z0-9_.-]*)(/edit|:appends)?/:appends?',
  })
  if (matchAppender && matchAppender.params) {
    const { params } = matchAppender
    return Object.assign(
      {},
      {
        ...params,
        // On /@userName/edit
        // matchPath returns appends === undefined.
        // To match our navigation first link,
        // we need to replace it wiith an empty string
        appends: params.appends === undefined ? '' : params.appends,
      }
    )
  }
  return { appends: '' }
}

const SocialNavigation = (props) => {
  const match = getParams(props.location.pathname)
  const isAuthenticated = window.DB && window.DB.getItem('whire-app-token')
  // const { me: { me: storedMe, storeMe } } = useContext(StoreContext);
  // const { ownPage : { ownPage } } = useContext(StoreContext)
  const getRandomColor = (letter) => {
    let colorValues = '#FFC117'
    if (letter.match(/^[a-cA-C 0-9\.\,\+\-]*$/)) {
      return colorValues
    } else if (letter.match(/^[d-fD-F\.\,\+\-]*$/)) {
      return (colorValues = '#007ED9')
    } else if (letter.match(/^[g-iG-I\.\,\+\-]*$/)) {
      return (colorValues = '#ED4A5E')
    } else if (letter.match(/^[j-lJ-L\.\,\+\-]*$/)) {
      return (colorValues = '#00A2AE')
    } else if (letter.match(/^[m-oM-O\.\,\+\-]*$/)) {
      return (colorValues = '#3C26BB')
    } else if (letter.match(/^[p-rP-R\.\,\+\-]*$/)) {
      return (colorValues = '#666be2')
    } else if (letter.match(/^[s-uS-U\.\,\+\-]*$/)) {
      return (colorValues = '#ff015b')
    } else if (letter.match(/^[v-xV-X\.\,\+\-]*$/)) {
      return (colorValues = '#30ca93')
    } else if (letter.match(/^[v-xV-X\.\,\+\-]*$/)) {
      return (colorValues = '#ffd000')
    } else {
      return (colorValues = '#F56A00')
    }
  }

  return (
    <UikNavPanel>
      {props.imgUrl ? (
        <UikNavUser
          imgUrl={props.imgUrl}
          name={props.name}
          textTop={props.summary}
        />
      ) : (
        <div>
          <UIAvatar
            background={getRandomColor(props.name.charAt(0).toLowerCase())}
            name={props.name}
            color="#FFFFFF"
            rounded
            size="80px"
            style={{
              marginLeft: '6em',
              marginTop: '2.5em',
              marginBottom: '-1em',
            }}
          />
          <UikNavUser name={props.name} textTop={props.summary} />
        </div>
      )}
      <UikDivider />
      <UikNavSection style={{ marginTop: '24px' }}>
        {/* {
          props.sections.map(link => (
            <UikNavLink
              className={link.appends === match.appends ? 'active' : null}
              Component={Link}
              key={link.text}
              onClick={props.onLinkIsClicked}
              highlighted
              rightEl={link.rightEl}
              to={link.appends === '' ? `/org/${match['0']}` : {
                pathname: `/org/${match['0']}/${link.appends}`,
                state: { modal: true },
              }}
            >
              {link.text}
            </UikNavLink>
          ))
        } */}
        {props.trainees.length ? (
          <div>
            <h4
              style={{
                marginLeft: '10px',
                color: '#767676',
                fontSize: '.875rem',
              }}
            >
              {' '}
              Nos profils
            </h4>
            {
              /*
                  This should be built in CompanyProfile/index
                */
              props.trainees.map((link) => (
                <UikNavLink
                  // className={link.appends === match.appends ? 'active' : null}
                  Component={Link}
                  key={Math.random()}
                  onClick={props.onLinkIsClicked}
                  highlighted
                  // rightEl={link.rightEl}
                  to={`/org/${match['0']}/trainees/${link.id}`}
                >
                  {link.name}
                </UikNavLink>
              ))
            }
          </div>
        ) : null}
      </UikNavSection>
    </UikNavPanel>
  )
}

SocialNavigation.defaultProps = {
  onLinkIsClicked: null,
  trainees: [],
}

export default SocialNavigation
