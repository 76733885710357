import React, { useContext, useState } from 'react'
import { StoreContext } from 'contexts/Store'
import { string, arrayOf, shape, number } from 'prop-types'
import { withRouter } from 'react-router'
import UIAvatar from 'react-ui-avatars'
import MetaTags from 'react-meta-tags'
import { graphql, withApollo } from '@apollo/client/react/hoc'
import { compose } from 'recompose'
import Modal from 'react-modal'
import ModalContent from 'components/ModalContent'

import { historyShape } from 'utils/shapes'
import { COMPANY_LINK_ITERABLE, DOMAIN } from 'utils/vars'

import { UikContainerHorizontal, UikLayoutMain } from 'uikit/containers'

import { UikAvatar, UikTag, UikSvg } from 'uikit/components'
import { SVG_URI } from 'utils/vars'
import { getMainProfile } from 'utils/handleMainProfile'

import {
  CreateNotificationMutation,
  UpdateNotificationNumber,
  CreateNotificationNumberMutation,
} from 'gql/page'
import {
  CreateProfileRequestMutation,
  DeleteProfileRequestMutation,
} from 'gql/profile'

import TextWidget from 'components/TextWidget'
import Menu from 'components/Menu'
import UikButton from 'uikit/components/UikButton'

import ReactGA from 'react-ga4'
import { Grid, Row, Col } from 'react-flexbox-grid'

import cls from './home.module.scss'
import clsApp from 'initStyles/App.scss'

const Home = ({
  links,
  history,
  title,
  summary,
  slug,
  tags,
  navSections,
  navSectionsDraft,
  setDraft,
  image,
  imageModifier,
  sections,
  video,
  owner,
  me,
  trainees,
  dataPages,
  ...props
}) => {
  const {
    ownPage: { ownPage },
    emberViewIsOpen: { emberViewIsOpen },
  } = useContext(StoreContext)

  const [hideButton, setHideButton] = useState(false)
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [modalGroupIsOpen, setModalGroupIsOpen] = useState(false)

  const path = window.location.hostname

  const userIsOwner =
    me && me.pages && me.pages.find((e) => e.id === dataPages.id)
  const mainProfile = getMainProfile()
  const idProfileRequestAndMe = mainProfile
    ? dataPages.company.owner.profileRequests
        .map((profile) => profile)
        .find((profile) =>
          profile.profile ? profile.profile.id === mainProfile.id : null
        )
    : null
  const isMatchedForCancel = mainProfile
    ? dataPages.company.owner.profileRequests
        .map((profile) => profile)
        .find((profile) =>
          profile.profile
            ? profile.profile.id === mainProfile.id &&
              profile.status === 'PENDING'
            : null
        )
    : null
  const isMatchedForDelete = mainProfile
    ? dataPages.company.owner.profileRequests
        .map((profile) => profile)
        .find((profile) =>
          profile.profile
            ? profile.profile.id === mainProfile.id &&
              profile.status === 'ACCEPTED'
            : null
        )
    : null

  const getRandomColor = (letter) => {
    let colorValues = '#FFC117'
    if (letter.match(/^[a-cA-C 0-9\.\,\+\-]*$/)) {
      return colorValues
    } else if (letter.match(/^[d-fD-F\.\,\+\-]*$/)) {
      return (colorValues = '#007ED9')
    } else if (letter.match(/^[g-iG-I\.\,\+\-]*$/)) {
      return (colorValues = '#ED4A5E')
    } else if (letter.match(/^[j-lJ-L\.\,\+\-]*$/)) {
      return (colorValues = '#00A2AE')
    } else if (letter.match(/^[m-oM-O\.\,\+\-]*$/)) {
      return (colorValues = '#3C26BB')
    } else if (letter.match(/^[p-rP-R\.\,\+\-]*$/)) {
      return (colorValues = '#666be2')
    } else if (letter.match(/^[s-uS-U\.\,\+\-]*$/)) {
      return (colorValues = '#ff015b')
    } else if (letter.match(/^[v-xV-X\.\,\+\-]*$/)) {
      return (colorValues = '#30ca93')
    } else if (letter.match(/^[v-xV-X\.\,\+\-]*$/)) {
      return (colorValues = '#ffd000')
    } else {
      return (colorValues = '#F56A00')
    }
  }

  const customStyles2 = {
    content: {
      border: 'none',
      padding: '0',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      minHeight: '30%',
      minWidth: '50%',
      transform: 'translate(-50%, -50%)',
    },
    overlay: {
      zIndex: '1',
      background: 'rgba(0,0,0,.3)',
    },
  }

  //filter the links in case we have multiples times the same link because there was a bug: sometimes, the same link were saved multiple times in the database
  const filterLinks = Array.from(new Set(links.map((link) => link.url))).map(
    (url) => {
      return {
        url,
        id: links.find((link) => link.url === url).id,
        type: links.find((link) => link.url === url).type,
      }
    }
  )

  const handleClick = (e) => {
    ReactGA.event({
      category: 'Trainees',
      action: 'click on the button to get to a trainee ',
      label: slug + ' - ' + e.name,
    })
    setDraft(false)
    history.push({
      pathname: `/org/@${slug}/trainees/${e.id}`,
      state: { modal: false },
    })
  }

  return (
    <div className={emberViewIsOpen ? cls.homeDiv : ''}>
      <Modal
        isOpen={modalGroupIsOpen}
        // onAfterOpen={this.afterOpenModal}
        onRequestClose={() => setModalGroupIsOpen(false)}
        style={customStyles2}
        contentLabel="Example Modal"
      >
        <ModalContent header="👇 Rejoindre ce groupe">
          <p style={{ marginBottom: '2em' }}>
            En nous rejoignant, vous confirmez avoir pris connaissance et{' '}
            <br></br> avoir accepté la politique de confidentialité de notre
            structure
          </p>
          <button
            className={`${clsApp.buttonRequest} ${clsApp.newRequest}`}
            style={{ marginRight: '2em' }}
            onClick={() => {
              props
                .createNotification({
                  variables: {
                    viewer: mainProfile.title,
                    // if i'm an org send the slug with the /org"
                    slug: `@${mainProfile.slug}`,
                    notificationMessage: 'souhaite vous rejoindre',
                    image: mainProfile.image,
                    imageModifier: mainProfile.imageModifier,
                    pageSlug: slug,
                  },
                })
                .then((res) => {
                  console.log(res, 'meeee resss')
                })
                .catch((err) => {
                  console.log(err, 'meee errrrroo')
                })
              dataPages.notificationNumbers.length < 1
                ? props
                    .createNotificationNumber({
                      variables: {
                        number: 1,
                        pageSlug: slug,
                      },
                    })
                    .then((res) => {
                      console.log(res, 'me res number')
                    })
                    .catch((err) => {
                      console.log(err, 'me error number')
                    })
                : props
                    .updateNotificationNumber({
                      variables: {
                        id: dataPages.notificationNumbers[0].id,
                        number: dataPages.notificationNumbers[0].number + 1,
                      },
                    })
                    .then((res) => {
                      console.log(res, 'update notification number success')
                    })
                    .catch((err) => {
                      console.log(
                        err,
                        'update update notification number error'
                      )
                    })
              props
                .createProfileRequest({
                  variables: {
                    // slugPage: slug,
                    emailPage: dataPages.company.owner.email,
                    slugProfile: mainProfile.slug,
                  },
                })
                .then(
                  (res) =>
                    console.log(res, 'RESPONSE PROFILE REQUEST') ||
                    setHideButton(true) ||
                    window.location.reload()
                )
                .catch((err) => console.log(err, 'ERROR'))
            }}
          >
            Nous rejoindre
          </button>
          <button
            className={`${clsApp.buttonRequest} ${clsApp.deleteRequest}`}
            onClick={() => setModalGroupIsOpen(false)}
          >
            Annuler
          </button>
        </ModalContent>
      </Modal>
      <div className={cls.mainDiv}>
        <UikLayoutMain
          className={
            /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(
              navigator.userAgent
            ) === false && ownPage
              ? cls.layout
              : ''
          }
        >
          <div className={cls.companyPage}>
            <div
              style={{
                textAlign: 'center',
                maxWidth: '580px',
                margin: 'auto',
              }}
            >
              {/* { ownPage ? (
            <a style={{marginLeft: "20em", fontSize: "25px"}} href={`/org/@${slug}/edit`}>✍️</a>
          ) : null
          } */}
              <div style={{ justifyContent: 'space-around' }}>
                {image ? (
                  <UikAvatar
                    imageId={image}
                    imageModifier={imageModifier}
                    size="larger"
                    style={{
                      justifyContent: 'space-around',
                    }}
                  />
                ) : (
                  <UIAvatar
                    background={getRandomColor(title.charAt(0).toLowerCase())}
                    name={title}
                    color="#FFFFFF"
                    rounded
                    size="80px"
                  />
                )}
              </div>
              <h1
                style={{
                  fontSize: '30px',
                  fontFamily:
                    '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica,Arial,sans-serif',
                  marginTop: '.6em',
                }}
              >
                <span role="img" aria-label="hello">
                  👋
                </span>{' '}
                Bonjour, nous sommes {title}
              </h1>
              <h4 style={{ marginTop: '1em' }}>{summary}</h4>
              <UikContainerHorizontal style={{ justifyContent: 'center' }}>
                {/* <div>
                {
                tags.map(elem => (
                  <UikTag
                    key={elem.name}
                    Component={elem.Component}
                    {...elem}
                    style={{
                      fontSize: '16px',
                      fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica,Arial,sans-serif',
                    }}
                  >
                    {elem.name}
                  </UikTag>
                ))
              }
              </div> */}
              </UikContainerHorizontal>
              <UikContainerHorizontal
                style={{
                  margin: '30px 12px',
                  justifyContent: 'center',
                }}
              >
                {filterLinks &&
                  filterLinks.map((item) => {
                    if (image) {
                      COMPANY_LINK_ITERABLE.PERSONAL.icon = `https://ucarecdn.com/${image}/${imageModifier}`
                    }
                    if (!item.id) item.id = genUniqueId()
                    if (!item.type) item.type = 'OTHER' //to-do parser here
                    if (
                      item.type === 'OTHER' &&
                      item.url.includes('instagram.com')
                    ) {
                      COMPANY_LINK_ITERABLE['OTHER'].icon =
                        '/static/links/instagram.png'
                    } else if (
                      item.type === 'OTHER' &&
                      item.url.includes('twitter.com')
                    ) {
                      COMPANY_LINK_ITERABLE['OTHER'].icon =
                        '/static/links/twitter.svg'
                    } else if (
                      item.type === 'OTHER' &&
                      item.url.includes('facebook.com')
                    ) {
                      COMPANY_LINK_ITERABLE['OTHER'].icon =
                        '/static/links/facebook.svg'
                    } else if (
                      item.type === 'OTHER' &&
                      item.url.includes('linkedin.com')
                    ) {
                      COMPANY_LINK_ITERABLE['OTHER'].icon =
                        '/static/links/linkedin.svg'
                    } else if (
                      item.type === 'OTHER' &&
                      item.url.includes('github')
                    ) {
                      COMPANY_LINK_ITERABLE['OTHER'].icon =
                        '/static/links/github.svg'
                    } else if (
                      item.type === 'OTHER' &&
                      item.url.includes('youtube.com')
                    ) {
                      COMPANY_LINK_ITERABLE['OTHER'].icon =
                        '/static/links/youtube.svg'
                    } else if (
                      item.type === 'OTHER' &&
                      item.url.includes('flickr.com')
                    ) {
                      COMPANY_LINK_ITERABLE['OTHER'].icon =
                        '/static/links/flickr.svg'
                    } else if (
                      item.type === 'OTHER' &&
                      item.url.includes('tumblr.com')
                    ) {
                      COMPANY_LINK_ITERABLE['OTHER'].icon =
                        '/static/links/tumblr.svg'
                    } else if (
                      item.type === 'OTHER' &&
                      item.url.includes('medium.com')
                    ) {
                      COMPANY_LINK_ITERABLE['OTHER'].icon =
                        '/static/links/medium.svg'
                    } else if (
                      item.type === 'OTHER' &&
                      item.url.includes('behance.net')
                    ) {
                      COMPANY_LINK_ITERABLE['OTHER'].icon =
                        '/static/links/behance.svg'
                    } else if (
                      item.type === 'OTHER' &&
                      item.url.includes('dribbble.com')
                    ) {
                      COMPANY_LINK_ITERABLE['OTHER'].icon =
                        '/static/links/dribbble.svg'
                    } else if (
                      item.type === 'OTHER' &&
                      item.url.includes('viadeo.com')
                    ) {
                      COMPANY_LINK_ITERABLE['OTHER'].icon =
                        '/static/links/viadeo.svg'
                    } else if (
                      item.type === 'OTHER' &&
                      item.url.includes('vimeo.com')
                    ) {
                      COMPANY_LINK_ITERABLE['OTHER'].icon =
                        '/static/links/vimeo.svg'
                    } else if (
                      item.type === 'OTHER' &&
                      item.url.includes('pinterest')
                    ) {
                      COMPANY_LINK_ITERABLE['OTHER'].icon =
                        '/static/links/pinterest.svg'
                    } else if (
                      item.type === 'OTHER' &&
                      item.url.includes('openbadge')
                    ) {
                      USER_LINK_ITERABLE['OTHER'].icon =
                        '/static/links/openbadge.png'
                    } else {
                      COMPANY_LINK_ITERABLE['OTHER'].icon =
                        '/static/links/icone-link.png'
                    }
                    return (
                      <span key={item.id}>
                        <a
                          rel="noopener noreferrer"
                          target="_blank"
                          className="link-reset"
                          href={
                            item.type !== 'RESUME'
                              ? item.url
                              : item.url.concat('-/inline/yes/')
                          }
                        >
                          <img
                            alt={COMPANY_LINK_ITERABLE[item.type].title}
                            //src={`${DOMAIN}${COMPANY_LINK_ITERABLE[item.type].icon}`}
                            src={`${COMPANY_LINK_ITERABLE[item.type].icon}`}
                            style={{
                              width: '52px',
                              height: '52px',
                              marginTop: '-1px',
                              marginLeft: '-1px',
                              borderRadius: '50%',
                            }}
                          />
                          &nbsp;&nbsp;&nbsp;&nbsp;
                        </a>
                      </span>
                    )
                  })}
              </UikContainerHorizontal>
            </div>
          </div>
          <div
            style={{
              maxWidth: '762px',
              paddingTop: '20px',
              margin: 'auto',
              marginTop: '28px',
              minWidth: '40%',
            }}
          >
            {video ? (
              <TextWidget
                style={{ marginTop: '30px', minWidth: '30em' }}
                content={{
                  videoUrl:
                    video.includes('vimeo') ||
                    video.includes('dailymotion') ||
                    video.includes('youtube') ||
                    video.includes('twitter')
                      ? video
                      : video.includes('https://www.ucarecdn.com')
                      ? video.split('https://www.ucarecdn.com/').join('')
                      : video,
                }}
              />
            ) : null}
          </div>
          {dataPages.company.owner.plan !== 'PREMIUM' &&
          me &&
          me.origin === 'CANDIDATE' &&
          mainProfile &&
          !isMatchedForCancel &&
          !isMatchedForDelete &&
          !hideButton ? (
            <div style={{ margin: 'auto' }}>
              <button
                onClick={() => setModalGroupIsOpen(true)}
                className={`${clsApp.buttonRequest} ${clsApp.newRequest}`}
              >
                Nous rejoindre
              </button>
            </div>
          ) : null}
          {dataPages.company.owner.plan !== 'PREMIUM' &&
          me &&
          me.origin === 'CANDIDATE' &&
          mainProfile &&
          isMatchedForCancel &&
          !isMatchedForDelete &&
          !hideButton ? (
            <div style={{ margin: 'auto' }}>
              <button
                onClick={() => {
                  props
                    .deleteProfileRequest({
                      variables: {
                        id: idProfileRequestAndMe.id,
                      },
                    })
                    .then(
                      (res) =>
                        console.log(res, 'RESPONSE PROFILE REQUEST') ||
                        setHideButton(true) ||
                        window.location.reload()
                    )
                    .catch((err) => console.log(err, 'ERROR'))
                }}
                className={`${clsApp.buttonRequest} ${clsApp.cancelRequest}`}
              >
                Annuler ma demande
              </button>
            </div>
          ) : null}
          {dataPages.company.owner.plan !== 'PREMIUM' &&
          me &&
          me.origin === 'CANDIDATE' &&
          mainProfile &&
          isMatchedForDelete &&
          !hideButton ? (
            <div style={{ margin: 'auto' }}>
              <button
                onClick={() => {
                  props
                    .deleteProfileRequest({
                      variables: {
                        id: idProfileRequestAndMe.id,
                      },
                    })
                    .then(
                      (res) =>
                        console.log(res, 'RESPONSE PROFILE REQUEST') ||
                        setHideButton(true) ||
                        window.location.reload()
                    )
                    .catch((err) => console.log(err, 'ERROR'))
                }}
                className={`${clsApp.buttonRequest} ${clsApp.deleteRequest}`}
              >
                Quitter le groupe
              </button>
            </div>
          ) : null}
        </UikLayoutMain>
        {ownPage ? (
          <button
            onClick={() => history.push(`/org/@${slug}/edit`)}
            className={cls.editButton}
          >
            <img src={`${SVG_URI}/1.modifier.svg`} />
            Modifier
          </button>
        ) : null}
      </div>
      {trainees.length && dataPages.company.owner.plan === 'PREMIUM' ? (
        <UikLayoutMain>
          <h2
            style={{
              fontSize: '1.5em',
              marginTop: '1.3em',
              marginBottom: '1em',
              textAlign: 'center',
            }}
          >
            {' '}
            👇 Découvrez nos profils{' '}
          </h2>
          <div
            className={cls.traineesDiv}
            style={{ textAlign: 'center', margin: 'auto' }}
          >
            {trainees.map((e) => (
              // <UikButton className={cls.traineesButton} onClick={()=>handleClick(e)}>{e.name}</UikButton>
              <div
                onClick={() => history.push(`/org/@${slug}/trainees/${e.id}`)}
                className={cls.traineesCard}
              >
                {ownPage ? (
                  <input
                    type="image"
                    alt="modifier"
                    src={`${SVG_URI}/1.modifier.svg`}
                    onClick={(event) =>
                      event.stopPropagation() ||
                      history.push(`/org/@${slug}/trainees/${e.id}/edit`)
                    }
                    className={cls.editIcon}
                  />
                ) : null}
                {e.name.length > 68 ? (
                  <h3
                    onClick={() =>
                      history.push(`/org/@${slug}/trainees/${e.id}`)
                    }
                    className={ownPage ? cls.h3OnMyPage : cls.h3Visitor}
                  >
                    {e.name.substring(0, 69)}...
                  </h3>
                ) : (
                  <h3
                    onClick={() =>
                      history.push(`/org/@${slug}/trainees/${e.id}`)
                    }
                    className={ownPage ? cls.h3OnMyPage : cls.h3Visitor}
                  >
                    {e.name}
                  </h3>
                )}
                <UikButton success className={cls.discoverButton}>
                  Découvrir
                </UikButton>
              </div>
            ))}
            {ownPage ? (
              <div
                onClick={() => history.push(`/org/@${slug}/creer-ma-section`)}
                className={cls.addTrainees}
              >
                <input
                  type="image"
                  alt="ajouter"
                  src={`${SVG_URI}/+.svg`}
                  className={cls.addIcon}
                />
                <p>Ajouter un nouveau groupe</p>
              </div>
            ) : null}
          </div>
        </UikLayoutMain>
      ) : null}
      {
        <UikLayoutMain>
          {dataPages.company.owner.plan === 'PREMIUM' &&
            sections.find(
              (e) => e && e.storiesTypeExpected === 'INTRODUCTION'
            ) && (
              <div style={{ width: '60%', margin: 'auto' }}>
                <TextWidget
                  style={{ marginTop: '30px' }}
                  content={sections
                    .find((e) => e.storiesTypeExpected === 'INTRODUCTION')
                    .stories.filter((elem) => !!elem.editorState)
                    .map((elem) => ({
                      ...elem,
                    }))}
                />
              </div>
            )}
        </UikLayoutMain>
      }
    </div>
  )
}

Home.propTypes = {
  links: arrayOf(
    shape({
      url: string.isRequired,
      type: string.isRequired,
    })
  ),
  tags: arrayOf(
    shape({
      name: string.isRequired,
    })
  ),
  title: string.isRequired,
  summary: string.isRequired,
  slug: string.isRequired,
  image: string.isRequired,
  sections: arrayOf(
    shape({
      storiesTypeExpected: string.isRequired,
    })
  ).isRequired,
  navSections: arrayOf(
    shape({
      title: string.isRequired,
      rightEl: number.isRequired,
    })
  ).isRequired,
  history: historyShape.isRequired,
}

Home.defaultProps = {
  links: [],
  tags: [],
}

Home.displayName = 'Home'

export default compose(
  graphql(CreateProfileRequestMutation, { name: 'createProfileRequest' }),
  graphql(DeleteProfileRequestMutation, { name: 'deleteProfileRequest' }),
  graphql(CreateNotificationMutation, { name: 'createNotification' }),
  graphql(CreateNotificationNumberMutation, {
    name: 'createNotificationNumber',
  }),
  graphql(UpdateNotificationNumber, {
    name: 'updateNotificationNumber',
  })
)(withApollo(Home))
