import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Measure from 'react-measure'
import classnames from 'classnames'

import cls from './affix.module.scss'

class Affix extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    FixedComponent: PropTypes.node || PropTypes.string,
    offset: PropTypes.number,
  }
  static defaultProps = {
    offset: document.documentElement.scrollTop || document.body.scrollTop || 0,
    FixedComponent: 'div',
  }

  state = {
    affix: false,
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll = () => {
    const { affix } = this.state
    const { offset } = this.props
    const scrollTop =
      document.documentElement.scrollTop || document.body.scrollTop
    if (!affix && scrollTop >= offset) {
      this.setState({
        affix: true,
      })
    }

    if (affix && scrollTop < offset) {
      this.setState({
        affix: false,
      })
    }
  }

  render() {
    const isAffix = this.state.affix

    const { className, FixedComponent, wrapperStyle, style, ...rest } =
      this.props

    return (
      <Measure
        bounds
        onResize={(contentRect) =>
          this.setState({ parentWidth: contentRect.bounds.width })
        }
      >
        {({ measureRef }) => (
          <div className={cls.wrapper} ref={measureRef} style={wrapperStyle}>
            <FixedComponent
              {...rest}
              className={classnames(
                isAffix &&
                  /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(
                    navigator.userAgent
                  ) === false
                  ? cls.affix
                  : isAffix &&
                    /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(
                      navigator.userAgent
                    ) &&
                    !location.pathname.includes('edit' || 'nouvelle')
                  ? cls.affix2
                  : isAffix &&
                    /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(
                      navigator.userAgent
                    ) &&
                    location.pathname.includes('edit' || 'nouvelle')
                  ? cls.affix
                  : null,
                className
              )}
              // className={classnames(isAffix ? cls.affix : cls.affix2, className)}
              style={{ width: this.state.parentWidth, ...style }}
            >
              {this.props.children}
            </FixedComponent>
          </div>
        )}
      </Measure>
    )
  }
}

Affix.displayName = 'Affix'

export default Affix
