import React, { useRef, useEffect, useContext } from 'react'
import { FieldArray, Field } from 'formik'

import Editor from 'modules/Editor'
import { StoreContext } from 'contexts/Store'
import { PROFILE_PAGE_TITLE } from 'utils/wording'

import { UikInput } from 'uikit/components'

const EditorFieldArray = ({
  errors,
  field,
  value,
  submitCount,
  getEditorRef,
}) => {
  const refEditor = useRef(null)
  const {
    me: { me },
  } = useContext(StoreContext)

  useEffect(() => {
    if (!!getEditorRef) {
      getEditorRef(refEditor)
    }
  })
  return (
    <FieldArray
      name={field.id}
      render={(arrayHelpers) => (
        <div>
          {!!value &&
            value.map((initialValue, index) => (
              <div key={`${field.id}-${index + 1}`}>
                <Field
                  name={`${field.id}[${index}].${field.accessor || 'url'}`}
                  render={({ field: renderedField }) => (
                    <div ref={refEditor}>
                      <h4
                        style={{
                          marginTop: index && '30px',
                        }}
                      >
                        {initialValue.formTitle}
                      </h4>
                      {
                        <UikInput
                          type="text"
                          onChange={({ target: { value: inputValue } }) => {
                            arrayHelpers.replace(index, {
                              ...initialValue,
                              touched: true,
                              title: inputValue,
                            })
                          }}
                          value={initialValue.title}
                          label={`Titre ${
                            !!me && !!me.origin
                              ? PROFILE_PAGE_TITLE(me.origin)
                              : ''
                          }`}
                          placeholder="Votre titre"
                          name={`${field.id}[${index}].title`}
                        />
                      }
                      <Editor
                        // removeBlockTypes
                        limitedBlockTypes
                        field={field}
                        spellCheck
                        placeholder={initialValue.placeholder}
                        {...renderedField}
                        rawContentState={initialValue.editorState}
                        onSave={(editorState) => {
                          arrayHelpers.replace(index, {
                            ...initialValue,
                            touched: true,
                            editorState,
                          })
                        }}
                      />
                      {(initialValue.touched || submitCount) &&
                      errors &&
                      errors[index] ? (
                        <p
                          style={{
                            color: '#E6492D',
                            marginTop: '6px',
                            lineHeight: '1.375rem',
                            fontSize: '.8rem',
                          }}
                          id={`error_editorState_${index}`}
                        >
                          {errors[index].editorState}
                        </p>
                      ) : null}
                    </div>
                  )}
                />
              </div>
            ))}
        </div>
      )}
    />
  )
}

// EditorFieldArray.defaultProps = {
//  hideAddMore: false,
// };

export default EditorFieldArray
