import React from 'react'

import TextWidget from 'components/TextWidget'
import UikContainerVertical from 'uikit/containers/UikContainerVertical'
import '../initStyles/App.scss'

export const PrivacyContent = () => (
  <div>
    <div
      style={{
        marginLeft: '2em',
      }}
    >
      <h1>POLITIQUE DE CONFIDENTIALITÉ DES DONNÉES</h1>
      <h2>PREAMBULE</h2>
      <p>
        Avant toute utilisation du site internet de Whire accessible à l’adresse{' '}
        <a href="https://www.whire.me/">www.whire.me</a> (ci-après «le Site»),
        vous vous engagez à accepter sans réserve la collecte et l’utilisation
        devos données personnelles (ci-après «Données) suivant les modalités
        décrites dans laprésente Politique de confidentialité des données
        personnelles (ci-après «Politique deconfidentialité»).
        <br></br>
        <br></br>
        En cas de révision, la présente politique de confidentialité sera mise à
        jour sur le Site. Vous êtes donc invité à venir la consulter
        régulièrement.
        <br></br>
        <br></br>
        Une validation des modifications de la présente sera demandée aux
        Utilisateurs (défini <a href="/cgu">ici</a>)lors de leur connexion à leur
        Compte d’utilisateur.
        <br></br>
        <br></br>
        En sa qualité de responsable de traitement, Whire porte toute son
        attention au respect de votrevie privée et de la confidentialité de vos
        Données et à préserver leur sécurité et leur intégrité.Whire s'oblige à
        collecter et traiter vos Données conformément aux lois et
        règlementsapplicables et notamment au{' '}
        <a href="https://eur-lex.europa.eu/legal-content/FR/TXT/?uri=CELEX:32016R0679">
          Règlement européen sur la protection des données personnelles du 27
          avril 2016
        </a>{' '}
        et à{' '}
        <a href="https://www.legifrance.gouv.fr/affichTexte.do?cidTexte=JORFTEXT000000886460">
          la loi Informatique et libertés du 6 janvier 1978 modifiée
        </a>
        .{' '}
      </p>

      <h2>Sommaire</h2>
      <br></br>
      <h3>DÉFINITIONS</h3>
      <p>
        Candidat : Utilisateur personne physique à la recherche d’un emploi ou
        d’une opportunité professionnelle sur le Site. <br></br> <br></br>
        CVthèque : ensemble des Pages Candidat associées à une Page Organisme
        Intermédiaire. <br></br>
        <br></br>
        Page : espace sur le Site où chaque Utilisateur peut écrire, répondre à
        des questions, entreposer des photos ou des vidéos ou des liens
        hypertextes. Il existe une Page spécifique pour les Candidats, les
        Organismes Intermédiaires et les Employeurs. <br></br> <br></br>
        Organisme Intermédiaire : Utilisateur personne morale inscrit en tant que
        structure de formation, d’accompagnement ou d’insertion professionnelle.
        Pour être inscrits, les Organismes intermédiaires doivent avoir souscrit
        auprès de Whire un contrat d’abonnement. <br></br> <br></br>
        Employeur : Utilisateur personne morale inscrit sur le Site en vue de
        déposer des offres d’emploi ou de promouvoir ses métiers, sa structure
        dans la perspective d’un recrutement. Pour être inscrits, les Employeurs
        doivent avoir souscrit auprès de Whire un contrat d’abonnement. <br></br>{' '}
        <br></br>
        Services : ensemble des services mis à la disposition des Utilisateurs sur
        le Site. <br></br> <br></br>
        Site : site internet accessible à l’adresse{' '}
        <a href="https://www.whire.me/">www.whire.me</a>. <br></br> <br></br>
        Utilisateur : toute personne physique ou morale ayant adhéré aux présentes{' '}
        <a href="/cgu">conditions générales d’utilisation</a> et à la politique de
        confidentialité des données et ayant ouvert un Compte d’utilisateur en
        tant que Candidat, Organisme ou Employeur. <br></br> <br></br>
        Visiteur : tout Internaute consultant le Site et pouvant accéder à
        certaines fonctionnalités en tant que visiteur. <br></br> <br></br>
        Whire : société par actions simplifiée au capital de 1.000 € immatriculée
        sous le numéro 822 000 055 au RCS de PARIS, dont le siège social est situé
        32 rue de la Solidarité – 75019 PARIS et représentée par son Président,
        Monsieur Kayoum Saïd Abdallah Fane.{' '}
      </p>
      <h3>ARTICLE 1ER – CHAMP D’APPLICATION ET EXCLUSION</h3>
      <p>
        La présente politique de confidentialité s’applique à l’ensemble des
        traitements de données àcaractère personnel effectués par Whire à partir
        de son site Internet <a href="https://www.whire.me/">www.whire.me</a>.
        <br></br> <br></br>A contrario, elle ne s’applique pas aux traitements
        réalisés sur des sites Internet ouapplications de tiers pour lesquels
        Whire n’est pas responsable de traitement, et ceci même silesdits sites ou
        applications sont mentionnés sur le site de Whire.
      </p>
      <h3>ARTICLE 2 – COLLECTE DES DONNÉES </h3>
      <p>
        Les Données concernées par la présente politique de confidentialité sont
        les « données personnelles » ou « données à caractère personnel » au sens
        de l’article 4 du RGPD, c’est-à-dire toute information permettant
        d’identifier directement ou non une personne physique, telle que nom,
        prénom, âge, adresse IP, adresse électronique, données de localisation,
        éléments propres à son identité physique, physiologique, génétique,
        psychique, économique, culturelle ou sociale…
        <br></br> <br></br>
        Ainsi, vos diplômes, titres, formations, vos expériences professionnelles,
        vos goûts et centres d’intérêts sont des données personnelles au sens du
        Règlement précité.{' '}
      </p>
      <h4>Article 2.1 – Données collectées directement</h4>
      <p>
        Il s’agit des Données que vous nous fournissez directement soit par le
        biais de formulaires et de questionnaires que Whire vous propose de
        remplir sur son Site (par exemple, au moment de la création de votre
        Compte d’Utilisateur), soit en publiant ou téléchargeant du contenu sur
        votre Page (CV, lettres de motivation, témoignages, vidéos, liens
        hypertextes) mis en ligne sur le site de Whire.{' '}
      </p>
      <ul>
        <li>
          Données fournies lors de la création de votre Compte d’Utilisateur
          (obligatoire)
        </li>
        <ul>
          <p>Courrier électronique</p>
          <p>Mot de passe</p>
        </ul>
        <li>
          Données fournies lors de la création de votre Profil public
          (obligatoire)
        </li>
        <ul>
          <p>Prénom et nom de famille</p>
        </ul>
        <li>Données complémentaires (facultatif)</li>
        <p>
          Il s’agit des Données contenues dans les textes, photos ou vidéos, liens
          hypertextes que vous partagez en remplissant les questionnaires proposés
          par le Site comme de celles que vous publiez spontanément sur votre
          Page. Ces informations ne sont pas obligatoires mais vous permettent de
          profiter au mieux de nos Services.{' '}
        </p>
      </ul>
      <h4>Article 2.2 – Données collectées indirectement</h4>
      <ul>
        <li>Données de navigation</li>
        <ul>
          <p>
            Il s’agit des données que Whire collecte à partir de votre navigation
            sur le site Internet <a href="https://www.whire.me/">www.whire.me</a>{' '}
            :
          </p>
          <ul style={{ marginLeft: '2em' }}>
            <li>la date, l’heure de la connexion et/ou navigation </li>
            <li>le type de navigateur </li>
            <li>la langue du navigateur</li>
            <li>URL consultée</li>
            <li>l’adresse de protocole Internet et l’adresse IP</li>
            <li>le terminal utilisé</li>
          </ul>
        </ul>
        <li>
          Données partagées par les Employeurs et par les Organismes
          Intermédiaires
        </li>
        <ul>
          <p>
            Lorsque vous postulez à une offre d’emploi à partir du Site,
            l’Employeur nous fournit des données sur l’annonce à laquelle vous
            postulez. Lorsque vous adhérez à un Organisme Intermédiaire à partir
            du Site, cet Organisme est également susceptible de nous fournir des
            données.
            <br></br>
            <br></br>
            Nos Services étant dynamiques, nous proposons souvent de nouvelles
            fonctionnalités qui peuvent nécessiter la collecte de nouvelles
            informations. Si nous devions collecter des données personnelles d’une
            nature substantiellement différente ou modifier considérablement notre
            utilisation de vos données, nous vous en informerions et modifierions
            également la présente politique de confidentialité.
          </p>
        </ul>
      </ul>
      <h4>Article 2.3 – Cookies</h4>
      <p>
        Les cookies sont des fichiers stockés sur votre ordinateur par les sites
        web que vous visitez et qui contiennent des informations telles que vos
        préférences de navigation ou votre statut de connexion.
        <br></br>
        <br></br>
        En utilisant le Site, vous reconnaissez avoir lu et accepté la « 
        <a href="/charte-cookies">Charte d’utilisation des Cookies</a> »
        qui permet de comprendre, plus précisément, ce qu’est un Cookie, à quoi il
        sert et comment on peut le paramétrer.
      </p>
      <h3>ARTICLE 3 – BASES LÉGALES ET FINALITÉS DU TRAITEMENT DES DONNÉES</h3>
      <p>
        Whire collecte et traite vos Données pour fournir et personnaliser les
        services proposés sur son Site et répondre au mieux à l’attente de ses
        Utilisateurs.
        <br></br>
        <br></br>
        Plus particulièrement, le traitement des Données visées à l’article 2 a
        pour finalités :{' '}
      </p>
      <li>
        de vous permettre de créer et gérer votre Compte d’utilisateur et votre
        Page Candidat
      </li>
      <li>
        de vous proposer des offres d’emploi susceptibles de vous intéresser
      </li>
      <li>
        de vous mettre en relation avec des Employeurs et des Organismes
        Intermédiaires, ce qui implique notamment la transmission aux Employeurs
        et aux Organismes Intermédiaires des informations contenues sur votre Page
        Candidat{' '}
      </li>
      <li>
        et, plus généralement, de vous fournir les services et informations
        sollicitées
      </li>
      <p>
        Dans le but légitime pour Whire de développer ses relations avec les
        Utilisateurs, prospects et partenaires, le traitement des Données visées à
        l’article 2 a également pour finalités :{' '}
      </p>
      <li>l’envoi d’informations et de newsletters</li>
      <li>
        l’invitation à des évènements organisés par Whire ou par ses partenaires
      </li>
      <li>la promotion et la publicité de ses Services.</li>
      <p>
        Enfin, dans le but légitime pour Whire d’assurer la sécurité du Site et
        d’améliorer son fonctionnement, le traitement des Données visées à
        l’article 2 permet :
      </p>
      <li>de procéder à des calculs statistiques anonymisés</li>
      <li>
        de vous proposer une utilisation optimisée de ses Services entre vos
        différents appareils (ordinateur, tablette, smartphone…)
      </li>
      <li>
        d’examiner les réclamations, problèmes, bugs générés par le Site, afin de
        les résoudre
      </li>
      <li>
        de s’assurer que l’utilisation du site est conforme aux conditions
        générales d’utilisation (accessible <a href="/cgu">ici</a>)
      </li>
      <li>de détecter d’éventuels abus et actes de malveillance informatiques</li>
      <p>
        Conformément au RGPD, le traitement de vos données repose sur les bases
        légales suivantes :{' '}
      </p>
      <li>
        l’exécution du contrat né de l’acceptation des conditions générales
        d’utilisation. Par exemple, vos Données sont traitées afin que nous
        puissions vous proposer des offres d’emploi adaptées à votre profil.
      </li>
      <li>
        votre consentement dans les hypothèses où il est spécialement requis (par
        exemple, lorsque vous manifestez la volonté de rejoindre la CVthèque d’un
        Organisme Intermédiaire ou lorsque vous acceptez de recevoir nos
        newsletters)
      </li>
      <li>
        l’intérêt légitime de Whire (vos Données peuvent être traitées pour nous
        permettre de prévenir ou de détecter une fraude informatique).
      </li>
      <br></br>
      <br></br>
      <h3>ARTICLE 4 – DESTINATAIRES DES DONNÉES</h3>
      <p>
        Vos Données sont réservées à l’usage exclusif de Whire et ne peuvent être
        communiquées, même à titre gratuit, à des tiers.
        <br></br>
        <br></br>
        Toutefois, Whire peut être amenée à transférer tout ou partie de ces
        Données à des tiers de confiance qui les traitent pour son compte, selon
        ses instructions, conformément à la présente Politique de confidentialité
        et dans le respect du RGPD et de la loi Informatique et Libertés.
        <br></br>
        <br></br>
        Dans cette hypothèse, le transfert de vos Données est encadré par un
        contrat de transfert fondé sur les Clauses Contractuelles Types de la
        Commission Européenne.
        <br></br>
        <br></br>
        Whire ne partage pas vos Données avec des tiers dans un objectif
        publicitaire ou marketing.{' '}
      </p>
      <h4>Données que vous partagez</h4>
      <p>
        En partageant votre Page depuis votre Compte d’Utilisateur, vous consentez
        à partager vos Données avec votre réseau. En publiant votre Page Candidat,
        vous consentez à partager vos Données avec les Employeurs et les
        Organismes Intermédiaires inscrits sur le Site et tout visiteur du Site Whire. En répondant à une offre
        d’emploi sur le Site, vous consentez à partager les Données de votre Page
        Candidat avec l’Employeur.{' '}
      </p>
      <br></br>
      <h4>
        Association de votre Page Candidat à celle d’un Organisme Intermédiaire :
      </h4>
      <p>
        En invitant un Organisme Intermédiaire à vous ajouter ou en acceptant une
        demande d’ajout d’un Organisme Intermédiaire, vous consentez à partager
        les Données de votre Page avec cet Organisme et à intégrer sa CVthèque.
        Votre ajout ne sera effectif qu’une fois que l’Organisme Intermédiaire
        aura validé votre invitation et que lorsque vous aurez pris connaissance
        et adhéré à la Politique de confidentialité des données de l’Organisme
        Intermédiaire
      </p>
      <br></br>
      <br></br>
      <h3>>ARTICLE 5 – DURÉE DE CONSERVATION DES DONNÉES</h3>
      <p>
        Whire ne conserve vos Données que pour la durée strictement nécessaire aux
        traitements visés à l’article 3 et au respect de ses obligations légales,
        dans les limites imposées par la loi.
        <br></br>
        <br></br>
        La durée de conservation de vos Données varie selon la finalité de leur
        traitement.
        <br></br>
        <br></br>
        La plupart de vos Données (par exemple, celles figurant sur votre Page
        Candidat) sont conservées pendant une durée de 5 ans à compter de votre
        dernière connexion à votre Compte d’utilisateur ou de votre dernière
        visite sur le Site.
        <br></br>
        <br></br>
        Vos données sont ensuite archivées avec un accès restreint pour une durée
        supplémentaire pour satisfaire à nos obligations légales puis elles sont
        anonymisées.
        <br></br>
        <br></br>
        Pour les autres traitements, les durées de conservation sont les
        suivantes :
      </p>
      <li>
        Pour la gestion des cookies, le consentement est exprès et la durée de
        conservation en base active est de 13 mois à compter du dépôt des cookies
      </li>
      <li>
        Pour la gestion de la newsletter, l’intérêt de Whire est légitime et la
        durée de conservation en base active est de 3 ans à compter de la dernière
        activité
      </li>
      <br></br>
      <h3>ARTICLE 6 – SÉCURITÉ DE VOS DONNÉES</h3>
      <p>
        Whire s’attache à conserver les données à caractère personnel qu’elle
        traite de manière sécurisée et uniquement pendant la durée nécessaire à la
        réalisation de la finalité poursuivie par le traitement.
        <br></br>
        <br></br>
        Dans cette perspective, Whire prend les mesures physiques, techniques et
        organisationnelles appropriées pour prévenir et empêcher, dans toute la
        mesure du possible, toute altération, perte de vos données ou tout accès
        non autorisé à celles-ci.
        <br></br>
        <br></br>
        Si Whire apprenait qu’un tiers auquel elle a communiqué vos Données dans
        les conditions prévues à l’article 4, les utilise ou les divulgue en
        violation de la présente politique de confidentialité ou en violation de
        ses engagements ou des lois et réglements applicables, elle prendrait
        toute mesure pour mettre fin immédiatement à une telle utilisation.
        <br></br>
        <br></br>
        Vous êtes invité à contribuer à la protection de vos Données en veillant à
        la sécurité de vos identifiant et mot de passe et au respect des
        conditions générales d’utilisation (accessible <a href="/cgu">ici</a>).
      </p>
      <h3>
        ARTICLE 7 – DROITS D’ACCÈS, DE RECTIFICATION ET DE SUPPRESSION DE VOS
        DONNÉES{' '}
      </h3>
      <p>
        Conformément au droit applicable, vous disposez d’un droit d’accès, de
        rectification, de limitation et de suppression de vos Données.
        <br></br>
        <br></br>
        Plus précisément, vous pouvez :{' '}
      </p>
      <li>
        faire rectifier, compléter, mettre à jour ou effacer vos Données
        inexactes, incomplètes ou périmées
      </li>
      <li>
        solliciter la limitation du traitement de vos données pour un motif
        légitime
      </li>
      <li>vous opposer pour un motif légitime au traitement de vos Données</li>
      <li>solliciter la suppression de vos Données</li>
      <p>
        Vous pouvez également à tout moment et sans motif, vous opposer au
        traitement de vos Données à des fins de communication.
        <br></br>
        <br></br>
        Vous pouvez exercer vos droits :
      </p>
      <li>
        soit depuis votre Page sur le Site si vous possédez un Compte
        d’Utilisateur
      </li>
      <li>
        soit par courrier électronique :{' '}
        <a href="kayoum@whire.me">kayoum@whire.me</a>
      </li>
      <li>
        soit par courrier postal : Whire SAS – 32, rue de la Solidarité – 75019
        PARIS
      </li>
      <br></br>
      <p>Whire s’engage à traiter votre demande dans les plus brefs délais.</p>
      <h3>ARTICLE 8 – RÉCLAMATIONS</h3>
      <p>
        Si vous pensez que la sécurité de vos Données a été compromise ou que ces
        Données ont fait l’objet d’une utilisation abusive, vous êtes invité à
        contacter Whire dans les plus brefs délais, par courriel (
        <a href="kayoum@whire.me">kayoum@whire.me</a>) ou par voie postale : 32,
        rue de la Solidarité – 75019 PARIS.
        <br></br>
        <br></br>
        Whire instruira votre réclamation dans les plus brefs délais et tentera de
        trouver une solution conformément à la réglementation en vigueur.
        <br></br>
        <br></br>
        Vous êtes informé que vous pouvez à tout moment introduire une réclamation
        auprès de la CNIL, autorité de contrôle en charge du respect des
        obligations en matière de protection des données personnelles directement
        sur le site internet de la CNIL (
        <a href="https://www.cnil.fr">https://www.cnil.fr</a>) ou par courrier : 3
        Place de Fontenoy, TSA 807125 – 75334 PARIS CEDEX 07.
      </p>
      <h3>ARTICLE 9 – MODIFICATION DE LA POLITIQUE DE CONFIDENTIALITÉ</h3>
      <p>
        Whire se réserve le droit de modifier ou de compléter la présente
        Politique de confidentialité à tout moment, notamment pour s’adapter à
        l’évolution de la réglementation. Les Utilisateurs seront avertis par
        courriel des ces modifications et leur consentement sera requis pour
        qu’elles leur deviennent applicables. En cas de refus de ces
        modifications, les Utilisateurs ne pourront plus utiliser accéder à leur
        Compte d’Utilisateur et bénéficier des services proposés sur le Site.{' '}
      </p>
    </div>
    <br></br>
    <br></br>
    <div
      style={{
        marginLeft: '2em',
      }}
    >
      <h1>Politique de Confidentialité liée à l’intelligence artificielle</h1>
      <p>
        Whire (ci-après "nous", "notre" ou "Whire") attache une grande importance à la protection de vos données personnelles et s’engage à respecter votre vie privée conformément à la réglementation en vigueur, notamment le Règlement Général sur la Protection des Données (RGPD). Cette Politique de Confidentialité vise à vous informer de la manière dont nous collectons, utilisons, partageons et protégeons vos données personnelles lorsque vous utilisez notre site et nos services.
      </p>

      <h2>1. Responsable du Traitement des Données</h2>
      <p>
        Whire, société par actions simplifiée au capital de 1.000 € immatriculée sous le numéro 822 000 055 au RCS de PARIS, dont le siège social est situé 32 rue de la Solidarité – 75019 PARIS et représentée par son Président, Monsieur Kayoum Saïd Abdallah Fane, est responsable du traitement de vos données personnelles.
      </p>

      <h2>2. Données Personnelles Collectées</h2>
      <p>
        Dans le cadre de l’utilisation de nos services, nous collectons et traitons les données suivantes :
        <ul>
          <li><strong>Données d'identification</strong> : nom, prénom, adresse e-mail, adresse, numéro de téléphone.</li>
          <li><strong>Données professionnelles</strong> : informations figurant sur votre CV, sur votre page Whire (projet professionnel, parcours professionnel, qualification, compétences, qualités, projets, intérêts).</li>
          <li><strong>Contenu généré par l'utilisateur</strong> : textes que vous soumettez via nos outils, tels que les lettres de motivation, les corrections orthographiques ou les pitchs d'entretien.</li>
          <li><strong>Données techniques</strong> : adresse IP (Protocole Internet), données de connexion, navigateur utilisé.</li>
        </ul>
      </p>

      <h2>3. Finalités et Bases Légales du Traitement</h2>
      <p>
        Les données personnelles collectées sont traitées pour les finalités suivantes :
        <ul>
          <li><strong>Chatbot de motivation</strong> : aider les utilisateurs à exprimer leur motivation pour un projet professionnel via des échanges avec un chatbot basé sur l’API OpenAI. <em>Base légale</em> : Exécution d’un contrat (article 6.1.b RGPD).</li>
          <li><strong>Chatbot stories</strong> : aider les utilisateurs à raconter une expérience professionnelle, un projet ou un intérêt via des échanges avec un chatbot basé sur l’API OpenAI. <em>Base légale</em> : Exécution d’un contrat (article 6.1.b RGPD).</li>
          <li><strong>Générateur de lettre de motivation</strong> : produire une lettre de motivation personnalisée à partir du CV et des informations de la page Whire. <em>Base légale</em> : Exécution d’un contrat (article 6.1.b RGPD).</li>
          <li><strong>Correcteur orthographique</strong> : traiter et corriger les erreurs linguistiques des textes soumis par les utilisateurs à l’aide de l’API OpenAI. <em>Base légale</em> : Exécution d’un contrat (article 6.1.b RGPD).</li>
          <li><strong>Générateur de pitch d’entretien</strong> : générer des questions de recruteurs et des réponses suggérées en fonction des informations contenues dans le CV et la page Whire. <em>Base légale</em> : Exécution d’un contrat (article 6.1.b RGPD).</li>
          <li><strong>Réutilisation des conversations à des fins d’entraînement de l’IA</strong> : améliorer les performances et la qualité de nos modèles d'IA en réutilisant les conversations anonymisées du chatbot. <em>Base légale</em> : Intérêt légitime (article 6.1.f RGPD) ou Consentement (article 6.1.a RGPD), selon le niveau d'anonymisation appliqué.</li>
        </ul>
      </p>

      <h2>4. Utilisation des API OpenAI</h2>
      <p>
        Nos services utilisent l’API OpenAI pour traiter les données que vous soumettez. Les textes que vous fournissez (comme les CV, les lettres de motivation, et les informations de la page Whire) sont temporairement envoyés à OpenAI pour traitement. Nous avons signé un <strong>Data Processing Agreement (DPA)</strong> avec OpenAI afin de garantir la conformité avec le RGPD et assurer la protection de vos données.
      </p>

      <h2>5. Partage de Données Personnelles</h2>
      <p>
        Nous ne partageons vos données personnelles qu’avec :
        <ul>
          <li><strong>Nos sous-traitants</strong> : tels que OpenAI, qui traite les données pour notre compte, conformément au DPA signé.</li>
          <li><strong>Autorités compétentes</strong> : si cela est requis par la loi ou dans le cadre de procédures judiciaires.</li>
        </ul>
        Aucun transfert de vos données personnelles en dehors de l’Espace Économique Européen (EEE) n’a lieu sans garantie de protection adéquate conformément au RGPD.
      </p>

      <h2>6. Durée de Conservation des Données</h2>
      <p>
        Nous conservons vos données personnelles uniquement pendant la durée nécessaire aux finalités pour lesquelles elles ont été collectées, à savoir :
        <ul>
          <li><strong>Données liées à la génération de lettres de motivation et pitchs d’entretien</strong> : conservées pendant 1 an après la dernière interaction avec notre service.</li>
          <li><strong>Données liées aux chatbots et au correcteur orthographique</strong> : conservées pendant 6 mois après la dernière interaction avec le service.</li>
        </ul>
      </p>

      <h2>7. Sécurité des Données</h2>
      <p>
        Nous mettons en œuvre des mesures techniques et organisationnelles appropriées pour garantir la sécurité et la confidentialité de vos données personnelles, notamment pour éviter tout accès non autorisé, altération ou perte.
      </p>

      <h2>8. Vos Droits</h2>
      <p>
        Conformément au RGPD, vous disposez des droits suivants concernant vos données personnelles :
        <ul>
          <li><strong>Droit d’accès</strong> : vous pouvez demander à consulter les données que nous détenons à votre sujet.</li>
          <li><strong>Droit de rectification</strong> : vous pouvez demander à corriger des données inexactes ou incomplètes.</li>
          <li><strong>Droit à l’effacement</strong> : vous pouvez demander la suppression de vos données sous certaines conditions.</li>
          <li><strong>Droit à la limitation</strong> : vous pouvez demander la suspension temporaire du traitement de vos données.</li>
          <li><strong>Droit d’opposition</strong> : vous pouvez vous opposer au traitement de vos données dans certaines situations.</li>
          <li><strong>Droit à la portabilité</strong> : vous pouvez demander à recevoir vos données dans un format structuré et lisible par machine.</li>
        </ul>
        Pour exercer ces droits, vous pouvez nous contacter à l’adresse suivante : <a href="mailto:tech@whire.me">tech@whire.me</a>
      </p>

      <h2>9. Modifications de la Politique de Confidentialité</h2>
      <p>
        Nous nous réservons le droit de modifier cette Politique de Confidentialité à tout moment afin de nous conformer aux évolutions législatives ou réglementaires. Nous publierons toute modification importante de la présente Politique de confidentialité par le biais de notifications appropriées sur notre site internet, ou en vous contactant par d’autres canaux de communication.
      </p>

      <h2>10. Réutilisation des Données à des Fins de Formation de l’IA</h2>
      <p>
        Dans le cadre de notre démarche d'amélioration continue, les conversations échangées avec nos <strong>chatbots</strong> peuvent être réutilisées à des fins de <strong>formation et d'amélioration de l'intelligence artificielle</strong>. Ces conversations sont <strong>entièrement anonymisées</strong> avant d'être réutilisées, garantissant qu'aucune information permettant d’identifier une personne ne soit utilisée.
        Si vous ne souhaitez pas que vos conversations soient réutilisées à ces fins, vous pouvez exercer votre droit d’opposition à tout moment en nous contactant à l'adresse suivante : <a href="mailto:tech@whire.me">tech@whire.me</a>
      </p>

      <h2>11. Contact</h2>
      <p>
        Pour toute question concernant cette Politique de Confidentialité ou sur la manière dont nous traitons vos données personnelles, vous pouvez nous contacter à l’adresse suivante :
      </p>
      <p><strong>Whire</strong></p>
      <p>32 rue de la solidarité, 75019 Paris</p>
      <p><a href="mailto:tech@whire.me">tech@whire.me</a></p>
    </div>
  </div>
)

export default () => {
  return (
    <div className="App">
      <UikContainerVertical>
        <TextWidget
          content={{
            pages: 'Privacy',
          }}
        />
      </UikContainerVertical>
    </div>
  )
}
