import React from 'react';

import TextWidget from 'components/TextWidget';
import UikContainerVertical from 'uikit/containers/UikContainerVertical';
import '../initStyles/App.scss';

export const PrivacyAIContent = () => (
  <div
    style={{
      marginLeft: '2em',
    }}
  >
    <h1>Politique de Confidentialité liée à l’intelligence artificielle</h1>
    <p>
      Whire (ci-après "nous", "notre" ou "Whire") attache une grande importance à la protection de vos données personnelles et s’engage à respecter votre vie privée conformément à la réglementation en vigueur, notamment le Règlement Général sur la Protection des Données (RGPD). Cette Politique de Confidentialité vise à vous informer de la manière dont nous collectons, utilisons, partageons et protégeons vos données personnelles lorsque vous utilisez notre site et nos services.
    </p>

    <h2>1. Responsable du Traitement des Données</h2>
    <p>
      Whire, société par actions simplifiée au capital de 1.000 € immatriculée sous le numéro 822 000 055 au RCS de PARIS, dont le siège social est situé 32 rue de la Solidarité – 75019 PARIS et représentée par son Président, Monsieur Kayoum Saïd Abdallah Fane, est responsable du traitement de vos données personnelles.
    </p>

    <h2>2. Données Personnelles Collectées</h2>
    <p>
      Dans le cadre de l’utilisation de nos services, nous collectons et traitons les données suivantes :
      <ul>
        <li><strong>Données d'identification</strong> : nom, prénom, adresse e-mail, adresse, numéro de téléphone.</li>
        <li><strong>Données professionnelles</strong> : informations figurant sur votre CV, sur votre page Whire (projet professionnel, parcours professionnel, qualification, compétences, qualités, projets, intérêts).</li>
        <li><strong>Contenu généré par l'utilisateur</strong> : textes que vous soumettez via nos outils, tels que les lettres de motivation, les corrections orthographiques ou les pitchs d'entretien.</li>
        <li><strong>Données techniques</strong> : adresse IP (Protocole Internet), données de connexion, navigateur utilisé.</li>
      </ul>
    </p>

    <h2>3. Finalités et Bases Légales du Traitement</h2>
    <p>
      Les données personnelles collectées sont traitées pour les finalités suivantes :
      <ul>
        <li><strong>Chatbot de motivation</strong> : aider les utilisateurs à exprimer leur motivation pour un projet professionnel via des échanges avec un chatbot basé sur l’API OpenAI. <em>Base légale</em> : Exécution d’un contrat (article 6.1.b RGPD).</li>
        <li><strong>Chatbot stories</strong> : aider les utilisateurs à raconter une expérience professionnelle, un projet ou un intérêt via des échanges avec un chatbot basé sur l’API OpenAI. <em>Base légale</em> : Exécution d’un contrat (article 6.1.b RGPD).</li>
        <li><strong>Générateur de lettre de motivation</strong> : produire une lettre de motivation personnalisée à partir du CV et des informations de la page Whire. <em>Base légale</em> : Exécution d’un contrat (article 6.1.b RGPD).</li>
        <li><strong>Correcteur orthographique</strong> : traiter et corriger les erreurs linguistiques des textes soumis par les utilisateurs à l’aide de l’API OpenAI. <em>Base légale</em> : Exécution d’un contrat (article 6.1.b RGPD).</li>
        <li><strong>Générateur de pitch d’entretien</strong> : générer des questions de recruteurs et des réponses suggérées en fonction des informations contenues dans le CV et la page Whire. <em>Base légale</em> : Exécution d’un contrat (article 6.1.b RGPD).</li>
        <li><strong>Réutilisation des conversations à des fins d’entraînement de l’IA</strong> : améliorer les performances et la qualité de nos modèles d'IA en réutilisant les conversations anonymisées du chatbot. <em>Base légale</em> : Intérêt légitime (article 6.1.f RGPD) ou Consentement (article 6.1.a RGPD), selon le niveau d'anonymisation appliqué.</li>
      </ul>
    </p>

    <h2>4. Utilisation des API OpenAI</h2>
    <p>
      Nos services utilisent l’API OpenAI pour traiter les données que vous soumettez. Les textes que vous fournissez (comme les CV, les lettres de motivation, et les informations de la page Whire) sont temporairement envoyés à OpenAI pour traitement. Nous avons signé un <strong>Data Processing Agreement (DPA)</strong> avec OpenAI afin de garantir la conformité avec le RGPD et assurer la protection de vos données.
    </p>

    <h2>5. Partage de Données Personnelles</h2>
    <p>
      Nous ne partageons vos données personnelles qu’avec :
      <ul>
        <li><strong>Nos sous-traitants</strong> : tels que OpenAI, qui traite les données pour notre compte, conformément au DPA signé.</li>
        <li><strong>Autorités compétentes</strong> : si cela est requis par la loi ou dans le cadre de procédures judiciaires.</li>
      </ul>
      Aucun transfert de vos données personnelles en dehors de l’Espace Économique Européen (EEE) n’a lieu sans garantie de protection adéquate conformément au RGPD.
    </p>

    <h2>6. Durée de Conservation des Données</h2>
    <p>
      Nous conservons vos données personnelles uniquement pendant la durée nécessaire aux finalités pour lesquelles elles ont été collectées, à savoir :
      <ul>
        <li><strong>Données liées à la génération de lettres de motivation et pitchs d’entretien</strong> : conservées pendant 1 an après la dernière interaction avec notre service.</li>
        <li><strong>Données liées aux chatbots et au correcteur orthographique</strong> : conservées pendant 6 mois après la dernière interaction avec le service.</li>
      </ul>
    </p>

    <h2>7. Sécurité des Données</h2>
    <p>
      Nous mettons en œuvre des mesures techniques et organisationnelles appropriées pour garantir la sécurité et la confidentialité de vos données personnelles, notamment pour éviter tout accès non autorisé, altération ou perte.
    </p>

    <h2>8. Vos Droits</h2>
    <p>
      Conformément au RGPD, vous disposez des droits suivants concernant vos données personnelles :
      <ul>
        <li><strong>Droit d’accès</strong> : vous pouvez demander à consulter les données que nous détenons à votre sujet.</li>
        <li><strong>Droit de rectification</strong> : vous pouvez demander à corriger des données inexactes ou incomplètes.</li>
        <li><strong>Droit à l’effacement</strong> : vous pouvez demander la suppression de vos données sous certaines conditions.</li>
        <li><strong>Droit à la limitation</strong> : vous pouvez demander la suspension temporaire du traitement de vos données.</li>
        <li><strong>Droit d’opposition</strong> : vous pouvez vous opposer au traitement de vos données dans certaines situations.</li>
        <li><strong>Droit à la portabilité</strong> : vous pouvez demander à recevoir vos données dans un format structuré et lisible par machine.</li>
      </ul>
      Pour exercer ces droits, vous pouvez nous contacter à l’adresse suivante : <a href="mailto:tech@whire.me">tech@whire.me</a>
    </p>

    <h2>9. Modifications de la Politique de Confidentialité</h2>
    <p>
      Nous nous réservons le droit de modifier cette Politique de Confidentialité à tout moment afin de nous conformer aux évolutions législatives ou réglementaires. Nous publierons toute modification importante de la présente Politique de confidentialité par le biais de notifications appropriées sur notre site internet, ou en vous contactant par d’autres canaux de communication.
    </p>

    <h2>10. Réutilisation des Données à des Fins de Formation de l’IA</h2>
    <p>
      Dans le cadre de notre démarche d'amélioration continue, les conversations échangées avec nos <strong>chatbots</strong> peuvent être réutilisées à des fins de <strong>formation et d'amélioration de l'intelligence artificielle</strong>. Ces conversations sont <strong>entièrement anonymisées</strong> avant d'être réutilisées, garantissant qu'aucune information permettant d’identifier une personne ne soit utilisée.
      Si vous ne souhaitez pas que vos conversations soient réutilisées à ces fins, vous pouvez exercer votre droit d’opposition à tout moment en nous contactant à l'adresse suivante : <a href="mailto:tech@whire.me">tech@whire.me</a>
    </p>

    <h2>11. Contact</h2>
    <p>
      Pour toute question concernant cette Politique de Confidentialité ou sur la manière dont nous traitons vos données personnelles, vous pouvez nous contacter à l’adresse suivante :
    </p>
    <p><strong>Whire</strong></p>
    <p>32 rue de la solidarité, 75019 Paris</p>
    <p><a href="mailto:tech@whire.me">tech@whire.me</a></p>
  </div>
);

export default () => {
  return (
    <div className="App">
      <UikContainerVertical>
        <TextWidget
          content={{
            pages: 'PrivacyAI',
          }}
        />
      </UikContainerVertical>
    </div>
  );
};
