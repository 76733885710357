import React from 'react'

import { UikButton } from 'uikit/components'
import PropTypes from 'prop-types'

const ImagePreview = ({
  deleteItem,
  src,
  swapBottom,
  swapTop,
  showBottomArrow,
  showTopArrow,
}) => (
  <div
    style={{
      border: '1px solid #FBFBFB',
      margin: '8px 0',
      padding: '8px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      verticalAlign: 'middle',
    }}
  >
    <div
      style={{
        width: '74px',
        height: '56px',
        backgroundImage: `url(${src})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        backgroundSize: 'cover',
      }}
    />
    {/* <img alt="" src={src} width="34px" height="34px" /> */}
    <div>
      {showTopArrow && (
        <UikButton xs onClick={swapTop} style={{ marginLeft: '6px' }}>
          <span role="img" aria-label="move right">
            ↑
          </span>
        </UikButton>
      )}
      {showBottomArrow && (
        <UikButton xs style={{ marginLeft: '6px' }} onClick={swapBottom}>
          <span role="img" aria-label="move left">
            ↓
          </span>
        </UikButton>
      )}
      <UikButton xs onClick={deleteItem} style={{ marginLeft: '6px' }}>
        <span role="img" aria-label="delete">
          🗑
        </span>
      </UikButton>
    </div>
  </div>
)

ImagePreview.propTypes = {
  deleteItem: PropTypes.func.isRequired,
  src: PropTypes.string.isRequired,
  swapBottom: PropTypes.func.isRequired,
  swapTop: PropTypes.func.isRequired,
  showBottomArrow: PropTypes.bool,
  showTopArrow: PropTypes.bool,
}

ImagePreview.defaultProps = {
  showBottomArrow: false,
  showTopArrow: false,
}

export default ImagePreview
