import React from 'react'

import { UikButton } from 'uikit/components'
import { openDialog } from 'uploadcare-widget'

const Image = (props) => {
  const handleUpload = (form) => {
    openDialog(null, {
      imagesOnly: true,
      crop: '4:4',
    }).done((file) => {
      file.promise().done((fileInfo) => {
        props.liftImageProps({
          image: fileInfo.uuid,
          imageModifier: fileInfo.cdnUrlModifiers,
        })
      })
    })
  }
  return (
    <div style={{ marginTop: '20px' }}>
      <div>
        {
          //props.field.preview && props.value.length ? (
          props.field.preview && props.value ? (
            <img
              alt="Your profile pic"
              src={`https://ucarecdn.com/${props.value}`}
              height="90px"
              width="90px"
            />
          ) : null
        }
      </div>
      {
        //props.value.length ? (
        props.value ? (
          <UikButton
            onClick={() => {
              return props.liftImageProps({
                image: '',
                imageModifier: '',
              })
            }}
          >
            {props.textDropImage}
          </UikButton>
        ) : (
          <UikButton success onClick={() => handleUpload(props)}>
            {props.textAddImage}
          </UikButton>
        )
      }
    </div>
  )
}

export default Image
