// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EIQArJby4lN6BoomcGAP{margin:0 15px;padding-top:30px}@media screen and (min-width: 768px){.EIQArJby4lN6BoomcGAP{padding-top:100px}}", "",{"version":3,"sources":["webpack://./src/modules/Thread/ThreadContent/thread-content.module.scss"],"names":[],"mappings":"AAEA,sBACE,aAAA,CACA,gBAAA,CAGF,qCACE,sBACE,iBAAA,CAAA","sourcesContent":["@import \"src/initStyles/_vars.scss\";\n\n.wrapper {\n  margin: 0 15px;\n  padding-top: 30px;\n}\n\n@media screen and (min-width: 768px) {\n  .wrapper {\n    padding-top: 100px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "EIQArJby4lN6BoomcGAP"
};
export default ___CSS_LOADER_EXPORT___;
