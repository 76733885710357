import React from 'react'
import AsyncCreatableSelect from 'react-select/async-creatable'

import { connectAutoComplete } from 'react-instantsearch-dom'

import cls from './asyncCreatableSelectInterest.module.scss'

// const filteredOptions = (array, inputValue) => array.filter(i =>
//   i.value.includes(inputValue.toLowerCase())
// );

const mapOptions = (array) =>
  array.map((e) => ({
    value: e.name,
    label: e.name,
    id: e.id,
    tagType: e.tagType,
  }))

const AsyncTagCreatableInterest = (props) => (
  <AsyncCreatableSelect
    className={cls.tagClass}
    isMulti
    // cacheOptions
    // closeMenuOnSelect={false}
    placeholder="Je sélectionne le(s) tag(s) qui me correspond(ent)"
    defaultOptions={mapOptions(props.hits)}
    value={props.currentSelectionInterest.filter(
      (current) => current.tagType === 'INTEREST'
    )}
    onChange={props.onChange}
    loadOptions={(value) => {
      props.refine(value)
      return new Promise((resolve) => {
        resolve(mapOptions(props.hits))
      })
    }}
  />
)

AsyncTagCreatableInterest.defaultProps = {
  currentSelectionInterest: [],
}

const AlgoliaAsyncCreatableInterest = connectAutoComplete(
  AsyncTagCreatableInterest
)

export default AlgoliaAsyncCreatableInterest
