import PropTypes from 'prop-types'

export const locationShape = PropTypes.shape({
  pathname: PropTypes.string.isRequired,
  search: PropTypes.string.isRequired,
  hash: PropTypes.string.isRequired,
  state: PropTypes.object,
})

export const historyShape = PropTypes.shape({
  push: PropTypes.func.isRequired,
})

export const artistShape = PropTypes.shape({
  title: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  theme: PropTypes.string.isRequired,
  imgUrl: PropTypes.string.isRequired,
  video: PropTypes.string.isRequired,
  song: PropTypes.string.isRequired,
  year: PropTypes.string.isRequired,
  lightOnPlay: PropTypes.string,
})
