import React from 'react'

import AsyncSelect from 'react-select/async'

import { connectAutoComplete } from 'react-instantsearch/connectors'

import cls from './asyncAllTag.scss'

const mapOptions = (array) =>
  array.map((e) => ({ value: e.name, label: e.name, id: e.id }))

const AsyncTagSelectAllTag = (props) => (
  <AsyncSelect
    className={cls.tagClass}
    isMulti
    // cacheOptions
    // closeMenuOnSelect={false}
    placeholder="Je tape des mots-clés qui correspondent à ma story"
    defaultOptions={mapOptions(props.hits)}
    value={props.currentSelection}
    onChange={props.onChange}
    loadOptions={(value) => {
      props.refine(value)
      return new Promise((resolve) => {
        resolve(mapOptions(props.hits))
      })
    }}
  />
)

AsyncTagSelectAllTag.defaultProps = {
  currentSelection: [],
}

const AlgoliaAsyncAllTag = connectAutoComplete(AsyncTagSelectAllTag)

export default AlgoliaAsyncAllTag
