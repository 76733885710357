import React, { createElement, PureComponent } from 'react'

import { Redirect } from 'react-router-dom'

import { USER_STORY_TYPE_ARRAY } from 'utils/vars'

import PublicProfile from './pages/PublicProfile'

// type PublicPagesType = {
//   navSections: Array,
//   match: {
//     params: {
//       appends: String,
//     }
//   }
// }

export default class PublicPages extends PureComponent {
  render() {
    const {
      match: {
        params: { appends },
      },
    } = this.props

    const userStoryTypeTransport = USER_STORY_TYPE_ARRAY.find(
      (e) => e.appends === appends
    )

    if (!userStoryTypeTransport) {
      return <Redirect to={`/${this.props.match.params[0]}`} />
    }

    return (
      <div>
        {appends === '' && (
          <div>{createElement(PublicProfile, { ...this.props })}</div>
        )}
      </div>
    )
  }
}
