import React, { useState, useContext } from 'react'
import { Link } from 'react-router-dom'
import LoginModal from 'containers/LoginModal'
import { UikButton } from '../../uikit/components'
import cls from './navLanding.scss'
import { SVG_URI } from '../../utils/vars'
import { StoreContext } from 'contexts/Store'


export function NavLanding() {
  const {
    navigationIsOpen: { navigationIsOpen, setNavigationIsOpen },
    ownPage: { ownPage },
    normalWidth: { normalWidth, setNormalWidth },
    navigationByTag: { navigationByTag, setNavigationByTag },
  } = useContext(StoreContext);
  const [visible, setVisible] = useState(false)
  const [aProposVisible, setAProposVisible] = useState(false)
  const [showLoginModal, setShowLoginModal] = useState(false)

  const handleVisible = () => {
    const burger = document.getElementsByClassName(cls.burgerMenu)[0]
    if (!visible) {
      setVisible(true)
      burger.classList.add(cls.active)
    } else {
      setVisible(false)
      burger.classList.remove(cls.active)
    }
  }

  const handleVisibleAPropos = () => {
    !aProposVisible ? setAProposVisible(true) : setAProposVisible(false)
  }

  const closeMenu = () => {
    if (visible) {
      setVisible(false)
      document
        .getElementsByClassName(cls.burgerMenu)[0]
        .classList.remove(cls.active)
    }
  }

  const closeAPropos = () => {
    aProposVisible && setAProposVisible(false)
  }

  const handleLoginModal = () => {
    if (!showLoginModal) {
      setShowLoginModal(true)
      document.getElementsByClassName(cls.listMenu)[0].classList.add(cls.hide)
    }
  }

  return (
    <>
      <nav className={cls.nav}>
        <ul className={cls.container__nav}>
          <li>
            <Link onClick={() => setNormalWidth(false)} to="/candidat">Candidat</Link>
          </li>
          <li>
            <Link onClick={() => setNormalWidth(false)} to="/org">Organisme Intermédiaire</Link>
          </li>
          <li>
            <Link onClick={() => setNormalWidth(false)} to="/academy">Whire Academy</Link>
          </li>
          <li>
            <Link onClick={() => setNormalWidth(false)} to="/entreprise">Employeur</Link>
          </li>
          {/*
                // Menu déroulant "A propos"

                <ul onClick={handleVisibleAPropos} className={cls.menuAPropos}>
                    <span>A propos</span>
                    {aProposVisible && <MenuAPropos />}
                </ul>
                */}
        </ul>
      </nav>
      <div onClick={handleVisible} className={cls.burgerMenu}>
        <span />
      </div>
      {visible && (
        <BurgerMenu
          handleModal={handleLoginModal}
          showLoginModal={showLoginModal}
        />
      )}
    </>
  )
}

const BurgerMenu = (props) => {
  return (
    <div>
      {!props.showLoginModal ? (
        <div className={cls.burger__listMenu}>
          <div className={cls.wrapper__burger_listMenu}>
            <div className={cls.logo}>
              <img src={`${SVG_URI}/Logo-whire_32x32.svg`} alt="Logo Whire" />
            </div>
            <div className={cls.wrapper__cta}>
              {window.location.pathname.startsWith('/candidat') ? (
                <UikButton
                  className={cls.registerButton__listMenu}
                  Component={Link}
                  to="/inscription"
                  success
                >
                  Créer ma page
                </UikButton>
              ) : window.location.pathname.startsWith('/org') ? (
                <UikButton
                  className={cls.registerButton__listMenu}
                  Component={Link}
                  to="/demo"
                  success
                >
                  Demander une démo
                </UikButton>
              ) : window.location.pathname.startsWith('/entreprise') ? (
                <UikButton
                  className={cls.registerButton__listMenu}
                  Component={Link}
                  to="/entreprise/rejoindreleclub"
                  success
                >
                  Rejoindre le club
                </UikButton>
              ) : (
                <UikButton
                  className={cls.registerButton__listMenu}
                  Component={Link}
                  to="/inscription"
                  success
                >
                  S'inscrire
                </UikButton>
              )}
              <UikButton
                className={cls.loginButton__listMenu}
                onClick={props.handleModal}
                success
              >
                Se connecter
                {props.showLoginModal && <LoginModal />}
              </UikButton>
            </div>
            <div className={cls.col__links}>
              <Link to="/candidat">Candidat</Link>
              {/*<Link to="/candidat/creermapage">Créer ma page Whire</Link>
                            <Link to="/candidat/whirrior">Devenir un Whirrior</Link>
                            <Link to="/candidat/stories">Belles histoires</Link>*/}
            </div>
            <div className={cls.col__links}>
              <Link to="/org">Organisme Intermédiaire</Link>
              {/*<Link to="/org/offre">Méthodologie</Link>
                            <Link to="/org/ecosystem">Ecosysteme</Link>
                            <Link to="/org/temoignage">Témoignage</Link>*/}
            </div>
            <div className={cls.col__links}>
              <Link to="/academy">Whire Academy</Link>
              {/*<Link to="/org/offre">Méthodologie</Link>
                            <Link to="/org/ecosystem">Ecosysteme</Link>
                            <Link to="/org/temoignage">Témoignage</Link>*/}
            </div>
            <div className={`${cls.col__links} ${cls.col__employeur}`}>
              <Link to="/entreprise">Employeur</Link>
              {/*<Link to="/entreprise/ecosystem">Ecosysteme</Link>
                            <Link to="/entreprise/club">Le club Empl´Whire</Link>*/}
            </div>
          </div>
        </div>
      ) : (
        <LoginModal />
      )}
    </div>
  )
}

const MenuAPropos = () => {
  return (
    <div className={cls.wrapper__menuApropos}>
      <li>
        <Link to="/">Lorem ipsum</Link>
      </li>
      <li>
        <Link to="/">Lorem ipsum</Link>
      </li>
      <li>
        <Link to="/">Lorem ipsum</Link>
      </li>
      <li>
        <Link to="/">Lorem ipsum</Link>
      </li>
      <li>
        <Link to="/">Lorem ipsum</Link>
      </li>
    </div>
  )
}
