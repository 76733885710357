import React, { useState } from 'react'
import { UikWidget, UikWidgetHeader, UikWidgetLink } from 'uikit/components'

const Menu = ({ links, title, small, ...rest }) => {
  return (
    <UikWidget {...rest}>
      {title === '🙈 Mes brouillons' || title === '🙈 Brouillons' ? (
        <UikWidgetHeader draft>{title}</UikWidgetHeader>
      ) : title ? (
        <UikWidgetHeader>{title}</UikWidgetHeader>
      ) : null}
      {title === '🙈 Mes brouillons' || title === '🙈 Brouillons'
        ? links.map((item) => (
            <UikWidgetLink
              key={item.title || item.name}
              {...item}
              style={{ padding: small && '0 18px' }}
              draft
            />
          ))
        : links.map((item) => (
            <UikWidgetLink
              key={item.title || item.name}
              {...item}
              style={{ padding: small && '0 18px' }}
            />
          ))}
    </UikWidget>
  )
}

Menu.defaultProps = {
  small: false,
}

export default Menu
