import React, { useEffect, useRef } from 'react'

import { UikInput, UikCheckbox } from 'uikit/components'

import cls from './inputDateOrNow.module.scss'

const formatDate = (date) => {
  if (date) {
    const shortDate = date.substr(0, 10)
    return shortDate.substr(0, 10)
  }
  return ''
}

const FormInput = ({
  touched,
  errors,
  field,
  handleBlur,
  handleChange,
  value,
  setFieldValue,

  current,
}) => {
  let dateInput = useRef(null)
  useEffect(() => {
    if (
      navigator.userAgent.indexOf('Safari') != -1 &&
      navigator.userAgent.indexOf('Chrome') == -1 &&
      dateInput &&
      dateInput.hasAttribute('data-has-picker')
    ) {
      dateInput.addEventListener('change', handleChange)
    }
  })

  return (
    <div>
      {field.fieldType === 'date' &&
      navigator.userAgent.indexOf('Safari') != -1 &&
      navigator.userAgent.indexOf('Chrome') == -1 ? (
        <input
          type={field.fieldType || 'text'}
          className={`${cls.inputWrapper} ${cls.inputLabel} ${cls.input} ${cls.clear}`}
          error={touched && errors}
          id={field.id}
          label={field.label}
          value={formatDate(value)}
          placeholder={'aaaa/mm/jj'}
          onChange={handleChange}
          onBlur={handleBlur}
          data-date-format="yyyy-mm-dd"
          lang="fr"
          ref={(input) => {
            dateInput = input
          }}
        />
      ) : (
        <UikInput
          id={field.id}
          type={field.fieldType || 'text'}
          label={field.label}
          placeholder={field.placeholder}
          error={touched && errors}
          value={formatDate(value)}
          onChange={handleChange}
          onBlur={handleBlur}
          errorMessage={touched && errors}
        />
      )}
      <div style={{ marginTop: '6px' }}>
        <UikCheckbox
          onChange={() => {
            setFieldValue('current', !current)
            document.getElementById(field.id).value = undefined
            setFieldValue(field.id, undefined)
          }}
          defaultChecked={current}
          label={field.endDateNowLabel}
        />
        {field.helpComponent && React.createElement(field.helpComponent)}
      </div>
    </div>
  )
}

export default FormInput
