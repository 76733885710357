// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@media only screen and (min-width: 120px)and (max-width: 320px){.x_Uhl5ozDQQI8ODwjoO6{max-width:270px}}@media only screen and (min-width: 321px)and (max-width: 410px){.x_Uhl5ozDQQI8ODwjoO6{max-width:315px}}@media only screen and (min-width: 411px)and (max-width: 700px){.x_Uhl5ozDQQI8ODwjoO6{max-width:360px}}", "",{"version":3,"sources":["webpack://./src/modules/CompanyProfile/traineesModal.module.scss"],"names":[],"mappings":"AAAA,gEACI,sBACE,eAAA,CAAA,CAMN,gEACI,sBACE,eAAA,CAAA,CAKN,gEACI,sBACI,eAAA,CAAA","sourcesContent":["@media only screen and (min-width : 120px) and (max-width : 320px) {\n    .responsiveFormModal {\n      max-width: 270px;\n    //   max-width: 100%;\n    }\n  }\n\n\n@media only screen and (min-width : 321px) and (max-width : 410px) {\n    .responsiveFormModal {\n      max-width: 315px;\n    //   max-width: 100%;\n    }\n  }\n\n@media only screen and (min-width: 411px) and (max-width : 700px){\n    .responsiveFormModal {\n        max-width: 360px;\n      }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"responsiveFormModal": "x_Uhl5ozDQQI8ODwjoO6"
};
export default ___CSS_LOADER_EXPORT___;
