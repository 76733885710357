import React, { useRef, useEffect } from 'react'
import Editor from 'modules/Editor'

import './editor.css'

const labelStyles = {
  marginTop: '24px',
  marginBottom: '0',
  fontSize: '.75rem',
  fontWeight: '500',
  color: '#9EA0A5',
  display: 'block',
  lineHeight: '1.125rem',
}

const FormInput = ({
  field,
  handleBlur,
  getEditorRef,
  rawContentState,
  setFieldValue,
  errors,
  key,
}) => {
  const refEditor = useRef(null)
  useEffect(() => {
    if (!!getEditorRef) {
      getEditorRef(refEditor)
    }
  })

  return (
    <div key={key} ref={refEditor}>
      <p style={labelStyles}>{field.label}</p>
      <Editor
        id={field.id}
        spellCheck
        stateSaveInterval={16}
        rawContentState={rawContentState || null}
        onBlur={handleBlur}
        onSave={(props) => setFieldValue(field.id, props)}
      />
      {errors && (
        <p
          style={{
            color: '#E6492D',
            marginTop: '6px',
            lineHeight: '1.375rem',
            fontSize: '.8rem',
          }}
        >
          {errors}
        </p>
      )}
    </div>
  )
}

export default FormInput
