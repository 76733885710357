function _log(e) {
  if (process.env.NODE_ENV === 'development') {
    console.error(e)
  }
}

export function call(action, payload) {
  if (['boot', 'update'].indexOf(action) === -1) {
    return _log(
      new Error(
        `Intercom.call: property 'action‘ should be one of ['boot', 'update']`
      )
    )
  }
  if (window.Intercom) {
    return window.Intercom(action, payload)
  }
  return _log(
    new Error(
      `Intercom.call: client does not exist. Did you register Intercom correctly?`
    )
  )
}

export function updateIntercomProfile(updateProfileData, user_id) {
  if (!user_id) {
    return _log(
      new Error(`Intercom.updateProfile: 'user_id' property is required`)
    )
  }
  return call('update', updateProfileData)
}

/* Update this with actual profile data */
export function createProfile(createProfileData, email) {
  if (!createProfileData.id) {
    return _log(new Error(`Intercom.createProfile: 'id' property is required`))
  }
  return call('boot', {
    user_id: createProfileData.id, // required
    name: createProfileData.title,
    type: 'User',
    email: email,
    user_name: `https://whire.me/@${createProfileData.slug}`,
    created_at: new Date(createProfileData.createdAt),
    stories_count: 0,
    /**
     * Example data
     * email: createProfileData.emailAddress,
     * last_updated_title: null,
     * name: createProfileData.displayName,
     * user_name: createProfileData.userName,
     * user_type: createProfileData.userType,
     * stories_count: 0,
     */
  })
}
