import React, { useRef, useEffect } from 'react'

import { UikInput } from 'uikit/components'

import cls from './inputDateOrNow.module.scss'

const formatDate = (date) => {
  if (date) {
    const shortDate = date.substr(0, 10)
    return shortDate.substr(0, 10)
  }
  return ''
}

const FormInput = ({
  touched,
  errors,
  field,
  handleBlur,
  handleChange,
  value,
  getInputRef,
  ...rest
}) => {
  let inputDate = useRef(null)
  const refEditor = useRef(null)
  useEffect(() => {
    value === '' ? value === '' : value
    if (!!getInputRef) {
      getInputRef(refEditor)
    }
    if (inputDate.current !== null) {
      if (
        navigator.userAgent.indexOf('Safari') != -1 &&
        navigator.userAgent.indexOf('Chrome') == -1
      ) {
        inputDate.current.addEventListener('change', handleChange)
      }
    }
  })

  return (
    <div ref={refEditor}>
      {field.fieldType === 'date' &&
      navigator.userAgent.indexOf('Safari') != -1 &&
      navigator.userAgent.indexOf('Chrome') == -1 ? (
        <input
          type={field.fieldType || 'text'}
          className={`${cls.inputWrapper} ${cls.inputLabel} ${cls.input} ${cls.clear}`}
          error={touched && errors}
          id={field.id}
          label={field.label}
          placeholder={'aaaa/mm/jj'}
          value={formatDate(value)}
          onChange={handleChange}
          onBlur={handleBlur}
          data-date-format="yyyy-mm-dd"
          lang="fr"
          // ref={(input) => { inputDate =  input; }}
          ref={inputDate}
        />
      ) : (
        <UikInput
          id={field.id}
          type={field.fieldType || 'date'}
          label={field.label}
          placeholder={field.placeholder}
          error={touched && errors}
          valueAsDate={value}
          valueAsNumber={value}
          value={field.fieldType === 'date' ? formatDate(value) : value}
          onChange={handleChange}
          onBlur={handleBlur}
          errorMessage={touched && errors}
          {...rest}
        />
      )}
      {field.helpComponent && React.createElement(field.helpComponent)}
    </div>
  )
}

export default FormInput
