export const CREER_MA_PAGE = (userType) => {
  if (userType === 'TRAINING') {
    return '/org/creer-ma-page'
  } else if (userType === 'BUSINESS') {
    return '/pro/creer-ma-page'
  } else {
    return '/creer-ma-page'
  }
}

export const INSCRIPTION = (userType) => {
  if (userType === 'TRAINING') {
    return '/org/inscription'
  } else if (userType === 'BUSINESS') {
    return '/pro/inscription'
  } else {
    return '/inscription'
  }
}

export const CONNEXION = (userType) => {
  if (userType === 'TRAINING') {
    return '/org/connexion'
  } else if (userType === 'BUSINESS') {
    return '/pro/connexion'
  } else {
    return '/connexion'
  }
}

export const PROFILES = (userType) => {
  if (userType === 'TRAINING') {
    return '/org/pages'
  } else if (userType === 'BUSINESS') {
    return '/pro/pages'
  } else {
    return '/profiles'
  }
}

export const NEW_STORY = (userType, slug) => {
  if (userType === 'TRAINING') {
    return `/org/@${slug}/nouvelle-story`
  } else if (userType === 'BUSINESS') {
    return `/pro/@${slug}/nouvelle-story`
  } else {
    return `/@${slug}/nouvelle-story`
  }
}

export const NEW_TRAINEE = (slug) => `/org/@${slug}/creer-ma-section`
export const DASHBOARD = (slug) => `/org/@${slug}/dashboard`
export const EDIT_PASSWORD = (slug) => `/@${slug}/edit-password`
export const EDIT_MAIL = (slug) => `/@${slug}/edit-mail`
export const CREATE_CARDS= (slug) => `/@${slug}/cards`

export const HOME = (userType) => {
  if (userType === 'TRAINING') {
    return '/org'
  } else if (userType === 'BUSINESS') {
    return '/pro'
  } else {
    return '/'
  }
}

export const EDIT = (userType, slug) => {
  //const pathname = window.location.pathname;
  return `@${slug}/edit`
}

export const TRAINEE = () => {
  const pathname = window.location.pathname
  return `${pathname}/edit`
}

export const SLUG = (userType, slug) => {
  if (userType === 'TRAINING') {
    return `/org/@${slug}`
  } else if (userType === 'BUSINESS') {
    return `/pro/@${slug}`
  } else {
    return `/@${slug}`
  }
}

export const ERROR = () => {
  return `/404`
  // return <Error />
}
