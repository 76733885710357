import React from 'react'
import PropTypes from 'prop-types'
import {
  UikDivider,
  UikWidget,
  UikWidgetHeader,
  UikWidgetBottomCta,
} from 'uikit/components'

import Content from './Content'
import { Row, Col } from 'react-flexbox-grid'

const TextWidget = ({
  bottomCta,
  content,
  className,
  header,
  children,
  draft,
  ...rest
}) => (
  <UikWidget {...rest} className={className}>
    {header && <UikWidgetHeader draft={draft}>{header}</UikWidgetHeader>}
    {Array.isArray(content) ? (
      <div>
        {content.map((elem, i) => (
          <div key={i}>
            <Content {...elem} draft={draft} />
            {content[i + 1] && <UikDivider {...elem} />}
          </div>
        ))}
      </div>
    ) : !!content ? (
      <div>
        <Content
          {...content}
          draft={draft}
          children={children ? children.props : undefined}
        />
      </div>
    ) : null}
    {bottomCta && (
      <div>
        <UikDivider />
        <UikWidgetBottomCta {...bottomCta}>
          {bottomCta.title}
        </UikWidgetBottomCta>
      </div>
    )}
  </UikWidget>
)

TextWidget.defaultProps = {
  bottomCta: undefined,
  className: undefined,
  header: undefined,
}

TextWidget.propTypes = {
  bottomCta: PropTypes.string,
  className: PropTypes.string,
  content: PropTypes.isRequired,
  header: PropTypes.string,
}

export default TextWidget
