import React from 'react'

import { SVG_URI } from 'utils/vars'

import { UikInput, UikSvg } from 'uikit/components'

const VideoInput = ({
  touched,
  errors,
  field,
  handleBlur,
  handleChange,
  value,
  setFieldValue,
}) => {
  return (
    <div>
      <UikInput
        id={field.id}
        type="text"
        label={field.label}
        placeholder={field.placeholder}
        error={touched && errors}
        iconPosition="right"
        icon={
          value ? (
            <UikSvg
              alt="remove field"
              width="22px"
              height="22px"
              style={{
                cursor: 'pointer',
                marginTop: '6px',
              }}
              src={`${SVG_URI}/remove.svg`}
              onClick={() => setFieldValue(field.id, '')}
            />
          ) : null
        }
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        wrapperProps={{
          style: { marginTop: '18px' },
        }}
      />
      {errors && (
        <p
          style={{
            color: '#E6492D',
            marginTop: '6px',
            lineHeight: '1.375rem',
            fontSize: '.8rem',
          }}
        >
          {errors}
        </p>
      )}
      {field.helpComponent && React.createElement(field.helpComponent)}
    </div>
  )
}

const VideoField = (props) => <VideoInput {...props} />

export default VideoField
