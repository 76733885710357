import React, { useState, useEffect, useCallback } from 'react'
import { withRouter } from 'react-router'
import { withApollo } from '@apollo/client/react/hoc'
import { Prompt } from 'react-router-dom'

import { SVG_URI } from 'utils/vars'
import createField from 'modules/Form/createField'

import { withContentRect } from 'react-measure'

import { Grid, Row, Col } from 'react-flexbox-grid'
import { DeleteProfileMutation } from 'gql/profile'
import { DeletePageMutation } from 'gql/page'

import {
  Modal,
  Header,
  Footer,
  FooterItem,
  Close,
  Body,
} from '@zendeskgarden/react-modals'
import { Button } from '@zendeskgarden/react-buttons'

import * as WORDING from 'utils/wording'

import { getUser } from 'utils/handleUser'

import { UikForm } from 'uikit/containers'
import {
  UikButton,
  UikInput,
  UikWidgetContent,
  UikHeadline,
  UikHeadlineDesc,
  UikFormInputGroup,
  UikCheckbox,
} from 'uikit/components'

import ChromeHeader from 'components/ChromeHeader'

import { Field } from 'formik'

import Preview from 'modules/Form/components/profilePreview'
import Affix from 'components/Affix'

import { resetMainProfile } from 'utils/handleMainProfile'

import * as ROUTES from 'utils/routes'

import cls from './form.module.scss'
import clsApp from 'initStyles/App.scss'
import { cpus } from 'os'

import { updateIntercomProfile } from 'utils/intercom'

import axios from 'axios'

function textBonjour(location) {
  return location.pathname.includes('/org/')
    ? 'Bonjour nous sommes'
    : "Bonjour je m'appelle"
}

function placeholderSlug(location) {
  return location.pathname.includes('/org/')
    ? 'Votre sigle ou le nom de votre structure par exemple'
    : 'monpseudo'
}

function labelSlug(location) {
  return location.pathname.includes('/org/') ? 'VOTRE' : 'MA'
}

//window to confirm if we really want to leave the page on profile form
function ConfirmWIndow (leavePage, message){

	useEffect(() => {
		window.addEventListener('beforeunload', beforePageUnload);
		return () => window.removeEventListener('beforeunload', beforePageUnload);		
	}, [leavePage]);

	const beforePageUnload = useCallback((e) => {
		if (leavePage) {
			e.preventDefault();
			return e.returnValue = message;
		}
	}, [leavePage]);

	return <Prompt message={() => leavePage ? message : true} />;
}
const ProfileForm = (props) => {
  const {
    // existingTags,
    client,
    header,
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    returnUrl,
    sections,
    setValues,
    setFieldValue,
    measureRef,
    submitCount,
    contentRect: { bounds },
    history,
    location,
    profile,
    page,
  } = props

  const [showPreview, handleShowPreview] = useState(false)
  const [showDeleteModal, handleDeleteModal] = useState(false)
  const [isDraft, setIsDraft] = useState(false)
  const [showErrorModal, handleErrorModal] = useState(false)
  const [noShowInput, setNoShowInput] = useState({
    0: true,
    1: true,
    2: true,
    3: true,
    4: true,
    5: true,
    6: true,
    7: true,
  })

  const [imageProps, liftImageProps] = useState()
  const token = localStorage.getItem('whire-app-token')
  const [leavingPage, setLeavingPage] = useState(false)


  const handleClick = (id) => {
    setNoShowInput((prevShownInputs) => ({
      ...prevShownInputs,
      [id]: !prevShownInputs[id],
    }))
  }

  useEffect(() => {
    setValues({
      ...values,
      ...imageProps,
    })
  }, [imageProps])

  const DeleteMutation = !!profile
    ? DeleteProfileMutation
    : !!page
    ? DeletePageMutation
    : null
  const id = !!profile ? profile.id : !!page ? page.id : null
  const handleIsDraft = (e) => {
    setIsDraft(e.target.checked)
    setFieldValue('isDraft', e.target.checked)
  }

  let inputRef
  const getInputRef = (ref) => {
    inputRef = ref
    return null
  }

  let editorRef
  const getEditorRef = (ref) => {
    editorRef = ref
    return null
  }
  const message = [
    'Pour valider votre profil, veuillez remplir les champs obligatoires',
  ]

  const user = getUser()
  const userType = !!user
    ? user.origin
    : window.location.pathname.includes('/org')
    ? 'TRAINING'
    : 'CANDIDATE'

  return (
    <div ref={measureRef}>
      {
        leavingPage === false ? 
          <ConfirmWIndow leavePage={true} message="Êtes-vous sûr de vouloir quitter cette page ?" />
        : ""
      }
      {userType !== 'TRAINING' ? (
        <div style={{ height: '50px' }}>
          <Affix
            offset={bounds.width < 768 ? 0 : Infinity}
            style={{ top: 0 }}
            wrapperStyle={{
              height: '50px',
              borderTop: '1px solid #DADEE7',
            }}
          >
            <ChromeHeader
              standalone
              info={{
                title: {
                  Component: 'span',
                  text: WORDING.PROFILES_CTA(window.location.pathname),
                },
              }}
              icon={{
                alt: 'close',
                height: '24px',
                src: `${SVG_URI}/remove.svg`,
                width: '24px',
              }}
              items={[
                bounds.width < 768
                  ? {
                      text: !showPreview ? 'Visualiser' : 'Formulaire',
                      onClick: () => {
                        window.scroll(0, 0)
                        handleShowPreview(!showPreview)
                      },
                    }
                  : null,
              ]}
              onIconClick={() => history.goBack()}
            />
          </Affix>
        </div>
      ) : null}
      <Grid>
        <Row style={{ paddingTop: '30px' }}>
          {bounds.width >= 768 ? (
            <Col sm={12} md={6}>
              <Affix>
                <div
                  style={{
                    paddingBottom: '120px',
                    margin: '15px',
                  }}
                >
                  <Preview
                    {...values}
                    textBonjour={textBonjour(location)}
                    placeholderSlug={placeholderSlug(location)}
                    labelSlug={labelSlug(location)}
                  />
                </div>
              </Affix>
            </Col>
          ) : (
            <Col xs={12}>
              <div style={{ paddingBottom: '120px' }}>
                {showPreview && (
                  <Preview
                    {...values}
                    textBonjour={textBonjour(location)}
                    placeholderSlug={placeholderSlug(location)}
                    labelSlug={labelSlug(location)}
                  />
                )}
              </div>
            </Col>
          )}
          <Col sm={12} md={6}>
            <form
              // header={header}
              onSubmit={(event) => {
                handleSubmit(event)
                setLeavingPage(true)
                if (!!errors.tags) {
                  // message.push('Il faut un tag')
                  // message = "il faut un tag"
                  console.log(message[0], 'MESSAGE')
                  setLeavingPage(false)
                  window.scrollTo(0, editorRef.current.offsetTop + 1300)
                }
                if (!!errors.tagsInterest) {
                  handleErrorModal(true)
                  setLeavingPage(false)
                  window.scrollTo(0, editorRef.current.offsetTop + 1350)
                }
                if (!!errors.title || !!errors.slug || !!errors.summary) {
                  handleErrorModal(true)
                  setLeavingPage(false)
                  window.scrollTo(0, inputRef.current.offsetTop - 250)
                } else if (
                  !!errors.editorStates &&
                  !!errors.editorStates[0] &&
                  !!errors.editorStates[0].editorState
                ) {
                  handleErrorModal(true)
                  setLeavingPage(false)
                  window.scrollTo(0, editorRef.current.offsetTop - 750)
                } else if (
                  !!errors.editorStates ||
                  (!!errors.editorStates &&
                    !!errors.editorStates[1] &&
                    !!errors.editorStates[1].editorState &&
                    !!errors.editorStates[2] &&
                    !!errors.editorStates[2].editorState)
                ) {
                  handleErrorModal(true)
                  setLeavingPage(false)
                  console.log(props, "prooooooops")
                  window.scrollTo(0, editorRef.current.offsetTop - 150)
                }
              }}
            >
              {sections.map((section, index) =>
                Object.keys(section).length !== 0 ? (
                  <UikWidgetContent key={`profile-section-${index + 1}`}>
                    {section.title && (
                      <div
                        className={cls.sectionTitle}
                        onClick={() => handleClick(index)}
                      >
                        <UikHeadline>{section.title}</UikHeadline>
                        <img
                          style={{
                            width:
                              noShowInput && noShowInput[index] !== false
                                ? '1em'
                                : '0.5em',
                            marginRight: '0.5em',
                          }}
                          src={
                            noShowInput && noShowInput[index] !== false
                              ? `${SVG_URI}/icon-horizontal-gris.svg`
                              : `${SVG_URI}/icon-gris.svg`
                          }
                        />
                      </div>
                    )}
                    {section.description && (
                      <UikHeadlineDesc>{section.description}</UikHeadlineDesc>
                    )}
                    <UikFormInputGroup>
                      {noShowInput && noShowInput[index] !== false
                        ? section.fields
                            .filter((e) => e)
                            .map((field, i) => {
                              if (field.type !== 'SLUG') {
                                return createField(
                                  location,
                                  field.type,
                                  {
                                    field,
                                    submitCount,
                                    errors: errors[field.id],
                                    touched: touched[field.id],
                                    value: values[field.id],
                                    handleChange,
                                    handleBlur,
                                    // only used by Draftail Editor
                                    rawContentState: values[field.id],
                                    setFieldValue,
                                    liftImageProps,
                                    current: values.current,
                                  },
                                  getInputRef,
                                  getEditorRef
                                )
                              }

                              return (
                                <div
                                  key={i}
                                  style={{
                                    marginBottom: '20px',
                                  }}
                                >
                                  <Field
                                    name="slug"
                                    validate={(value) =>
                                      field.validate(client, value, location)
                                    }
                                    render={({ field: renderedField }) => (
                                      <UikInput
                                        {...renderedField}
                                        label={`${labelSlug(
                                          location
                                        )} page whire sera dispo à l'adresse`}
                                        name="slug"
                                        style={{
                                          height: '46px',
                                        }}
                                        autoComplete="off"
                                        spellCheck="false"
                                        errorMessage={
                                          (submitCount ||
                                            touched.slug ||
                                            (values.slug &&
                                              values.slug.length)) &&
                                          errors.slug
                                        }
                                        placeholder={placeholderSlug(location)}
                                      />
                                    )}
                                  />
                                  {values.slug &&
                                  values.slug.length &&
                                  !errors.slug ? (
                                    <p
                                      style={{
                                        marginTop: '6px',
                                      }}
                                    >
                                      <span role="img" aria-label="show up">
                                        👆
                                      </span>{' '}
                                      Votre page:&nbsp;
                                      {location.pathname.includes('/org') ? (
                                        <button
                                          className={clsApp.textButton}
                                          style={{
                                            color: '#458',
                                          }}
                                        >
                                          https://whire.me/org/@
                                          {values.slug}
                                        </button>
                                      ) : (
                                        <button
                                          className={clsApp.textButton}
                                          style={{
                                            color: '#458',
                                          }}
                                        >
                                          https://whire.me/@
                                          {values.slug}
                                        </button>
                                      )}
                                    </p>
                                  ) : null}
                                </div>
                              )
                            })
                        : null}
                    </UikFormInputGroup>
                  </UikWidgetContent>
                ) : null
              )}
              <UikWidgetContent
                style={
                  location.pathname.includes('/edit')
                    ? {
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                        position: 'fixed',
                        zIndex: 'auto',
                        bottom: '-2em',
                        background: 'white',
                        width: '36em',
                      }
                    : {
                        position: 'fixed',
                        zIndex: 'auto',
                        bottom: '-2em',
                        background: 'white',
                        width: '36em',
                      }
                }
              >
                <UikButton
                  type="submit"
                  success
                  md
                  style={{
                    marginRight: '16px',
                    marginBottom: '16px',
                  }}
                >
                  {isDraft ? 'Valider' : 'Valider et publier'}
                </UikButton>
                <UikCheckbox
                  id="isDraft"
                  label="Brouillon"
                  defaultChecked={false}
                  onChange={handleIsDraft}
                  style={{ marginBottom: '16px' }}
                />
                {location.pathname.includes('/edit') && (
                  <Button
                    type="button"
                    isDanger
                    className={cls.buttonDanger}
                    style={
                      window.innerWidth > 552
                        ? {
                            marginLeft: '16px',
                            marginRight: '16px',
                            marginBottom: '16px',
                          }
                        : {
                            marginTop: '16px',
                            marginBottom: '16px',
                          }
                    }
                    onClick={() => handleDeleteModal(true)}
                  >
                    Supprimer
                  </Button>
                )}
              </UikWidgetContent>
            </form>
          </Col>
        </Row>
      </Grid>
      {showErrorModal && (
        <Modal
          className={cls.responsiveFormModal}
          onClose={() => handleErrorModal(false)}
          animate
        >
          <Header danger>Attention</Header>
          {message.map((msg) => (
            <Body>
              <p>{msg}</p>
            </Body>
          ))}
          <Footer>
            <FooterItem
              style={{
                display: 'block',
                marginRight: 'auto',
                marginLeft: 'auto',
              }}
            >
              <Button onClick={() => handleErrorModal(false)} animate>
                Ok
              </Button>
            </FooterItem>
          </Footer>
          <Close aria-label="Close modal" />
        </Modal>
      )}
      {showDeleteModal && (
        <Modal
          className="responsiveFormModal"
          onClose={() => handleDeleteModal(false)}
          animate
        >
          <Header danger>Supprimer le profil</Header>
          <Body>Etes-vous sûr ?</Body>
          <Footer>
            <FooterItem>
              <Button onClick={() => handleDeleteModal(false)}  className={cls.buttonBasic} basic>
                Annuler
              </Button>
            </FooterItem>
            <FooterItem>
              <Button
                onClick={() => {
                  handleDeleteModal(false)
                  client
                    .mutate({
                      mutation: DeleteMutation,
                      variables: { id },
                    })
                    .then(() => {
                      if (profile) {
                        updateIntercomProfile(
                          {
                            user_id: profile.id,
                            name: "Ce profile n'existe plus",
                          },
                          profile.id
                        )
                      }
                      resetMainProfile()
                      window.location.replace(ROUTES.PROFILES(userType))
                    })
                }}
                primary
                isDanger
                className={cls.buttonDanger}
              >
                Confirmer
              </Button>
            </FooterItem>
          </Footer>
          <Close aria-label="Close modal" />
        </Modal>
      )}
    </div>
  )
}

const ProfileFormWithContentRect = withContentRect('bounds')(
  withRouter(withApollo(ProfileForm))
)

export default ProfileFormWithContentRect
