import React from 'react'

import { SVG_URI, IMG_URI } from 'utils/vars'

import { UikButton, UikInput, UikSvg } from 'uikit/components'

import { FieldArray, Field } from 'formik'

const FormFieldArray = ({ field, hideAddMore, value, setFieldValue }) => (
  <FieldArray
    name={field.id}
    render={(arrayHelpers) => (
      <div>
        {!!value &&
          value.map((_, index) => (
            <div key={`${field.id}-${index + 1}`}>
              <Field
                name={`${field.id}[${index}]${field.accessor || 'url'}`}
                render={({ field: renderedField }) => (
                  <div
                    style={{
                      display: 'flex',
                      width: '100%',
                    }}
                  >
                    <img
                      style={{
                        width: '2em',
                        marginRight: '0.5em',
                        marginTop: _.type === 'OTHER' ? '0.5em' : '2em',
                      }}
                      src={
                        _.type === 'FACEBOOK'
                          ? `${IMG_URI}/links/facebook.svg`
                          : _.type === 'LINKEDIN'
                          ? `${IMG_URI}/links/linkedin.svg`
                          : _.type === 'INSTAGRAM'
                          ? `${IMG_URI}/links/instagram.svg`
                          : _.type === 'TWITTER'
                          ? `${IMG_URI}/links/twitter.svg`
                          : _.type === 'YOUTUBE'
                          ? `${IMG_URI}/links/youtube.svg`
                          : _.type === 'PERSONAL'
                          ? `${IMG_URI}/links/vinyl.svg`
                          : _.type === 'GITHUB'
                          ? `${IMG_URI}/links/github.svg`
                          : `${IMG_URI}/links/icone-link.png`
                      }
                    />
                    <UikInput
                      {...renderedField}
                      iconPosition="right"
                      icon={
                        renderedField.value ? (
                          <UikSvg
                            alt="remove field"
                            width="22px"
                            height="22px"
                            style={{
                              cursor: 'pointer',
                              marginTop: '6px',
                            }}
                            src={`${SVG_URI}/remove.svg`}
                            onClick={() => {
                              if (value.length > 1) {
                                _.url = ''
                                arrayHelpers.replace(index, _)
                              } else {
                                arrayHelpers.replace(0, field.init)
                              }
                            }}
                          />
                        ) : null
                      }
                      label={_.label || null}
                      placeholder={_.placeholder || 'Une URL valide'}
                      wrapperProps={{
                        style: {
                          marginTop: index ? '12px' : 'initial',
                          width: '100%',
                        },
                      }}
                    />
                  </div>
                )}
              />
            </div>
          ))}
        {!hideAddMore ? (
          <UikButton
            onClick={() => arrayHelpers.push(field.init)}
            style={{ marginTop: '12px' }}
          >
            Ajouter +
          </UikButton>
        ) : null}
      </div>
    )}
  />
)

FormFieldArray.defaultProps = {
  hideAddMore: false,
}

export default FormFieldArray
