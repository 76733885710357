import { gql } from '@apollo/client'

const CheckProfileSlugExistenceQuery = gql`
  query checkProfileSlug($slug: String!) {
    profiles(where: { slug: { equals: $slug } }) {
      slug
    }
  }
`

const CheckPageSlugExistenceQuery = gql`
  query checkPageSlug($slug: String!) {
    pages(where: { slug: { equals: $slug } }) {
      slug
    }
  }
`

const userNameRegex = /^(\w){1,1000}$/

const userNameFormattingError = (value) => {
  if (!value) {
    return 'Pseudo obligatoire'
  }
  if (!value.match(userNameRegex) || value.indexOf(' ') !== -1) {
    return 'Les caractères spéciaux sont interdits !'
  }
  if (value.length < 3 || value.length > 15) {
    return 'Doit contenir entre 3 et 15 caractères'
  }
  return undefined
}

const userNamesTaken = []
const userNamesAvailable = []

// client is a valid Apollo Graphql client object
export default (client, value, location) => {
  const formattingError = userNameFormattingError(value)
  if (formattingError) {
    return formattingError
  }
  if (userNamesTaken.indexOf(value) !== -1) {
    return 'Pseudo déjà utilisé 😢'
  }
  if (
    location.pathname.match(/(@[a-zA-Z0-9_.-]*)/g) &&
    location.pathname.match(/(@[a-zA-Z0-9_.-]*)/g)[0] === `@${value}`
  ) {
    return undefined
  }

  if (userNamesAvailable.indexOf(value) !== -1) {
    return undefined
  }
  return client
    .query({
      query: location.pathname.includes('/org/')
        ? CheckPageSlugExistenceQuery
        : CheckProfileSlugExistenceQuery,
      variables: {
        slug: value,
      },
    })
    .then((res) => {
      if (location.pathname.includes('/org/')) {
        if (!res.data || !res.data.pages || !res.data.pages.length) {
          userNamesAvailable.push(value)
          return undefined
        }
      } else if (!res.data || !res.data.profiles || !res.data.profiles.length) {
        userNamesAvailable.push(value)
        return undefined
      }
      // Used to not requery slugs we got previously
      userNamesTaken.push(value)
      return null
    })
    .catch((err) => {
      console.log('catch', err)
      return 'CATCH ERR'
    })
}
