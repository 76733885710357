import React, { Component, useState } from 'react'
import {
  Modal,
  Header,
  Footer,
  FooterItem,
  Close,
  Body,
} from '@zendeskgarden/react-modals'
import { Button } from '@zendeskgarden/react-buttons'
import axios from 'axios'
import { CONTACT_ME } from 'utils/vars'

import { createForm, formShape } from 'rc-form'

import { UikInput } from 'uikit/components'

import cls from './traineesModal.module.scss'

const TraineesModal = (props) => {
  const [showModal, handleModal] = useState(false)
  const [showErrorModal, handleErrorModal] = useState(false)
  const [showNetworkErrorModal, handleNetworkErrorModal] = useState(false)

  const submit = () => {
    props.form.validateFields((error, value) => {
      const body = {
        ...value,
      }
      if (error) {
        // handleErrorModal(true)
        handleModal(true)
      } else {
        axios({
          method: 'POST',
          url: `https://us-central1-send-mail-fcf88.cloudfunctions.net/sendVisitorMail?normal=${value.normal}`,
          data: JSON.stringify(body),
        })
          .then((response) => {
            console.log('response', response)
            handleModal(true)
            localStorage.setItem('whire-app-emailVisitor', response.config.data)
          })
          .catch((err) => {
            console.log(err, 'errorrrr')
            handleNetworkErrorModal(true)
          })
      }
    })
  }

  // let errors;
  const { getFieldProps } = props.form
  return (
    <div>
      {!showModal && (
        <Modal className={cls.responsiveFormModal}>
          <Header default>Information</Header>
          <Body>
            <UikInput
              {...getFieldProps('normal', {
                rules: [{ required: true, type: 'email' }],
              })}
              label="Merci de bien vouloir vous connecter ou rentrer votre adresse mail pour avoir accès à cette page."
              placeholder="De préférence professionnelle"
              // style={{ marginTop: '24px' }}
            />
          </Body>
          <Footer>
            <FooterItem
              style={{
                display: 'block',
                marginRight: 'auto',
                marginLeft: 'auto',
              }}
            >
              <Button
                style={{ margin: '10px' }}
                success
                onClick={() => {
                  submit()
                  // handleConfirmationModal(true);
                }}
              >
                Envoyer
              </Button>
              <Button
                danger
                style={{ marginLeft: '10px' }}
                onClick={() => window.history.back()}
                animate
              >
                Retour
              </Button>
            </FooterItem>
          </Footer>
        </Modal>
      )}
      {showErrorModal && (
        <Modal
          className={cls.responsiveFormModal}
          onClose={() => handleErrorModal(false)}
          animate
        >
          <Header danger>Attention</Header>
          <Body>Vous devez rentrer une adresse mail valide</Body>
          <Footer>
            <FooterItem
              style={{
                display: 'block',
                marginRight: 'auto',
                marginLeft: 'auto',
              }}
            >
              <Button onClick={() => handleErrorModal(false)} animate>
                Ok
              </Button>
            </FooterItem>
          </Footer>
          <Close aria-label="Close modal" />
        </Modal>
      )}
      {showNetworkErrorModal && (
        <Modal
          className={cls.responsiveFormModal}
          onClose={() => handleNetworkErrorModal(false)}
          animate
        >
          <Header danger>Attention</Header>
          <Body>
            Votre message n'a pas pu être envoyé, il semblerait qu'il y ait un
            problème.
          </Body>
          <Footer>
            <FooterItem
              style={{
                display: 'block',
                marginRight: 'auto',
                marginLeft: 'auto',
              }}
            >
              <Button onClick={() => handleNetworkErrorModal(false)} animate>
                Ok
              </Button>
            </FooterItem>
          </Footer>
          <Close aria-label="Close modal" />
        </Modal>
      )}
    </div>
  )
}

export default createForm()(TraineesModal)
