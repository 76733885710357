// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".lr7wRpHtOiYQpQQcojAW{width:100%;z-index:1}.KDPBbPbptEd9mDRw1lsN{position:fixed;overflow:auto;max-height:95%;padding-bottom:60px;z-index:1}.eA4RNRaLwX2p0qNVtCi5{position:fixed;overflow:none;max-height:95%;padding-bottom:60px;z-index:1}", "",{"version":3,"sources":["webpack://./src/components/Affix/affix.module.scss"],"names":[],"mappings":"AAEA,sBACE,UAAA,CACA,SAAA,CAGF,sBACE,cAAA,CAGA,aAAA,CACA,cAAA,CACA,mBAbY,CAcZ,SAAA,CAGF,sBACE,cAAA,CAGA,aAAA,CACA,cAAA,CACA,mBAvBY,CAwBZ,SAAA","sourcesContent":["$pagePadding: 60px;\n\n.wrapper {\n  width: 100%;\n  z-index: 1;\n}\n\n.affix {\n  position: fixed;\n  // if children.offsetHeight may be > 100vh\n  // allow an overflow + define some bottom padding\n  overflow: auto;\n  max-height: 95%;\n  padding-bottom: $pagePadding;\n  z-index: 1;\n}\n\n.affix2 {\n  position: fixed;\n  // if children.offsetHeight may be > 100vh\n  // allow an overflow + define some bottom padding\n  overflow: none;\n  max-height: 95%;\n  padding-bottom: $pagePadding;\n  z-index: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "lr7wRpHtOiYQpQQcojAW",
	"affix": "KDPBbPbptEd9mDRw1lsN",
	"affix2": "eA4RNRaLwX2p0qNVtCi5"
};
export default ___CSS_LOADER_EXPORT___;
