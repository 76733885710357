// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".YNMk7OiWHAuPvzY1_Yic .WVHcqbbGflRpkjyyKlHR{max-width:100%}", "",{"version":3,"sources":["webpack://./src/modules/Thread/thread.scss"],"names":[],"mappings":"AACE,4CACE,cAAA","sourcesContent":[".thread-wrapper {\n  .drawer-content-wrapper {\n    max-width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"thread-wrapper": "YNMk7OiWHAuPvzY1_Yic",
	"drawer-content-wrapper": "WVHcqbbGflRpkjyyKlHR"
};
export default ___CSS_LOADER_EXPORT___;
