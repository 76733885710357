import React from 'react'
import AsyncCreatableSelect from 'react-select/async-creatable'

import { connectAutoComplete } from 'react-instantsearch-dom'

import cls from './asyncCreatableSelect.module.scss'

// const filteredOptions = (array, inputValue) => array.filter(i =>
//   i.value.includes(inputValue.toLowerCase())
// );

const mapOptions = (array) =>
  array.map((e) => ({ value: e.name, label: e.name, id: e.id }))

const AsyncTagCreatable = (props) => (
  <AsyncCreatableSelect
    className={cls.tagClass}
    isMulti
    formatCreateLabel={(inputText) => `Ajouter "${inputText}"`}
    // cacheOptions
    // closeMenuOnSelect={false}
    placeholder="Je tape des mots-clés qui me correspondent"
    defaultOptions={mapOptions(props.hits)}
    value={props.currentSelection.filter(
      (current) => current.tagType !== 'INTEREST' && current.tagType !== 'WORK'
    )}
    onChange={props.onChange}
    loadOptions={(value) => {
      props.refine(value)
      return new Promise((resolve) => {
        resolve(mapOptions(props.hits))
      })
    }}
  />
)

AsyncTagCreatable.defaultProps = {
  currentSelection: [],
}

const AlgoliaAsyncCreatable = connectAutoComplete(AsyncTagCreatable)

export default AlgoliaAsyncCreatable
