import React from 'react'

const UikSvg = ({ alt, height, src, width, ...rest }) => (
  <img alt={alt} height={height} width={width} src={src} {...rest} />
)

UikSvg.defaultProps = {
  height: '16px',
  width: '16px',
}

export default UikSvg
