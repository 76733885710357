import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { UikButton } from 'uikit/components'
import { openDialog } from 'uploadcare-widget'

const PdfFile = (props) => {
  const [loading, setLoading] = useState(false)

  const handleUpload = (form) => {
    openDialog(null, {
      multiple: false,
      tabs: 'file',
      inputAcceptTypes: '.pdf',
    }).done((file) => {
      setLoading(true)
      file.promise().done((fileInfo) => {
        setLoading(false)
        form.setFieldValue(props.field.id, fileInfo.uuid)
      })
    })
  }

  return (
    <div style={{ marginTop: '20px' }}>
      {!!props.value ? (
        <UikButton onClick={() => props.setFieldValue(props.field.id, '')}>
          Supprimer mon fichier
        </UikButton>
      ) : (
        <UikButton
          success
          isLoading={loading}
          onClick={() => handleUpload(props)}
        >
          Ajouter mon CV
        </UikButton>
      )}
    </div>
  )
}

PdfFile.propTypes = {
  value: PropTypes.string.isRequired,
  field: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  setFieldValue: PropTypes.func.isRequired,
}

export default PdfFile
