import React, { Fragment, useContext, useEffect } from 'react'

import { withRouter } from 'react-router'
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom'

import { graphql } from '@apollo/client/react/hoc'
import { compose } from 'recompose'

import { UserQuery } from 'gql/auth'
import { PublicProfileFirstQuery } from 'gql/profile'
import { PageFirstQuery } from 'gql/page'

import { Modal, Header, Body, Close } from '@zendeskgarden/react-modals'

import asyncComponent from 'utils/AsyncComponent'
import CreateProfile from 'containers/CreateProfile'
import CreatePage from 'containers/CreatePage'
import UserProfile from 'modules/UserProfile'
import CompanyProfile from 'modules/CompanyProfile'
import Loading from 'components/Loading'
import AuthPage from 'pages/Auth'
import Page404 from 'pages/page404'
import NoAccess from 'pages/NoAccess'
import Cookies from 'components/Cookies'
import Cgu from 'pages/Cgu'
import Privacy from 'pages/Privacy'
import LegalMention from 'pages/LegalMention'
import Charter from 'pages/Charter'
import CreateCards from 'pages/CreateCards'
import CreateCardsEdu from 'pages/CreateCards/CreateCardsEdu'
import CreateCardsMenu from 'pages/CreateCards/CreateCardsMenu'
import MainHeader from 'components/MainHeader'

import { getMainProfile, getMainProfileUrl, resetMainProfile, getUser } from 'utils/handleMainProfile'
import { setUser } from 'utils/handleUser'

import { StoreContext } from 'contexts/Store'
import compareObj from 'utils/compareObj'
import LoginModal from './containers/LoginModal'

import '../node_modules/draft-js/dist/Draft.css'
import '../node_modules/draftail/dist/draftail.css'
import './initStyles/App.scss'
import Demo from './modules/Landing/utilsLanding/formDemo'
import LateralMenu from './modules/CompanyProfile/pages/LateralMenu'

const App = (prps) => {
  const {
    data: { me, loading },
    location: { pathname },
  } = prps

  if (loading) return <Loading />

  // eslint-disable-next-line
  const {
    me: { me: storedMe, storeMe },
    normalWidth: { normalWidth, setNormalWidth },
  } = useContext(StoreContext)

  useEffect(() => {
    // if last connexion before prisma migration 1 to 3 then clear local storage 
    const parseLocal = window.DB.getItem('whire-app-user')
    const lastLogin = parseLocal ? JSON.parse(parseLocal).lastLogin : ""
    const migrationDate =  new Date("August 22 2022 23:43:00 GMT+02:00")
    if(lastLogin!== "" && Date.parse(lastLogin) < Date.parse(migrationDate)){
      if (window.DB && Object.keys(window.DB).length > 0) {
        Object.keys(window.DB).map((key) => {
          if (key.includes('whire-app-')) {
            return window.DB.removeItem(key)
          }
          return null
        })
      }
      window.location.replace("/")
    }
    if (!compareObj(me, storedMe) && !!me) {
      storeMe(me)
      setUser(me)
    }
  }, [me])


  window.location.pathname === '/' ? setNormalWidth(false) : ''

  // me has at least 1 existing profile and no main profile
  // and tries to access a page which is not meant to set their main profile
  if (
    [
      '/',
      '/org',
      '/profiles',
      '/org/pages',
      '/logout',
      '/creer-ma-page',
      '/org/creer-ma-page',
    ].indexOf(pathname) === -1 &&
    me &&
    (me.profiles.length || me.pages.length) &&
    // && !getMainProfile()
    !getMainProfileUrl()
  ) {
    const ProfilesHandler = asyncComponent(
      () => import('modules/ProfilesHandler').then((module) => module.default),
      { ...prps }
    )
    return <ProfilesHandler user={me} />
  }

  const isAuthenticated = window.DB && window.DB.getItem('whire-app-token')
  const ua = window.navigator.userAgent
  const msie = ua.indexOf('MSIE ')
  if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
    return (
      <div className="App">
        <div
          style={{
            fontSize: '30px',
            maxWidth: '500px',
            margin: 'auto',
            textAlign: 'center',
          }}
        >
          <br />
          <br />
          <br />
          <br />
          <h1 style={{ fontSize: '20px' }}>
            {' '}
            🤦 Ouch, votre navigateur date un peu. Nous travaillons pour qu'il
            puisse supporter notre site. En attendant, rejoignez-nous dans le
            futur avec Edge, Mozilla Firefox ou Google Chrome{' '}
          </h1>
          <iframe
            src="https://giphy.com/embed/i5dIK5GSq2OGY"
            width="480"
            height="264"
            frameBorder="0"
            class="giphy-embed"
            allowFullScreen
          ></iframe>
          <p>
            <a href="https://giphy.com/gifs/back-to-the-future-i5dIK5GSq2OGY">
              via GIPHY
            </a>
          </p>{' '}
        </div>
      </div>
    )
  }

  return (
    // <Router>
    <div className="App">
      <MainHeader
        //pageid={prps.pageBySlug.pageBySlug}
        // profileid={prps.profileBySlug.profileBySlug}
        me={me}
      />
      <LateralMenu />
      <Switch>
        <Route path="/cgu" component={Cgu} />
        <Route path="/privacy" component={Privacy} />
        <Route path="/mentions-legales" component={LegalMention} />
        <Route path="/charte-cookies" component={Charter} />
        <Route path="/cards2" component={CreateCardsEdu} />
        <Route path="/cards1" component={CreateCards} />
        <Route path="/cards" component={CreateCardsMenu} />
        <Route exact path="/404" render={() => <Page404 />} />
        <Route
          exact
          path="/logout"
          // This is just meant to be used
          // while we do something else
          render={(props) => {
            const Logout = asyncComponent(
              () =>
                import('containers/Logout').then((module) => module.default),
              { ...props }
            )
            return (
              <div>
                <Logout />
              </div>
            )
          }}
        />
        <Route
          exact
          path="/loading"
          // This is just meant to be used
          // while we do something else
          render={() => <Loading />}
        />
        <Route
          exact
          path="/reset"
          // This is just meant to be used
          // while we do something else
          render={() => <LoginModal />}
        />
        <Route
          exact
          path="/org/pages"
          render={(props) => {
            const isAuthenticated =
              window.DB && window.DB.getItem('whire-app-token')
            const Profiles = asyncComponent(
              () =>
                import('containers/Profiles').then((module) => module.default),
              { ...props }
            )
            return (
              <Fragment>
                {loading ? (
                  <Loading />
                ) : (
                  <Fragment>
                    {me &&
                    (me.origin === 'TRAINING' || me.origin === 'BUSINESS') ? (
                      <Profiles {...props} me={me} xl />
                    ) : (
                      <NoAccess />
                    )}
                  </Fragment>
                )}
              </Fragment>
            )
          }}
        />
        <Route
          exact
          path="/profiles"
          render={(props) => {
            const Profiles = asyncComponent(
              () =>
                import('containers/Profiles').then((module) => module.default),
              { ...props }
            )
            return (
              <Fragment>
                {loading ? (
                  <Loading />
                ) : (
                  <Fragment>
                    {me && me.origin === 'CANDIDATE' ? (
                      <Profiles {...props} me={me} xl />
                    ) : (
                      <NoAccess />
                    )}
                  </Fragment>
                )}
              </Fragment>
            )
          }}
        />
        <Route
          path="/creer-ma-page"
          render={(props) => (
            <Fragment>
              {loading ? (
                <div />
              ) : (
                <Fragment>
                  {me && me.origin === 'CANDIDATE' ? (
                    <CreateProfile {...props} user={me} redirectUrl="/" xl />
                  ) : (
                    <NoAccess />
                  )}
                </Fragment>
              )}
            </Fragment>
          )}
        />
        <Route
          path="/org/creer-ma-page"
          render={(props) => (
            <Fragment>
              {loading ? (
                <div />
              ) : (
                <Fragment>
                  {me &&
                  (me.origin === 'TRAINING' || me.origin === 'BUSINESS') ? (
                    <CreatePage {...props} user={me} returnUrl="/org" xl />
                  ) : (
                    <NoAccess />
                  )}
                </Fragment>
              )}
            </Fragment>
          )}
        />
        <Route
          exact
          path="/org/inscription"
          render={(props) => (
            <Fragment>
              {!me ? (
                <AuthPage {...props} hideRight mode="signup" returnUrl="/org" />
              ) : (
                <Redirect to="/org" />
              )}
            </Fragment>
          )}
        />
        <Route
          exact
          path="/inscription"
          render={(props) => (
            <Fragment>
              {!me ? (
                <AuthPage
                  {...props}
                  hideRight
                  mode="signup"
                  returnUrl="/"
                  // bottomCta={(
                  //   <p className="hideSm">
                  //     Déjà inscrit(e) sur Whire?
                  //     {' '}
                  //     <a style={{ color: '#458' }}>
                  //       C&apos;est par ici !
                  //     </a>
                  //   </p>
                  // )}
                />
              ) : (
                <Redirect to="/" />
              )}
            </Fragment>
          )}
        />
        <Route
          exact
          path="/org/connexion"
          render={(props) => (
            <Fragment>
              {!me ? (
                <LoginModal
                  {...props}
                  returnUrl="/org"
                  headline="Connexion !"
                />
              ) : (
                <Redirect to="/org" />
              )}
            </Fragment>
          )}
        />
        <Route
          exact
          path="/connexion"
          render={(props) => (
            <Fragment>
              {!me ? (
                <LoginModal {...props} returnUrl="/" headline="Connexion !" />
              ) : (
                <Redirect to="/" />
              )}
            </Fragment>
          )}
        />
        <Route
          path="/org/(@[a-zA-Z0-9_.-]*)"
          render={(props) => <CompanyProfile {...props} me={me} />}
        />
        <Route
          path="/(@[a-zA-Z0-9_.-]*)"
          render={(props) => (
            <UserProfile
              {...props}
              me={me}
              //locationProfile={prps.profileBySlug}
            />
          )}
        />
        <Route
          exact
          path="/accompagnement"
          render={(props) => {
            const Support = asyncComponent(
              () =>
                import('modules/Landing/pages/Support').then(
                  (module) => module.default
                ),
              { ...props }
            )
            return (
              <div>
                <Support />
              </div>
            )
          }}
        />
        {/*
         *** Landing Page Organisme intermédiaire
         */}
        <Route
          exact
          path={['/org', '/org/offre', '/org/ecosystem', ['/org/temoignage']]}
          render={(props) => {
            if (isAuthenticated) {
              window.location.replace(getMainProfileUrl())
            }
            const MainOrg = asyncComponent(
              () =>
                import('modules/Landing/pages/MainOrg').then(
                  (module) => module.default
                ),
              { ...props }
            )
            return (
              <div>
                <MainOrg />
              </div>
            )
          }}
        />
        <Route
          exact
          path='/academy'
          render={(props) => {
            if (isAuthenticated) {
              window.location.replace(getMainProfileUrl())
            }
            const MainOrg = asyncComponent(
              () =>
                import('modules/Landing/pages/MainAcademy').then(
                  (module) => module.default
                ),
              { ...props }
            )
            return (
              <div>
                <MainOrg />
              </div>
            )
          }}
        />
        {/*
         *** Créer ma page organisation
         */}
        <Route
          exact
          path="/org/org-login"
          render={(props) => {
            if (isAuthenticated) {
              window.location.replace(getMainProfileUrl())
            }
            const OrgSignUp = asyncComponent(
              () =>
                import('modules/Landing/pages/OrgSignUp').then(
                  (module) => module.default
                ),
              { ...props }
            )
            return (
              <div>
                <OrgSignUp />
              </div>
            )
          }}
        />
        {/*
         *** Formulaire Demander une demo
         */}
        <Route exact path="/demo">
          <Demo />
        </Route>
        {/*
         *** Formulaire Rejoindre le club
         */}
        <Route exact path="/entreprise/rejoindreleclub">
          <Demo />
        </Route>
        {/*
         *** Landing Page Candidat
         */}
        <Route
          exact
          path={[
            '/candidat',
            '/candidat/creermapage',
            '/candidat/whirrior',
            '/candidat/stories',
          ]}
          render={(props) => {
            if (isAuthenticated) {
              window.location.replace(getMainProfileUrl())
            }
            const MainCandidat = asyncComponent(
              () =>
                import('modules/Landing/pages/MainCandidat').then(
                  (module) => module.default
                ),
              { ...props }
            )
            return (
              <div>
                <MainCandidat />
              </div>
            )
          }}
        />
        {/*
         *** Landing Page Employeur
         */}
        <Route
          exact
          path={['/entreprise', '/entreprise/ecosystem', '/entreprise/club']}
          render={(props) => {
            if (isAuthenticated) {
              window.location.replace(getMainProfileUrl())
            }
            const MainEntreprise = asyncComponent(
              () =>
                import('modules/Landing/pages/MainEntreprise').then(
                  (module) => module.default
                ),
              { ...props }
            )
            return (
              <div>
                <MainEntreprise />
              </div>
            )
          }}
        />
        {/*
         *** Landing Page
         */}
        <Route
          exact
          path="/"
          render={(props) => {
            if (isAuthenticated) {
              window.location.replace(getMainProfileUrl())
            }
            const Main = asyncComponent(
              () =>
                import('modules/Landing/pages/Main').then(
                  (module) => module.default
                ),
              { ...props }
            )
            return (
              <div>
                <Main />
              </div>
            )
          }}
        />
        <Route path="/.netlify" render={() => <Fragment />} />
        <Route component={Page404} />
      </Switch>
      {!isAuthenticated ? <Cookies /> : null}
    </div>
    // </Router>
  )
}
// check authLink in apollo.jsx
// for localstorage authentication
const AppWithQuery = compose(
  graphql(UserQuery, {
    // name: 'UserQuery',
    options: { fetchPolicy: 'cache-and-network' },
  }),
  // graphql(PublicProfileFirstQuery, {
  //   options: (props) => ({
  //     variables: {
  //       slug: !window.location.pathname.includes('/org/@')
  //         ? window.location.pathname.substring(2)
  //         : null,
  //     },
  //   }),
  //   name: 'profileBySlug',
  // }),
  // graphql(PageFirstQuery, {
  //   options: (props) => ({
  //     variables: {
  //       slug: window.location.pathname.includes('/org/@')
  //         ? window.location.pathname.substring(6)
  //         : null,
  //     },
  //   }),
  //   name: 'pageBySlug',
  // })
)(withRouter(App))
export default AppWithQuery
