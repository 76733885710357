import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { UikButton } from 'uikit/components'
import { openDialog } from 'uploadcare-widget'

const Webcam = (props) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  const handleUpload = (form) => {
    openDialog(
      null,
      {
        multiple: false,
        previewStep: true,
        doNotStore: false,
        fileOnly: true,
        tabs: 'file camera dropbox instagram',
      },
      'video'
    ).done((file) => {
      setLoading(true)
      file.promise().done((fileInfo) => {
        if (
          !fileInfo.name.match(
            /.(webm|avi|mkv|wmf|mp4|m4v|mpg|mpeg|asf|wma|wmv|flv|ogg|ogv|oga|ogx|ogm|xvid|mov)$/i
          )
        ) {
          form.setFieldValue(null)
          setLoading(false)
          setError(true)
        } else {
          setLoading(false)
          setError(false)
          form.setFieldValue(props.field.id, fileInfo.uuid)
          // form.setFieldValue(props.field.id, fileInfo.originalUrl);
        }
      })
    })
  }

  return (
    <div style={{ marginTop: '20px' }}>
      {props.value ? (
        <UikButton onClick={() => props.setFieldValue(props.field.id, '')}>
          Supprimer ma vidéo
        </UikButton>
      ) : (
        <UikButton
          success
          isLoading={loading}
          onClick={() => handleUpload(props)}
        >
          <span role="img" aria-label="add images">
            {' '}
            🎥{' '}
          </span>{' '}
          Ajouter ma vidéo
        </UikButton>
      )}
      {error ? (
        <p style={{ color: '#E65037', fontSize: '12px' }}>
          Le format n'est pas supporté !
        </p>
      ) : null}
    </div>
  )
}

Webcam.propTypes = {
  value: PropTypes.string.isRequired,
  field: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  setFieldValue: PropTypes.func.isRequired,
}

export default Webcam
