/*

  Sets/Unsets main profile.
  Used to create top bar menu.

  EG. "Nouvelle Story" will redirect user to
  '/@{mainProfile.slug}/nouvelle-story'

  Please note that mainProfile can one of
  - user profile
  - organization page

*/

import * as ROUTES from './routes'
import { getUser } from './handleUser'
const PROFILE_ITEM = 'whire-app-main-profile-auto-key'

export const setMainProfile = (profile, type) => {
  window.DB.setItem(
    PROFILE_ITEM,
    JSON.stringify({
      ...profile,
      type,
    })
  )
}

export const resetMainProfile = () => {
  window.DB.removeItem(PROFILE_ITEM)
}

export const getMainProfile = () => {
  const data = window.DB.getItem(PROFILE_ITEM)
  return data ? JSON.parse(data) : null
}

export const getMainProfileUrl = () => {
  const data = window.DB.getItem(PROFILE_ITEM)
  const user = getUser()

  if (!data && !!user && !!user.origin) {
    return ROUTES.PROFILES(user.origin)
  } else if (!data) {
    return window.location.pathname.includes('/org')
      ? '/org/pages'
      : '/profiles'
  }

  const parsedData = JSON.parse(data)
  const location = window.location.pathname

  if (!!user && !!user.origin) {
    if (location === '/' || location === '/org' || location === '/academy' ||  location === '/academy' ||
      location === '/entreprise' || location === '/org/offre' || location === '/org/ecosystem' || 
      location === '/org/temoignage' || location == '/entreprise/ecosystem' || location === '/entreprise/club' ||
      location === '/candidat/whirrior' || location === '/candidat/stories' || location === '/candidat/creermapage') {
      return ROUTES.SLUG(user.origin, parsedData.slug)
    } else return ROUTES.ERROR()
  }

  // if (!!user && !!user.origin) {
  //   return ROUTES.SLUG(user.origin, parsedData.slug);
  // } "/(@[a-zA-Z0-9_.-]*)

  return null
}
