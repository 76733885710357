import React, { Component, useState } from 'react'

import { Link, Redirect, Route, Switch } from 'react-router-dom'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import { compose } from 'recompose'

import Drawer from 'rc-drawer'
import 'rc-drawer/assets/index.css'
import asyncComponent from 'utils/AsyncComponent'

import Modal from 'react-modal'
import ModalContent from 'components/ModalContent'

import ContactCompany from 'components/ContactCompany'

import {
  COMPANY_STORY_TYPE_ITERABLE,
  COMPANY_STORY_TYPE_ARRAY,
  SVG_URI,
} from 'utils/vars'

import {
  PageFirstQuery,
  EMAIL_COMPANY,
  CreateNotificationMutation,
  UpdateNotificationNumber,
  CreateNotificationNumberMutation,
} from 'gql/page'
import {
  CreateProfileRequestMutation,
  DeleteProfileRequestMutation,
} from 'gql/profile'
import { getMainProfile } from 'utils/handleMainProfile'

import Navigation from 'modules/CompanyProfile/components/Navigation'

import {
  // UikPageFade,
  UikContainerVertical,
} from 'uikit/containers'

import { UikButton } from 'uikit/components'
import * as ROUTES from 'utils/routes'

import TraineesModal from 'modules/CompanyProfile/TraineesModal.js'
import Loading from 'components/Loading'
import Page404 from 'pages/page404'
import Page500 from 'pages/page500'
import NoAccess from 'pages/NoAccess'
import NoAccessPremium from 'pages/NoAccessPremium'
import ChromeHeader from 'components/ChromeHeader'
import Affix from 'components/Affix'
import Thread from 'modules/Thread'
// import CreateStoryMenu from 'modules/UserProfile/pages/CreateStoryMenu';

import PublicPages from './PublicPages'
import cls from 'initStyles/App.scss'

Modal.setAppElement('#root')
const customStyles = {
  content: {
    border: 'none',
    padding: '0',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    minHeight: '50%',
    minWidth: '50%',
    transform: 'translate(-50%, -50%)',
  },
  overlay: {
    zIndex: '1',
    background: 'rgba(0,0,0,.3)',
  },
}
const customStyles2 = {
  content: {
    border: 'none',
    padding: '0',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    minHeight: '30%',
    minWidth: '50%',
    transform: 'translate(-50%, -50%)',
  },
  overlay: {
    zIndex: '1',
    background: 'rgba(0,0,0,.3)',
  },
}
class CompanyModule extends Component {
  constructor(props) {
    super(props)
    this.state = {
      modalIsOpen: false,
      draft: false,
      authModal: true,
      hideButton: false,
      modalGroupIsOpen: false,
    }
    this.previousLocation = this.props.location
  }

  componentWillUpdate(nextProps) {
    const { location } = this.props
    // set previousLocation if props.location is not modal
    if (
      nextProps.history.action !== 'POP' &&
      (!location.state || !location.state.modal)
    ) {
      this.previousLocation = this.props.location
    }
  }

  setDraft = (draft) => {
    this.setState({ draft })
  }

  render() {
    const {
      me,
      location,
      match: {
        params: { 0: userName },
      },
      pageBySlug: {
        loading: pageLoading,
        error: pageError,
        pageBySlug: dataPages,
      },
      emailOfSlug: {
        loading: emailLoading,
        error: emailError,
        page: dataEmail,
      },
      createProfileRequest,
      deleteProfileRequest,
      createNotification,
      createNotificationNumber,
      updateNotificationNumber,
    } = this.props

    if (pageError || emailError) {
      return <Page404 />
    } else if (pageLoading || emailLoading) {
      return <Loading />
    } else {
      const {
        id,
        image,
        imageModifier,
        video,
        summary,
        title,
        tags,
        slug,
        isPublished,
        company: { name },
        sections,
        trainees,
        // owner,
        links: pageLinks,
        notificationNumbers,
      } = dataPages

      const {
        company: {
          owner: { email },
        },
      } = dataEmail

      const loc = window.location.pathname.includes('trainees')

      const userIsOwner = me && me.pages && me.pages.find((e) => e.id === id)

      const isModal = !!(
        location.state &&
        location.state.modal &&
        this.previousLocation !== location
      )
      const navSections = [
        COMPANY_STORY_TYPE_ITERABLE.PAGE,
        ...Object.keys(COMPANY_STORY_TYPE_ITERABLE)
          .map((key) => {
            const section = sections.find(
              (e) =>
                e.storiesTypeExpected === key &&
                e.stories.some((story) => story.isPublished)
            )
            if (section) {
              return {
                ...COMPANY_STORY_TYPE_ITERABLE[key],
                title: COMPANY_STORY_TYPE_ITERABLE[key].text,
                rightEl: section.stories.filter((s) => s.isPublished).length,
                userSection: section,
              }
            }
            return null
          })
          .filter((e) => e),
      ]

      const navSectionsDraft = [
        COMPANY_STORY_TYPE_ITERABLE.PAGE,
        ...Object.keys(COMPANY_STORY_TYPE_ITERABLE)
          .map((key) => {
            const section = sections.find(
              (e) =>
                e.storiesTypeExpected === key &&
                e.stories.some((story) => !story.isPublished)
            )
            if (section) {
              return {
                ...COMPANY_STORY_TYPE_ITERABLE[key],
                title: COMPANY_STORY_TYPE_ITERABLE[key].text,
                rightEl: section.stories.filter((s) => !s.isPublished).length,
                userSection: section,
              }
            }
            return null
          })
          .filter((e) => e),
      ]

      const mainProfile = getMainProfile()
      const idProfileRequestAndMe = mainProfile
        ? dataPages.company.owner.profileRequests
            .map((profile) => profile)
            .find((profile) =>
              profile.profile ? profile.profile.id === mainProfile.id : null
            )
        : null
      const isMatchedForCancel = mainProfile
        ? dataPages.company.owner.profileRequests
            .map((profile) => profile)
            .find((profile) =>
              profile.profile
                ? profile.profile.id === mainProfile.id &&
                  profile.status === 'PENDING'
                : null
            )
        : null
      const isMatchedForDelete = mainProfile
        ? dataPages.company.owner.profileRequests
            .map((profile) => profile)
            .find((profile) =>
              profile.profile
                ? profile.profile.id === mainProfile.id &&
                  profile.status === 'ACCEPTED'
                : null
            )
        : null
      const plan = dataPages.company.owner.plan
      const licences = dataPages.licences

      return (
        <div className="App">
          <UikContainerVertical>
            <Switch location={isModal ? this.previousLocation : location}>
              <Route
                exact
                path="/org/(@[a-zA-Z0-9_.-]*)/nouvelle-story"
                render={(props) => {
                  if (userIsOwner) {
                    const CreateStoryMenu = asyncComponent(
                      () =>
                        import('../../pages/CreateStoryMenu').then(
                          (module) => module.default
                        ),
                      { ...props }
                    )
                    return (
                      <CreateStoryMenu
                        {...props}
                        baseUrl={`/org/@${slug}`}
                        userType="TRAINING"
                      />
                    )
                  }
                  return (
                    <div>
                      <NoAccess />
                    </div>
                  )
                }}
              />
              <Route
                exact
                path="/org/(@[a-zA-Z0-9_.-]*)/dashboard"
                render={(props) => {
                  if (userIsOwner) {
                    const Dashboard = asyncComponent(
                      () =>
                        import('./pages/dashboard/Request').then(
                          (module) => module.default
                        ),
                      { ...props }
                    )
                    return (
                      <div>
                        <Dashboard returnUrl={`/org/@${slug}`} {...props} />
                      </div>
                    )
                  }
                  return (
                    <div>
                      <NoAccess />
                    </div>
                  )
                }}
              />
              <Route
                exact
                path="/org/(@[a-zA-Z0-9_.-]*)/dashboard/mes-candidats"
                render={(props) => {
                  if (userIsOwner) {
                    const Dashboard = asyncComponent(
                      () =>
                        import('./pages/dashboard/AcceptedRequest').then(
                          (module) => module.default
                        ),
                      { ...props }
                    )
                    return (
                      <div>
                        <Dashboard returnUrl={`/org/@${slug}`} {...props} />
                      </div>
                    )
                  }
                  return (
                    <div>
                      <NoAccess />
                    </div>
                  )
                }}
              />
              <Route
                exact
                path="/org/(@[a-zA-Z0-9_.-]*)/dashboard/mes-candidats-archivés"
                render={(props) => {
                  if (userIsOwner) {
                    const Dashboard = asyncComponent(
                      () =>
                        import('./pages/dashboard/ArchivedRequest').then(
                          (module) => module.default
                        ),
                      { ...props }
                    )
                    return (
                      <div>
                        <Dashboard returnUrl={`/org/@${slug}`} {...props} />
                      </div>
                    )
                  }
                  return (
                    <div>
                      <NoAccess />
                    </div>
                  )
                }}
              />
              <Route
                exact
                path="/org/(@[a-zA-Z0-9_.-]*)/edit-mail"
                render={(props) => {
                  if (userIsOwner) {
                    const EditMail = asyncComponent(
                      () =>
                        import('../../containers/EditMail').then(
                          (module) => module.default
                        ),
                      { ...props }
                    )
                    return (
                      <div>
                        <EditMail returnUrl={`/org/@${slug}`} {...props} />
                      </div>
                    )
                  }
                  return (
                    <div>
                      <NoAccess />
                    </div>
                  )
                }}
              />
              <Route
                exact
                path="/org/(@[a-zA-Z0-9_.-]*)/edit-password"
                render={(props) => {
                  if (userIsOwner) {
                    const EditPassword = asyncComponent(
                      () =>
                        import('../../containers/EditPassword').then(
                          (module) => module.default
                        ),
                      { ...props }
                    )
                    return (
                      <div>
                        <EditPassword returnUrl={`/org/@${slug}`} {...props} />
                      </div>
                    )
                  }
                  return (
                    <div>
                      <NoAccess />
                    </div>
                  )
                }}
              />
              <Route
                exact
                path="/org/(@[a-zA-Z0-9_.-]*)/referents"
                render={(props) => {
                  if (userIsOwner) {
                    const EditPassword = asyncComponent(
                      () =>
                        import('../../containers/ReferentForm').then(
                          (module) => module.default
                        ),
                      { ...props }
                    )
                    return (
                      <div>
                        <EditPassword
                          returnUrl={`/org/@${slug}`}
                          owner={dataPages.company.owner}
                          {...props}
                        />
                      </div>
                    )
                  }
                  return (
                    <div>
                      <NoAccess />
                    </div>
                  )
                }}
              />
              <Route
                exact
                path="/org/(@[a-zA-Z0-9_.-]*)/ressources/guide"
                render={(props) => {
                  if (userIsOwner) {
                    const Guide = asyncComponent(
                      () =>
                        import('../../components/Guide').then(
                          (module) => module.default
                        ),
                      { ...props }
                    )
                    if (plan === 'PREMIUM') {
                      return (
                        <div>
                          <Guide
                            returnUrl={`/org/@${slug}`}
                            owner={dataPages.company.owner}
                            {...props}
                          />
                        </div>
                      )
                    }
                    return (
                      <div>
                        <NoAccessPremium />
                      </div>
                    )
                  }
                  return (
                    <div>
                      <NoAccess />
                    </div>
                  )
                }}
              />
              <Route
                exact
                path="/org/(@[a-zA-Z0-9_.-]*)/ressources/whire-academy"
                render={(props) => {
                  if (userIsOwner) {
                    const WhireAcademy = asyncComponent(
                      () =>
                        import('../../components/WhireAcademy').then(
                          (module) => module.default
                        ),
                      { ...props }
                    )
                    if (plan === 'PREMIUM') {
                      return (
                        <div>
                          <WhireAcademy
                            returnUrl={`/org/@${slug}`}
                            owner={dataPages.company.owner}
                            {...props}
                          />
                        </div>
                      )
                    }
                    return (
                      <div>
                        <NoAccessPremium />
                      </div>
                    )
                  }
                  return (
                    <div>
                      <NoAccess />
                    </div>
                  )
                }}
              />
              <Route
                exact
                path="/org/(@[a-zA-Z0-9_.-]*)/ressources/cards"
                render={(props) => {
                  if (userIsOwner) {
                    const CardsRessources = asyncComponent(
                      () =>
                        import('../../components/CardsRessources').then(
                          (module) => module.default
                        ),
                      { ...props }
                    )
                    if (plan === 'PREMIUM') {
                      return (
                        <div>
                          <CardsRessources
                            returnUrl={`/org/@${slug}`}
                            owner={dataPages.company.owner}
                            {...props}
                          />
                        </div>
                      )
                    }
                    return (
                      <div>
                        <NoAccessPremium />
                      </div>
                    )
                  }
                  return (
                    <div>
                      <NoAccess />
                    </div>
                  )
                }}
              />
              <Route
                exact
                path="/org/(@[a-zA-Z0-9_.-]*)/home"
                render={(props) => {
                  if (userIsOwner) {
                    const Homepage = asyncComponent(
                      () =>
                        import('../../components/Homepage').then(
                          (module) => module.default
                        ),
                      { ...props }
                    )
                    return (
                      <div>
                        <Homepage
                          returnUrl={`/org/@${slug}`}
                          owner={dataPages.company.owner}
                          licences={licences}
                          {...props}
                        />
                      </div>
                    )
                  }
                  return (
                    <div>
                      <NoAccess />
                    </div>
                  )
                }}
              />
              <Route
                exact
                path="/org/(@[a-zA-Z0-9_.-]*)/trainees/:id/edit"
                render={(props) => {
                  if (userIsOwner) {
                    const EditTraining = asyncComponent(
                      () =>
                        import('./pages/EditTraining').then(
                          (module) => module.default
                        ),
                      { ...props }
                    )
                    return (
                      <div>
                        <EditTraining
                          returnUrl={`/org/@${slug}`}
                          {...props}
                          page={this.props.pageBySlug.pageBySlug}
                        />
                      </div>
                    )
                  }
                  return (
                    <div>
                      <NoAccess />
                    </div>
                  )
                }}
              />
              <Route
                exact
                path="/org/(@[a-zA-Z0-9_.-]*)/trainees"
                render={(props) => {
                  if (userIsOwner) {
                    const TraineesPage = asyncComponent(
                      () =>
                        import('./pages/TraineesPage').then(
                          (module) => module.default
                        ),
                      { ...props }
                    )
                    if (plan === 'PREMIUM') {
                      return (
                        <div>
                          <TraineesPage
                            returnUrl={`/org/@${slug}`}
                            {...props}
                            page={this.props.pageBySlug.pageBySlug}
                          />
                        </div>
                      )
                    }
                    return (
                      <div>
                        <NoAccessPremium />
                      </div>
                    )
                  }
                  return (
                    <div>
                      <NoAccess />
                    </div>
                  )
                }}
              />
              <Route
                exact
                path="/org/(@[a-zA-Z0-9_.-]*)/trainees/:id"
                render={(props) => {
                  window
                  const isAuthenticated =
                    window.DB && window.DB.getItem('whire-app-token')
                  const hasMail =
                    window.DB && window.DB.getItem('whire-app-emailVisitor')
                  // if(isAuthenticated){
                  const { params } = props.match
                  const match = Object.assign(
                    {},
                    {
                      ...props.match,
                      params: {
                        ...params,
                        // On /@userName/edit
                        // matchPath returns appends === undefined.
                        // To match our navigation first link,
                        // we need to replace it wiith an empty string
                        appends:
                          params.appends === undefined ? '' : params.appends,
                      },
                    }
                  )
                  // This won't hold when we add custom sections
                  const matchedStoryType = COMPANY_STORY_TYPE_ARRAY.find(
                    (e) => e.appends === match.params.appends
                  )

                  if (!matchedStoryType) {
                    return <Redirect to={`/org/${userName}`} />
                  }
                  const matchedStoryTypeText = matchedStoryType.text
                  const Trainees = asyncComponent(
                    () =>
                      import('modules/Trainees').then(
                        (module) => module.default
                      ),
                    { ...props }
                  )
                  return (
                    <div>
                      {/* { !isAuthenticated && !hasMail ? (
                          <TraineesModal />
                          ) : null
                        }                    */}
                      {!userIsOwner ? (
                        <Affix
                          offset={50}
                          style={{ top: 0 }}
                          wrapperStyle={{
                            height: '50px',
                            borderTop: '1px solid #DADEE7',
                          }}
                        >
                          <ChromeHeader
                            standalone
                            offset={50}
                            info={{
                              appends:
                                match.params.appends !== ''
                                  ? matchedStoryTypeText
                                  : undefined,
                              title: {
                                Component: Link,
                                text: title,
                                params: {
                                  to: `/org/@${slug}`,
                                  href: `/org/@${slug}`,
                                },
                              },
                              // userName: slug,
                            }}
                            profile={{
                              displayName: name,
                              userName: slug,
                            }}
                            icon={{
                              alt: 'menu',
                              height: '16px',
                              src: `${SVG_URI}/menu.svg`,
                              width: '16px',
                            }}
                            items={
                              //   userIsOwner && !loc ? [{
                              //     text: '✍ Modifier ma page',
                              //       onClick: () => {
                              //         //window.scroll fait page blanche
                              //         // window.scroll(0, 0);
                              //         this.props.history.push(`/org/@${slug}/edit`);
                              //       }
                              //   },
                              // ]:
                              !userIsOwner
                                ? [
                                    {
                                      text: `Nous contacter`,
                                      onClick: () => {
                                        this.setState({
                                          modalIsOpen: true,
                                        })
                                      },
                                    },
                                  ]
                                : // : userIsOwner && loc ? [{
                                  //   text: '✍ Modifier le groupe',
                                  //   onClick: () => {
                                  //     // window.scroll(0, 0);
                                  //     this.props.history.push(`${window.location.pathname}/edit`);
                                  //   }
                                  // },
                                  // ]
                                  [null]
                            }
                            // contactUser={() => this.setState({ modalIsOpen: true })}
                            onIconClick={() =>
                              this.setState({
                                navigationIsOpen: true,
                              })
                            }
                          />
                        </Affix>
                      ) : null}
                      <Trainees
                        returnUrl={`/org/@${slug}`}
                        {...props}
                        page={this.props.dataPages}
                        sections={sections}
                        tags={
                          (props.location.state && props.location.state.tags) ||
                          []
                        }
                      />
                    </div>
                  )
                  // }
                  return (
                    <div>
                      <NoAccess />
                    </div>
                  )
                }}
              />
              <Route
                exact
                path="/org/(@[a-zA-Z0-9_.-]*)/nouvelle/:appends"
                render={(props) => {
                  if (userIsOwner) {
                    const CreateStory = asyncComponent(
                      () =>
                        import('../UserProfile/pages/CreateStory').then(
                          (module) => module.default
                        ),
                      { ...props }
                    )

                    return (
                      <div>
                        <CreateStory
                          returnUrl={`/org/@${slug}`}
                          userType="TRAINING"
                          existingTags={[]}
                          {...props}
                          page={this.props.pageBySlug.pageBySlug}
                        />
                      </div>
                    )
                  }
                  return (
                    <div>
                      <NoAccess />
                    </div>
                  )
                }}
              />
              <Route
                exact
                path="/org/(@[a-zA-Z0-9_.-]*)/:appends/:id/edit"
                render={(props) => {
                  if (userIsOwner) {
                    const EditStory = asyncComponent(
                      () =>
                        import('../UserProfile/pages/EditStory').then(
                          (module) => module.default
                        ),
                      { ...props }
                    )
                    //  const tags = [];
                    return (
                      <div>
                        <EditStory
                          returnUrl={`/org/@${slug}`}
                          userType="TRAINING"
                          existingTags={[]}
                          {...props}
                          page={this.props.dataPages}
                        />
                      </div>
                    )
                  }
                  return (
                    <div>
                      <NoAccess />
                    </div>
                  )
                }}
              />
              <Route
                exact
                path="/org/(@[a-zA-Z0-9_.-]*)/edit"
                render={(props) => {
                  if (userIsOwner) {
                    const EditPage = asyncComponent(
                      () =>
                        import('./pages/EditPage').then(
                          (module) => module.default
                        ),
                      { ...props }
                    )
                    return (
                      <div>
                        <EditPage
                          returnUrl={`/org/@${slug}`}
                          {...props}
                          page={this.props.pageBySlug.pageBySlug}
                          user={me}
                        />
                      </div>
                    )
                  }
                  return (
                    <div>
                      <NoAccess />
                    </div>
                  )
                }}
              />
              <Route
                exact
                path="/org/(@[a-zA-Z0-9_.-]*)/creer-ma-section"
                render={(props) => {
                  if (userIsOwner) {
                    const CreateTraining = asyncComponent(
                      () =>
                        import('./pages/CreateTraining').then(
                          (module) => module.default
                        ),
                      { ...props }
                    )
                    if (plan === 'PREMIUM') {
                      return (
                        <div>
                          <CreateTraining
                            returnUrl={`/org/@${slug}`}
                            {...props}
                            page={this.props.pageBySlug.pageBySlug}
                          />
                        </div>
                      )
                    }
                    return (
                      <div>
                        <NoAccessPremium />
                      </div>
                    )
                  }
                  return (
                    <div>
                      <NoAccess />
                    </div>
                  )
                }}
              />
              <Route
                exact
                path="/org/(@[a-zA-Z0-9_.-]*)"
                render={(props) => {
                  const { params } = props.match
                  const match = Object.assign(
                    {},
                    {
                      ...props.match,
                      params: {
                        ...params,
                        // On /@userName/edit
                        // matchPath returns appends === undefined.
                        // To match our navigation first link,
                        // we need to replace it wiith an empty string
                        appends:
                          params.appends === undefined ? '' : params.appends,
                      },
                    }
                  )

                  // This won't hold when we add custom sections
                  const matchedStoryType = COMPANY_STORY_TYPE_ARRAY.find(
                    (e) => e.appends === match.params.appends
                  )

                  if (!matchedStoryType) {
                    return <Redirect to={`/org/${userName}`} />
                  }
                  const matchedStoryTypeText = matchedStoryType.text

                  return (
                    <div>
                      {!userIsOwner ? (
                        <Affix
                          offset={50}
                          style={{ top: 0 }}
                          wrapperStyle={{
                            height: '50px',
                            borderTop: '1px solid #DADEE7',
                          }}
                        >
                          <ChromeHeader
                            standalone
                            offset={50}
                            info={{
                              appends:
                                match.params.appends !== ''
                                  ? matchedStoryTypeText
                                  : undefined,
                              title: {
                                Component: Link,
                                text: title,
                                params: {
                                  to: `/org/@${slug}`,
                                  href: `/org/@${slug}`,
                                },
                              },
                              // userName: slug,
                            }}
                            profile={{
                              displayName: name,
                              userName: slug,
                            }}
                            icon={{
                              alt: 'menu',
                              height: '16px',
                              src: `${SVG_URI}/menu.svg`,
                              width: '16px',
                            }}
                            newRequest={
                              me &&
                              me.origin === 'CANDIDATE' &&
                              mainProfile &&
                              !isMatchedForCancel &&
                              !isMatchedForDelete &&
                              !this.state.hideButton
                                ? [
                                    {
                                      text: 'Nous rejoindre',
                                      onClick: () => {
                                        this.setState({
                                          modalGroupIsOpen: true,
                                        })
                                        // createProfileRequest({
                                        //     variables: {
                                        //         slugPage: slug,
                                        //         slugProfile: mainProfile.slug
                                        //     }
                                        // })
                                        // .then((res) => console.log(res, "RESPONSE PROFILE REQUEST")|| this.setState({hideButton: true}) || window.location.reload())
                                        // .catch((err) => console.log(err, "ERROR"))
                                      },
                                    },
                                  ]
                                : null
                            }
                            cancelRequest={
                              me &&
                              me.origin === 'CANDIDATE' &&
                              mainProfile &&
                              isMatchedForCancel &&
                              !isMatchedForDelete &&
                              !this.state.hideButton
                                ? [
                                    {
                                      text: 'Annuler ma demande',
                                      onClick: () => {
                                        deleteProfileRequest({
                                          variables: {
                                            id: idProfileRequestAndMe.id,
                                          },
                                        })
                                          .then(
                                            (res) =>
                                              console.log(
                                                res,
                                                'RESPONSE PROFILE REQUEST'
                                              ) ||
                                              this.setState({
                                                hideButton: true,
                                              }) ||
                                              window.location.reload()
                                          )
                                          .catch((err) =>
                                            console.log(err, 'ERROR')
                                          )
                                      },
                                    },
                                  ]
                                : null
                            }
                            deleteRequest={
                              me &&
                              me.origin === 'CANDIDATE' &&
                              mainProfile &&
                              isMatchedForDelete &&
                              !this.state.hideButton
                                ? [
                                    {
                                      text: 'Quitter le groupe',
                                      onClick: () => {
                                        deleteProfileRequest({
                                          variables: {
                                            id: idProfileRequestAndMe.id,
                                          },
                                        })
                                          .then(
                                            (res) =>
                                              console.log(
                                                res,
                                                'RESPONSE PROFILE REQUEST'
                                              ) ||
                                              this.setState({
                                                hideButton: true,
                                              }) ||
                                              window.location.reload()
                                          )
                                          .catch((err) =>
                                            console.log(err, 'ERROR')
                                          )
                                      },
                                    },
                                  ]
                                : null
                            }
                            items={
                              !isPublished && userIsOwner
                                ? [
                                    {
                                      text: 'Publier ma page',
                                      onClick: () => {
                                        window.scroll(0, 0)
                                        this.props.history.push(
                                          `/org/@${slug}/edit`
                                        )
                                      },
                                    },
                                  ]
                                : userIsOwner
                                ? [
                                    {
                                      text: 'Modifier ma page',
                                      onClick: () => {
                                        //window.scroll fait page blanche
                                        // window.scroll(0, 0);
                                        this.props.history.push(
                                          `/org/@${slug}/edit`
                                        )
                                      },
                                    },
                                  ]
                                : !userIsOwner
                                ? [
                                    {
                                      text: `Découvrez nos profils`,
                                      onClick: () => {
                                        window.scroll(0, 800)
                                      },
                                    },
                                  ]
                                : [null]
                            }
                            contactUs={
                              !userIsOwner
                                ? [
                                    {
                                      text: `Nous contacter`,
                                      onClick: () => {
                                        this.setState({
                                          modalIsOpen: true,
                                        })
                                      },
                                    },
                                  ]
                                : null
                            }
                            newStory={
                              userIsOwner && isPublished
                                ? [
                                    {
                                      text: 'Nouvelle Story',
                                      onClick: () => {
                                        window.scroll(0, 0)
                                        this.props.history.push(
                                          `/org/@${slug}/nouvelle-story`
                                        )
                                      },
                                    },
                                  ]
                                : null
                            }
                            // contactUser={() => this.setState({ modalIsOpen: true })}
                            onIconClick={() =>
                              this.setState({
                                navigationIsOpen: true,
                              })
                            }
                          />
                        </Affix>
                      ) : (
                        ''
                      )}
                      {isPublished || userIsOwner ? (
                        <PublicPages
                          match={match}
                          history={this.props.history}
                          navSections={navSections}
                          navSectionsDraft={navSectionsDraft}
                          setDraft={this.setDraft}
                          links={pageLinks}
                          openModal={() =>
                            this.setState({
                              modalIsOpen: true,
                            })
                          }
                          sections={sections}
                          summary={summary}
                          slug={slug}
                          title={title}
                          image={image}
                          imageModifier={imageModifier}
                          video={video}
                          navUser={{
                            imgUrl: image,
                            name: title,
                            imageUrlLarge: image,
                            userName: slug,
                          }}
                          tags={tags}
                          trainees={trainees}
                          dataPages={dataPages}
                          me={me}
                        />
                      ) : (
                        <div
                          style={{
                            fontSize: '40px',
                            maxWidth: '500px',
                            margin: 'auto',
                            textAlign: 'center',
                          }}
                        >
                          <br />
                          <br />
                          <br />
                          <br />
                          🤔 Cette page n'existe pas, ou n'est pas publique...
                          <br />
                          <br />
                          <br />
                          <UikButton
                            onClick={() =>
                              this.props.history.push(
                                ROUTES.HOME(!!me && me.origin)
                              )
                            }
                          >
                            {' '}
                            Revenir à la page d'accueil{' '}
                          </UikButton>
                        </div>
                      )}
                    </div>
                  )
                }}
              />
              <Route component={Page404} />
            </Switch>
            <Drawer
              handler={null}
              onMaskClick={() => this.setState({ navigationIsOpen: false })}
              open={this.state.navigationIsOpen}
            >
              <Navigation
                location={this.props.location}
                imgUrl={imageModifier ? `${image}/${imageModifier}` : image}
                name={title}
                summary={summary}
                onLinkIsClicked={() =>
                  this.setState({ navigationIsOpen: false })
                }
                sections={navSections}
                slug={slug}
                trainees={trainees}
              />
            </Drawer>
          </UikContainerVertical>
          {isModal && (
            <Drawer
              ease="cubic-bezier(12, 12, 12, 12)"
              handler={false}
              level={null}
              onMaskClick={() =>
                this.props.history.push(
                  Object.assign(
                    {},
                    {
                      ...this.previousLocation,
                      state: { modal: false },
                    }
                  )
                )
              }
              open
              placement="right"
              width={
                /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(
                  navigator.userAgent
                )
                  ? '100%'
                  : '650px'
              }
              wrapperClassName={cls.threadWrapper}
            >
              <Route
                exact
                path="/org/(@[a-zA-Z0-9_.-]*)/:appends?"
                render={(props) => {
                  const { params } = props.match
                  const match = Object.assign(
                    {},
                    {
                      ...props.match,
                      params: {
                        ...params,
                        // On /@userName/edit
                        // matchPath returns appends === undefined.
                        // To match our navigation first link,
                        // we need to replace it wiith an empty string
                        appends:
                          params.appends === undefined ? '' : params.appends,
                      },
                    }
                  )

                  const thisNavSection = navSections.find(
                    (e) => e.appends === match.params.appends
                  )
                  const thisNavSectionDraft = navSectionsDraft.find(
                    (e) => e.appends === match.params.appends
                  )

                  // const indexUpdatedStory = thisNavSection.userSection.stories
                  //   .findIndex(story => story.id === updatedStory.id);
                  // thisNavSection.userSection.stories
                  //   .splice(indexUpdatedStory, 1, updatedStory);

                  return (
                    <div
                      style={{
                        paddingBottom: '60px',
                        background: '#FBFBFD',
                      }}
                    >
                      <Thread
                        baseUrl="org/"
                        match={match}
                        // links={links}
                        onClose={() =>
                          this.props.history.push(
                            Object.assign(
                              {},
                              {
                                ...this.previousLocation,
                                state: {
                                  modal: false,
                                },
                              }
                            )
                          )
                        }
                        navSections={
                          !this.state.draft ? navSections : navSectionsDraft
                        }
                        userStories={
                          !this.state.draft
                            ? thisNavSection.userSection.stories.filter(
                                (s) => s.isPublished
                              )
                            : thisNavSectionDraft.userSection.stories.filter(
                                (s) => !s.isPublished
                              )
                        }
                        transport={
                          !this.state.draft
                            ? thisNavSection
                            : thisNavSectionDraft
                        }
                        ref={this.thread}
                        userIsOwner={userIsOwner}
                      />
                    </div>
                  )
                }}
              />
            </Drawer>
          )}
          <Modal
            isOpen={this.state.modalIsOpen}
            // onAfterOpen={this.afterOpenModal}
            onRequestClose={() => this.setState({ modalIsOpen: false })}
            style={customStyles}
            contentLabel="Example Modal"
          >
            <ModalContent
              header="👇 Nous contacter"
              bottomCta={{
                title: '👆 Sans compte et 100% gratuit',
              }}
            >
              <ContactCompany />
            </ModalContent>
          </Modal>
          <Modal
            isOpen={this.state.modalGroupIsOpen}
            // onAfterOpen={this.afterOpenModal}
            onRequestClose={() => this.setState({ modalGroupIsOpen: false })}
            style={customStyles2}
            contentLabel="Example Modal"
          >
            <ModalContent header="👇 Rejoindre ce groupe">
              <p style={{ marginBottom: '2em' }}>
                En nous rejoignant, vous confirmez avoir pris connaissance et{' '}
                <br></br> avoir accepté la politique de confidentialité de notre
                structure
              </p>
              <button
                className={`${cls.buttonRequest} ${cls.newRequest}`}
                style={{ marginRight: '2em' }}
                onClick={() => {
                  createNotification({
                    variables: {
                      viewer: mainProfile.title,
                      // if i'm an org send the slug with the /org"
                      slug: `@${mainProfile.slug}`,
                      notificationMessage: 'souhaite vous rejoindre',
                      image: mainProfile.image,
                      imageModifier: mainProfile.imageModifier,
                      pageSlug: slug,
                    },
                  })
                    .then((res) => {
                      console.log(res, 'meeee resss')
                    })
                    .catch((err) => {
                      console.log(err, 'meee errrrroo')
                    })
                  notificationNumbers.length < 1
                    ? createNotificationNumber({
                        variables: {
                          number: 1,
                          pageSlug: slug,
                        },
                      })
                        .then((res) => {
                          console.log(res, 'me res number')
                        })
                        .catch((err) => {
                          console.log(err, 'me error number')
                        })
                    : updateNotificationNumber({
                        variables: {
                          id: notificationNumbers[0].id,
                          number: notificationNumbers[0].number + 1,
                        },
                      })
                        .then((res) => {
                          console.log(res, 'update notification number success')
                        })
                        .catch((err) => {
                          console.log(
                            err,
                            'update update notification number error'
                          )
                        })
                  createProfileRequest({
                    variables: {
                      // slugPage: slug,
                      emailPage: email,
                      slugProfile: mainProfile.slug,
                    },
                  })
                    .then(
                      (res) =>
                        console.log(res, 'RESPONSE PROFILE REQUEST') ||
                        this.setState({
                          hideButton: true,
                        }) ||
                        window.location.reload()
                    )
                    .catch((err) => console.log(err, 'ERROR'))
                }}
              >
                Nous rejoindre
              </button>
              <button
                className={`${cls.buttonRequest} ${cls.deleteRequest}`}
                onClick={() => this.setState({ modalGroupIsOpen: false })}
              >
                Annuler
              </button>
            </ModalContent>
          </Modal>
        </div>
      )
    }
  }
}

export default compose(
  graphql(PageFirstQuery, {
    options: (props) => ({
      variables: {
        slug: props.match.params['0'].slice(1),
      },
      fetchPolicy: 'cache-and-network',
    }),
    name: 'pageBySlug',
  }),
  graphql(EMAIL_COMPANY, {
    options: (props) => ({
      variables: {
        slug: props.match.params['0'].slice(1),
      },
    }),
    name: 'emailOfSlug',
  }),
  graphql(CreateProfileRequestMutation, { name: 'createProfileRequest' }),
  graphql(DeleteProfileRequestMutation, { name: 'deleteProfileRequest' }),
  graphql(CreateNotificationMutation, { name: 'createNotification' }),
  graphql(CreateNotificationNumberMutation, {
    name: 'createNotificationNumber',
  }),
  graphql(UpdateNotificationNumber, {
    name: 'updateNotificationNumber',
  })
)(withApollo(CompanyModule))
