import React, { node, useState, useContext } from 'react'

import { withRouter } from 'react-router'
import { UikSvg } from 'uikit/components'

import {
  Header,
  HeaderItem,
  // HeaderItemIcon,
  HeaderItemText,
  HeaderItemWrapper,
} from '@zendeskgarden/react-chrome'

import {
  FacebookShareButton,
  FacebookIcon,
  TwitterIcon,
  TwitterShareButton,
  LinkedinIcon,
  LinkedinShareButton,
} from 'react-share'

import { Menu, Item, Separator } from '@zendeskgarden/react-menus'
import { Button } from '@zendeskgarden/react-buttons'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import { StoreContext } from 'contexts/Store'
import { SVG_URI, USER_LINK_ITERABLE, DOMAIN } from 'utils/vars'
 
import '@zendeskgarden/react-chrome/dist/styles.css'
import { fixControlledValue } from 'antd/lib/input/Input'

import '@zendeskgarden/react-chrome/dist/styles.css'
import '@zendeskgarden/react-menus/dist/styles.css'
//import '@zendeskgarden/react-buttons/dist/styles.css'

import clsApp from 'initStyles/App.scss'
import cls from './chromeHeader.module.scss'

const ChromeHeader = ({
  standalone,
  icon: { style: iconStyle, ...iconRest },
  info: {
    title: { Component: TitleComponent, text: titleText, params: titleParams },
    appends,
  },
  items,
  editItems,
  onIconClick,
  share,
  newStory,
  contactUs,
  style,
  newRequest,
  cancelRequest,
  deleteRequest,
}) => {
  const [copied, setCopied] = useState(false)
  const {
    me: { me: storedMe, storeMe },
    normalWidth: { normalWidth, setNormalWidth },
  } = useContext(StoreContext)
  return (
    <Header
      standalone={standalone}
      style={{
        backgroundColor: 'white',
        width:
          normalWidth &&
          /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(
            navigator.userAgent
          ) === false
            ? 'calc(100% - 270px)'
            : /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(
                navigator.userAgent
              ) && !location.pathname.includes('edit' || 'nouvelle')
            ? '100%'
            : '100%',
        zIndex: '9999',
        boxShadow: 'unset',
        ...style,
      }}
    >
      <HeaderItem
        logo
        style={{ cursor: 'pointer', width: '100px', ...iconStyle }}
        onClick={onIconClick}
      >
        <div style={{ display: 'flex' }}>
          <UikSvg {...iconRest} />
        </div>
      </HeaderItem>
      {titleText ? (
        <HeaderItemWrapper maxX className={clsApp.hideSm}>
          <TitleComponent {...titleParams} style={{ color: 'inherit' }}>
            {titleText}
          </TitleComponent>
          {appends ? (
            <span>
              &nbsp;
              <UikSvg
                alt="next"
                height="16px"
                src={`${SVG_URI}/next.svg`}
                width="16px"
                style={{ marginBottom: '-3px' }}
              />
              <span>{appends}</span>
            </span>
          ) : null}
        </HeaderItemWrapper>
      ) : null}
      {items
        .filter((e) => e)
        .map((item, i) => (
          <HeaderItem style={{ zIndex: 'unset' }} key={`{e.text}-${i + 1}`}>
            {share ? (
              <Menu
                small
                style={{
                  width: '25em',
                }}
                trigger={({ ref, isOpen }) => (
                  <Button
                    size="small"
                    innerRef={ref}
                    active={isOpen}
                    style={{
                      marginRight: '0.3em',
                      background: '#6977FF',
                      color: 'white',
                      padding: '0 2.25em',
                      minWidth: '8.33333em',
                      lineHeight: '30px',
                      fontSize: '12px',
                      border: "0px,"
                    }}
                  >
                    Partager ma page
                  </Button>
                )}
              >
                <div style={{ textAlign: 'center' }}>
                  <p style={{ fontWeight: 'bold' }}>Partager ma page</p>
                </div>
                <div style={{ textAlign: 'center' }}>
                  <FacebookShareButton
                    url={`www.whire.me/@${share[0].slugAdress}`}
                    quote=""
                    style={{ marginRight: '2em' }}
                  >
                    <FacebookIcon
                      logoFillColor="white"
                      round
                      style={{ width: '3em' }}
                    />
                    <p>Facebook</p>
                  </FacebookShareButton>
                  <TwitterShareButton
                    url={`www.whire.me/@${share[0].slugAdress} #CVraimentMoi #Whirriors @WhireMe`}
                    style={{ marginRight: '2em' }}
                  >
                    <TwitterIcon round style={{ width: '3em' }} />
                    <p>Twitter</p>
                  </TwitterShareButton>
                  <LinkedinShareButton
                    url={`www.whire.me/@${share[0].slugAdress}`}
                    style={{ marginRight: '2em' }}
                  >
                    <LinkedinIcon round style={{ width: '3em' }} />
                    <p>LinkedIn</p>
                  </LinkedinShareButton>
                  <CopyToClipboard
                    text={window.location.href}
                    onCopy={() => setCopied(true)}
                  >
                    <input
                      type="image"
                      alt={USER_LINK_ITERABLE.OTHER.title}
                      src={`${DOMAIN}${USER_LINK_ITERABLE.OTHER.icon}`}
                      style={{
                        width: '2.6em',
                        marginBottom: '1.3em',
                        cursor: 'pointer',
                      }}
                    />
                  </CopyToClipboard>
                  {!copied ? (
                    <CopyToClipboard
                      text={window.location.href}
                      onCopy={() => setCopied(true)}
                    >
                      <p
                        style={{
                          marginLeft: '16em',
                          marginTop: '-2.1em',
                          cursor: 'pointer',
                        }}
                      >
                        Copier
                      </p>
                    </CopyToClipboard>
                  ) : (
                    <p
                      style={{
                        marginLeft: '16em',
                        marginTop: '-2.1em',
                        color: '#32CD32',
                      }}
                    >
                      C'est copié
                    </p>
                  )}
                </div>
              </Menu>
            ) : null}
            {newStory &&
            /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(
              navigator.userAgent
            ) === false ? (
              <HeaderItemText {...newStory[0]}>
                <Button
                  style={{
                    // marginRight: "1em",
                    // background: "#1665D8",
                    // color: "white"
                    padding: '0 2.25em',
                    minWidth: '8.33333em',
                    lineHeight: '30px',
                    fontSize: '12px',
                  }}
                  className={cls.buttonBasic}
                  size="small">{newStory[0].text}</Button>
              </HeaderItemText>
            ) : null}
            {newRequest ? (
              <HeaderItemText {...newRequest[0]}>
                <button
                  className={`${clsApp.buttonRequest} ${clsApp.newRequest}`}
                >
                  {newRequest[0].text}
                </button>
              </HeaderItemText>
            ) : null}
            {cancelRequest ? (
              <HeaderItemText {...cancelRequest[0]}>
                <button
                  className={`${clsApp.buttonRequest} ${clsApp.cancelRequest}`}
                >
                  {cancelRequest[0].text}
                </button>
              </HeaderItemText>
            ) : null}
            {deleteRequest ? (
              <HeaderItemText {...deleteRequest[0]}>
                <button
                  className={`${clsApp.buttonRequest} ${clsApp.deleteRequest}`}
                >
                  {deleteRequest[0].text}
                </button>
              </HeaderItemText>
            ) : null}
            {item.Component ? (
              <item.Component {...item.params} />
            ) : item.text &&
              /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(
                navigator.userAgent
              ) === false ? (
              <HeaderItemText {...item}>
                <Button
                  size="small"
                  style={{
                    // marginRight: "1em",
                    // background: "#1665D8",
                    // color: "white"
                    padding: '0 2.25em',
                    minWidth: '8.33333em',
                    lineHeight: '30px',
                    fontSize: '12px',

                  }}
                  className={cls.buttonBasic}
                >
                  {item.text}
                </Button>
              </HeaderItemText>
            ) : null}
            {contactUs ? (
              <HeaderItemText {...contactUs[0]}>
                <Button 
                  style={{
                    // marginRight: "1em",
                    // background: "#1665D8",
                    // color: "white"
                    padding: '0 2.25em',
                    minWidth: '8.33333em',
                    lineHeight: '30px',
                    fontSize: '12px',

                  }}
                  size="small">{contactUs[0].text}</Button>
              </HeaderItemText>
            ) : null}
          </HeaderItem>
        ))}
    </Header>
  )
}

ChromeHeader.defaultProps = {
  info: {
    title: {
      text: null,
    },
    appends: null,
  },
}

// ChromeHeader.propTypes = {
//   name: PropTypes.string.isRequired,
// }

export default withRouter(ChromeHeader)
