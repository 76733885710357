import * as Yup from 'yup'
import validateSlugAsync from 'utils/validateSlugAsync'
import { yupToFormErrors } from 'formik'

const ProfileValidationSchema = Yup.object().shape({
  title: Yup.string().required('Titre obligatoire'),
  slug: Yup.string().required('Pseudo obligatoire'),
  summary: Yup.string().required('Description obligatoire'),
  editorStates: Yup.array().of(
    Yup.object().shape({
      editorState: Yup.object()
        .shape({
          blocks: Yup.array().min(1),
        })
        .test('is-empty', '${path} is empty', function (item) {
          const index = parseInt(this.path.split('[')[1].split(']')[0], 10)
          return !item && index === 2
            ? this.createError({
                message: '"Je recherche" est obligatoire',
              })
            : true
        })
        .nullable(),
    })
  ),
  tagsInterest: Yup.array().required('Tag Aspirations obligatoire').min(1),
  videoUrl: Yup.string()
    .matches(
      //if not a valid url or uploadcare uuid
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/,
      "L'url de la vidéo n'est pas valide"
    )
})

const profileInitialValues = {
  image: '',
  title: '',
  summary: '',
  editorStates: [
    {
      editorState: null,
      formTitle: "1/ D'abord, une intro qui me ressemble (optionnel)",
      placeholder:
        "Je reste moi-même et je donne envie d’en découvrir davantage ! L'essentiel est que ce paragraphe me ressemble.",
      title: 'Introduction',
    },
    {
      editorState: null,
      formTitle:
        '2/ Mon parcours, en fonction de mon projet actuel - Le fil directeur qui explique mes envies aujourd’hui (optionnel) ',
      placeholder:
        'Qu’est-ce qui m’a amené à ce projet ou à cette recherche ? \n Je parle rapidement des rencontres, des expériences personnelles ou professionnelles, de mes formations directement en lien avec mon projet. Je peux parler de mes erreurs de parcours, ce sont aussi elles qui m’ont fait avancer. Je reste concis car cela pourra être développé dans les stories.',
      title: 'Parcours',
    },
    {
      editorState: null,
      formTitle: "3/ J'exprime ma recherche",
      placeholder:
        "J'indique ce que je veux aujourd'hui. Les recruteurs doivent comprendre en 1 clin d'oeil comment ils peuvent m'être utiles... Je suis libre de parler des valeurs, du type d’environnement recherchés, de mes disponibilités…",
      title: 'Je recherche',
    },
  ],
  // slug: '',
  links: [
    {
      placeholder: 'https://linkedin.com/in/votrepseudo',
      label: 'Mon compte LinkedIn',
      type: 'LINKEDIN',
      url: '',
    },
    {
      placeholder: 'https://www.facebook.com/profile.php?id=votreidentifiant',
      label: 'Mon compte Facebook',
      type: 'FACEBOOK',
      url: '',
    },
    {
      placeholder: 'https://www.instagram.com/votrepseudo',
      label: 'Mon compte Instagram',
      type: 'INSTAGRAM',
      url: '',
    },
    {
      placeholder: 'https://twitter.com/votrepseudo?lang=fr',
      label: 'Mon compte Twitter',
      type: 'TWITTER',
      url: '',
    },
    {
      placeholder: 'https://youtube.com/channel/votreidentifiant',
      label: 'Mon compte Youtube',
      type: 'YOUTUBE',
      url: '',
    },
    {
      placeholder: 'https://github.com/votrepseudo',
      label: 'Mon compte Github',
      type: 'GITHUB',
      url: '',
    },
  ],
}

// const emoji = <span role="img" aria-label="info">👉</span>;

export const titleField = {
  id: 'title',
  label: "BONJOUR, JE M'APPELLE",
  placeholder: '...',
  type: 'INPUT',
  fieldType: 'text',
}

export const summaryField = {
  id: 'summary',
  label: 'ACCROCHE',
  placeholder: "Une phrase qui capte l'intérêt de mes lecteurs",
  type: 'INPUT',
  fieldType: 'text',
}

export const imageField = {
  id: 'image',
  label: 'Ajouter mon image',
  type: 'IMAGE',
}

export const webCamField = {
  id: 'videoUrl',
  label: 'Ajouter ma Vidéo',
  type: 'WEBCAM',
}

const videoField = {
  id: 'videoUrl',
  label: 'Je peux aussi intégrer 1 vidéo en ligne (Youtube, Viméo, etc.).',
  placeholder: "L'adresse de ma vidéo",
  type: 'VIDEO',
}

export const introField = {
  id: 'editorStates',
  type: 'EDITORS_ARRAY',
  accessor: 'editorState',
}

export const fieldArray = {
  id: 'array',
  init: {},
  accessor: '',
  placeholder: 'Une URL valide',
  type: 'FIELD_ARRAY',
}

export const pdfField = {
  id: 'resume',
  label: 'Ajouter mon CV au format pdf',
  type: 'PDFFILE',
}

const createTagField = {
  id: 'tags',
  placeholder: 'Exemple: développement logiciel',
  type: 'CREATE_TAG',
}

const createInterestTagField = {
  id: 'tagsInterest',
  placeholder: 'Exemple: développement personnel',
  type: 'CREATE_INTEREST_TAG',
}

const createWorkTagField = {
  id: 'tagsWork',
  placeholder: 'Exemple: développeur',
  type: 'CREATE_WORK_TAG',
}

const selectTagField = {
  id: 'tags',
  placeholder: 'Exemple: développement logiciel',
  type: 'SELECT_TAG',
}

const selectInterestTagField = {
  id: 'tagsInterest',
  placeholder: 'Exemple: développement personnel',
  type: 'SELECT_INTEREST_TAG',
}

const selectWorkTagField = {
  id: 'tagsWork',
  placeholder: 'Exemple: développeur',
  type: 'SELECT_WORK_TAG',
}
// argument could be
// -> initialValues.editorStates
// to load specific questions depending on user track

export default (profileValues) =>
  Object.assign(
    {
      initialValues: profileValues || profileInitialValues,
      validationSchema: ProfileValidationSchema,
    },
    {
      header: 'Créer ma page pro',
      sections: [
        {
          title: 'Infos générales',
          description: '👉 Les 1ères infos que verront les recruteurs',
          fields: [
            titleField,
            {
              type: 'SLUG',
              validate: (...args) => validateSlugAsync(...args),
            },
            summaryField,
            imageField,
          ],
        },
        {
          title: 'Introduction',
          description:
            "👉  Affichées sur ma page d'accueil, ces infos permettent aux recruteurs de me comprendre.",
          fields: [introField],
        },
        {
          title: 'Profils sociaux (optionnel)',
          description:
            '👉 Les recruteurs utilisent Google. Je leur fais gagner du temps ! Ces champs sont optionnels.',
          fields: [
            {
              ...fieldArray,
              id: 'links',
              accessor: 'url',
              init: { url: '' },
            },
          ],
        },
        {
          title: 'CV (optionnel)',
          description:
            "👉 Certains recruteurs sont habitués au CV. J'ajoute mon CV (format PDF)",
          fields: [pdfField],
        },
        {
          title: 'Vidéo (optionnel)',
          description:
            '👉 Si je suis à l’aise, je peux ajouter une vidéo de moi ou de mes réalisations ! Résultat garanti auprès des pros et des recruteurs. (Taille maximale: 100mo).',
          fields: [webCamField, videoField],
        },
        {
          title: 'Tags Aspirations',
          description:
            '👉 Je choisis dans la liste suivante les tags “Aspirations” (au max 3) pour mettre en avant des choses qui me définissent bien ou qui me tiennent à coeur. C’est la touche Whire pour qu’on me découvre autrement que par un intitulé métier ou un diplôme.',

          // fields: profileValues ? [selectInterestTagField] : [createInterestTagField],
          fields: [selectInterestTagField],
        },
        {
          title: 'Tags Métiers/Domaines',
          description:
            '👉 Si j’ai une idée précise du ou des métier(s) et/ou domaine(s) que je vise (ou que j’exerce), je l’ajoute là.',
          // fields: profileValues ? [selectWorkTagField] : [createWorkTagField],
          fields: [selectWorkTagField],
        },
        {
          title: 'Tags Atouts',
          description:
            "👉 J'ajoute d’autres tags (5 max) pour mettre en avant mes atouts principaux (compétences, qualités, outils maîtrisés, type de publics avec lesquelles je veux travailler…). Cela permet à mes lecteurs de trouver facilement le contenu qui les intéresse ! Je pourrai aussi en ajouter dans les stories donc je reste synthétique.",
          // fields: [tagField],
          // fields:[createTagField,]
          fields: [selectTagField],
        },
      ],
    }
  )
