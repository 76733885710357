import React, { Component } from 'react'

import { Link, Redirect, Route, Switch, Prompt } from 'react-router-dom'

import slugify from 'slugify'

import { withRouter } from 'react-router'
import asyncComponent from 'utils/AsyncComponent'

import Drawer from 'rc-drawer'
import 'rc-drawer/assets/index.css'
import Modal from 'react-modal'
import ModalContent from 'components/ModalContent'

import ContactUser from 'components/ContactUser'
import { StoreContext } from 'contexts/Store'

import {
  USER_STORY_TYPE_ITERABLE,
  USER_STORY_TYPE_ARRAY,
  SVG_URI,
} from 'utils/vars'

import {
  PublicProfileFirstQuery,
  EMAIL_OF_SLUG,
  CreateNotificationMutation,
  UpdateNotificationNumber,
  CreateNotificationNumberMutation,
} from 'gql/profile'

import {
  // UikPageFade,
  UikContainerVertical,
} from 'uikit/containers'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import { compose } from 'recompose'

import { UikButton } from 'uikit/components'
import * as ROUTES from 'utils/routes'

import ChromeHeader from 'components/ChromeHeader'
import Navigation from 'components/Navigation'
import Affix from 'components/Affix'
import Loading from 'components/Loading'
import Page404 from 'pages/page404'
import NoAccess from 'pages/NoAccess'

import Thread from 'modules/Thread'
import TagNavigation from 'modules/Thread/TagNavigation'

import PublicPages from './PublicPages'
import CreateCardsMenu from 'pages/CreateCards/CreateCardsMenu'
import CreateCards from 'pages/CreateCards'
import CreateCardsEdu from 'pages/CreateCards/CreateCardsEdu'


import { getMainProfile, getMainProfileUrl } from 'utils/handleMainProfile'

import cls from 'initStyles/App.scss'
import { array } from 'prop-types'

Modal.setAppElement('#root')
const customStyles = {
  content: {
    border: 'none',
    padding: '0',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    minHeight: '50%',
    minWidth: '50%',
    transform:
      window.innerWidth > 1400
        ? 'translate(-30%, -80%)'
        : 'translate(-50%, -50%)',
  },
  overlay: {
    zIndex: '1',
    background: 'rgba(0,0,0,.3)',
  },
}

class UserModule extends Component {
  static contextType = StoreContext
  constructor(props) {
    super(props)
    this.state = {
      modalIsOpen: false,
      draft: false,
      navigationIsDisabled: false,
    }
    this.previousLocation = this.props.location
  }

  componentWillUpdate(nextProps) {
    const { location } = this.props
    const {
      navigationIsOpen: { navigationIsOpen, setNavigationIsOpen },
    } = this.context

    // set previousLocation if props.location is not modal
    if (
      nextProps.history.action !== 'POP' &&
      (!location.state || !location.state.modal)
    ) {
      this.previousLocation = this.props.location
    }
    if (
      window.location.pathname !==
      `/@${this.props.profileBySlug.variables.slug}`
    ) {
      if (navigationIsOpen) {
        setNavigationIsOpen(false)
      }
    } else {
      setNavigationIsOpen(true)
    }
    //   if(!this.state.navigationIsDisabled) {
    //     this.setState({navigationIsDisabled: true})
    //   }
    // }
    // else {
    //   this.setState({navigationIsDisabled: false})

    // }
  }
  async componentDidMount() {
    const {
      ownPage: { ownPage },
    } = this.context
    const mainProfile = getMainProfile()

    const me = this.props.me
    const slug = this.props.profileBySlug.variables.slug

    await new Promise((resolve) => {
      setTimeout(resolve, 1000)
    })

    const profileValues = this.props.profileBySlug.profileBySlug
    console.log(this.props, "PROFILEBYSLUG2")

    // if I'm a connected as company or a candidate and if i'm a profile page that is not mine && if I have profileValues
    // (cause if there is no profilValues there will be a blank page)
    if (me) {
      const meSlug = me.profiles.map((profile) => profile.slug)
      //we re-check if slug it's not is ownpage
      const mySlugs = meSlug.find((slg) => slg === slug)
      if (
        me &&
        ownPage === false &&
        mySlugs === undefined &&
        window.location.href === `${window.location.origin}/@${slug}` &&
        profileValues
      ) {
        this.props
          .createNotification({
            variables: {
              viewer: mainProfile.title,
              // if i'm an org send the slug with the /org"
              slug:
                me.origin === 'CANDIDATE'
                  ? `@${mainProfile.slug}`
                  : `org/@${mainProfile.slug}`,
              notificationMessage: 'a visité votre profil',
              image: mainProfile.image,
              imageModifier: mainProfile.imageModifier,
              profileSlug: slug,
            },
          })
          .then((res) => {
            console.log(res, 'meeee resss')
          })
          .catch((err) => {
            console.log(err, 'meee errrrroo')
          })
        profileValues.notificationNumbers.length < 1
          ? this.props
              .createNotificationNumber({
                variables: {
                  number: 1,
                  profileSlug: slug,
                },
              })
              .then((res) => {
                console.log(res, 'me res number')
              })
              .catch((err) => {
                console.log(err, 'me error number')
              })
          : this.props
              .updateNotificationNumber({
                variables: {
                  id: profileValues.notificationNumbers[0].id,
                  number: profileValues.notificationNumbers[0].number + 1,
                },
              })
              .then((res) => {
                console.log(res, 'update notification number success')
              })
              .catch((err) => {
                console.log(err, 'update update notification number error')
              })
      }
    }
    //if i'm a visitor
    else if (me === undefined && profileValues) {
      this.props
        .createNotification({
          variables: {
            viewer: 'Un visiteur',
            notificationMessage: 'a visité votre profil',
            profileSlug: slug,
          },
        })
        .then((res) => {
          console.log(res, 'meeeee res')
        })
        .catch((err) => {
          console.log(err, 'meeee error')
        })
      profileValues.notificationNumbers.length < 1
        ? this.props
            .createNotificationNumber({
              variables: {
                number: 1,
                profileSlug: slug,
              },
            })
            .then((res) => {
              console.log(res, 'me res number')
            })
            .catch((err) => {
              console.log(err, 'me error number')
            })
        : this.props
            .updateNotificationNumber({
              variables: {
                id: profileValues.notificationNumbers[0].id,
                number: profileValues.notificationNumbers[0].number + 1,
              },
            })
            .then((res) => {
              console.log(res, 'update notification number succes')
            })
            .catch((err) => {
              console.log(err, 'update update notification number error')
            })
    }
  }

  setDraft = (draft) => {
    this.setState({ draft })
  }

  render() {
    const {
      me,
      location,
      match: {
        params: { 0: userName },
      },
      profileBySlug: {
        loading: profileLoading,
        error: profileError,
        profileBySlug: dataProfiles,
      },
      emailOfSlug: {
        loading: emailLoading,
        error: emailError,
        profiles: dataEmail,
      },
    } = this.props
    if (profileError || emailError) {
      return <Page404 />
    } else if (profileLoading || emailLoading) {
      return <Loading />
    } else {
      const {
        id,
        image,
        imageModifier,
        summary,
        title,
        slug,
        tags,
        sections,
        video,
        resume,
        isPublished,
        links: userLinks,
        profileRequests,
        memberships
      } = dataProfiles

      const [
        {
          owner: { email },
        },
      ] = dataEmail

      const userIsOwner =
        me && me.profiles && me.profiles.find((e) => e.id === id)
      const {
        normalWidth: { normalWidth, setNormalWidth },
        navigationByTag: { navigationByTag, setNavigationByTag },
        navigationIsOpen: { navigationIsOpen, setNavigationIsOpen },
      } = this.context
      navigationIsOpen === false ? setNormalWidth(false) : setNormalWidth(true)
      this.state.navigationIsDisabled === true ? setNormalWidth(false) : ''

      const isModal = !!(
        location.state &&
        location.state.modal &&
        this.previousLocation !== location
      )
      navigationIsOpen
        ? (document.body.style.overflowX = 'hidden')
        : (document.body.style.overflowX = '')

      const navSections = [
        USER_STORY_TYPE_ITERABLE.PAGE,
        ...Object.keys(USER_STORY_TYPE_ITERABLE)
          .map((key) => {
            const section = sections.find(
              (e) =>
                e.storiesTypeExpected === key &&
                e.stories.some((story) => story.isPublished)
            )
            if (section) {
              return {
                ...USER_STORY_TYPE_ITERABLE[key],
                title: USER_STORY_TYPE_ITERABLE[key].text,
                rightEl: section.stories.filter((s) => s.isPublished).length,
                userSection: section,
              }
            }
            // return null;
            return console.log()
          })
          .filter((e) => e),
      ]

      const navSectionsDraft = [
        USER_STORY_TYPE_ITERABLE.PAGE,
        ...Object.keys(USER_STORY_TYPE_ITERABLE)
          .map((key) => {
            const section = sections.find(
              (e) =>
                e.storiesTypeExpected === key &&
                e.stories.some((story) => !story.isPublished)
            )
            if (section) {
              return {
                ...USER_STORY_TYPE_ITERABLE[key],
                title: USER_STORY_TYPE_ITERABLE[key].text,
                rightEl: section.stories.filter((s) => !s.isPublished).length,
                userSection: section,
                public: false,
              }
            }
            return null
          })
          .filter((e) => e),
      ]

      const allTags = []
      const allStories = []
      const stories = navSections.filter((e) => e.text !== 'Présentation')
      const storiesFiltered = stories.map((elem) => elem.userSection)
      const storiesFiltered2 = storiesFiltered.map((e) => e.stories)
      for (const value of storiesFiltered2.values()) {
        // we collect all the tags from stories
        value.map((v) => v.tags.map((t) => allTags.push(t.name)))
        // we collect all the stories into one array
        value.map((v) => allStories.push(v))
      }
      // allTags.map(tag => allStories.map(s => s.tags.find(e => e.name === tag ? console.log(s, "navtest99999") : null)))

      // get the tags and count of duplicates tags and push them into an array of object
      const count = Object.entries(
        allTags.reduce((acc, curr) => {
          acc[curr] = (acc[curr] || 0) + 1
          return acc
        }, {})
      ).map(([key, value]) => ({ tag: key, quantity: value }))

      const handleNavMenu = () => {
        setNavigationIsOpen((prevState) => !prevState)
        this.setState((prevState) => ({
          navigationIsDisabled: !prevState.navigationIsDisabled,
        }))
      }

      return (
        <div className="App">
          <UikContainerVertical>
            <Switch location={isModal ? this.previousLocation : location}>
              <Route
                exact
                path="/(@[a-zA-Z0-9_.-]*)/nouvelle-story"
                render={(props) => {
                  if (userIsOwner) {
                    const CreateStoryMenu = asyncComponent(
                      () =>
                        import('../../pages/CreateStoryMenu').then(
                          (module) => module.default
                        ),
                      { ...props }
                    )
                    return (
                      <div>
                        <CreateStoryMenu
                          {...props}
                          baseUrl={`/@${slug}`}
                          userType="CANDIDATE"
                          profile={this.props.profileBySlug.profileBySlug}
                        />
                      </div>
                    )
                  }
                  return (
                    <div>
                      <NoAccess />
                    </div>
                  )
                }}
              />
              <Route
                //exact
                path="/(@[a-zA-Z0-9_.-]*)/cards"
                render={(props) => {
                  if (userIsOwner) {
                    return (
                      <div>
                        <CreateCardsMenu
                          {...props}
                          me={me}
                          baseUrl={`/@${slug}`}
                          userType="CANDIDATE"
                          profile={this.props.profileBySlug.profileBySlug}
                        />
                      </div>
                    )
                  }
                  return (
                    <div>
                      <NoAccess />
                    </div>
                  )
                }}
              />
              <Route
                //exact
                path="/(@[a-zA-Z0-9_.-]*)/cards1"
                render={(props) => {
                  if (userIsOwner) {
                    return (
                      <div>
                        <CreateCards
                          {...props}
                          me={me}
                          baseUrl={`/@${slug}`}
                          userType="CANDIDATE"
                          profile={this.props.profileBySlug.profileBySlug}
                        />
                      </div>
                    )
                  }
                  return (
                    <div>
                      <NoAccess />
                    </div>
                  )
                }}
              />
              <Route
                //exact
                path="/(@[a-zA-Z0-9_.-]*)/cards2"
                render={(props) => {
                  if (userIsOwner) {
                    return (
                      <div>
                        <CreateCardsEdu
                          {...props}
                          me={me}
                          baseUrl={`/@${slug}`}
                          userType="CANDIDATE"
                          profile={this.props.profileBySlug.profileBySlug}
                        />
                      </div>
                    )
                  }
                  return (
                    <div>
                      <NoAccess />
                    </div>
                  )
                }}
              />
              <Route
                exact
                path="/(@[a-zA-Z0-9_.-]*)/edit"
                render={(props) => {
                  if (userIsOwner) {
                    const EditProfile = asyncComponent(
                      () =>
                        import('./pages/EditProfile').then(
                          (module) => module.default
                        ),
                      { ...props }
                    )
                    return (
                      <div>
                        <EditProfile
                          returnUrl={`/@${slug}`}
                          {...props}
                          profile={this.props.profileBySlug.profileBySlug}
                        />
                      </div>
                    )
                  }
                  return (
                    <div>
                      <NoAccess />
                    </div>
                  )
                }}
              />
              <Route
                exact
                path="/(@[a-zA-Z0-9_.-]*)/edit-password"
                render={(props) => {
                  if (userIsOwner) {
                    const EditPassword = asyncComponent(
                      () =>
                        import('../../containers/EditPassword').then(
                          (module) => module.default
                        ),
                      { ...props }
                    )
                    return (
                      <div>
                        <EditPassword returnUrl={`/@${slug}`} {...props} />
                      </div>
                    )
                  }
                  return (
                    <div>
                      <NoAccess />
                    </div>
                  )
                }}
              />
              <Route
                exact
                path="/(@[a-zA-Z0-9_.-]*)/edit-mail"
                render={(props) => {
                  if (userIsOwner) {
                    const EditMail = asyncComponent(
                      () =>
                        import('../../containers/EditMail').then(
                          (module) => module.default
                        ),
                      { ...props }
                    )
                    return (
                      <div>
                        <EditMail returnUrl={`/@${slug}`} {...props} />
                      </div>
                    )
                  }
                  return (
                    <div>
                      <NoAccess />
                    </div>
                  )
                }}
              />
              <Route
                exact
                path="/(@[a-zA-Z0-9_.-]*)/:appends/:id/edit"
                render={(props) => {
                  if (userIsOwner) {
                    const EditStory = asyncComponent(
                      () =>
                        import('./pages/EditStory').then(
                          (module) => module.default
                        ),
                      { ...props }
                    )
                    return (
                      <div>
                        <EditStory
                          returnUrl={`/@${slug}`}
                          userType="CANDIDATE"
                          existingTags={[]}
                          {...props}
                          profile={this.props.profileBySlug.profileBySlug}
                        />
                      </div>
                    )
                  }
                  return (
                    <div>
                      <NoAccess />
                    </div>
                  )
                }}
              />
              <Route
                exact
                path="/(@[a-zA-Z0-9_.-]*)/nouvelle/:appends"
                render={(props) => {
                  if (userIsOwner) {
                    const CreateStory = asyncComponent(
                      () =>
                        import('./pages/CreateStory').then(
                          (module) => module.default
                        ),
                      { ...props }
                    )

                    return (
                      <div>
                        <CreateStory
                          returnUrl={`/@${slug}`}
                          userType="CANDIDATE"
                          existingTags={[]}
                          {...props}
                          profile={this.props.profileBySlug.profileBySlug}
                        />
                      </div>
                    )
                  }
                  return (
                    <div>
                      <NoAccess />
                    </div>
                  )
                }}
              />
              <Route
                exact
                path="/(@[a-zA-Z0-9_.-]*)"
                render={(props) => {
                  const { params } = props.match
                  const match = Object.assign(
                    {},
                    {
                      ...props.match,
                      params: {
                        ...params,
                        // On /@userName/edit
                        // matchPath returns appends === undefined.
                        // To match our navigation first link,
                        // we need to replace it wiith an empty string
                        appends:
                          params.appends === undefined ? '' : params.appends,
                      },
                    }
                  )

                  // This won't hold when we add custom sections
                  const matchedStoryType = USER_STORY_TYPE_ARRAY.find(
                    (e) => e.appends === match.params.appends
                  )

                  if (!matchedStoryType) {
                    return <Redirect to={`/${userName}`} />
                  }
                  const matchedStoryTypeText = matchedStoryType.text

                  return (
                    <div>
                      <Affix
                        offset={50}
                        style={{ top: 0 }}
                        wrapperStyle={{
                          height: '50px',
                          borderTop: '1px solid #DADEE7',
                        }}
                      >
                        <ChromeHeader
                          standalone
                          offset={50}
                          info={{
                            appends:
                              match.params.appends !== ''
                                ? matchedStoryTypeText
                                : undefined,
                            title: {
                              Component: Link,
                              text: title,
                              params: {
                                to: `/@${slug}`,
                                href: `/@${slug}`,
                              },
                            },
                            // userName: slug,
                          }}
                          profile={{
                            displayName: title,
                            userName: slug,
                          }}
                          icon={
                            /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(
                              navigator.userAgent
                            )
                              ? {
                                  width: '0',
                                  height: '0',
                                  style: {
                                    borderRight: 'none',
                                  },
                                }
                              : navigationIsOpen &&
                                !this.state.navigationIsDisabled
                              ? {
                                  alt: 'menu',
                                  height: '22px',
                                  src: `${SVG_URI}/remove.svg`,
                                  width: '22px',
                                }
                              : {
                                  alt: 'menu',
                                  height: '16px',
                                  src: `${SVG_URI}/menu.svg`,
                                  width: '16px',
                                }
                          }
                          items={
                            !isPublished && userIsOwner
                              ? [
                                  {
                                    text: 'Publier ma page',
                                    onClick: () => {
                                      window.scroll(0, 0)
                                      this.props.history.push(`/@${slug}/edit`)
                                    },
                                  },
                                ]
                              : userIsOwner
                              ? [
                                  {
                                    text: 'Modifier ma page',
                                    onClick: () => {
                                      //window.scroll fait page blanche
                                      // window.scroll(0, 0);
                                      this.props.history.push(`/@${slug}/edit`)
                                    },
                                  },
                                ]
                              : !userIsOwner
                              ? [
                                  {
                                    text: `Me contacter`,
                                    onClick: () => {
                                      this.setState({
                                        modalIsOpen: true,
                                      })
                                    },
                                  },
                                ]
                              : [null]
                          }
                          items={
                            !isPublished && userIsOwner
                              ? [
                                  {
                                    text: 'Publier ma page',
                                    onClick: () => {
                                      window.scroll(0, 0)
                                      this.props.history.push(`/@${slug}/edit`)
                                    },
                                  },
                                ]
                              : userIsOwner
                              ? [
                                  {
                                    text: 'Modifier ma page',
                                    onClick: () => {
                                      //window.scroll fait page blanche
                                      // window.scroll(0, 0);
                                      this.props.history.push(`/@${slug}/edit`)
                                    },
                                  },
                                ]
                              : !userIsOwner
                              ? [
                                  {
                                    text: `Me contacter`,
                                    onClick: () => {
                                      this.setState({
                                        modalIsOpen: true,
                                      })
                                    },
                                  },
                                ]
                              : [null]
                          }
                          share={
                            userIsOwner && isPublished
                              ? [
                                  {
                                    text: 'Partager ma page',
                                    slugAdress: slug,
                                  },
                                ]
                              : null
                          }
                          newStory={
                            userIsOwner && isPublished
                              ? [
                                  {
                                    text: 'Nouvelle Story',
                                    onClick: () => {
                                      // window.scroll(0, 0);
                                      this.props.history.push(
                                        `/@${slug}/nouvelle-story`
                                      )
                                    },
                                  },
                                ]
                              : null
                          }
                          //   items={!isPublished && userIsOwner ? [{
                          //     text: 'Publier ma page',
                          //     onClick: () => {
                          //       window.scroll(0, 0);
                          //       this.props.history.push(`/@${slug}/edit`);
                          //     }
                          //   },
                          //  ]: [null]}
                          //   items={ !userIsOwner ? [{
                          //     text: 'Me contacter',https://whire-test.netlify.app/
                          //     onClick: () => {
                          //         this.setState({ modalIsOpen: true });
                          //       },
                          //     }]: [null]}
                          onIconClick={() => handleNavMenu()}
                        />
                      </Affix>
                      {isPublished || userIsOwner ? (
                        <PublicPages
                          match={match}
                          navSections={navSections}
                          navSectionsDraft={navSectionsDraft}
                          setDraft={this.setDraft}
                          links={userLinks}
                          openModal={() =>
                            this.setState({
                              modalIsOpen: true,
                            })
                          }
                          sections={sections}
                          summary={summary}
                          slug={slug}
                          title={title}
                          image={image}
                          imageModifier={imageModifier}
                          tags={tags}
                          video={video}
                          resume={resume}
                          navUser={{
                            imgUrl: image,
                            name: title,
                            imageUrlLarge: image,
                            userName: slug,
                            summary,
                          }}
                        />
                      ) : (
                        <div
                          style={{
                            fontSize: '40px',
                            maxWidth: '500px',
                            margin: 'auto',
                            textAlign: 'center',
                          }}
                        >
                          <br />
                          <br />
                          <br />
                          <br />
                          🤔 Cet utilisateur n'existe pas ou son profil n'est
                          pas public
                          <br />
                          <br />
                          <br />
                          <UikButton
                            onClick={() =>
                              this.props.history.push(
                                ROUTES.HOME(!!me && me.origin)
                              )
                            }
                          >
                            {' '}
                            Revenir à la page d'accueil{' '}
                          </UikButton>
                        </div>
                      )}
                    </div>
                  )
                }}
              />
              <Route component={Page404} />
            </Switch>
            {!this.state.navigationIsDisabled &&
            !/iPhone|iPad|iPod|Android/i.test(navigator.userAgent) ? (
              <Drawer
                showMask={false}
                handler={null}
                onMaskClick={() =>
                  setNavigationIsOpen((prevState) => !prevState)
                }
                open={navigationIsOpen}
                defaultOpen={true}
                maskClosable={true}
              >
                <Navigation
                  location={this.props.location}
                  navUser={{
                    imgUrl: imageModifier ? `${image}/${imageModifier}` : image,
                    name: title,
                    imageUrlLarge: image,
                    userName: slug,
                    summary,
                  }}
                  onLinkIsClicked={() => setNavigationIsOpen(false)}
                  sections={navSections}
                  profileRequests={profileRequests}
                  memberships={memberships.filter(m=> m.trainee !== null)}
                />
              </Drawer>
            ) : null}
          </UikContainerVertical>
          {isModal && (
            <Drawer
              ease="cubic-bezier(12, 12, 12, 12)"
              handler={false}
              level={null}
              onMaskClick={() =>
                this.props.history.push(
                  Object.assign(
                    {},
                    {
                      ...this.previousLocation,
                      state: { modal: false },
                    }
                  )
                )
              }
              open
              placement="right"
              width={
                /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(
                  navigator.userAgent
                )
                  ? '100%'
                  : '650px'
              }
              wrapperClassName={cls.threadWrapper}
            >
              <Route
                exact
                path="/(@[a-zA-Z0-9_.-]*)/:appends"
                render={(props) => {
                  const { params } = props.match
                  const match = Object.assign(
                    {},
                    {
                      ...props.match,
                      params: {
                        ...params,
                        appends:
                          params.appends === undefined ? '' : params.appends,
                      },
                    }
                  )
                  const thisNavSection = navSections.find(
                    (e) => e.appends === match.params.appends
                  )
                  const thisNavSectionDraft = navSectionsDraft.find(
                    (e) => e.appends === match.params.appends
                  )
                  return (
                    <div
                      style={{
                        paddingBottom: '60px',
                        background: '#FBFBFD',
                      }}
                    >
                      <Thread
                        baseUrl=""
                        match={match}
                        // links={links}
                        onClose={() =>
                          this.props.history.push(
                            Object.assign(
                              {},
                              {
                                ...this.previousLocation,
                                state: {
                                  modal: false,
                                },
                              }
                            )
                          )
                        }
                        navSections={
                          !this.state.draft ? navSections : navSectionsDraft
                        }
                        userStories={
                          !this.state.draft
                            ? thisNavSection.userSection.stories.filter(
                                (s) => s.isPublished
                              )
                            : thisNavSectionDraft.userSection.stories.filter(
                                (s) => !s.isPublished
                              )
                        }
                        transport={
                          !this.state.draft
                            ? thisNavSection
                            : thisNavSectionDraft
                        }
                        ref={this.thread}
                        userIsOwner={userIsOwner}
                      />
                    </div>
                  )
                }}
              />
            </Drawer>
          )}
          {isModal && navigationByTag && (
            <Drawer
              ease="cubic-bezier(12, 12, 12, 12)"
              handler={false}
              level={null}
              onMaskClick={() =>
                this.props.history.push(
                  Object.assign(
                    {},
                    {
                      ...this.previousLocation,
                      state: { modal: false },
                    }
                  )
                )
              }
              open
              placement="right"
              width={
                /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(
                  navigator.userAgent
                )
                  ? '100%'
                  : '650px'
              }
              wrapperClassName={cls.threadWrapper}
            >
              <Route
                exact
                path="/(@[a-zA-Z0-9_.-]*)/tag/:appends"
                render={(props) => {
                  const { params } = props.match
                  const match = Object.assign(
                    {},
                    {
                      ...props.match,
                      params: {
                        ...params,
                        appends:
                          params.appends === undefined ? '' : params.appends,
                      },
                    }
                  )
                  // const thisNavSection = allStories.map(s => count.map(c => s.tags.find(e => e.name === c.tag ? s : null)))

                  // console.log(thisNavSection, "navsection123")
                  allStories.map((s) =>
                    count.map((c) =>
                      s.tags.find((e) => {
                        if (e.name === match.params.appends) return s
                      })
                    )
                  )

                  const story = []
                  // slugify cause we slugify the tag on Navigation.js
                  allStories.map((s) =>
                    s.tags.find((e) =>
                      slugify(e.name) === match.params.appends
                        ? story.push(s)
                        : null
                    )
                  )

                  return (
                    <div
                      style={{
                        paddingBottom: '60px',
                        background: '#FBFBFD',
                      }}
                    >
                      <TagNavigation
                        baseUrl=""
                        match={match}
                        // links={links}
                        onClose={() =>
                          this.props.history.push(
                            Object.assign(
                              {},
                              {
                                ...this.previousLocation,
                                state: {
                                  modal: false,
                                },
                              }
                            )
                          )
                        }
                        navSections={navSections}
                        userStories={story}
                        transport={story}
                        // transport={allStories.map(s => count.map(c => s.tags.find(e => e.name === match.params.appends ? s && console.log(s, "navtest55555544") : null)))}
                        // transport={(!this.state.draft) ?
                        //   thisNavSection : thisNavSectionDraft}
                        ref={this.thread}
                        userIsOwner={userIsOwner}
                      />
                    </div>
                  )
                }}
              />
            </Drawer>
          )}
          <Modal
            isOpen={this.state.modalIsOpen}
            // onAfterOpen={this.afterOpenModal}
            onRequestClose={() => this.setState({ modalIsOpen: false })}
            style={customStyles}
            contentLabel="Example Modal"
          >
            <ModalContent
              header="👇 Me contacter"
              bottomCta={{
                title: '👆 Sans compte et 100% gratuit',
              }}
            >
              <ContactUser />
            </ModalContent>
          </Modal>
        </div>
      )
    }
  }
}
export default compose(
  graphql(CreateNotificationMutation, { name: 'createNotification' }),
  graphql(CreateNotificationNumberMutation, {
    name: 'createNotificationNumber',
  }),
  graphql(PublicProfileFirstQuery, {
    options: (props) => ({
      variables: {
        slug: props.match.params['0'].slice(1),
      },
    }),
    name: 'profileBySlug',
  }),
  graphql(EMAIL_OF_SLUG, {
    options: (props) => ({
      variables: {
        slug: props.match.params['0'].slice(1),
      },
    }),
    name: 'emailOfSlug',
  }),
  graphql(UpdateNotificationNumber, {
    name: 'updateNotificationNumber',
  })
)(withApollo(UserModule))
