import React, { useState } from 'react'
import { createForm } from 'rc-form'
import axios from 'axios'
import { graphql, withApollo } from '@apollo/client/react/hoc'
import { compose } from 'recompose'
import { gql } from '@apollo/client'
import {
  Modal,
  Header,
  Footer,
  FooterItem,
  Close,
  Body,
} from '@zendeskgarden/react-modals'
import { Button } from '@zendeskgarden/react-buttons'
import { CONTACT_ME } from 'utils/vars'

import { UikInput, UikTextarea, UikButton } from 'uikit/components'

import { EMAIL_COMPANY } from 'gql/page'
import { extractSlug } from 'utils/regexp'

import clsLogin from 'containers/LoginModal/loginModal.module.scss'

// const EMAIL_OF_SLUG = gql`
//     query emailOfSlug(
//       $slug: String!
//     ) {
//       profiles(where: {
//         slug: $slug
//       }){
//         owner {
//           email
//         }
//       }
//     }
//   `;
const ContactCompany = (props) => {
  const [showConfirmationModal, handleConfirmationModal] = useState(false)
  const [showErrorModal, handleErrorModal] = useState(false)
  const [showNetworkErrorModal, handleNetworkErrorModal] = useState(false)

  const [captcha, setCaptcha] = useState(null)

  // const slug = window.location.pathname.split('/@')[1];
  // const { data } = useQuery(EMAIL_OF_SLUG,  {
  //   variables: { slug: "hello" },
  // });

  const onCaptchaChange = (c) => {
    setCaptcha(c)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    yo(captcha)
  }
  const submit = () => {
    const emailReceiver = props.data.page.company.owner.email

    props.form.validateFields((error, value) => {
      const body = {
        ...value,
        receiver: emailReceiver,
      }
      if (error) {
        handleErrorModal(true)
      } else {
        axios({
          method: 'POST',
          url: `https://us-central1-send-mail-fcf88.cloudfunctions.net/sendMail?normal=${value.normal}&&emailReceiver=${emailReceiver}&&required=${value.required}`,
          data: JSON.stringify(body),
        })
          .then((response) => {
            handleConfirmationModal(true)
          })
          .catch((err) => {
            console.log(err, 'error')
            handleNetworkErrorModal(true)
          })
      }
    })
  }

  // let errors;
  const { getFieldProps } = props.form
  // console.log('data', data);
  // console.log(error);

  // if (loading) {
  //   return <div> Loading...</div>
  // }
  return (
    <div>
      <UikInput
        {...getFieldProps('normal', {
          rules: [{ required: true, type: 'email' }],
        })}
        label="Votre adresse email"
        placeholder="De préférence professionnelle"
        // style={{ marginTop: '24px' }}
      />
      <div style={{ marginTop: '30px' }}>
        <UikTextarea
          {...getFieldProps('required', {
            rules: [{ required: true }],
          })}
          label="Votre message / question"
          placeholder="Votre organisation  m'intéresse !"
        />
      </div>
      <div style={{ marginTop: '30px' }}>
        <UikButton
          success
          onClick={() => {
            submit()
            // handleConfirmationModal(true);
          }}
        >
          Engager la conversation
        </UikButton>
      </div>
      {/* {(errors = getFieldError('required')) ? errors.join(',') : null} */}
      {showConfirmationModal && (
        <Modal
          className={clsLogin.responsiveFormModal}
          onClose={() => {
            handleConfirmationModal(false)
            window.location.reload()
          }}
          animate
        >
          <Header default>Information</Header>
          <Body>
            Un mail vous a été envoyé afin de confirmer votre identité. Pour
            valider l'envoi du message, merci de cliquer sur le lien de
            confirmation.
          </Body>
          <Footer>
            <FooterItem
              style={{
                display: 'block',
                marginRight: 'auto',
                marginLeft: 'auto',
              }}
            >
              <Button
                onClick={() => {
                  handleConfirmationModal(false)
                  window.location.reload()
                }}
                animate
              >
                Ok
              </Button>
            </FooterItem>
          </Footer>
          <Close aria-label="Close modal" />
        </Modal>
      )}
      {showErrorModal && (
        <Modal
          className={clsLogin.responsiveFormModal}
          onClose={() => handleErrorModal(false)}
          animate
        >
          <Header danger>Attention</Header>
          <Body>Vous devez rentrer une adresse mail et un message.</Body>
          <Footer>
            <FooterItem
              style={{
                display: 'block',
                marginRight: 'auto',
                marginLeft: 'auto',
              }}
            >
              <Button onClick={() => handleErrorModal(false)} animate>
                Ok
              </Button>
            </FooterItem>
          </Footer>
          <Close aria-label="Close modal" />
        </Modal>
      )}
      {showNetworkErrorModal && (
        <Modal
          className={clsLogin.responsiveFormModal}
          onClose={() => handleNetworkErrorModal(false)}
          animate
        >
          <Header danger>Attention</Header>
          <Body>
            Votre message n'a pas pu être envoyé, il semblerait qu'il y ait un
            problème.
          </Body>
          <Footer>
            <FooterItem
              style={{
                display: 'block',
                marginRight: 'auto',
                marginLeft: 'auto',
              }}
            >
              <Button onClick={() => handleNetworkErrorModal(false)} animate>
                Ok
              </Button>
            </FooterItem>
          </Footer>
          <Close aria-label="Close modal" />
        </Modal>
      )}
    </div>
  )
}
const slug = extractSlug(window.location.pathname)

export default compose(
  graphql(EMAIL_COMPANY, {
    options: () => ({
      variables: {
        slug,
      },
    }),
  }),
  createForm()
)(withApollo(ContactCompany))
// export default createForm()(ContactUser);
