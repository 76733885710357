import React, { Component } from 'react'

import PropTypes from 'prop-types'

import uploadcare from 'uploadcare-widget'

import { UikButton } from 'uikit/components'

import axios from 'axios'

import { UPLOADCARE_PRIVATE_KEY, UPLOADCARE_PUBLIC_KEY } from 'utils/vars'

import ImagePreview from '../components/imagePreview'

class ImagesArrayField extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
    }
    this.deleteItem = this.deleteItem.bind(this)
    this.moveItem = this.moveItem.bind(this)
    this.openUploadcareDialog = uploadcare.openDialog.bind(this)
    this.handleUpload = this.handleUpload.bind(this)
  }
  handleUpload() {
    this.openUploadcareDialog(null, {
      imagesOnly: true,
      multiple: true,
      // imageShrink: '1024x1024',
      crop: '16:9 upscale',
      multipleMax: 5 - this.props.value.length,
    }).done((files) => {
      this.setState({ loading: true })
      files.promise().done((fileInfo) => {
        // eslint-disable-next-line
        axios({
          method: 'get',
          url: `https://api.uploadcare.com/groups/${fileInfo.uuid}/`,
          headers: {
            Authorization: `Uploadcare.Simple ${UPLOADCARE_PUBLIC_KEY}:${UPLOADCARE_PRIVATE_KEY}`,
            'Content-type': 'application/json',
          },
        })
          .then((res) => {
            if (res && res.data && res.data.files) {
              this.setState({ loading: false })
            }
            const newImages = res.data.files.map((file) => file.uuid)
            const images = [...this.props.value, ...newImages]
            this.props.setFieldValue('images', images)
          })
          .catch(console.log)

        // for (let i = 0; i < fileInfo.count; i += 1) {
        //   newImages.push(fileInfo.cdnUrl.concat('nth/').concat(i.toString()).concat('/'));
        // }
      })
    })
  }

  // Move up : -1 ... Move down: 1
  moveItem(index, direction) {
    const tempArr = this.props.value
    if (!tempArr[index + direction]) {
      return
    }
    const tempVal = tempArr[index]
    tempArr[index] = tempArr[index + direction]
    tempArr[index + direction] = tempVal
    this.props.setFieldValue('images', [...tempArr])
  }

  deleteItem(index) {
    const tempArr = this.props.value.slice()
    tempArr.splice(index, 1)
    this.props.setFieldValue('images', [...tempArr])
  }

  render() {
    const images = this.props.value
    return (
      <div>
        {images.length ? (
          <div>
            {images.map((elem, i) => (
              <ImagePreview
                deleteItem={() => this.deleteItem(i)}
                key={`${elem}-${i + 1}`}
                src={`https://ucarecdn.com/${elem}/`}
                showBottomArrow={images[i + 1]}
                showTopArrow={images[i - 1]}
                swapBottom={() => this.moveItem(i, 1)}
                swapTop={() => this.moveItem(i, -1)}
              />
            ))}
          </div>
        ) : null}
        <UikButton
          success
          isLoading={this.state.loading}
          onClick={this.handleUpload}
          style={{ marginTop: '24px' }}
        >
          <span role="img" aria-label="add images">
            📷
          </span>{' '}
          Ajouter des images
        </UikButton>
      </div>
    )
  }
}

ImagesArrayField.propTypes = {
  value: PropTypes.arrayOf(PropTypes.object).isRequired,
  setFieldValue: PropTypes.func.isRequired,
}

export default ImagesArrayField
