import React from 'react'

import TextWidget from 'components/TextWidget'
import UikContainerVertical from 'uikit/containers/UikContainerVertical'
import '../initStyles/App.scss'

export const PrivacyContent = () => (
  <div
    style={{
      marginLeft: '2em',
    }}
  >
    <h1>POLITIQUE DE CONFIDENTIALITÉ DES DONNÉES</h1>
    <h2>PREAMBULE</h2>
    <p>
      Avant toute utilisation du site internet de Whire accessible à l’adresse{' '}
      <a href="https://www.whire.me/">www.whire.me</a> (ci-après «le Site»),
      vous vous engagez à accepter sans réserve la collecte et l’utilisation
      devos données personnelles (ci-après «Données) suivant les modalités
      décrites dans laprésente Politique de confidentialité des données
      personnelles (ci-après «Politique deconfidentialité»).
      <br></br>
      <br></br>
      En cas de révision, la présente politique de confidentialité sera mise à
      jour sur le Site. Vous êtes donc invité à venir la consulter
      régulièrement.
      <br></br>
      <br></br>
      Une validation des modifications de la présente sera demandée aux
      Utilisateurs (défini <a href="/cgu">ici</a>)lors de leur connexion à leur
      Compte d’utilisateur.
      <br></br>
      <br></br>
      En sa qualité de responsable de traitement, Whire porte toute son
      attention au respect de votrevie privée et de la confidentialité de vos
      Données et à préserver leur sécurité et leur intégrité.Whire s'oblige à
      collecter et traiter vos Données conformément aux lois et
      règlementsapplicables et notamment au{' '}
      <a href="https://eur-lex.europa.eu/legal-content/FR/TXT/?uri=CELEX:32016R0679">
        Règlement européen sur la protection des données personnelles du 27
        avril 2016
      </a>{' '}
      et à{' '}
      <a href="https://www.legifrance.gouv.fr/affichTexte.do?cidTexte=JORFTEXT000000886460">
        la loi Informatique et libertés du 6 janvier 1978 modifiée
      </a>
      .{' '}
    </p>

    <h2>Sommaire</h2>
    <br></br>
    <h3>DÉFINITIONS</h3>
    <p>
      Candidat : Utilisateur personne physique à la recherche d’un emploi ou
      d’une opportunité professionnelle sur le Site. <br></br> <br></br>
      CVthèque : ensemble des Pages Candidat associées à une Page Organisme
      Intermédiaire. <br></br>
      <br></br>
      Page : espace sur le Site où chaque Utilisateur peut écrire, répondre à
      des questions, entreposer des photos ou des vidéos ou des liens
      hypertextes. Il existe une Page spécifique pour les Candidats, les
      Organismes Intermédiaires et les Employeurs. <br></br> <br></br>
      Organisme Intermédiaire : Utilisateur personne morale inscrit en tant que
      structure de formation, d’accompagnement ou d’insertion professionnelle.
      Pour être inscrits, les Organismes intermédiaires doivent avoir souscrit
      auprès de Whire un contrat d’abonnement. <br></br> <br></br>
      Employeur : Utilisateur personne morale inscrit sur le Site en vue de
      déposer des offres d’emploi ou de promouvoir ses métiers, sa structure
      dans la perspective d’un recrutement. Pour être inscrits, les Employeurs
      doivent avoir souscrit auprès de Whire un contrat d’abonnement. <br></br>{' '}
      <br></br>
      Services : ensemble des services mis à la disposition des Utilisateurs sur
      le Site. <br></br> <br></br>
      Site : site internet accessible à l’adresse{' '}
      <a href="https://www.whire.me/">www.whire.me</a>. <br></br> <br></br>
      Utilisateur : toute personne physique ou morale ayant adhéré aux présentes{' '}
      <a href="/cgu">conditions générales d’utilisation</a> et à la politique de
      confidentialité des données et ayant ouvert un Compte d’utilisateur en
      tant que Candidat, Organisme ou Employeur. <br></br> <br></br>
      Visiteur : tout Internaute consultant le Site et pouvant accéder à
      certaines fonctionnalités en tant que visiteur. <br></br> <br></br>
      Whire : société par actions simplifiée au capital de 1.000 € immatriculée
      sous le numéro 822 000 055 au RCS de PARIS, dont le siège social est situé
      32 rue de la Solidarité – 75019 PARIS et représentée par son Président,
      Monsieur Kayoum Saïd Abdallah Fane.{' '}
    </p>
    <h3>ARTICLE 1ER – CHAMP D’APPLICATION ET EXCLUSION</h3>
    <p>
      La présente politique de confidentialité s’applique à l’ensemble des
      traitements de données àcaractère personnel effectués par Whire à partir
      de son site Internet <a href="https://www.whire.me/">www.whire.me</a>.
      <br></br> <br></br>A contrario, elle ne s’applique pas aux traitements
      réalisés sur des sites Internet ouapplications de tiers pour lesquels
      Whire n’est pas responsable de traitement, et ceci même silesdits sites ou
      applications sont mentionnés sur le site de Whire.
    </p>
    <h3>ARTICLE 2 – COLLECTE DES DONNÉES </h3>
    <p>
      Les Données concernées par la présente politique de confidentialité sont
      les « données personnelles » ou « données à caractère personnel » au sens
      de l’article 4 du RGPD, c’est-à-dire toute information permettant
      d’identifier directement ou non une personne physique, telle que nom,
      prénom, âge, adresse IP, adresse électronique, données de localisation,
      éléments propres à son identité physique, physiologique, génétique,
      psychique, économique, culturelle ou sociale…
      <br></br> <br></br>
      Ainsi, vos diplômes, titres, formations, vos expériences professionnelles,
      vos goûts et centres d’intérêts sont des données personnelles au sens du
      Règlement précité.{' '}
    </p>
    <h4>Article 2.1 – Données collectées directement</h4>
    <p>
      Il s’agit des Données que vous nous fournissez directement soit par le
      biais de formulaires et de questionnaires que Whire vous propose de
      remplir sur son Site (par exemple, au moment de la création de votre
      Compte d’Utilisateur), soit en publiant ou téléchargeant du contenu sur
      votre Page (CV, lettres de motivation, témoignages, vidéos, liens
      hypertextes) mis en ligne sur le site de Whire.{' '}
    </p>
    <ul>
      <li>
        Données fournies lors de la création de votre Compte d’Utilisateur
        (obligatoire)
      </li>
      <ul>
        <p>Courrier électronique</p>
        <p>Mot de passe</p>
      </ul>
      <li>
        Données fournies lors de la création de votre Profil public
        (obligatoire)
      </li>
      <ul>
        <p>Prénom et nom de famille</p>
      </ul>
      <li>Données complémentaires (facultatif)</li>
      <p>
        Il s’agit des Données contenues dans les textes, photos ou vidéos, liens
        hypertextes que vous partagez en remplissant les questionnaires proposés
        par le Site comme de celles que vous publiez spontanément sur votre
        Page. Ces informations ne sont pas obligatoires mais vous permettent de
        profiter au mieux de nos Services.{' '}
      </p>
    </ul>
    <h4>Article 2.2 – Données collectées indirectement</h4>
    <ul>
      <li>Données de navigation</li>
      <ul>
        <p>
          Il s’agit des données que Whire collecte à partir de votre navigation
          sur le site Internet <a href="https://www.whire.me/">www.whire.me</a>{' '}
          :
        </p>
        <ul style={{ marginLeft: '2em' }}>
          <li>la date, l’heure de la connexion et/ou navigation </li>
          <li>le type de navigateur </li>
          <li>la langue du navigateur</li>
          <li>URL consultée</li>
          <li>l’adresse de protocole Internet et l’adresse IP</li>
          <li>le terminal utilisé</li>
        </ul>
      </ul>
      <li>
        Données partagées par les Employeurs et par les Organismes
        Intermédiaires
      </li>
      <ul>
        <p>
          Lorsque vous postulez à une offre d’emploi à partir du Site,
          l’Employeur nous fournit des données sur l’annonce à laquelle vous
          postulez. Lorsque vous adhérez à un Organisme Intermédiaire à partir
          du Site, cet Organisme est également susceptible de nous fournir des
          données.
          <br></br>
          <br></br>
          Nos Services étant dynamiques, nous proposons souvent de nouvelles
          fonctionnalités qui peuvent nécessiter la collecte de nouvelles
          informations. Si nous devions collecter des données personnelles d’une
          nature substantiellement différente ou modifier considérablement notre
          utilisation de vos données, nous vous en informerions et modifierions
          également la présente politique de confidentialité.
        </p>
      </ul>
    </ul>
    <h4>Article 2.3 – Cookies</h4>
    <p>
      Les cookies sont des fichiers stockés sur votre ordinateur par les sites
      web que vous visitez et qui contiennent des informations telles que vos
      préférences de navigation ou votre statut de connexion.
      <br></br>
      <br></br>
      En utilisant le Site, vous reconnaissez avoir lu et accepté la « 
      <a href="/charte-cookies">Charte d’utilisation des Cookies</a> »
      qui permet de comprendre, plus précisément, ce qu’est un Cookie, à quoi il
      sert et comment on peut le paramétrer.
    </p>
    <h3>ARTICLE 3 – BASES LÉGALES ET FINALITÉS DU TRAITEMENT DES DONNÉES</h3>
    <p>
      Whire collecte et traite vos Données pour fournir et personnaliser les
      services proposés sur son Site et répondre au mieux à l’attente de ses
      Utilisateurs.
      <br></br>
      <br></br>
      Plus particulièrement, le traitement des Données visées à l’article 2 a
      pour finalités :{' '}
    </p>
    <li>
      de vous permettre de créer et gérer votre Compte d’utilisateur et votre
      Page Candidat
    </li>
    <li>
      de vous proposer des offres d’emploi susceptibles de vous intéresser
    </li>
    <li>
      de vous mettre en relation avec des Employeurs et des Organismes
      Intermédiaires, ce qui implique notamment la transmission aux Employeurs
      et aux Organismes Intermédiaires des informations contenues sur votre Page
      Candidat{' '}
    </li>
    <li>
      et, plus généralement, de vous fournir les services et informations
      sollicitées
    </li>
    <p>
      Dans le but légitime pour Whire de développer ses relations avec les
      Utilisateurs, prospects et partenaires, le traitement des Données visées à
      l’article 2 a également pour finalités :{' '}
    </p>
    <li>l’envoi d’informations et de newsletters</li>
    <li>
      l’invitation à des évènements organisés par Whire ou par ses partenaires
    </li>
    <li>la promotion et la publicité de ses Services.</li>
    <p>
      Enfin, dans le but légitime pour Whire d’assurer la sécurité du Site et
      d’améliorer son fonctionnement, le traitement des Données visées à
      l’article 2 permet :
    </p>
    <li>de procéder à des calculs statistiques anonymisés</li>
    <li>
      de vous proposer une utilisation optimisée de ses Services entre vos
      différents appareils (ordinateur, tablette, smartphone…)
    </li>
    <li>
      d’examiner les réclamations, problèmes, bugs générés par le Site, afin de
      les résoudre
    </li>
    <li>
      de s’assurer que l’utilisation du site est conforme aux conditions
      générales d’utilisation (accessible <a href="/cgu">ici</a>)
    </li>
    <li>de détecter d’éventuels abus et actes de malveillance informatiques</li>
    <p>
      Conformément au RGPD, le traitement de vos données repose sur les bases
      légales suivantes :{' '}
    </p>
    <li>
      l’exécution du contrat né de l’acceptation des conditions générales
      d’utilisation. Par exemple, vos Données sont traitées afin que nous
      puissions vous proposer des offres d’emploi adaptées à votre profil.
    </li>
    <li>
      votre consentement dans les hypothèses où il est spécialement requis (par
      exemple, lorsque vous manifestez la volonté de rejoindre la CVthèque d’un
      Organisme Intermédiaire ou lorsque vous acceptez de recevoir nos
      newsletters)
    </li>
    <li>
      l’intérêt légitime de Whire (vos Données peuvent être traitées pour nous
      permettre de prévenir ou de détecter une fraude informatique).
    </li>
    <br></br>
    <br></br>
    <h3>ARTICLE 4 – DESTINATAIRES DES DONNÉES</h3>
    <p>
      Vos Données sont réservées à l’usage exclusif de Whire et ne peuvent être
      communiquées, même à titre gratuit, à des tiers.
      <br></br>
      <br></br>
      Toutefois, Whire peut être amenée à transférer tout ou partie de ces
      Données à des tiers de confiance qui les traitent pour son compte, selon
      ses instructions, conformément à la présente Politique de confidentialité
      et dans le respect du RGPD et de la loi Informatique et Libertés.
      <br></br>
      <br></br>
      Dans cette hypothèse, le transfert de vos Données est encadré par un
      contrat de transfert fondé sur les Clauses Contractuelles Types de la
      Commission Européenne.
      <br></br>
      <br></br>
      Whire ne partage pas vos Données avec des tiers dans un objectif
      publicitaire ou marketing.{' '}
    </p>
    <h4>Données que vous partagez</h4>
    <p>
      En partageant votre Page depuis votre Compte d’Utilisateur, vous consentez
      à partager vos Données avec votre réseau. En publiant votre Page Candidat,
      vous consentez à partager vos Données avec les Employeurs et les
      Organismes Intermédiaires inscrits sur le Site et tout visiteur du Site Whire. En répondant à une offre
      d’emploi sur le Site, vous consentez à partager les Données de votre Page
      Candidat avec l’Employeur.{' '}
    </p>
    <br></br>
    <h4>
      Association de votre Page Candidat à celle d’un Organisme Intermédiaire :
    </h4>
    <p>
      En invitant un Organisme Intermédiaire à vous ajouter ou en acceptant une
      demande d’ajout d’un Organisme Intermédiaire, vous consentez à partager
      les Données de votre Page avec cet Organisme et à intégrer sa CVthèque.
      Votre ajout ne sera effectif qu’une fois que l’Organisme Intermédiaire
      aura validé votre invitation et que lorsque vous aurez pris connaissance
      et adhéré à la Politique de confidentialité des données de l’Organisme
      Intermédiaire
    </p>
    <br></br>
    <br></br>
    <h3>>ARTICLE 5 – DURÉE DE CONSERVATION DES DONNÉES</h3>
    <p>
      Whire ne conserve vos Données que pour la durée strictement nécessaire aux
      traitements visés à l’article 3 et au respect de ses obligations légales,
      dans les limites imposées par la loi.
      <br></br>
      <br></br>
      La durée de conservation de vos Données varie selon la finalité de leur
      traitement.
      <br></br>
      <br></br>
      La plupart de vos Données (par exemple, celles figurant sur votre Page
      Candidat) sont conservées pendant une durée de 5 ans à compter de votre
      dernière connexion à votre Compte d’utilisateur ou de votre dernière
      visite sur le Site.
      <br></br>
      <br></br>
      Vos données sont ensuite archivées avec un accès restreint pour une durée
      supplémentaire pour satisfaire à nos obligations légales puis elles sont
      anonymisées.
      <br></br>
      <br></br>
      Pour les autres traitements, les durées de conservation sont les
      suivantes :
    </p>
    <li>
      Pour la gestion des cookies, le consentement est exprès et la durée de
      conservation en base active est de 13 mois à compter du dépôt des cookies
    </li>
    <li>
      Pour la gestion de la newsletter, l’intérêt de Whire est légitime et la
      durée de conservation en base active est de 3 ans à compter de la dernière
      activité
    </li>
    <br></br>
    <h3>ARTICLE 6 – SÉCURITÉ DE VOS DONNÉES</h3>
    <p>
      Whire s’attache à conserver les données à caractère personnel qu’elle
      traite de manière sécurisée et uniquement pendant la durée nécessaire à la
      réalisation de la finalité poursuivie par le traitement.
      <br></br>
      <br></br>
      Dans cette perspective, Whire prend les mesures physiques, techniques et
      organisationnelles appropriées pour prévenir et empêcher, dans toute la
      mesure du possible, toute altération, perte de vos données ou tout accès
      non autorisé à celles-ci.
      <br></br>
      <br></br>
      Si Whire apprenait qu’un tiers auquel elle a communiqué vos Données dans
      les conditions prévues à l’article 4, les utilise ou les divulgue en
      violation de la présente politique de confidentialité ou en violation de
      ses engagements ou des lois et réglements applicables, elle prendrait
      toute mesure pour mettre fin immédiatement à une telle utilisation.
      <br></br>
      <br></br>
      Vous êtes invité à contribuer à la protection de vos Données en veillant à
      la sécurité de vos identifiant et mot de passe et au respect des
      conditions générales d’utilisation (accessible <a href="/cgu">ici</a>).
    </p>
    <h3>
      ARTICLE 7 – DROITS D’ACCÈS, DE RECTIFICATION ET DE SUPPRESSION DE VOS
      DONNÉES{' '}
    </h3>
    <p>
      Conformément au droit applicable, vous disposez d’un droit d’accès, de
      rectification, de limitation et de suppression de vos Données.
      <br></br>
      <br></br>
      Plus précisément, vous pouvez :{' '}
    </p>
    <li>
      faire rectifier, compléter, mettre à jour ou effacer vos Données
      inexactes, incomplètes ou périmées
    </li>
    <li>
      solliciter la limitation du traitement de vos données pour un motif
      légitime
    </li>
    <li>vous opposer pour un motif légitime au traitement de vos Données</li>
    <li>solliciter la suppression de vos Données</li>
    <p>
      Vous pouvez également à tout moment et sans motif, vous opposer au
      traitement de vos Données à des fins de communication.
      <br></br>
      <br></br>
      Vous pouvez exercer vos droits :
    </p>
    <li>
      soit depuis votre Page sur le Site si vous possédez un Compte
      d’Utilisateur
    </li>
    <li>
      soit par courrier électronique :{' '}
      <a href="kayoum@whire.me">kayoum@whire.me</a>
    </li>
    <li>
      soit par courrier postal : Whire SAS – 32, rue de la Solidarité – 75019
      PARIS
    </li>
    <br></br>
    <p>Whire s’engage à traiter votre demande dans les plus brefs délais.</p>
    <h3>ARTICLE 8 – RÉCLAMATIONS</h3>
    <p>
      Si vous pensez que la sécurité de vos Données a été compromise ou que ces
      Données ont fait l’objet d’une utilisation abusive, vous êtes invité à
      contacter Whire dans les plus brefs délais, par courriel (
      <a href="kayoum@whire.me">kayoum@whire.me</a>) ou par voie postale : 32,
      rue de la Solidarité – 75019 PARIS.
      <br></br>
      <br></br>
      Whire instruira votre réclamation dans les plus brefs délais et tentera de
      trouver une solution conformément à la réglementation en vigueur.
      <br></br>
      <br></br>
      Vous êtes informé que vous pouvez à tout moment introduire une réclamation
      auprès de la CNIL, autorité de contrôle en charge du respect des
      obligations en matière de protection des données personnelles directement
      sur le site internet de la CNIL (
      <a href="https://www.cnil.fr">https://www.cnil.fr</a>) ou par courrier : 3
      Place de Fontenoy, TSA 807125 – 75334 PARIS CEDEX 07.
    </p>
    <h3>ARTICLE 9 – MODIFICATION DE LA POLITIQUE DE CONFIDENTIALITÉ</h3>
    <p>
      Whire se réserve le droit de modifier ou de compléter la présente
      Politique de confidentialité à tout moment, notamment pour s’adapter à
      l’évolution de la réglementation. Les Utilisateurs seront avertis par
      courriel des ces modifications et leur consentement sera requis pour
      qu’elles leur deviennent applicables. En cas de refus de ces
      modifications, les Utilisateurs ne pourront plus utiliser accéder à leur
      Compte d’Utilisateur et bénéficier des services proposés sur le Site.{' '}
    </p>
  </div>
)

export default () => {
  return (
    <div className="App">
      <UikContainerVertical>
        <TextWidget
          content={{
            pages: 'Privacy',
          }}
        />
      </UikContainerVertical>
    </div>
  )
}
