import React, { useContext, useState } from 'react'

import { SVG_URI, USER_LINK_ITERABLE, DOMAIN } from 'utils/vars'
import {
  UikAvatar,
  UikTag,
  UikTagInterest,
  UikTagOther,
  UikSvg,
} from 'uikit/components'

const TagOnProfile = ({ tagsInterest, tagsWork, tagsOther }) => {
  const [showWork, setShowWork] = useState(false)
  const [showInterest, setShowInterest] = useState(false)
  const [showOther, setShowOther] = useState(false)

  return (
    <div>
      {/* tags work  first then tag interest and then the other tags */}
      <div style={{ marginBottom: '1rem' }}>
        {tagsWork.length ? (
          tagsWork.length < 3 || showWork ? (
            <div>
              <UikSvg
                alt="next"
                height="18px"
                src={`${SVG_URI}/mallette.svg`}
                width="18px"
                style={{
                  marginBottom: '-3px',
                  marginRight: '1rem',
                }}
              />
              {tagsWork.map((elem, i) => (
                <span
                  key={i}
                  // Component={elem.Component}
                  // {...elem}
                  style={{
                    fontSize: '16px',
                    color: '#4087DC',
                    fontWeight: '600',
                    textTransform: 'capitalize',
                    margin: '4px 10px',
                    padding: '3px 0px',
                    // fontFamily: 'Roboto',
                  }}
                >
                  {elem}
                </span>
              ))}
              {showWork ? (
                <span
                  onClick={() => setShowWork(false)}
                  style={{
                    fontWeight: '800',
                    cursor: 'pointer',
                  }}
                >
                  -
                </span>
              ) : (
                ''
              )}
            </div>
          ) : (
            <div>
              <UikSvg
                alt="next"
                height="18px"
                src={`${SVG_URI}/mallette.svg`}
                width="18px"
                style={{
                  marginBottom: '-3px',
                  marginRight: '1rem',
                }}
              />
              {tagsWork.slice(0, 2).map((elem, i) => (
                <span
                  key={i}
                  // Component={elem.Component}
                  // {...elem}
                  style={{
                    fontSize: '16px',
                    color: '#4087DC',
                    textTransform: 'capitalize',
                    fontWeight: '600',
                    margin: '4px 10px',
                    padding: '3px 0px',
                  }}
                >
                  {elem}
                </span>
              ))}
              <span
                onClick={() => setShowWork(true)}
                style={{ fontWeight: '800', cursor: 'pointer' }}
              >
                +{tagsWork.length - 2}
              </span>
            </div>
          )
        ) : null}
      </div>
      <div style={{ marginBottom: '1rem' }}>
        {tagsInterest.length ? (
          tagsInterest.length < 3 || showInterest ? (
            <div>
              <UikSvg
                alt="next"
                height="18px"
                src={`${SVG_URI}/coeur.svg`}
                width="18px"
                style={{
                  marginBottom: '-3px',
                  marginRight: '1rem',
                }}
              />
              {tagsInterest.map((elem, i) => (
                <UikTagInterest
                  key={i}
                  // Component={elem.Component}
                  // {...elem}
                  style={{
                    fontSize: '16px',
                    fontFamily:
                      '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica,Arial,sans-serif',
                  }}
                >
                  {elem}
                </UikTagInterest>
              ))}
              {showInterest ? (
                <span
                  onClick={() => setShowInterest(false)}
                  style={{
                    fontWeight: '800',
                    cursor: 'pointer',
                  }}
                >
                  -
                </span>
              ) : (
                ''
              )}
            </div>
          ) : (
            <div>
              <UikSvg
                alt="next"
                height="18px"
                src={`${SVG_URI}/coeur.svg`}
                width="18px"
                style={{
                  marginBottom: '-3px',
                  marginRight: '1rem',
                }}
              />
              {tagsInterest.slice(0, 2).map((elem, i) => (
                <UikTagInterest
                  key={i}
                  // Component={elem.Component}
                  // {...elem}
                  style={{
                    fontSize: '16px',
                    fontFamily:
                      '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica,Arial,sans-serif',
                  }}
                >
                  {elem}
                </UikTagInterest>
              ))}
              <span
                onClick={() => setShowInterest(true)}
                style={{ fontWeight: '800', cursor: 'pointer' }}
              >
                +{tagsInterest.length - 2}
              </span>
            </div>
          )
        ) : null}
      </div>
      {tagsOther.length ? (
        tagsOther.length < 6 || showOther ? (
          <div>
            <UikSvg
              alt="next"
              height="18px"
              src={`${SVG_URI}/rocket.svg`}
              width="18px"
              style={{
                marginBottom: '-3px',
                marginRight: '1rem',
              }}
            />
            {tagsOther.map((elem, i) => (
              <UikTagOther
                key={i}
                // Component={elem.Component}
                // {...elem}
                style={{
                  fontSize: '16px',
                  fontFamily:
                    '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica,Arial,sans-serif',
                }}
              >
                {elem}
              </UikTagOther>
            ))}
            {showOther ? (
              <span
                onClick={() => setShowOther(false)}
                style={{ fontWeight: '800', cursor: 'pointer' }}
              >
                -
              </span>
            ) : (
              ''
            )}
          </div>
        ) : (
          <div>
            <UikSvg
              alt="next"
              height="18px"
              src={`${SVG_URI}/rocket.svg`}
              width="18px"
              style={{
                marginBottom: '-3px',
                marginRight: '1rem',
              }}
            />
            {tagsOther.slice(0, 5).map((elem, i) => (
              <UikTagOther
                key={i}
                // Component={elem.Component}
                // {...elem}
                style={{
                  fontSize: '16px',
                  fontFamily:
                    '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica,Arial,sans-serif',
                }}
              >
                {elem}
              </UikTagOther>
            ))}
            <span
              onClick={() => setShowOther(true)}
              style={{ fontWeight: '800', cursor: 'pointer' }}
            >
              +{tagsOther.length - 5}
            </span>
          </div>
        )
      ) : null}
    </div>
  )
}

export default TagOnProfile
