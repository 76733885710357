import React, { useState, useEffect } from 'react'
import { string, arrayOf, array, shape } from 'prop-types'
import UIAvatar from 'react-ui-avatars'

import { UikContainerHorizontal } from 'uikit/containers'
import {
  UikAvatar,
  UikWidget,
  UikWidgetHeader,
  UikTag,
  UikTagInterest,
  UikTagOther,
  UikSvg,
} from 'uikit/components'

import { SVG_URI, USER_LINK_ITERABLE, DOMAIN } from 'utils/vars'

import TextWidget from 'components/TextWidget'
import TagOnProfile from 'components/TagOnProfile/TagOnProfile'

const genUniqueId = () => {
  return '_' + Math.random().toString(36).substr(2, 9)
}

const Preview = (props) => {
  const [video, updateVideo] = useState('')

  useEffect(() => {
    if (!!props.video) {
      updateVideo(props.video)
    } else if (!!props.videoUrl) {
      updateVideo(props.videoUrl)
    }
    if (!props.video && !props.videoUrl) {
      updateVideo('')
    }
  }, [props.video, props.videoUrl])

  const getRandomColor = (title) => {
    let colorValues = '#FFC117'
    if (title.match(/^[a-cA-C 0-9\.\,\+\-]*$/)) {
      return colorValues
    } else if (title.match(/^[d-fD-F\.\,\+\-]*$/)) {
      return (colorValues = '#007ED9')
    } else if (title.match(/^[g-iG-I\.\,\+\-]*$/)) {
      return (colorValues = '#ED4A5E')
    } else if (title.match(/^[j-lJ-L\.\,\+\-]*$/)) {
      return (colorValues = '#00A2AE')
    } else if (title.match(/^[m-oM-O\.\,\+\-]*$/)) {
      return (colorValues = '#3C26BB')
    } else if (title.match(/^[p-rP-R\.\,\+\-]*$/)) {
      return (colorValues = '#666be2')
    } else if (title.match(/^[s-uS-U\.\,\+\-]*$/)) {
      return (colorValues = '#ff015b')
    } else if (title.match(/^[v-xV-X\.\,\+\-]*$/)) {
      return (colorValues = '#30ca93')
    } else if (title.match(/^[v-xV-X\.\,\+\-]*$/)) {
      return (colorValues = '#ffd000')
    } else {
      return (colorValues = '#F56A00')
    }
  }
  const [showWork, setShowWork] = useState(false)
  const [showInterest, setShowInterest] = useState(false)
  const [showOther, setShowOther] = useState(false)
  const tagsInterest = props.tagsInterest
  const tagsWork = props.tagsWork
  const tagsOther = props.tags
    ? props.tags.filter(
        (elem) =>
          elem.type !== 'WORK' &&
          elem.type !== 'INTEREST' &&
          elem.type !== 'DOMAIN'
      )
    : ''

  return (
    <div>
      <UikWidget
        style={{
          textAlign: 'center',
          maxWidth: '580px',
          margin: 'auto',
          boxShadow: '0 3px 6px #00000029',
          borderRadius: '12px',
          paddingBottom: '20px',
        }}
      >
        <UikWidgetHeader>
          Ma page ressemblera à
          <span aria-label="show down" role="img">
            👇
          </span>
        </UikWidgetHeader>
        <div
          style={{
            justifyContent: 'space-around',
            marginTop: '30px',
          }}
        >
          {props.image ? (
            <UikAvatar
              imageId={props.image}
              imageModifier={props.imageModifier}
              size="larger"
              style={{ justifyContent: 'space-around' }}
            />
          ) : (
            <UIAvatar
              background={getRandomColor(props.title.charAt(0).toLowerCase())}
              name={props.title}
              color="#FFFFFF"
              rounded
              size="80px"
            />
          )}
        </div>
        <h1
          style={{
            fontSize: '28px',
            fontFamily:
              '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica,Arial,sans-serif',
            marginTop: '.55em',
          }}
        >
          <span role="img" aria-label="hello">
            👋
          </span>{' '}
          {props.textBonjour} {props.title}
        </h1>
        <h4 style={{ marginTop: '1em' }}>{props.summary}</h4>
        <UikContainerHorizontal style={{ justifyContent: 'center' }}>
          <div>
            <div style={{ marginBottom: '1rem' }}>
              {tagsWork && tagsWork.length > 0 ? (
                tagsWork.length < 3 || showWork ? (
                  <div>
                    <UikSvg
                      alt="next"
                      height="18px"
                      src={`${SVG_URI}/mallette.svg`}
                      width="18px"
                      style={{
                        marginBottom: '-3px',
                        marginRight: '1rem',
                      }}
                    />
                    {tagsWork.map((elem) => (
                      <span
                        key={elem.slug}
                        Component={elem.Component}
                        {...elem}
                        style={{
                          fontSize: '16px',
                          color: '#4087DC',
                          fontWeight: '600',
                          textTransform: 'capitalize',
                          margin: '4px 10px',
                          padding: '3px 0px',
                          // fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica,Arial,sans-serif',
                        }}
                      >
                        {elem.name}
                      </span>
                    ))}
                    {showWork ? (
                      <span
                        onClick={() => setShowWork(false)}
                        style={{
                          fontWeight: '800',
                          cursor: 'pointer',
                        }}
                      >
                        -
                      </span>
                    ) : (
                      ''
                    )}
                  </div>
                ) : (
                  <div>
                    <UikSvg
                      alt="next"
                      height="18px"
                      src={`${SVG_URI}/mallette.svg`}
                      width="18px"
                      style={{
                        marginBottom: '-3px',
                        marginRight: '1rem',
                      }}
                    />
                    {tagsWork.slice(0, 2).map((elem) => (
                      <span
                        key={elem.slug}
                        Component={elem.Component}
                        {...elem}
                        style={{
                          fontSize: '16px',
                          color: '#4087DC',
                          fontWeight: '600',
                          textTransform: 'capitalize',
                          margin: '4px 10px',
                          padding: '3px 0px',
                          // fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica,Arial,sans-serif',
                        }}
                      >
                        {elem.name}
                      </span>
                    ))}
                    <span
                      onClick={() => setShowWork(true)}
                      style={{
                        fontWeight: '800',
                        cursor: 'pointer',
                      }}
                    >
                      +{tagsWork.length - 3}
                    </span>
                  </div>
                )
              ) : null}
            </div>
            <div style={{ marginBottom: '1rem' }}>
              {tagsInterest && tagsInterest.length > 0 ? (
                tagsInterest.length < 3 || showInterest ? (
                  <div>
                    <UikSvg
                      alt="next"
                      height="18px"
                      src={`${SVG_URI}/coeur.svg`}
                      width="18px"
                      style={{
                        marginBottom: '-3px',
                        marginRight: '1rem',
                      }}
                    />
                    {tagsInterest.map((elem) => (
                      <UikTagInterest
                        key={elem.slug}
                        Component={elem.Component}
                        {...elem}
                        style={{
                          fontSize: '16px',
                          fontFamily:
                            '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica,Arial,sans-serif',
                        }}
                      >
                        {elem.name}
                      </UikTagInterest>
                    ))}
                    {showInterest ? (
                      <span
                        onClick={() => setShowInterest(false)}
                        style={{
                          fontWeight: '800',
                          cursor: 'pointer',
                        }}
                      >
                        -
                      </span>
                    ) : (
                      ''
                    )}
                  </div>
                ) : (
                  <div>
                    <UikSvg
                      alt="next"
                      height="18px"
                      src={`${SVG_URI}/coeur.svg`}
                      width="18px"
                      style={{
                        marginBottom: '-3px',
                        marginRight: '1rem',
                      }}
                    />
                    {tagsInterest.slice(0, 2).map((elem) => (
                      <UikTagInterest
                        key={elem.slug}
                        Component={elem.Component}
                        {...elem}
                        style={{
                          fontSize: '16px',
                          fontFamily:
                            '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica,Arial,sans-serif',
                        }}
                      >
                        {elem.name}
                      </UikTagInterest>
                    ))}
                    <span
                      onClick={() => setShowInterest(true)}
                      style={{
                        fontWeight: '800',
                        cursor: 'pointer',
                      }}
                    >
                      +{tagsInterest.length - 2}
                    </span>
                  </div>
                )
              ) : null}
            </div>
            <div>
              {tagsOther && tagsOther.length > 0 ? (
                tagsOther.length < 6 || showOther ? (
                  <div>
                    <UikSvg
                      alt="next"
                      height="18px"
                      src={`${SVG_URI}/rocket.svg`}
                      width="18px"
                      style={{
                        marginBottom: '-3px',
                        marginRight: '1rem',
                      }}
                    />
                    {tagsOther.map((elem) => (
                      <UikTagOther
                        key={elem.slug}
                        Component={elem.Component}
                        {...elem}
                        style={{
                          fontSize: '16px',
                          fontFamily:
                            '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica,Arial,sans-serif',
                        }}
                      >
                        {/* {elem.type !== "INTEREST" && elem.type !== "WORK" ? elem.name : null} */}
                        {elem.name}
                      </UikTagOther>
                    ))}
                    {showOther ? (
                      <span
                        onClick={() => setShowOther(false)}
                        style={{
                          fontWeight: '800',
                          cursor: 'pointer',
                        }}
                      >
                        -
                      </span>
                    ) : (
                      ''
                    )}
                  </div>
                ) : (
                  <div>
                    <UikSvg
                      alt="next"
                      height="18px"
                      src={`${SVG_URI}/rocket.svg`}
                      width="18px"
                      style={{
                        marginBottom: '-3px',
                        marginRight: '1rem',
                      }}
                    />
                    {tagsOther.slice(0, 5).map((elem) => (
                      <UikTagOther
                        key={elem.slug}
                        Component={elem.Component}
                        {...elem}
                        style={{
                          fontSize: '16px',
                          fontFamily:
                            '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica,Arial,sans-serif',
                        }}
                      >
                        {/* {elem.type !== "INTEREST" && elem.type !== "WORK" ? elem.name : null} */}
                        {elem.name}
                      </UikTagOther>
                    ))}
                    <span
                      onClick={() => setShowOther(true)}
                      style={{
                        fontWeight: '800',
                        cursor: 'pointer',
                      }}
                    >
                      +{tagsOther.length - 5}
                    </span>
                  </div>
                )
              ) : null}
            </div>
          </div>
        </UikContainerHorizontal>
        <UikContainerHorizontal
          style={{ margin: '30px 12px', justifyContent: 'center' }}
        >
          {props.resume ? (
            <span key={props.resume}>
              <a
                rel="noopener noreferrer"
                target="_blank"
                className="link-reset"
                href={`https://ucarecdn.com/${props.resume}/-/inline/yes/`}
              >
                <img
                  alt={USER_LINK_ITERABLE['RESUME'].title}
                  src={`${DOMAIN}${USER_LINK_ITERABLE['RESUME'].icon}`}
                  style={{
                    width: '52px',
                    height: '52px',
                    marginTop: '-1px',
                    marginLeft: '-1px',
                  }}
                />
                &nbsp;&nbsp;&nbsp;&nbsp;
              </a>
            </span>
          ) : null}
          {props.links &&
            props.links
              .filter((e) => e.url.length)
              .map((item) => {
                if (props.image) {
                  USER_LINK_ITERABLE.PERSONAL.icon = `https://ucarecdn.com/${props.image}/${props.imageModifier}`
                }
                if (!item.id) item.id = genUniqueId()
                if (!item.type) item.type = 'OTHER' //to-do parser here
                //console.log(USER_LINK_ITERABLE.PERSONAL.icon, `${DOMAIN}${USER_LINK_ITERABLE[item.type].icon}`, "TYPEE")
                if (
                  item.type === 'OTHER' &&
                  item.url.includes('instagram.com')
                ) {
                  USER_LINK_ITERABLE['OTHER'].icon =
                    '/static/links/instagram.png'
                } else if (
                  item.type === 'OTHER' &&
                  item.url.includes('twitter.com')
                ) {
                  USER_LINK_ITERABLE['OTHER'].icon = '/static/links/twitter.svg'
                } else if (
                  item.type === 'OTHER' &&
                  item.url.includes('facebook.com')
                ) {
                  USER_LINK_ITERABLE['OTHER'].icon =
                    '/static/links/facebook.svg'
                } else if (
                  item.type === 'OTHER' &&
                  item.url.includes('linkedin.com')
                ) {
                  USER_LINK_ITERABLE['OTHER'].icon =
                    '/static/links/linkedin.svg'
                } else if (
                  item.type === 'OTHER' &&
                  item.url.includes('github')
                ) {
                  USER_LINK_ITERABLE['OTHER'].icon = '/static/links/github.svg'
                } else if (
                  item.type === 'OTHER' &&
                  item.url.includes('youtube.com')
                ) {
                  USER_LINK_ITERABLE['OTHER'].icon = '/static/links/youtube.svg'
                } else if (
                  item.type === 'OTHER' &&
                  item.url.includes('flickr.com')
                ) {
                  USER_LINK_ITERABLE['OTHER'].icon = '/static/links/flickr.svg'
                } else if (
                  item.type === 'OTHER' &&
                  item.url.includes('tumblr.com')
                ) {
                  USER_LINK_ITERABLE['OTHER'].icon = '/static/links/tumblr.svg'
                } else if (
                  item.type === 'OTHER' &&
                  item.url.includes('medium.com')
                ) {
                  USER_LINK_ITERABLE['OTHER'].icon = '/static/links/medium.svg'
                } else if (
                  item.type === 'OTHER' &&
                  item.url.includes('behance.net')
                ) {
                  USER_LINK_ITERABLE['OTHER'].icon = '/static/links/behance.svg'
                } else if (
                  item.type === 'OTHER' &&
                  item.url.includes('dribbble.com')
                ) {
                  USER_LINK_ITERABLE['OTHER'].icon =
                    '/static/links/dribbble.svg'
                } else if (
                  item.type === 'OTHER' &&
                  item.url.includes('viadeo.com')
                ) {
                  USER_LINK_ITERABLE['OTHER'].icon = '/static/links/viadeo.svg'
                } else if (
                  item.type === 'OTHER' &&
                  item.url.includes('vimeo.com')
                ) {
                  USER_LINK_ITERABLE['OTHER'].icon = '/static/links/vimeo.svg'
                } else if (
                  item.type === 'OTHER' &&
                  item.url.includes('pinterest')
                ) {
                  USER_LINK_ITERABLE['OTHER'].icon =
                    '/static/links/pinterest.svg'
                } else if (
                  item.type === 'OTHER' &&
                  item.url.includes('openbadge')
                ) {
                  USER_LINK_ITERABLE['OTHER'].icon =
                    '/static/links/openbadge.png'
                } else {
                  USER_LINK_ITERABLE['OTHER'].icon =
                    '/static/links/icone-link.png'
                }
                return (
                  <span key={item.id}>
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      className="link-reset"
                      href={item.url}
                    >
                      <img
                        alt={USER_LINK_ITERABLE[item.type].title}
                        //src={`${DOMAIN}${USER_LINK_ITERABLE[item.type].icon}`}
                        src={`${USER_LINK_ITERABLE[item.type].icon}`}
                        style={{
                          width: '52px',
                          height: '52px',
                          marginTop: '-1px',
                          marginLeft: '-1px',
                          borderRadius: '50%',
                        }}
                      />
                      &nbsp;&nbsp;&nbsp;&nbsp;
                    </a>
                  </span>
                )
              })}
        </UikContainerHorizontal>
      </UikWidget>
      {!!video ? (
        <TextWidget
          style={{ marginTop: '30px' }}
          content={[
            {
              videoUrl:
                video.includes('vimeo') ||
                video.includes('dailymotion') ||
                video.includes('youtube') ||
                video.includes('twitter')
                  ? video
                  : video.includes('https://www.ucarecdn.com')
                  ? video.split('https://www.ucarecdn.com/').join('')
                  : video,
            },
          ]}
        />
      ) : null}
      {props.editorStates &&
      props.editorStates.length &&
      props.editorStates.find(
        (e) => e.editorState && e.editorState.blocks.length
      ) ? (
        <TextWidget
          style={{ marginTop: '30px' }}
          content={props.editorStates.filter(
            (e) => e.editorState && e.editorState.blocks.length
          )}
        />
      ) : null}
    </div>
  )
}

Preview.propTypes = {
  image: string.isRequired,
  summary: string.isRequired,
  title: string.isRequired,
  links: arrayOf(
    shape({
      url: string.isRequired,
      type: string.isRequired,
    })
  ).isRequired,
  editorStates: arrayOf(
    shape({
      editorState: shape({
        blocks: array.isRequired,
      }),
    })
  ).isRequired,
}

export default Preview
