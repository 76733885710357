export const GQL_ID = 'cj24jwmf57uvo0164y2bas64h'

export const APOLLO = {
  development: {
    //uri: 'https://whire-develop-e52e8620.herokuapp.com/graphql',
    //uri: 'https://whire-production-cb5db4ac.herokuapp.com/graphql',
    uri: 'http://0.0.0.0:4000/graphql'
  },
  staging: {
    uri: 'https://staging-server-5a3a739dd1.herokuapp.com/graphql',
  },
  production: {
    uri: 'https://whire-production-cb5db4ac.herokuapp.com/graphql',
  },
}

export const CONTACT_ME_SERVER = {
  development: {
    uri: 'https://whire-develop-e52e8620.herokuapp.com/contact-me',
    // uri: 'http://46.138.248.77:4000/graphql',
  },
  staging: {
    uri: 'https://whire-staging-beba17f9.herokuapp.com/contact-me',
  },
  production: {
    uri: 'https://whire-production-cb5db4ac.herokuapp.com/contact-me',
  },
}

export const ALGOLIA_API = {
  // development: {
  //   appId: 'UFISOBAAG6',
  //   apiKey: '3449626bfd590e87949e4d7cdc41acb3',
    
  //   // appId: 'RRCACQBBPX',
  //   // apiKey: '663b947b43f7b0bb550852f7a198723f',
  //   tagIndexName: 'Tag',
  //   profileIndexName: 'Profile',
  // },
  development: {
    appId: 'N5IBFGFWIQ',
    apiKey: '94c292428b43c840bcabbbc86360b268',
    tagIndexName: 'Tag',
    profileIndexName: 'Profile',
  },
  staging: {
    appId: 'ZZ7IL8SNK8',
    apiKey: 'da7c05331cc5ce0316c9c0f158d7593c',
    tagIndexName: 'Tag',
    profileIndexName: 'Profile',
  },
  production: {
    appId: 'N5IBFGFWIQ',
    apiKey: '94c292428b43c840bcabbbc86360b268',
    tagIndexName: 'Tag',
    profileIndexName: 'Profile',
  },
}

// const regexp = /https:\/\/[a-zA-Z0-9_.-]*--nostalgic-jones-bfb4fe\.netlify\.com/g;

export const DOMAIN_NETLIFY = {
  development: {
    uri: 'http://localhost:5000',
  },
  staging: {
    uri: window.location.origin,
  },
  production: {
    uri: window.location.origin,
  },
}

export const UPLOADCARE_PUBLIC = {
  development: '199050a047fee9047f4c',
  staging: '199050a047fee9047f4c',
  production: '199050a047fee9047f4c',
}

export const UPLOADCARE_PRIVATE = {
  development: '10a61e82a03d564f9efd',
  staging: '10a61e82a03d564f9efd',
  production: '10a61e82a03d564f9efd',
}
