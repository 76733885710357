// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "*>div[class~=css-2b097c-container],*>div[class=DraftEditor-editorContainer],*>div[class=public-DraftEditorPlaceholder-root]{z-index:auto !important}@media only screen and (min-width: 120px)and (max-width: 320px){.mZ2flStqD3HOd1ApoS8v{max-width:270px}}@media only screen and (min-width: 321px)and (max-width: 410px){.mZ2flStqD3HOd1ApoS8v{max-width:315px}}@media only screen and (min-width: 411px)and (max-width: 700px){.mZ2flStqD3HOd1ApoS8v{max-width:360px}}.GEmbYbbsRRHfBi6XrvB2{display:flex;justify-content:space-between}.GCMwzywTkxl3oQSmx_I9:hover{background-color:#e35b66;color:#fff;border:none}.lRcKYLm4yaDYOnwr0Hr8:hover{background-color:#5293c7;color:#fff;border:none}", "",{"version":3,"sources":["webpack://./src/modules/Form/containers/form.module.scss"],"names":[],"mappings":"AAAA,4HAGE,uBAAA,CAGF,gEACI,sBACE,eAAA,CAAA,CAMN,gEACI,sBACE,eAAA,CAAA,CAKN,gEACI,sBACI,eAAA,CAAA,CAIR,sBACE,YAAA,CACA,6BAAA,CAGF,4BACE,wBAAA,CACA,UAAA,CACA,WAAA,CAGF,4BACE,wBAAA,CACA,UAAA,CACA,WAAA","sourcesContent":["* > div[class~=\"css-2b097c-container\"],\n* > div[class=\"DraftEditor-editorContainer\"],\n* > div[class=\"public-DraftEditorPlaceholder-root\"] {\n  z-index: auto!important;\n}\n\n@media only screen and (min-width : 120px) and (max-width : 320px) {\n    .responsiveFormModal {\n      max-width: 270px;\n    //   max-width: 100%;\n    }\n  }\n\n\n@media only screen and (min-width : 321px) and (max-width : 410px) {\n    .responsiveFormModal {\n      max-width: 315px;\n    //   max-width: 100%;\n    }\n  }\n\n@media only screen and (min-width: 411px) and (max-width : 700px){\n    .responsiveFormModal {\n        max-width: 360px;\n      }\n}\n\n.sectionTitle{\n  display: flex;\n  justify-content: space-between;\n}\n\n.buttonDanger:hover{\n  background-color: #E35B66;\n  color: white;\n  border: none\n}\n\n.buttonBasic:hover{\n  background-color: #5293C7;\n  color: white;\n  border: none\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"responsiveFormModal": "mZ2flStqD3HOd1ApoS8v",
	"sectionTitle": "GEmbYbbsRRHfBi6XrvB2",
	"buttonDanger": "GCMwzywTkxl3oQSmx_I9",
	"buttonBasic": "lRcKYLm4yaDYOnwr0Hr8"
};
export default ___CSS_LOADER_EXPORT___;
