// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".pomQFqjcZotg8u9j68_Y{z-index:3}@media only screen and (min-width: 120px)and (max-width: 319px){.pomQFqjcZotg8u9j68_Y{font-size:10px}}@media only screen and (min-width: 320px)and (max-width: 360px){.pomQFqjcZotg8u9j68_Y{font-size:13px}}.pomQFqjcZotg8u9j68_Y{z-index:auto}", "",{"version":3,"sources":["webpack://./src/components/AlgoliaAsyncCreatableOnPages/asyncCreatableOnPages.scss"],"names":[],"mappings":"AAAA,sBACI,SAAA,CAEF,gEACI,sBACE,cAAA,CAAA,CAIN,gEACI,sBACE,cAAA,CAAA,CAIJ,sBACE,YAAA","sourcesContent":[".tagClass {\n    z-index: 3;\n  }\n  @media only screen and (min-width : 120px) and (max-width : 319px) {\n      .tagClass {\n        font-size: 10px;\n      }\n    }\n  \n  @media only screen and (min-width : 320px) and (max-width : 360px) {\n      .tagClass {\n        font-size: 13px;\n      }\n    }\n  \n    .tagClass {\n      z-index: auto;\n    }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tagClass": "pomQFqjcZotg8u9j68_Y"
};
export default ___CSS_LOADER_EXPORT___;
