import React, { useState } from 'react'
// import { graphql, withApollo } from 'react-apollo';
import { withApollo, graphql } from '@apollo/client/react/hoc'
import { compose } from 'recompose'
import PropTypes from 'prop-types'
import slugify from 'slugify'
import {
  Modal,
  Header,
  Footer,
  FooterItem,
  Close,
  Body,
} from '@zendeskgarden/react-modals'
import { Button } from '@zendeskgarden/react-buttons'

import { withFormik } from 'formik'

import createPageSections from 'modules/Form/createPageSections'
import ProfileForm from 'modules/Form/containers/ProfileForm'

import { setMainProfile } from 'utils/handleMainProfile'
import { CreateTagMutation } from 'gql/tag'
import { CreatePageMutation } from 'gql/page'

import clsLogin from 'containers/LoginModal/loginModal.module.scss'

// using this function with React.memo to prevent the component to re-render.
// we had a re-render problem when we have an error on the mutation request
const areEqual = (prevProps, nextProps) => true

const CreatePage = React.memo((props) => {
  const currentForm = createPageSections()
  const sections = currentForm.sections
  const [showModal, handleModal] = useState(false)
  const [redirect, handleRedirect] = useState('')

  const plan = props.user.plan
  const generateSerial = (len) => {
    const p = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
    return [...Array(len)].reduce(
      (a) => a + p[~~(Math.random() * p.length)],
      ''
    )
  }

  plan === 'BASIC' ? (currentForm.sections[1] = {}) : ''

  // console.log(sections, "TYPETAG")
  //   const typeTag = sections[4].fields.forEach((elem) => {
  //     if (elem.type === "SELECT_TAG") {
  //       elem.type = "CREATE_TAG"
  //       return console.log(elem, "typetag")
  //     }
  // })
  // console.log(sections[4].fields, "typetag")
  const formikEnhancer = withFormik({
    validationSchema: currentForm.validationSchema,
    mapPropsToValues: () => ({
      // typeTag,
      ...currentForm.initialValues,
    }),
    handleSubmit: (vals, { setSubmitting }) => {
      const cleanVals = vals
      cleanVals.links =
        vals.links &&
        vals.links
          .filter((e) => e.url.length)
          .map((link) => ({
            id: link.id,
            url: link.url,
            type: link.type,
          }))
      cleanVals.editorStates = vals.editorStates.map((elem) => ({
        title: elem.title,
        editorState: elem.editorState,
        storyType: 'INTRODUCTION',
        isPublished: true,
      }))
      const tagsnew = vals.tags && vals.tags.filter((tag) => !tag.id)
      vals.tags &&
        vals.tags
          .filter((tag) => !tag.id)
          .map((tag) => {
            props
              .createTag({
                variables: {
                  name: tag.name,
                  slug: slugify(tag.name),
                },
              })
              .then(({ data }) => {
                setSubmitting(false)
                console.log('tagId', data)
              })
              .catch((err) => {
                console.log(err)
                setSubmitting(false)
              })
          })
      cleanVals.tags =
        vals.tags &&
        vals.tags
          .filter((tag) => tag.id)
          .map(
            (tag) =>
              console.log('SHOULD PRINT ONLY KNOWN TAGS', tag) || {
                id: tag.id,
              }
          )
      cleanVals.video = vals.video || vals.videoUrl
      cleanVals.isPublished = !vals.isDraft

      const variables = {
        ...cleanVals,
        type: 'TRAINING',
        ownerId: props.user.id,
        code_licence: generateSerial(10),
        nb_slot_available: 1,
      }
      props
        .createPage({
          variables,
        })
        .then(
          ({
            data: {
              createCompany: { pages },
            },
          }) => {
            setSubmitting(false)
            // Set New Profile to Main Profile
            setMainProfile(pages[0], 'TRAINING')
            // tagsnew.length >= 1 ? (
            //   handleModal(true),
            //   handleRedirect(`/org/@${pages[0].slug}`)
            // ) :
            window.location.replace(`/org/@${pages[0].slug}`)
          }
        )
        .catch((err) => {
          console.log(err)
          setSubmitting(false)
        })
    },
    displayName: 'PageForm',
  })
  const relocate = () => {
    window.location.replace(redirect)
  }
  return (
    <div style={{ paddingBottom: '60px' }}>
      {React.createElement(formikEnhancer(ProfileForm), {
        existingTags: props.existingTags,
        returnUrl: props.returnUrl,
        ...currentForm,
        ...props,
      })}
      {showModal && (
        <Modal className={clsLogin.responsiveFormModal}>
          <Header default>Information</Header>
          <Body>
            Les tags créés seront disponibles après validation par Whire.
          </Body>
          <Footer>
            <FooterItem
              style={{
                display: 'block',
                marginRight: 'auto',
                marginLeft: 'auto',
              }}
            >
              <Button
                success
                onClick={() => {
                  relocate()
                  handleModal(true)
                }}
              >
                ok
              </Button>
            </FooterItem>
          </Footer>
        </Modal>
      )}
    </div>
  )
}, areEqual)

CreatePage.propTypes = {
  returnUrl: PropTypes.string.isRequired,
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
}

export default compose(
  graphql(CreatePageMutation, { name: 'createPage' }),
  graphql(CreateTagMutation, { name: 'createTag' })
)(withApollo(CreatePage))
