// Patch global.`fetch` so that Apollo calls to GraphQL work
import 'isomorphic-fetch'
import 'babel-polyfill'
import 'date-input-polyfill'
import 'date-input-polyfill-react'
import '@zendeskgarden/react-modals/dist/styles.css'
import 'initStyles/draft-js-editor.scss'
import 'draftail/dist/draftail.css'

import React from 'react'

//import { render, ReactDOM } from 'react-dom';
import ReactDOM from 'react-dom'

// Browser routing
import { BrowserRouter } from 'react-router-dom'

import { AppContainer } from 'react-hot-loader'

// Apollo Provider. This HOC will 'wrap' our React component chain
// and handle injecting data down to any listening component
import { ApolloProvider } from '@apollo/client'

import { ThemeProvider } from '@zendeskgarden/react-theming'

import '@zendeskgarden/react-notifications/dist/styles.css'
import App from 'App'
import StoreProvider from 'contexts/Store'

// Grab the shared Apollo Client
import browserClient from './apollo'

import ReactGA from 'react-ga4'

// ----------------------

// Create a new browser Apollo client
// eslint-disable-next-line
export const client = browserClient()

const root = document.getElementById('root')
ReactGA.initialize('G-6V8TME9VRK')
ReactGA.send({ hitType: "pageview", page: window.location.pathname});
//ReactGA.pageview(window.location.pathname + window.location.search)

window.UPLOADCARE_LOCALE = 'fr'

const Load = () => {
  return (
    <ThemeProvider>
      <AppContainer>
        <BrowserRouter>
          <StoreProvider>
            <ApolloProvider client={client}>
              <App />
            </ApolloProvider>
          </StoreProvider>
        </BrowserRouter>
      </AppContainer>
    </ThemeProvider>
  )
}

// This is needed for Hot Module Replacement
if (module.hot) {
  module.hot.accept('App', load)
}

// load();
ReactDOM.render(<Load />, document.getElementById('root'))
