import * as Yup from 'yup'
import validateSlugAsync from 'utils/validateSlugAsync'

const PageValidationSchema = Yup.object().shape({
  title: Yup.string().required('Titre obligatoire'),
  // tags: Yup.string()
  //   .required('Tag obligatoire'),
  slug: Yup.string().required('Pseudo obligatoire'),
  summary: Yup.string().required('Description obligatoire'),
  editorStates: Yup.array()
    .of(
      Yup.object().shape({
        editorState: Yup.object()
          .shape({
            blocks: Yup.array().min(1),
          })
          // .required('Description obligatoire !')
          .nullable(),
      })
    )
    // .required('Description obligatoire !')
    .test('len', 'sdfsdfsdf', (val) => val.length === 3),
})

const pageInitialValues = {
  image: '',
  title: '',
  summary: '',
  editorStates: [
    {
      editorState: null,
      formTitle:
        "1/ D'abord, une intro pour présenter les personnes que vous accompagnez, leurs forces et leurs particularités/singularités",
      placeholder:
        'Décrivez les personnes que vous accompagnez, en particulier, leurs forces et leurs particularités qui les différencient d’autres candidats',
      title: 'Qui sont nos candidats ?',
    },
    {
      editorState: null,
      formTitle:
        '2/ Ensuite, expliciter les partenariats recherchés, le lien entre vos candidats et ceux à qui vous vous adressez',
      placeholder:
        'Décrivez les partenariats que vous cherchez à développer grâce à Whire, comment les structures auxquelles vous vous adressez vont bénéficier des atouts de vos candidats.\n\nA qui vous adressez-vous, à quel type de structures ? Pourquoi ? Qu’est-ce que les candidats que vous accompagnez peuvent leur apporter ?',
      title: 'Ce que nous vous proposons',
    },
    {
      editorState: null,
      formTitle:
        '3/ Enfin, présentez-vous, dites ce qui vous rend unique et les raisons de vous faire confiance',
      placeholder:
        "Décrivez ce qu'est et ce que fait votre organisation et mettez en avant des éléments qui vont pousser vos partenaires à vous faire confiance.\n\nRecommandation : Les titres devraient pouvoir se lire sans les paragraphes et raconter quelque chose.",
      title: 'Qui sommes-nous ? / Pourquoi nous faire confiance ?',
    },
  ],
  links: [
    {
      placeholder: 'https://urldevotresiteinternet',
      label: 'Votre site Internet',
      type: 'PERSONAL',
      url: '',
    },
    {
      placeholder: 'https://linkedin.com/company/votreidentifiant',
      label: 'Votre page LinkedIn',
      type: 'LINKEDIN',
      url: '',
    },
    {
      placeholder: 'https://twitter.com/votreidentifiant?lang=fr',
      label: 'Votre page Twitter',
      type: 'TWITTER',
      url: '',
    },
    {
      placeholder: 'https://youtube.com/channel/votreidentifiant',
      label: 'Votre page Youtube',
      type: 'YOUTUBE',
      url: '',
    },
    {
      placeholder: 'https://www.facebook.com/profile.php?id=votreidentifiant',
      label: 'Votre page Facebook',
      type: 'FACEBOOK',
      url: '',
    },
    {
      placeholder: 'https://www.instagram.com/votrepseudo',
      label: 'Votre page Instagram',
      type: 'INSTAGRAM',
      url: '',
    },
  ],
}

// const emoji = <span role="img" aria-label="info">👉</span>;

const titleField = {
  id: 'title',
  label: 'Nom de votre organisation',
  placeholder: '...',
  type: 'INPUT',
  fieldType: 'text',
}

const summaryField = {
  id: 'summary',
  label: "Votre phrase d'accroche",
  placeholder:
    'Une phrase qui donne envie aux employeurs de découvrir vos candidats',
  type: 'INPUT',
  fieldType: 'text',
}

const imageField = {
  id: 'image',
  label: 'Ajouter votre logo',
  type: 'IMAGE',
}

const webCamField = {
  id: 'videoUrl',
  label: 'Ajouter ma Video',
  type: 'WEBCAM',
}

const videoField = {
  id: 'videoUrl',
  label: 'Vous pouvez aussi intégrer 1 vidéo en ligne (Youtube, Viméo, etc.).',
  placeholder: "L'adresse de la vidéo",
  type: 'VIDEO',
}

const introField = {
  id: 'editorStates',
  type: 'EDITORS_ARRAY',
  accessor: 'editorState',
}

const fieldArray = {
  id: 'array',
  init: {},
  accessor: '',
  placeholder: 'Une URL valide',
  type: 'FIELD_ARRAY',
}

// const tagField = {
//   id: 'tags',
//   placeholder: 'Exemple: développement logiciel',
//   type: 'TAG',
// };
// const createTagOnPagesField = {
//   id: 'tags',
//   placeholder: 'Exemple: mon nouveau tag',
//   type: 'CREATE_TAG_ON_PAGES',
// };

// const selectTagOnPagesField = {
//   id: 'tags',
//   placeholder: 'Exemple: mon nouveau tag',
//   type: 'SELECT_TAG_ON_PAGES',
// };

// argument could be
// -> initialValues.editorStates
// to load specific questions depending on user track

export default (pageValues) =>
  Object.assign(
    {
      initialValues: pageValues || pageInitialValues,
      validationSchema: PageValidationSchema,
    },
    {
      header: 'Créer votre page whire',
      sections: [
        {
          title: 'Infos générales',
          description: '👉 Les 1ères infos que verront vos futurs partenaires ',
          fields: [
            titleField,
            {
              type: 'SLUG',
              validate: (...args) => validateSlugAsync(...args),
            },
            summaryField,
            imageField,
          ],
        },
        {
          title: 'Présentation',
          description:
            "👉 Affichées sur votre page d'accueil, ces infos permettent à vos futurs partenaires de mieux comprendre qui sont vos candidats, ce que vous attendez de ces partenariats et en quoi vous êtes un tiers de confiance pour eux.",
          fields: [introField],
        },
        {
          title: 'Profils sociaux',
          description:
            '👉 Les recruteurs utilisent Google pour vous trouver. Faites leurs gagner du temps ! Ces champs sont optionnels.',
          fields: [
            {
              ...fieldArray,
              id: 'links',
              accessor: 'url',
              init: { url: '' },
            },
          ],
        },
        {
          title: 'Vidéo (optionnel)',
          description:
            '👉 Faites découvrir votre organisation par une vidéo. Résultat garanti auprès des recruteurs. (Taille maximale: 100mo).',
          fields: [webCamField, videoField],
        },
        // {
        //   title: 'Tags',
        //   description: '👉 Ajoutez des tags pour permettre à vos lecteurs de trouver facilement le contenu qui les intéresse !\n' +
        //   'Chaque tag sera disponible sur votre page. Vous pouvez également créer de nouveaux tags, ils seront alors disponibles\n' +
        //   'après validation par Whire.',
        //   // fields: [tagField],
        //   fields: pageValues ? [selectTagOnPagesField] : [createTagOnPagesField],
        // },
      ],
    }
  )
