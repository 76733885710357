import { ApolloLink, HttpLink } from '@apollo/client'
import { ApolloClient } from '@apollo/client/core'
import { onError } from '@apollo/client/link/error'
import { InMemoryCache } from '@apollo/client/cache'
import fetch from 'unfetch'

import { APOLLO } from '../kit/project'

const httpLink = new HttpLink({
  uri: APOLLO[process.env.NODE_ENV].uri,
})

// console.log("process.env.NODE_ENV", process.env.NODE_ENV);
// console.log("APOLLO[process.env.NODE_ENV].uri", APOLLO[process.env.NODE_ENV].uri);

const authLink = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => {
    const token = window.DB ? window.DB.getItem('whire-app-token') : undefined
    // console.log(`Bearer ${token}`);
    const head = {
      ...headers,
    }
    if (token) {
      head.authorization = `Bearer ${token}`
    }
    return {
      headers: {
        ...head,
      },
    }
  })

  return forward(operation)
})

const errorLink = onError(({ graphQLErrors, networkError, response }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message }) => {
      if (response) {
        response.errors = [new Error(message)]
      }
      if (message === 'NOT_AUTHENTICATED') {
        // signOut(client);
      }
    })
  }

  if (networkError) {
    console.log('Network error', networkError)

    if (networkError.statusCode === 401) {
      // signOut(client);
    }
  }
})

const link = ApolloLink.from([errorLink, authLink, httpLink])

const cache = new InMemoryCache()

const client = new ApolloClient({
  fetchOptions: { fetch },
  link,
  cache,
  resolvers: {},
})
// https://stackoverflow.com/questions/52353339/apollo-beginner-cannot-read-data-back-from-cache
// https://github.com/apollographql/apollo-client/issues/2780

// const data = {
//   user: {},
// };

// cache.writeData({ data });

// client.onResetStore(() => {
//   console.log('Apollo-client, client.onResetStore is called');
//   return cache.writeData({ data });
// });

function createClient() {
  return client
}

// Creates a new browser client
export default function browserClient() {
  return createClient()
}
