import React from 'react'

import AsyncSelect from 'react-select/async'

import { connectAutoComplete } from 'react-instantsearch/connectors'

import cls from './algoliaAsyncWork.module.scss'

const mapOptions = (array) =>
  array.map((e) => ({
    value: e.name,
    label: e.name,
    id: e.id,
    tagType: e.tagType,
  }))

const AsyncTagSelect = (props) => (
  <AsyncSelect
    className={cls.tagClass}
    isMulti
    // cacheOptions
    // closeMenuOnSelect={false}
    placeholder="Je tape un métier ou domaine pro que je vise"
    // defaultOptions={mapOptions(props.hits)}
    noOptionsMessage={({ inputValue }) =>
      !inputValue
        ? 'Taper pour voir la liste des tags défiler'
        : `Aucun résultat`
    }
    value={props.currentSelectionWork}
    onChange={props.onChange}
    loadOptions={(value) => {
      props.refine(value)
      return new Promise((resolve) => {
        resolve(mapOptions(props.hits))
      })
    }}
  />
)

AsyncTagSelect.defaultProps = {
  currentSelectionWork: [],
}

const AlgoliaAsyncWork = connectAutoComplete(AsyncTagSelect)

export default AlgoliaAsyncWork
