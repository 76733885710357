import React from 'react'

import AsyncSelect from 'react-select/async'

import { connectAutoComplete } from 'react-instantsearch/connectors'

import cls from './algoliaAsyncInterest.module.scss'

const mapOptions = (array) =>
  array.map((e) => ({
    value: e.name,
    label: e.name,
    id: e.id,
    tagType: e.tagType,
  }))

const AsyncTagSelect = (props) => (
  <AsyncSelect
    className={cls.tagClass}
    isMulti
    // cacheOptions
    // closeMenuOnSelect={false}
    placeholder="Je sélectionne le(s) tag(s) qui me correspond(ent)"
    defaultOptions={mapOptions(props.hits)}
    value={props.currentSelectionInterest}
    onChange={props.onChange}
    loadOptions={(value) => {
      props.refine(value)
      return new Promise((resolve) => {
        resolve(mapOptions(props.hits))
      })
    }}
  />
)

AsyncTagSelect.defaultProps = {
  currentSelectionInterest: [],
}

const AlgoliaAsyncInterest = connectAutoComplete(AsyncTagSelect)

export default AlgoliaAsyncInterest
