import React, { node } from 'react'

import cls from './thread-header.module.scss'

const ThreadHeader = ({ children, ...rest }) => (
  <div className={cls.wrapper} {...rest}>
    {children}
  </div>
)

export default ThreadHeader
