import React, { createContext, useState, useEffect } from 'react'

import compareObj from 'utils/compareObj'
import {
  extractSlug,
  traineePage,
  userPage,
  proPage,
  editPage,
  storyPage,
  profilePage,
  createPage,
  dashboardPage,
} from 'utils/regexp'
import { graphql, withApollo } from '@apollo/client/react/hoc'
import { compose } from 'recompose'

import { PageFirstQuery, EMAIL_COMPANY } from 'gql/page'

export const StoreContext = createContext(null)

export const StoreProvider = ({ children }) => {
  const oldUser = window.DB
    ? window.DB.getItem('whire-app-user') &&
      JSON.parse(window.DB.getItem('whire-app-user'))
    : null

  const oldMe = window.DB
    ? window.DB.getItem('whire-app-me') &&
      JSON.parse(window.DB.getItem('whire-app-me'))
    : null
  const oldUpdatedStory = window.DB
    ? window.DB.getItem('whire-app-updatedStory') &&
      JSON.parse(window.DB.getItem('whire-app-updatedStory'))
    : null
  const initState = {
    user: oldUser,
    me: oldMe,
    story: oldUpdatedStory,
  }

  const [user, setUser] = useState(initState.user)
  const storeUser = (usr) => {
    // eslint-disable-next-line
    if (window.DB && (!oldUser || (oldUser && !compareObj(usr, oldUser)))) {
      console.log('storeUser')
      window.DB.setItem('whire-app-user', JSON.stringify(usr))
      setUser(usr)
    }
    return null
  }

  const [me, setMe] = useState(initState.me)
  const storeMe = (m) => {
    // eslint-disable-next-line
    if (window.DB && (!oldMe || (oldMe && !compareObj(m, oldMe)))) {
      console.log('storeMe', m)
      window.DB.setItem('whire-app-me', JSON.stringify(m))
      setMe(m)
    }
    return null
  }

  const [story, setStory] = useState(initState.story)
  const storeUpdatedStory = (s) => {
    // eslint-disable-next-line
    if (
      window.DB &&
      (!oldUpdatedStory || (oldUpdatedStory && !compareObj(s, oldUpdatedStory)))
    ) {
      console.log('storeUpdatedStory')
      window.DB.setItem('whire-app-updatedStory', JSON.stringify(s))
      setStory(s)
    }
    return null
  }

  const [ownPage, setOwnPage] = useState(false)
  const { pathname } = window.location

  useEffect(() => {
    const cleanProfiles =
      me && me.profiles && me.profiles.map((obj) => obj.slug)
    const cleanPages = me && me.pages && me.pages.map((obj) => obj.slug)
    const userSlugs =
      cleanPages && cleanProfiles ? [...cleanProfiles, ...cleanPages] : []
    const isOwnPage =
      userSlugs &&
      userSlugs.filter((slug) => extractSlug(pathname) === slug).length > 0
    // eslint-disable-next-line
    setOwnPage(isOwnPage)
  }, [me, pathname])

  const [editablePage, setEditablePage] = useState(false)

  useEffect(() => {
    if (
      !editPage(pathname) &&
      (proPage(pathname) || userPage(pathname)) &&
      !storyPage(pathname) &&
      !traineePage(pathname) &&
      !dashboardPage(pathname)
    ) {
      setEditablePage(true)
    } else {
      setEditablePage(false)
    }
  }, [pathname])

  const [profile, setProfile] = useState(false)

  useEffect(() => {
    if (!profilePage(pathname)) {
      setProfile(false)
    } else {
      setProfile(true)
    }
  }, [pathname])

  const [creatablePage, setCreatablePage] = useState(false)

  useEffect(() => {
    if (!createPage(pathname)) {
      setCreatablePage(false)
    } else {
      setCreatablePage(true)
    }
  }, [pathname])

  const [traineesPage, setTraineesPage] = useState(false)

  useEffect(() => {
    if (!traineePage(pathname)) {
      setTraineesPage(false)
    } else {
      setTraineesPage(true)
    }
  }, [pathname])

  const [ref, setRef] = useState(null)

  const [mode, setCurrentState] = useState(
    window.location.pathname.includes('reset') ? 'reset' : 'login'
  )
  const [showThanks, setShowThanks] = useState(false)
  const [showNoMail, setShowNoMail] = useState(false)

  const [showNewPasswordSuccess, setShowNewPasswordSuccess] = useState(false)

  const [modifiers, setModifiers] = useState()
  const [normalWidth, setNormalWidth] = useState(false)
  const [navigationByTag, setNavigationByTag] = useState(false)
  const [navigationIsOpen, setNavigationIsOpen] = useState(true)
  const [emberViewIsOpen, setEmberViewIsOpen] = useState(false)

  const store = {
    user: {
      user,
      storeUser,
    },
    me: {
      me,
      storeMe,
    },
    story: {
      story,
      storeUpdatedStory,
    },
    ownPage: {
      ownPage,
      setOwnPage,
    },
    editablePage: {
      editablePage,
      setEditablePage,
    },
    profile: {
      profile,
      setProfile,
    },
    creatablePage: {
      creatablePage,
      setCreatablePage,
    },
    traineesPage: {
      traineesPage,
      setTraineesPage,
    },
    ref: {
      ref,
      setRef,
    },
    loginModal: {
      mode,
      setCurrentState,
      showThanks,
      setShowThanks,
      showNoMail,
      setShowNoMail,
      showNewPasswordSuccess,
      setShowNewPasswordSuccess,
    },
    modifiers: {
      modifiers,
      setModifiers,
    },
    normalWidth: {
      normalWidth,
      setNormalWidth,
    },
    navigationByTag: {
      navigationByTag,
      setNavigationByTag,
    },
    navigationIsOpen: {
      navigationIsOpen,
      setNavigationIsOpen,
    },
    emberViewIsOpen: {
      emberViewIsOpen,
      setEmberViewIsOpen,
    },
  }

  return <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
}

export default StoreProvider
