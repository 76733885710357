import React, { useContext, useState } from 'react'

import { Link, matchPath } from 'react-router-dom'
import UIAvatar from 'react-ui-avatars'
import slugify from 'slugify'

import { StoreContext } from 'contexts/Store'
import { SVG_URI } from 'utils/vars'

import {
  UikNavSection,
  UikNavLink,
  UikNavUser,
  UikDivider,
} from 'uikit/components'

import { UikNavPanel } from 'uikit/containers'
import { Tag } from 'antd'
import { element } from 'prop-types'

// import menuLinks from './menuLinks';
// import menuLinksRecruiters from './menuLinksRecruiters';
// import candidateLinks from './candidateLinks';
// import menuFavourites from './menuFavourites';

const getParams = (pathname) => {
  const matchAppender = matchPath(pathname, {
    path: '/(@[a-zA-Z0-9_.-]*)(/edit|)?/:appends?',
  })
  if (matchAppender && matchAppender.params) {
    const { params } = matchAppender
    return Object.assign(
      {},
      {
        ...params,
        // On /@userName/edit
        // matchPath returns appends === undefined.
        // To match our navigation first link,
        // we need to replace it wiith an empty string
        appends: params.appends === undefined ? '' : params.appends,
      }
    )
  }
  return { appends: '' }
}
const getRandomColor = (letter) => {
  let colorValues = '#FFC117'
  if (letter.match(/^[a-cA-C 0-9\.\,\+\-]*$/)) {
    return colorValues
  } else if (letter.match(/^[d-fD-F\.\,\+\-]*$/)) {
    return (colorValues = '#007ED9')
  } else if (letter.match(/^[g-iG-I\.\,\+\-]*$/)) {
    return (colorValues = '#ED4A5E')
  } else if (letter.match(/^[j-lJ-L\.\,\+\-]*$/)) {
    return (colorValues = '#00A2AE')
  } else if (letter.match(/^[m-oM-O\.\,\+\-]*$/)) {
    return (colorValues = '#3C26BB')
  } else if (letter.match(/^[p-rP-R\.\,\+\-]*$/)) {
    return (colorValues = '#666be2')
  } else if (letter.match(/^[s-uS-U\.\,\+\-]*$/)) {
    return (colorValues = '#ff015b')
  } else if (letter.match(/^[v-xV-X\.\,\+\-]*$/)) {
    return (colorValues = '#30ca93')
  } else if (letter.match(/^[v-xV-X\.\,\+\-]*$/)) {
    return (colorValues = '#ffd000')
  } else {
    return (colorValues = '#F56A00')
  }
}
const SocialNavigation = (props) => {
  const [showTypes, setShowTypes] = useState(false)
  const [showTags, setShowTags] = useState(true)
  const [tagHover, setTagHover] = useState(false)
  const [storyHover, setStoryHover] = useState(false)
  const [myGroups, setMyGroups] = useState(false)
  const [myGroupsHover, setMyGroupsHover] = useState(false)

  const {
    ownPage: { ownPage, setOwnPage },
    navigationByTag: { navigationByTag, setNavigationByTag },
    normalWidth: { normalWidth, setNormalWidth },
    navigationIsOpen: { navigationIsOpen, setNavigationIsOpen },
  } = useContext(StoreContext)
  const match = getParams(props.location.pathname)
  const allTags = []
  const allStories = []
  const stories = props.sections.filter((e) => e.text !== 'Présentation')
  const storiesFiltered = stories.map((elem) => elem.userSection)
  const storiesFiltered2 = storiesFiltered.map((e) => e.stories)

  for (const value of storiesFiltered2.values()) {
    // we collect all the tags from stories
    value
      .filter((e) => e.isPublished)
      // since prisma migration v1 to v2 there is a bug that duplicate tags when you edit a story. this filter duplicate tags
      .map((v) => v.tags.filter((v,i,a)=>a.findIndex(v2=>(v2.id===v.id))===i)
      .map((t) => allTags.push(t.name)))
    // we collect all the stories into one array
    value.map((v) => allStories.push(v))
  }

  // get the tags and the count of duplicates tags and push them into an array of object and sort by occurency
  const count = Object.entries(
    allTags.reduce((acc, curr) => {
      acc[curr] = (acc[curr] || 0) + 1
      return acc
    }, {})
  )
    .map(([key, value]) => ({ tag: key, quantity: value }))
    .sort((a, b) => b.quantity - a.quantity)
  
  const trainees = props.memberships.map(m=> m.trainee)
  // remove duplicate pages
  const trainee = Object.values(trainees.reduce((acc,cur)=>Object.assign(acc,{[cur.page.id]:cur}),{}))

  return (
    <UikNavPanel>
      {props.navUser.imgUrl ? (
        <UikNavUser
          imgUrl={props.navUser.imgUrl}
          name={props.navUser.name}
          textTop={props.navUser.summary}
        />
      ) : (
        <div>
          <UIAvatar
            background={getRandomColor(
              props.navUser.name.charAt(0).toLowerCase()
            )}
            name={props.navUser.name}
            color="#FFFFFF"
            rounded
            size="80px"
            style={{
              marginLeft: '6em',
              marginTop: '2.5em',
              marginBottom: '-1em',
            }}
          />
          <UikNavUser
            name={props.navUser.name}
            textTop={props.navUser.summary}
          />
        </div>
      )}
      <UikDivider />
      <UikNavSection style={{ marginTop: '24px' }}>
        {props.sections
          .filter((l) => l.text === 'Présentation')
          .map((link) => (
            <UikNavLink
              className={link.appends === match.appends ? 'active' : null}
              Component={Link}
              key={link.text}
              // onClick={props.onLinkIsClicked}
              onClick={() => setNavigationByTag(false)}
              // highlighted
              rightEl={link.rightEl}
              to={
                link.appends === ''
                  ? `/${match['0']}`
                  : {
                      pathname: `/${match['0']}/${link.appends}`,
                      state: { modal: true },
                    }
              }
            >
              {link.text}
            </UikNavLink>
          ))}
        {ownPage ? (
          <div>
            <UikNavLink
              highlighted
              onClick={() =>
                window.open(
                  'https://www.notion.so/Le-Guide-du-Whirrior-c9f54e82c5954253b85264726c3c5b4a'
                )
              }
            >
              Guide du Whirrior
            </UikNavLink>
            <UikNavLink
                Component={Link}
                highlighted
                onClick={() => {
                  setNavigationByTag(false)
                  setNormalWidth(false)
                }}
                to={`/@${props.navUser.userName}/cards`}
              >
                Créer ma carte
              </UikNavLink>
          </div>
        ) : null}
        <UikNavLink
          highlighted
          onMouseOver={() => setStoryHover(true)}
          onMouseOut={() => setStoryHover(false)}
          onClick={() => setShowTypes((prevState) => !prevState)}
        >
          <img
            style={{
              width: showTypes ? '1em' : '0.5em',
              marginRight: '0.5em',
            }}
            src={
              showTypes && storyHover
                ? `${SVG_URI}/icone-horizontal-violet.svg`
                : showTypes && !storyHover
                ? `${SVG_URI}/icon-horizontal-gris.svg`
                : !showTypes && storyHover
                ? `${SVG_URI}/icon-violet.svg`
                : !showTypes && !storyHover
                ? `${SVG_URI}/icon-gris.svg`
                : ''
            }
          />
          Mes Histoires
        </UikNavLink>
        {showTypes
          ? props.sections
              .filter((l) => l.text !== 'Présentation')
              .map((link) => (
                <UikNavLink
                  className={link.appends === match.appends ? 'active' : null}
                  Component={Link}
                  key={link.text}
                  // onClick={props.onLinkIsClicked}
                  onClick={() => setNavigationByTag(false)}
                  // highlighted
                  rightEl={link.rightEl}
                  to={
                    link.appends === ''
                      ? `/${match['0']}`
                      : {
                          pathname: `/${match['0']}/${link.appends}`,
                          state: { modal: true },
                        }
                  }
                >
                  {link.text}
                </UikNavLink>
              ))
          : null}
        <UikNavLink
          highlighted
          onMouseOver={() => setTagHover(true)}
          onMouseOut={() => setTagHover(false)}
          onClick={() => setShowTags((prevState) => !prevState)}
        >
          <img
            style={{
              width: showTags ? '1em' : '0.5em',
              marginRight: '0.5em',
            }}
            src={
              showTags && tagHover
                ? `${SVG_URI}/icone-horizontal-violet.svg`
                : showTags && !tagHover
                ? `${SVG_URI}/icon-horizontal-gris.svg`
                : !showTags && tagHover
                ? `${SVG_URI}/icon-violet.svg`
                : !showTags && !tagHover
                ? `${SVG_URI}/icon-gris.svg`
                : ''
            }
          />
          Navigation par Tag
        </UikNavLink>
        {showTags
          ? count.map((c) => (
              <UikNavLink
                rightEl={c.quantity}
                key={c.tag}
                Component={Link}
                // highlighted
                onClick={() => setNavigationByTag(true)}
                to={
                  c.tag === ''
                    ? `/${match['0']}`
                    : {
                        pathname: `/${match['0']}/tag/${slugify(c.tag)}`,
                        state: { modal: true },
                      }
                }
              >
                {c.tag}
              </UikNavLink>
            ))
          : null}
        {ownPage ? (
          <UikNavLink
            highlighted
            onMouseOver={() => setMyGroupsHover(true)}
            onMouseOut={() => setMyGroupsHover(false)}
            onClick={() => setMyGroups((prevState) => !prevState)}
          >
            <img
              style={{
                width: myGroups ? '1em' : '0.5em',
                marginRight: '0.5em',
              }}
              src={
                myGroups && myGroupsHover
                  ? `${SVG_URI}/icone-horizontal-violet.svg`
                  : myGroups && !myGroupsHover
                  ? `${SVG_URI}/icon-horizontal-gris.svg`
                  : !myGroups && myGroupsHover
                  ? `${SVG_URI}/icon-violet.svg`
                  : !myGroups && !myGroupsHover
                  ? `${SVG_URI}/icon-gris.svg`
                  : ''
              }
            />
            Mes groupes
          </UikNavLink>
        ) : null}
        {myGroups && ownPage && trainee
          ? trainee.map((t) => (
              <UikNavLink
                className={t.appends === match.appends ? 'active' : null}
                Component={Link}
                key={t.id}
                onClick={() => {
                  setNavigationByTag(false)
                  setNormalWidth(false)
                }}
                to={
                  t.page.slug === ''
                    ? `/${match['0']}`
                    : {
                        pathname: `/org/@${t.page.slug}`,
                        state: { modal: true },
                      }
                }
              >
                {t.page.title}
              </UikNavLink>
            ))
          : null}
      </UikNavSection>
    </UikNavPanel>
  )
}

SocialNavigation.defaultProps = {
  onLinkIsClicked: null,
}

export default SocialNavigation
