import React, { useContext, useState } from 'react'
import { StoreContext } from 'contexts/Store'
import { string, arrayOf, shape, number } from 'prop-types'
import { withRouter } from 'react-router'

import UIAvatar from 'react-ui-avatars'
import MetaTags from 'react-meta-tags'

import { historyShape } from 'utils/shapes'
import { SVG_URI, USER_LINK_ITERABLE, DOMAIN } from 'utils/vars'

import { UikContainerHorizontal, UikLayoutMain } from 'uikit/containers'
import {
  UikAvatar,
  UikTag,
  UikTagInterest,
  UikTagOther,
  UikSvg,
} from 'uikit/components'

import TagOnProfile from 'components/TagOnProfile/TagOnProfile'
import TextWidget from 'components/TextWidget'
import Menu from 'components/Menu'
import Onboarding from 'components/Onboarding'

import {
  FacebookShareButton,
  FacebookIcon,
  TwitterIcon,
  TwitterShareButton,
  LinkedinIcon,
  LinkedinShareButton,
} from 'react-share'

import cls from './publicProfile.module.scss'

const PublicProfile = ({
  links,
  history,
  title,
  summary,
  slug,
  tags,
  navSections,
  navSectionsDraft,
  setDraft,
  image,
  imageModifier,
  sections,
  video,
  resume,
}) => {
  const {
    ownPage: { ownPage },
    navigationByTag: { setNavigationByTag },
    normalWidth: { normalWidth },
  } = useContext(StoreContext)

  const path = window.location.hostname

  const getRandomColor = (letter) => {
    let colorValues = '#FFC117'
    if (letter.match(/^[a-cA-C 0-9\.\,\+\-]*$/)) {
      return colorValues
    } else if (letter.match(/^[d-fD-F\.\,\+\-]*$/)) {
      return (colorValues = '#007ED9')
    } else if (letter.match(/^[g-iG-I\.\,\+\-]*$/)) {
      return (colorValues = '#ED4A5E')
    } else if (letter.match(/^[j-lJ-L\.\,\+\-]*$/)) {
      return (colorValues = '#00A2AE')
    } else if (letter.match(/^[m-oM-O\.\,\+\-]*$/)) {
      return (colorValues = '#3C26BB')
    } else if (letter.match(/^[p-rP-R\.\,\+\-]*$/)) {
      return (colorValues = '#666be2')
    } else if (letter.match(/^[s-uS-U\.\,\+\-]*$/)) {
      return (colorValues = '#ff015b')
    } else if (letter.match(/^[v-xV-X\.\,\+\-]*$/)) {
      return (colorValues = '#30ca93')
    } else if (letter.match(/^[v-xV-X\.\,\+\-]*$/)) {
      return (colorValues = '#ffd000')
    } else {
      return (colorValues = '#F56A00')
    }
  }
  //filter the links in case we have multiples times the same link because there was a bug. Sometimes, the same link were saved multiple times in the database
  const filterLinks = Array.from(new Set(links.map((link) => link.url))).map(
    (url) => {
      return {
        url,
        id: links.find((link) => link.url === url).id,
        type: links.find((link) => link.url === url).type,
      }
    }
  )
  // since prisma migration v1 to v2 there is a bug that duplicate tags when you edit profile. this filter duplicate tags
  const tagsProfile = tags.filter((v,i,a)=>a.findIndex(v2=>(v2.id===v.id))===i)

  const tagsInterest = []
  tagsProfile.map((elem) =>
    elem.type === 'INTEREST' ? tagsInterest.push(elem.name) : ''
  )
  const tagsWork = []
  tagsProfile.map((elem) =>
    elem.type === 'WORK' || elem.type === 'DOMAIN'
      ? tagsWork.push(elem.name)
      : ''
  )
  const tagsOther = []
  tagsProfile.map((elem) =>
    elem.type !== 'WORK' && elem.type !== 'INTEREST' && elem.type !== 'DOMAIN'
      ? tagsOther.push(elem.name)
      : ''
  )

  return (
    <UikLayoutMain
      style={{
        backgroundColor: 'white',
        borderTop: 'solid 1px #DADEE7',
        paddingTop: '0',
        width:
          normalWidth &&
          /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(
            navigator.userAgent
          ) === false
            ? 'calc(100% - 270px)'
            : '100%',
      }}
    >
      {/* <MetaTags>
        <title>Découvrez le profil de {title}</title>
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content={path + "/@"+ slug} />
        <meta property="og:url"  content={path + "/@"+ slug} />
        <meta property="og:type" content="website" />
        <meta name="twitter:title" content={"Découvrez le profil de " + title} />
        <meta property="og:title" content={"Découvrez le profil de " + title}/>
        <meta name="twitter:description" content={summary} />
        <meta property="og:description" content={summary} /> 
        <meta name="twitter:image" content={image} />                     
        <meta property="og:image" content={image} />
      </MetaTags> */}
      <div
        className={cls.profilePage}
      >
        <div
          style={{
            textAlign: 'center',
            maxWidth: '580px',
            margin: 'auto',
          }}
        >
          {ownPage ? (
            <a
              style={{
                marginLeft: window.innerWidth < 550 ? '10em' : '20em',
                fontSize: '25px',
              }}
              href={`/@${slug}/edit`}
            >
              ✍️
            </a>
          ) : null}
          <div style={{ justifyContent: 'space-around' }}>
            {image ? (
              <UikAvatar
                imageId={image}
                imageModifier={imageModifier}
                size="larger"
                style={{ justifyContent: 'space-around' }}
              />
            ) : (
              <UIAvatar
                background={getRandomColor(title.charAt(0).toLowerCase())}
                name={title}
                color="#FFFFFF"
                rounded
                size="80px"
              />
            )}
          </div>
          <h1
            style={{
              fontSize: '30px',
              fontFamily:
                '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica,Arial,sans-serif',
              marginTop: '.6em',
            }}
          >
            <span role="img" aria-label="hello">
              👋
            </span>{' '}
            Bonjour, je m&rsquo;appelle {title}
          </h1>
          <h4 style={{ marginTop: '1em' }}>{summary}</h4>
          <UikContainerHorizontal style={{ justifyContent: 'center' }}>
            <TagOnProfile
              tagsInterest={tagsInterest}
              tagsWork={tagsWork}
              tagsOther={tagsOther}
            />
          </UikContainerHorizontal>
          <UikContainerHorizontal
            style={{
              margin: '30px 12px',
              justifyContent: 'center',
            }}
          >
            {!!resume ? (
              <span key={resume}>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  className="link-reset"
                  href={`https://ucarecdn.com/${resume}/-/inline/yes/`}
                >
                  <img
                    alt={USER_LINK_ITERABLE['RESUME'].title}
                    src={`${DOMAIN}${USER_LINK_ITERABLE['RESUME'].icon}`}
                    style={{
                      width: '52px',
                      height: '52px',
                      marginTop: '-1px',
                      marginLeft: '-1px',
                    }}
                  />
                  &nbsp;&nbsp;&nbsp;&nbsp;
                </a>
              </span>
            ) : null}
            {filterLinks &&
              filterLinks.map((item) => {
                if (
                  item.type === 'OTHER' &&
                  item.url.includes('instagram.com')
                ) {
                  USER_LINK_ITERABLE['OTHER'].icon =
                    '/static/links/instagram.png'
                } else if (
                  item.type === 'OTHER' &&
                  item.url.includes('twitter.com')
                ) {
                  USER_LINK_ITERABLE['OTHER'].icon = '/static/links/twitter.svg'
                } else if (
                  item.type === 'OTHER' &&
                  item.url.includes('facebook.com')
                ) {
                  USER_LINK_ITERABLE['OTHER'].icon =
                    '/static/links/facebook.svg'
                } else if (
                  item.type === 'OTHER' &&
                  item.url.includes('linkedin.com')
                ) {
                  USER_LINK_ITERABLE['OTHER'].icon =
                    '/static/links/linkedin.svg'
                } else if (
                  item.type === 'OTHER' &&
                  item.url.includes('github')
                ) {
                  USER_LINK_ITERABLE['OTHER'].icon = '/static/links/github.svg'
                } else if (
                  item.type === 'OTHER' &&
                  item.url.includes('youtube.com')
                ) {
                  USER_LINK_ITERABLE['OTHER'].icon = '/static/links/youtube.svg'
                } else if (
                  item.type === 'OTHER' &&
                  item.url.includes('flickr.com')
                ) {
                  USER_LINK_ITERABLE['OTHER'].icon = '/static/links/flickr.svg'
                } else if (
                  item.type === 'OTHER' &&
                  item.url.includes('tumblr.com')
                ) {
                  USER_LINK_ITERABLE['OTHER'].icon = '/static/links/tumblr.svg'
                } else if (
                  item.type === 'OTHER' &&
                  item.url.includes('medium.com')
                ) {
                  USER_LINK_ITERABLE['OTHER'].icon = '/static/links/medium.svg'
                } else if (
                  item.type === 'OTHER' &&
                  item.url.includes('behance.net')
                ) {
                  USER_LINK_ITERABLE['OTHER'].icon = '/static/links/behance.svg'
                } else if (
                  item.type === 'OTHER' &&
                  item.url.includes('dribbble.com')
                ) {
                  USER_LINK_ITERABLE['OTHER'].icon =
                    '/static/links/dribbble.svg'
                } else if (
                  item.type === 'OTHER' &&
                  item.url.includes('viadeo.com')
                ) {
                  USER_LINK_ITERABLE['OTHER'].icon = '/static/links/viadeo.svg'
                } else if (
                  item.type === 'OTHER' &&
                  item.url.includes('vimeo.com')
                ) {
                  USER_LINK_ITERABLE['OTHER'].icon = '/static/links/vimeo.svg'
                } else if (
                  item.type === 'OTHER' &&
                  item.url.includes('pinterest')
                ) {
                  USER_LINK_ITERABLE['OTHER'].icon =
                    '/static/links/pinterest.svg'
                } else if (
                  item.type === 'OTHER' &&
                  item.url.includes('openbadge')
                ) {
                  USER_LINK_ITERABLE['OTHER'].icon =
                    '/static/links/openbadge.png'
                } else {
                  USER_LINK_ITERABLE['OTHER'].icon =
                    '/static/links/icone-link.png'
                }

                return (
                  <span key={item.id}>
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      className="link-reset"
                      href={
                        item.type !== 'RESUME'
                          ? item.url
                          : item.url.concat('-/inline/yes/')
                      }
                    >
                      <img
                        alt={USER_LINK_ITERABLE[item.type].title}
                        src={`${DOMAIN}${USER_LINK_ITERABLE[item.type].icon}`}
                        style={{
                          width: '52px',
                          height: '52px',
                          marginTop: '-1px',
                          marginLeft: '-1px',
                        }}
                      />
                      &nbsp;&nbsp;&nbsp;&nbsp;
                    </a>
                  </span>
                )
              })}
          </UikContainerHorizontal>
        </div>
        {video ? (
          <TextWidget
            style={{ marginTop: '30px' }}
            content={{
              videoUrl:
                video.includes('vimeo') ||
                video.includes('dailymotion') ||
                video.includes('youtube') ||
                video.includes('twitter')
                  ? video
                  : video.includes('https://www.ucarecdn.com')
                  ? video.split('https://www.ucarecdn.com/').join('')
                  : video,
            }}
          />
        ) : null}
        {sections.find(
          (e) => e && e.storiesTypeExpected === 'INTRODUCTION'
        ) && (
          <TextWidget
            style={{ marginTop: '30px' }}
            content={sections
              .find((e) => e.storiesTypeExpected === 'INTRODUCTION')
              .stories.filter((elem) => !!elem.editorState)
              .map((elem) => ({
                ...elem,
              }))}
          />
        )}
        {navSections.filter((e) => e.text !== 'Présentation').length ? (
          <Menu
            links={navSections
              .filter((e) => {
                return e.text !== 'Présentation'
              })
              .map((e) => ({
                ...e,
                Component: 'a',
                onClick: () => {
                  setDraft(false)
                  setNavigationByTag(false)
                  history.push({
                    pathname: `/@${slug}/${e.appends}`,
                    state: { modal: true },
                  })
                },
              }))}
            style={{
              marginTop: '30px',
              boxShadow: '0 3px 6px #00000029',
            }}
            title="👇 Ces infos pourraient vous plaire également"
          />
        ) : null}
        {ownPage &&
        navSectionsDraft.filter((e) => e.text !== 'Présentation').length ? (
          <Menu
            links={navSectionsDraft
              .filter((e) => {
                return e.text !== 'Présentation'
              })
              .map((e) => ({
                ...e,
                Component: 'a',
                onClick: () => {
                  setDraft(true)
                  setNavigationByTag(false)
                  history.push({
                    pathname: `/@${slug}/${e.appends}`,
                    state: { modal: true },
                  })
                },
              }))}
            style={{
              marginTop: '30px',
              color: 'rgba(62,63,66,0.4)',
            }}
            title="🙈 Mes brouillons"
          />
        ) : null}
      </div>
    </UikLayoutMain>
  )
}

// PublicProfile.propTypes = {
//   links: arrayOf(shape({
//     url: string.isRequired,
//     type: string.isRequired,
//   })),
//   tags: arrayOf(shape({
//     name: string.isRequired,
//   })),
//   title: string.isRequired,
//   summary: string.isRequired,
//   slug: string.isRequired,
//   image: string.isRequired,
//   sections: arrayOf(shape({
//     storiesTypeExpected: string.isRequired,
//   })).isRequired,
//   navSections: arrayOf(shape({
//     title: string.isRequired,
//     rightEl: number.isRequired,
//   })).isRequired,
//   history: historyShape.isRequired,
// };

PublicProfile.defaultProps = {
  links: [],
  tags: [],
}

PublicProfile.displayName = 'PublicProfile'

export default withRouter(PublicProfile)
