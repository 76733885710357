import React, { useContext, useState } from 'react'
import { Mutation } from '@apollo/client/react/components'
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'

import { Formik } from 'formik'
import * as Yup from 'yup'

import { UikInput, UikButton, UikFormInputGroup } from 'uikit/components'
import { Notification, Title } from '@zendeskgarden/react-notifications'

import { SVG_URI } from 'utils/vars'
import { LoginMutation } from 'gql/auth'
import { getMainProfileUrl } from 'utils/handleMainProfile'
import * as ROUTES from 'utils/routes'
import { StoreContext } from 'contexts/Store'
import cls from './login.module.scss'
import clsApp from 'initStyles/App.scss'

const Login = (prps) => {
  const {
    user: { storeUser },
  } = useContext(StoreContext)
  const store = useContext(StoreContext)
  const isAuthenticated = window.DB && window.DB.getItem('whire-app-token')
  if (isAuthenticated) {
    window.location.replace(getMainProfileUrl())
  }

  // const route_inscription = window.location.pathname.includes('/org')
  //   ? '/org/inscription'
  //   : '/inscription'
  const route_inscription = '/inscription'
  const [showPassword, setShowPassword] = useState(false)

  return (
    <Mutation mutation={LoginMutation}>
      {(logUser, { error }) => (
        <Formik
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .email('Cette adresse semble invalide')
              .required('Votre adresse email est requise'),
            password: Yup.string().required('Mot de passe obligatoire'),
          })}
          onSubmit={(values, actions) => {
            logUser({
              variables: {
                ...values,
                email: values.email.toLowerCase(),
              },
            })
              .then(
                ({
                  data: {
                    login: { token, user, ...restLogin },
                  },
                }) => {
                  if (window.DB) {
                    if (token) window.DB.setItem('whire-app-token', token)
                    if (user) storeUser(user)
                    if (restLogin.error) console.log(restLogin.error)
                    window.location.replace(
                      ROUTES.PROFILES(!!user && user.origin)
                    )
                  }
                  return null
                }
              )
              .catch((err) => {
                console.log(err, 'err')
                actions.setSubmitting(false)
              })
          }}
          validateOnBlur
          render={(props) => (
            <form onSubmit={props.handleSubmit}>
              {error && (
                <div style={{ marginBottom: '1em' }}>
                  <Notification type="error" style={{ paddingTop: '0' }}>
                    <Title>&nbsp;</Title>
                      <span>
                        {error.networkError.result.errors[0].message === 'No User found' || error.networkError.result.errors[0].message === 'No user found.'
                          ? 'Email ou mot de passe incorrects' : 'La connexion est indisponible pour le moment. Pas de panique, nos développeurs sont sur le coup !'
                        }
                      </span>
                  </Notification>
                </div>
              )}
              <UikFormInputGroup>
                <div style={{ marginBottom: '20px' }}>
                  <UikInput
                    label="Adresse email"
                    name="email"
                    style={{ height: '46px' }}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    value={props.values.email}
                    errorMessage={
                      (props.submitCount || props.touched.email) &&
                      props.errors.email
                    }
                    placeholder="votre@email.com"
                  />
                </div>
                <div style={{ marginBottom: '20px' }}>
                  <UikInput
                    errorMessage={
                      (props.submitCount || props.touched.password) &&
                      props.errors.password
                    }
                    label="Mot de passe"
                    name="password"
                    placeholder="··········"
                    style={{
                      height: '46px',
                      marginBottom: '4px',
                    }}
                    type={showPassword ? 'text' : 'password'}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    value={props.values.password}
                    eye={
                      showPassword
                        ? `${SVG_URI}/eye.svg`
                        : `${SVG_URI}/eye-slash.svg`
                    }
                    onEyeClick={() => setShowPassword(!showPassword)}
                  />
                  <button
                    className={clsApp.textButton}
                    onClick={prps.emailForgotten}
                    type="button"
                  >
                    Mot de passe oublié ?
                  </button>
                </div>
              </UikFormInputGroup>
              <UikButton
                className={cls.btnAction}
                type="submit"
                success
                lg
                isLoading={props.isSubmitting}
              >
                Connexion
              </UikButton>

              <p
                style={{
                  textAlign: 'center',
                  marginTop: '22px',
                }}
              >
                Pas encore inscrit(e) sur Whire ?{' '}
                <Link
                  href={route_inscription}
                  style={{ color: '#458' }}
                  to={route_inscription}
                  onClick={prps.clickOnSignUp}
                >
                  C&apos;est par ici !
                </Link>
              </p>
            </form>
          )}
        />
      )}
    </Mutation>
  )
}

Login.displayName = 'LoginContainer'

export default withRouter(Login)
