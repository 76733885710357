import { gql } from '@apollo/client'

export const CheckUserExistenceQuery = gql`
  query checkUser($slug: String!) {
    profiles(where: { slug: { equals: $slug } }) {
      slug
    }
  }
`

export const UpdateProfileMutation = gql`
  mutation updateProfile(
    $id: String!
    $title: String!
    $summary: String!
    $image: String
    $imageModifier: String
    $video: String
    $resume: String
    $slug: String!
    $sectionId: String!
    $storyIds: [StoryWhereUniqueInput!]
    $stories: [StoryCreateWithoutSectionInput!]
    $linkIds: [String!]
    $links: [LinkCreateWithoutProfileInput!]
    $discoTags: [TagWhereUniqueInput!]
    $coTags: [TagWhereUniqueInput!]
    $isPublished: Boolean!
  ) {
    updateProfile(
      where: { id: $id }
      data: {
        title: { set: $title }
        summary: { set: $summary }
        slug: { set: $slug }
        image: { set: $image }
        imageModifier: { set: $imageModifier }
        video: { set: $video }
        resume: { set: $resume }
        isPublished: { set: $isPublished }
        links: { deleteMany: { id: { in: $linkIds } }, create: $links }
        tags: { disconnect: $discoTags, connect: $coTags }
      }
    ) {
      id
      slug
      title
      resume
      tags{
        id
        slug
      }
    }
    updateSection(
      where: { id: $sectionId }
      data: { stories: { delete: $storyIds, create: $stories } }
    ) {
      id
    }
  }
`

export const CreateProfileMutation = gql`
  mutation createProfile(
    $title: String!
    $summary: String!
    $slug: String!
    $image: String
    $imageModifier: String
    $video: String
    $resume: String
    $id: String!
    $tags: [TagWhereUniqueInput!]
    $links: [LinkCreateWithoutProfileInput!]
    $editorStates: [StoryCreateWithoutSectionInput!]!
    $isPublished: Boolean!
  ) {
    createProfile(
      data: {
        slug: $slug
        summary: $summary
        title: $title
        image: $image
        imageModifier: $imageModifier
        video: $video
        resume: $resume
        owner: { connect: { id: $id } }
        links: { create: $links }
        tags: { connect: $tags }
        sections: {
          create: [
            {
              name: "section name?"
              description: "section description?"
              storiesTypeExpected: INTRODUCTION
              stories: { create: $editorStates }
            }
          ]
        }
        isPublished: $isPublished
      }
    ) {
      id
      slug
      image
      summary
      title
    }
  }
`

export const UserStoriesByTypeAndUsernameQuery = gql`
  query userStories($userName: String!, $userStoryType: USER_STORY_TYPE!) {
    allUserStories(
      filter: {
        owner: { userName: $userName }
        userStoryType_not: DRAFT
        isDraft: false
        userStoryType: $userStoryType
      }
    ) {
      id
      slug
      image
      summary
      title
      links
      startDate
      endDate
      endDatePresent
      userStoryType
      createdAt
      __typename
      images
      videoUrl
      editorState
      isPublished
      columns
      iFrames
      owner {
        id
        displayName
        imageUrl
        __typename
      }
      tags {
        _offersMeta {
          count
        }
        id
        name
        slug
      }
      people {
        id
        displayName
        userName
      }
    }
  }
`

export const PublicProfileFirstQuery = gql`
  query profileBySlug($slug: String!) {
    profileBySlug(slug: $slug) {
      id
      owner {
        firstname
        lastname
        email
        licences {
          id
          code_licence
        }
      }
      slug
      image
      imageModifier
      video
      resume
      isPublished
      summary
      title
      links {
        id
        url
        type
      }
      tags {
        id
        slug
        name
        type
      }
      sections {
        id
        storiesTypeExpected
        description
        stories (orderBy: { order: asc }) {
          id
          createdAt
          endDate
          endDatePresent
          startDate
          isPublished
          editorState
          title
          images
          iFrames
          videoUrl
          storyType
          order
          tags {
            id
            name
            slug
            type
          }
        }
      }
      notifications(orderBy: { createdAt: desc }) {
        slug
        id
        viewer
        image
        imageModifier
        createdAt
        notificationMessage
      }
      notificationNumbers {
        id
        number
      }
      profileRequests {
        id
        status
        profileIsComplete
        page {
          id
          title
          slug
        }
        owner {
          id
          pages {
            id
            slug
          }
        }
      }
      memberships {
        id
        trainee {
          id
          name
          page{
            slug
            title
            id
          }
        }
      }
    }
  }
`

export const DeleteProfileMutation = gql`
  mutation deleteProfile($id: String!) {
    deleteProfile(where: { id: $id }) {
      id
    }
  }
`
export const EMAIL_OF_SLUG = gql`
  query emailOfSlug($slug: String!) {
    profiles(where: { slug: { equals: $slug } }) {
      owner {
        email
      }
      id
    }
  }
`

export const CreateProfileRequestMutation = gql`
  mutation createProfileRequest(
    $slugProfile: String
    # $slugPage: String
    $emailPage: String
  ) {
    createProfileRequest(
      data: {
        profile: { connect: { slug: $slugProfile } }
        # page: {connect: {slug: $slugPage}}
        owner: { connect: { email: $emailPage } }
      }
    ) {
      id
      status
      profile {
        slug
        owner {
          id
          email
          origin
          profiles (orderBy: { createdAt: asc }) {
            id
            slug
            title
            summary
            image
            imageModifier
            isPublished
            profileRequests {
              id
              status
              profileIsComplete
              page {
                id
                title
                slug
              }
              owner {
                id
                email
                pages {
                  id
                  slug
                }
              }
            }
          }
          pages (orderBy: { createdAt: asc }) {
            id
            slug
            title
            summary
            image
            imageModifier
            isPublished
          }
          plan
          licences {
            id
            code_licence
            page{
              id
              company {
                owner {
                  id
                  email
                }
              }
            }
          }
        }
      }
      # page {
      #   slug
      # }
      owner {
        email
      }
    }
  }
`
export const DeleteProfileRequestMutation = gql`
  mutation DeleteProfileRequest($id: String!) {
    deleteProfileRequest(where: { id: $id }) {
      id
      status
      profile {
        slug
        owner {
          id
          email
          origin
          profiles (orderBy: { createdAt: asc }) {
            id
            slug
            title
            summary
            image
            imageModifier
            isPublished
            profileRequests {
              id
              status
              profileIsComplete
              page {
                id
                title
                slug
              }
              owner {
                id
                email
                pages {
                  id
                  slug
                }
              }
            }
          }
          pages (orderBy: { createdAt: asc }) {
            id
            slug
            title
            summary
            image
            imageModifier
            isPublished
          }
          plan
          licences {
            id
            code_licence
            page{
              id
              company {
                owner {
                  id
                  email
                }
              }
            }
          }
        }
      }
      # page {
      #   slug
      # }
      owner {
        email
      }
    }
  }
`
export const UpdateThenDeleteProfileRequestMutation = gql`
  mutation updateThenDeleteProfileRequest(
    $id: String!
  ) {
    updateThenDeleteProfileRequest(
      where: { id: $id }
      data: {
        profileIsComplete: { set: true }
      }
    ) {
      id
      profileIsComplete
      search
      status
      profile {
        slug
        owner {
          id
          email
          origin
          profiles (orderBy: { createdAt: asc }) {
            id
            slug
            title
            summary
            image
            imageModifier
            isPublished
            profileRequests {
              id
              status
              profileIsComplete
              page {
                id
                title
                slug
              }
              owner {
                id
                email
                pages {
                  id
                  slug
                }
              }
            }
          }
          pages (orderBy: { createdAt: asc }) {
            id
            slug
            title
            summary
            image
            imageModifier
            isPublished
          }
          plan
          licences {
            id
            code_licence
            page{
              id
              company {
                owner {
                  id
                  email
                }
              }
            }
          }
        }
      }
      referent {
        name
      }
    }
  }
`
export const CreateNotificationMutation = gql`
  mutation createNotification(
    $viewer: String!
    $slug: String
    $image: String
    $imageModifier: String
    $profileSlug: String
    $notificationMessage: String
  ) {
    createNotification(
      data: {
        viewer: $viewer
        slug: $slug
        image: $image
        imageModifier: $imageModifier
        profile: { connect: { slug: $profileSlug } }
        notificationMessage: $notificationMessage
      }
    ) {
      id
      slug
      viewer
      image
      imageModifier
      notificationMessage
      profile {
        slug
      }
    }
  }
`

export const CreateNotificationNumberMutation = gql`
  mutation createNotificationNumber($number: Int, $profileSlug: String) {
    createNotificationNumber(
      data: { number: $number, profile: { connect: { slug: $profileSlug } } }
    ) {
      id
      number
      profile {
        slug
      }
    }
  }
`

export const UpdateNotificationNumber = gql`
  mutation updateNotificationNumber($id: String!, $number: Int) {
    updateNotificationNumber(
      where: { id: $id }
      data: { number: { set: $number } }
    ) {
      id
      number
      profile {
        slug
      }
    }
  }
`
