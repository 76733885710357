import { gql } from '@apollo/client'

export const CreateTagMutation = gql`
  mutation CreateTag($name: String!, $slug: String!) {
    createTag(
      data: { name: $name, status: PENDING, type: USER_TAGS, slug: $slug }
    ) {
      id
      name
    }
  }
`
