import React, { useContext, useState } from 'react'
import { Mutation } from '@apollo/client/react/components'
import { Link } from 'react-router-dom'
import { Formik } from 'formik'
import * as Yup from 'yup'

import * as ROUTES from 'utils/routes'
import { SVG_URI } from 'utils/vars'

import { SignupMutation } from 'gql/auth'
import { getMainProfileUrl } from 'utils/handleMainProfile'

import {
  UikInput,
  UikButton,
  UikFormInputGroup,
  UikCheckbox,
} from 'uikit/components'
import { Notification, Title } from '@zendeskgarden/react-notifications'
import { StoreContext } from 'contexts/Store'

import cls from './signup.module.scss'

const Signup = () => {
  const {
    user: { storeUser },
  } = useContext(StoreContext)

  let origin = 'CANDIDATE'
  if (window.location.pathname === '/org/inscription') {
    origin = 'TRAINING'
  } else if (window.location.pathname === '/pro/inscription') {
    origin = 'BUSINESS'
  }

  const [showPassword, setShowPassword] = useState(false)
  const [confirmPassword, setConfirmPassword] = useState(false)

  return (
    <Mutation mutation={SignupMutation}>
      {(signup, { error }) => (
        <Formik
          validationSchema={Yup.object().shape({
            support: Yup.boolean(),
            cgu: Yup.boolean().required().oneOf([true]),
            email: Yup.string()
              .email('Cette adresse semble invalide')
              .required('Votre adresse email est requise'),
            confirmEmail: Yup.string().oneOf(
              [Yup.ref('email'), null],
              'Les adresses mails doivent correspondre'
            )
              .required('Vous devez confirmer votre adresse mail'),
            password: Yup.string()
              .min(6, 'Mot de passe: au moins 6 caractères')
              .required('Mot de passe obligatoire'),
            confirmPassword: Yup.string().oneOf(
              [Yup.ref('password'), null],
              'Les mots de passes doivent correspondre'
            )
              .required('La confirmation du mot de passe est requis'),
            phoneNumber: Yup.string().when('support', {
              is: true,
              then: Yup.string().required(),
              otherwise: Yup.string(),
            }),
          })}
          onSubmit={(values, actions) => {
            const form = values
            if (!form.support) {
              delete form.phoneNumber
            }
            signup({
              variables: {
                ...values,
                email: values.email.toLowerCase(),
                origin: origin,
              },
            })
              .then(
                ({
                  data: {
                    signup: { token, user },
                  },
                }) => {
                  if (window.DB) {
                    if (token) window.DB.setItem('whire-app-token', token)
                    if (user) storeUser(user)
                    if (origin === 'CANDIDATE') {
                      window.location.replace('/profiles')
                    } else {
                      window.location.replace(ROUTES.CREER_MA_PAGE(origin))
                    }
                  }
                  return null
                  // actions.setSubmitting(false);
                }
              )
              .catch((err) => {
                console.log(err)
                actions.setSubmitting(false)
              })
          }}
          validateOnBlur
          render={(props) => (
            <form onSubmit={props.handleSubmit}>
              <h3>
                On est d&apos;accord, c&apos;est pas top les formulaires, mais
                il faut bien commencer quelque part{' '}
                <span role="img" aria-label="Hmmm...">
                  🤔
                </span>
              </h3>
              <p>-- La suite est plus fun, promis --</p>
              {error && (
                <div style={{ marginBottom: '1em' }}>
                  <Notification type="error" style={{ paddingTop: '0' }}>
                    <Title>&nbsp;</Title>
                    Adresse email déjà utilisée{' '}
                    <span aria-label="sad" role="img">
                      🤔
                    </span>
                    .
                    <br />
                    <Link
                      href={ROUTES.CONNEXION(origin)}
                      to={ROUTES.CONNEXION(origin)}
                    >
                      Cliquez ici
                    </Link>{' '}
                    pour vous connecter&nbsp;!
                  </Notification>
                </div>
              )}
              <UikFormInputGroup>
                <div style={{ marginBottom: '20px' }}>
                  <UikInput
                    label="Adresse email"
                    name="email"
                    style={{ height: '46px' }}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    value={props.values.email}
                    errorMessage={
                      (props.submitCount || props.touched.email) &&
                      props.errors.email
                    }
                    placeholder="votre@email.com"
                  />
                </div>
                <div style={{ marginBottom: '20px' }}>
                  <UikInput
                    label="Confirmer votre adresse mail"
                    name="confirmEmail"
                    style={{ height: '46px' }}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    errorMessage={
                      (props.submitCount || props.touched.confirmEmail) &&
                      props.errors.confirmEmail
                    }
                    placeholder="votre@email.com"
                  />
                </div>
                <div style={{ marginBottom: '20px' }}>
                  <UikInput
                    errorMessage={
                      (props.submitCount || props.touched.password) &&
                      props.errors.password
                    }
                    label="Mot de passe"
                    name="password"
                    placeholder="··········"
                    style={{
                      height: '46px',
                      marginBottom: '4px',
                    }}
                    type={showPassword ? 'text' : 'password'}
                    autoComplete="current-password"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    value={props.values.password}
                    eye={
                      showPassword
                        ? `${SVG_URI}/eye.svg`
                        : `${SVG_URI}/eye-slash.svg`
                    }
                    onEyeClick={() => setShowPassword(!showPassword)}
                  />
                </div>
                <div style={{ marginBottom: '20px' }}>
                  <UikInput
                    errorMessage={
                      (props.submitCount || props.touched.confirmPassword) &&
                      props.errors.confirmPassword
                    }
                    label="Confirmer votre mot de passe"
                    name="confirmPassword"
                    placeholder="··········"
                    style={{
                      height: '46px',
                      marginBottom: '4px',
                    }}
                    type={confirmPassword ? 'text' : 'password'}
                    autoComplete="current-password"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    // value={props.values.password}
                    eye={
                      confirmPassword
                        ? `${SVG_URI}/eye.svg`
                        : `${SVG_URI}/eye-slash.svg`
                    }
                    onEyeClick={() => setConfirmPassword(!confirmPassword)}
                  />
                </div>
                <UikCheckbox
                  name="cgu"
                  description="J'ai lu et j'accepte les conditions générales d'utilisation"
                  hasError={
                    (props.submitCount || props.touched.cgu) && props.errors.cgu
                  }
                  label={<a href="/cgu"> Conditions générales </a>}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  value={props.values.cgu}
                />
                {props.values.support ? (
                  <div>
                    <UikInput
                      errorMessage={
                        (props.submitCount || props.touched.phoneNumber) &&
                        props.errors.phoneNumber
                      }
                      label="Numéro de téléphone"
                      name="phoneNumber"
                      placeholder="06xxxxxxxx"
                      style={{
                        height: '46px',
                        marginBottom: '4px',
                      }}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      value={props.values.phoneNumber}
                    />
                    <p>
                      <span role="img" aria-label="show up">
                        👆
                      </span>{' '}
                      Nous vous enverrons un SMS et nos disponibilités&nbsp;!
                    </p>
                  </div>
                ) : null}
              </UikFormInputGroup>
              <UikButton
                className={cls.btnAction}
                type="submit"
                success
                lg
                isLoading={props.isSubmitting}
                style={{ marginTop: '24px' }}
              >
                Démarrer avec Whire !
              </UikButton>
            </form>
          )}
        />
      )}
    </Mutation>
  )
}

export default Signup
