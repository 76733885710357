import React, { createElement, PureComponent } from 'react'

import { Redirect } from 'react-router-dom'

import { COMPANY_STORY_TYPE_ARRAY } from 'utils/vars'

import Home from './pages/Home'

export default class PublicPages extends PureComponent {
  render() {
    const {
      match: {
        params: { appends },
      },
    } = this.props

    const userStoryTypeTransport = COMPANY_STORY_TYPE_ARRAY.find(
      (e) => e.appends === appends
    )

    if (!userStoryTypeTransport) {
      return <Redirect to={`/${this.props.match.params[0]}`} />
    }

    return (
      <div>
        {appends === '' && <div>{createElement(Home, { ...this.props })}</div>}
      </div>
    )
  }
}
