import React, { useRef, useEffect } from 'react'
import { InstantSearch, Configure } from 'react-instantsearch/dom'
import AlgoliaAsyncOnPages from 'components/AlgoliaAsyncOnPages'
import { ALGOLIA } from 'utils/vars.js'

const FormTagOnPages = (props) => {
  const refEditor = useRef(null)
  useEffect(() => {
    if (!!props.getTagRef) {
      props.getTagRef(refEditor)
    }
  })

  return (
    <InstantSearch
      appId={ALGOLIA.appId}
      apiKey={ALGOLIA.apiKey}
      indexName={ALGOLIA.tagIndexName}
      ref={refEditor}
    >
      <AlgoliaAsyncOnPages
        currentSelection={
          props.value &&
          props.value.map((tag) => ({
            id: tag.id,
            value: tag.slug,
            label: tag.name,
          }))
        }
        onChange={(tags) => {
          if (!tags) tags = []
          props.setFieldValue(
            'tags',
            tags.map((tag) => ({
              id: tag.id,
              slug: tag.value,
              name: tag.label,
            }))
          )
        }}
      />
      <Configure
        filters="status:VERIFIED"
        hitsPerPage={20}
        facets={['tagType', 'status']}
        facetFilters={[
          [
            'tagType:HIGH_ORDER_SKILL',
            'tagType:SKILL',
            'tagType:TOOL',
            'tagType:BEHAVIOUR',
            'tagType:REQUIREMENT',
            'tagType:THEME',
            'tagType:DOMAIN',
            'tagType:INTEREST',
            'tagType:ENVIRONMENT',
            'tagType:BENEFICIARIES',
            'tagType:WORK',
            'tagType:USER_TAGS',
          ],
        ]}
      />
      {(props.touched || props.submitCount) && props.errors ? (
        <p
          style={{
            color: '#E6492D',
            marginTop: '6px',
            lineHeight: '1.375rem',
            fontSize: '.8rem',
          }}
          id={!!props.field ? `error_${props.field.id}` : 'error'}
        >
          {props.errors}
        </p>
      ) : null}
    </InstantSearch>
  )
}

export default FormTagOnPages
