import React from 'react'
import PropTypes from 'prop-types'

import { Link } from 'react-router-dom'

import Signup from 'containers/Signup'
import Login from 'containers/Login'

import UIAvatar from 'react-ui-avatars'

import {
  UikWidgetContent,
  UikWidget,
  UikHeadline,
  UikAvatar,
} from 'uikit/components'

import { DOMAIN } from 'utils/vars'

// import * as images from '@exampleAssets'

import cls from '../auth.module.scss'

// import LoginCard from './components/Card'
// import LoginCardContainer from './components/CardContainer'

const AuthPage = (props) => {
  let right
  if (!props.hideRight) {
    right = {
      avatar: {
        imgUrl: `${DOMAIN}/static/svg/whire.png`,
        name: 'Paul Capdet',
      },
      backgroundImage: `linear-gradient(-180deg, rgba(0,0,0,0.00) 0%, rgba(0,0,0,.25) 100%), url("${DOMAIN}/static/akakuma.jpg")`,
      description:
        "Whire m'a permis d'être recruté plus rapidement, sans les contraintes du CV classique",
    }
  }
  return (
    <div
      className={cls.pageWrapper}
      style={
        props.mode === 'signup'
          ? {
              paddingTop: '60px',
              backgroundImage: `url("${DOMAIN}/static/accompagnement2.png")`,
              backgroundPosition: 'right bottom',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain',
            }
          : null
      }
    >
      {props.returnUrl ? (
        <Link href={props.returnUrl} to={props.returnUrl}>
          <span className={cls.close} />
        </Link>
      ) : null}
      <UikWidget className={cls.widgetWrapper}>
        <div className={cls.content}>
          <UikWidgetContent className={cls.left}>
            {props.mode === 'login' ? (
              <UikHeadline style={{ marginBottom: '24px' }}>
                <img
                  alt="Whire logo"
                  src={`${DOMAIN}/static/svg/whire.png`}
                  width="44px"
                />
              </UikHeadline>
            ) : null}
            {props.mode === 'login' ? <Login /> : <Signup />}
          </UikWidgetContent>
        </div>
      </UikWidget>
      <div style={{ marginTop: '16px' }}>{props.bottomCta}</div>
    </div>
  )
}

AuthPage.propTypes = {
  bottomCta: PropTypes.element,
  hideRight: PropTypes.bool,
  mode: PropTypes.oneOf(['login', 'signup']),
  returnUrl: PropTypes.string,
}

AuthPage.defaultProps = {
  bottomCta: null,
  hideRight: false,
  mode: 'login',
  returnUrl: null,
}

export default AuthPage
