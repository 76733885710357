import React from 'react'

export default () => {
  return (
    <div
      style={{
        fontSize: '40px',
        maxWidth: '500px',
        margin: 'auto',
        textAlign: 'center',
      }}
    >
      <br />
      <br />
      <br />
      <br />
      <h1>
        {' '}
        🤦 Désolé, seules les structures ayant un compte premium peuvent accéder
        à cette page !{' '}
      </h1>
      <iframe
        style={{ marginBottom: '2em' }}
        src="https://giphy.com/embed/d2lcHJTG5Tscg"
        width="360"
        height="240"
        frameBorder="0"
        className="giphy-embed"
      />
      <br />
      <br />
      <br />
    </div>
  )
}
