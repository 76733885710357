import React from 'react'
import { Link } from 'react-router-dom'
import cls from './footerLanding.scss'
import { IMG_URI, SVG_URI } from '../../../../utils/vars'

export const FooterLanding = () => {
  return (
    <section className={cls.container__footer}>
      <div className={cls.footer__top}>
        <div>
          <div className={cls.logo} style={{margin: "auto", display: "flex"}}>
            <img className={cls.logoWhire} src={`${SVG_URI}/Logo-whire_32x32.svg`} alt="Logo Whire" />
          </div>
          <div className={cls.logo}>
            <img className={cls.logoQualiopi} src={`${SVG_URI}/LogoQualiopi.png`} alt="Logo Qualiopi" />
            <p>Certification dans la catégorie : <br></br> Actions de formation.</p>
          </div>
        </div>
        <div className={cls.col__links}>
          <Link to="/candidat">Candidat</Link>
          <Link to="/candidat/creermapage">Créer ma page Whire</Link>
          <Link to="/candidat/whirrior">Devenir un Whirrior</Link>
          <Link to="/candidat/stories">Belles histoires</Link>
        </div>
        <div className={cls.col__links}>
          <Link to="/org">Organisme Intermédiaire</Link>
          <Link to="/org/offre">Méthodologie</Link>
          <Link to="/academy">Whire Academy</Link>
          <Link to="/org/ecosystem">Ecosystème</Link>
          <Link to="/org/temoignage">Témoignage</Link>
        </div>
        <div className={`${cls.col__links} ${cls.col__employeur}`}>
          <Link to="/entreprise">Employeur</Link>
          <Link to="/entreprise/ecosystem">Ecosystème</Link>
          <Link to="/entreprise/club">Le club Empl´Whire</Link>
        </div>
        <div className={cls.col__links}>
          <h4>Nous rencontrer</h4>
          <p>
            157 Boulevard Macdonald,
            <br />
            75019 Paris
          </p>
          <div className={cls.logo__reseaux}>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.facebook.com/whireMe"
            >
              <img src={`${IMG_URI}/links/facebook.svg`} alt="facebook" />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://twitter.com/whireme?lang=fr"
            >
              <img src={`${IMG_URI}/links/twitter.svg`} alt="twitter" />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://fr.linkedin.com/company/whire"
            >
              <img src={`${IMG_URI}/links/linkedin.svg`} alt="linkedin" />
            </a>
          </div>
        </div>
      </div>
      <div className={cls.copyright}>
        <div>
          <Link to="/mentions-legales">Mentions Légales</Link>
          <Link to="/cgu">Conditions Générales d'Utilisation</Link>
        </div>
        <p>Copyright © Whire 2016-2021</p>
      </div>
    </section>
  )
}
