import React, { node } from 'react'

import {
  UikDivider,
  UikWidget,
  UikWidgetContent,
  UikWidgetHeader,
  UikWidgetBottomCta,
} from 'uikit/components'

const ModalContent = ({ bottomCta, children, className, header, ...rest }) => (
  <UikWidget {...rest} className={className}>
    {header && <UikWidgetHeader>{header}</UikWidgetHeader>}
    <UikWidgetContent>{children}</UikWidgetContent>
    {bottomCta && (
      <div>
        <UikDivider />
        <UikWidgetBottomCta {...bottomCta}>
          {bottomCta.title}
        </UikWidgetBottomCta>
      </div>
    )}
  </UikWidget>
)

ModalContent.defaultProps = {
  bottomCta: undefined,
  className: undefined,
  header: undefined,
}

export default ModalContent
