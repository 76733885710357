// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".oe3OsXacQ7bgCUP3HmVp{height:44px;width:100%;padding:0 10px;border:1px solid #9da3a6;background:#fff;text-overflow:ellipsis;-webkit-box-sizing:border-box;-moz-box-sizing:border-box;box-sizing:border-box;-webkit-border-radius:4px;-moz-border-radius:4px;-khtml-border-radius:4px;border-radius:4px;-webkit-box-shadow:none;-moz-box-shadow:none;box-shadow:none;color:#000;font-size:1em;font-weight:400;direction:ltr}.oe3OsXacQ7bgCUP3HmVp:focus{outline:0;border:1px solid #6977ff;-webkit-box-shadow:none;-moz-box-shadow:none;box-shadow:none;background-color:#fff}", "",{"version":3,"sources":["webpack://./src/containers/Signup/signup.module.scss"],"names":[],"mappings":"AAEA,sBACE,WAAA,CACA,UAAA,CACA,cAAA,CACA,wBAAA,CACA,eAAA,CACA,sBAAA,CACA,6BAAA,CACA,0BAAA,CACA,qBAAA,CACA,yBAAA,CACA,sBAAA,CACA,wBAAA,CACA,iBAAA,CACA,uBAAA,CACA,oBAAA,CACA,eAAA,CACA,UAAA,CACA,aAAA,CACA,eAAA,CACA,aAAA,CAEA,4BACE,SAAA,CACA,wBAAA,CACA,uBAAA,CACA,oBAAA,CACA,eAAA,CACA,qBAAA","sourcesContent":["@import \"src/initStyles/_vars.scss\";\n\n.input {\n  height: 44px;\n  width: 100%;\n  padding: 0 10px;\n  border: 1px solid #9da3a6;\n  background: #fff;\n  text-overflow: ellipsis;\n  -webkit-box-sizing: border-box;\n  -moz-box-sizing: border-box;\n  box-sizing: border-box;\n  -webkit-border-radius: 4px;\n  -moz-border-radius: 4px;\n  -khtml-border-radius: 4px;\n  border-radius: 4px;\n  -webkit-box-shadow: none;\n  -moz-box-shadow: none;\n  box-shadow: none;\n  color: #000;\n  font-size: 1em;\n  font-weight: 400;\n  direction: ltr;\n\n  &:focus {\n    outline: 0;\n    border: 1px solid $success;\n    -webkit-box-shadow: none;\n    -moz-box-shadow: none;\n    box-shadow: none;\n    background-color: #fff;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": "oe3OsXacQ7bgCUP3HmVp"
};
export default ___CSS_LOADER_EXPORT___;
