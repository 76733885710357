import { gql } from '@apollo/client'

export const UserQuery = gql`
  query me {
    me {
      id
      email
      origin
      profiles (orderBy: { createdAt: asc }) {
        id
        slug
        title
        summary
        image
        imageModifier
        isPublished
      }
      pages (orderBy: { createdAt: asc }) {
        id
        slug
        title
        summary
        image
        imageModifier
        isPublished
      }
      plan
      licences {
        id
        code_licence
      }
    }
  }
`

export const SignupMutation = gql`
  mutation signup($email: String!, $origin: ORIGIN_TYPE!, $password: String!) {
    signup(email: $email, origin: $origin, password: $password) {
      token
      user {
        id
        emailConfirmed
        lastLogin
        firstname
        lastname
      }
    }
  }
`

export const LoginMutation = gql`
  mutation login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      token
      user {
        id
        email
        emailConfirmed
        lastLogin
        firstname
        lastname
        origin
        licences {
          id
          code_licence
        }
      }
    }
  }
`

export const TriggerPasswordResetMutation = gql`
  mutation triggerPasswordReset($email: String!) {
    triggerPasswordReset(email: $email)
  }
`

export const PasswordReset = gql`
  mutation passwordReset(
    $email: String!
    $resetToken: String!
    $password: String!
  ) {
    passwordReset(email: $email, resetToken: $resetToken, password: $password) {
      id
    }
  }
`

export const ChangePassword = gql`
  mutation changePassword($oldPassword: String!, $newPassword: String!) {
    changePassword(oldPassword: $oldPassword, newPassword: $newPassword) {
      id
    }
  }
`

export const ConfirmEmail = gql`
  mutation confirmEmail($email: String!, $emailConfirmToken: String!) {
    confirmEmail(email: $email, emailConfirmToken: $emailConfirmToken) {
      user {
        id
      }
      token
    }
  }
`

export const changeEmail = gql`
  mutation updateCurrentUser(
    $email: String! # $currentEmail: String!
  ) {
    updateCurrentUser(data: { email: { set: $email } }) {
      id
      email
    }
  }
`

export const addUserToLicence = gql`
  mutation addUserToLicence($codeLicence: String!, $id: String!) {
    addUserToLicence(code_licence: $codeLicence, userId: $id) {
      id
      code_licence
    }
  }
`
