import React, { Fragment, useContext, useEffect, lazy, Suspense } from 'react'

import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate
} from 'react-router-dom'

import { graphql } from '@apollo/client/react/hoc'
import { compose } from 'recompose'

import { UserQuery } from 'gql/auth'
import { PublicProfileFirstQuery } from 'gql/profile'
import { PageFirstQuery } from 'gql/page'

import { Modal, Header, Body, Close } from '@zendeskgarden/react-modals'

import asyncComponent from 'utils/AsyncComponent'
// import CreatePage from 'containers/CreatePage'
// import UserProfile from 'modules/UserProfile'
// import CompanyProfile from 'modules/CompanyProfile'
import Loading from 'components/Loading'
// import AuthPage from 'pages/Auth'
import Page404 from 'pages/page404'
import NoAccess from 'pages/NoAccess'
import Cookies from 'components/Cookies'
import Cgu from 'pages/Cgu'
import Privacy from 'pages/Privacy'
import PrivacyAI from 'pages/PrivacyAI'
import LegalMention from 'pages/LegalMention'
import Charter from 'pages/Charter'
import CreateCards from 'pages/CreateCards'
import CreateCardsEdu from 'pages/CreateCards/CreateCardsEdu'
import CreateCardsMenu from 'pages/CreateCards/CreateCardsMenu'
import MainHeader from 'components/MainHeader'
import ChatBot from 'pages/ChatBot'


import { getMainProfile, getMainProfileUrl, resetMainProfile, getUser } from 'utils/handleMainProfile'
import { setUser } from 'utils/handleUser'

import { StoreContext } from 'contexts/Store'
import compareObj from 'utils/compareObj'
// import LoginModal from './containers/LoginModal'

import '../node_modules/draft-js/dist/Draft.css'
import '../node_modules/draftail/dist/draftail.css'
import './initStyles/App.scss'
import Demo from './modules/Landing/utilsLanding/formDemo'
import LateralMenu from './modules/CompanyProfile/pages/LateralMenu'

const Logout = lazy(() => import('containers/Logout'));
const Profiles = React.lazy(() => import('containers/Profiles'));
const CreateProfile = React.lazy(() => import('containers/CreateProfile'));
const CreatePage = lazy(() => import('containers/CreatePage'));
const AuthPage = lazy(() => import('pages/Auth'));
const LoginModal = lazy(() => import('containers/LoginModal'));
const CompanyProfile = lazy(() => import('modules/CompanyProfile'));
const UserProfile = lazy(() => import('modules/UserProfile'));
const Support = lazy(() =>import('modules/Landing/pages/Support'));
const MainOrg = lazy(() => import('modules/Landing/pages/MainOrg'));
const MainAcademy = lazy(() => import('modules/Landing/pages/MainAcademy'));
const OrgSignUp = lazy(() => import('modules/Landing/pages/OrgSignUp'));
const MainCandidat = lazy(() => import('modules/Landing/pages/MainCandidat'));
const MainEntreprise = lazy(() => import('modules/Landing/pages/MainEntreprise'));
const Main = lazy(() => import('modules/Landing/pages/Main'));


// Composant qui ne rend rien
const NoRender = () => {
  // Effectuez ici toute logique nécessaire
  return null;
};

const App = (prps) => {
  const {
    data: { me, loading },
  } = prps
  

  // eslint-disable-next-line
  const {
    me: { me: storedMe, storeMe },
    normalWidth: { normalWidth, setNormalWidth },
  } = useContext(StoreContext)

  const location = useLocation()

  useEffect(() => {
    // if last connexion before prisma migration 1 to 3 then clear local storage 
    const parseLocal = window.DB.getItem('whire-app-user')
    const lastLogin = parseLocal ? JSON.parse(parseLocal).lastLogin : ""
    const migrationDate =  new Date("August 22 2022 23:43:00 GMT+02:00")
    if(lastLogin!== "" && Date.parse(lastLogin) < Date.parse(migrationDate)){
      if (window.DB && Object.keys(window.DB).length > 0) {
        Object.keys(window.DB).map((key) => {
          if (key.includes('whire-app-')) {
            return window.DB.removeItem(key)
          }
          return null
        })
      }
      window.location.replace("/")
    }
    if (!compareObj(me, storedMe) && !!me) {
      storeMe(me)
      setUser(me)
    }
  }, [me])

  if (loading) return <Loading />



  window.location.pathname === '/' ? setNormalWidth(false) : ''

  // me has at least 1 existing profile and no main profile
  // and tries to access a page which is not meant to set their main profile
  if (
    [
      '/',
      '/org',
      '/profiles',
      '/org/pages',
      '/logout',
      '/creer-ma-page',
      '/org/creer-ma-page',
    ].indexOf(location.pathname) === -1 &&
    me &&
    (me.profiles.length || me.pages.length) &&
    // && !getMainProfile()
    !getMainProfileUrl()
  ) {
    const ProfilesHandler = asyncComponent(
      () => import('modules/ProfilesHandler').then((module) => module.default),
      { ...prps }
    )
    return <ProfilesHandler user={me} />
  }

  const isAuthenticated = window.DB && window.DB.getItem('whire-app-token')
  const ua = window.navigator.userAgent
  const msie = ua.indexOf('MSIE ')
  if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
    return (
      <div className="App">
        <div
          style={{
            fontSize: '30px',
            maxWidth: '500px',
            margin: 'auto',
            textAlign: 'center',
          }}
        >
          <br />
          <br />
          <br />
          <br />
          <h1 style={{ fontSize: '20px' }}>
            {' '}
            🤦 Ouch, votre navigateur date un peu. Nous travaillons pour qu'il
            puisse supporter notre site. En attendant, rejoignez-nous dans le
            futur avec Edge, Mozilla Firefox ou Google Chrome{' '}
          </h1>
          <iframe
            src="https://giphy.com/embed/i5dIK5GSq2OGY"
            width="480"
            height="264"
            frameBorder="0"
            class="giphy-embed"
            allowFullScreen
          ></iframe>
          <p>
            <a href="https://giphy.com/gifs/back-to-the-future-i5dIK5GSq2OGY">
              via GIPHY
            </a>
          </p>{' '}
        </div>
      </div>
    )
  }

  // Composant pour gérer la redirection si authentifié
  const AuthenticatedRedirect = ({ children }) => {
    const navigate = useNavigate();
    useEffect(() => {
      if (isAuthenticated) {
        navigate(getMainProfileUrl());
      }
    }, [navigate]);

    return children;
  };
  console.log(prps, "meeeeee")

  return (
    // <Router>
    <div className="App">
      <MainHeader
        //pageid={prps.pageBySlug.pageBySlug}
        // profileid={prps.profileBySlug.profileBySlug}
        me={me}
      />
      <LateralMenu />
      <Suspense fallback={<Loading />}>
      <Routes>
        <Route path="/cgu" element={<Cgu />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/privacy-ai" element={<PrivacyAI />} />
        <Route path="/mentions-legales" element={<LegalMention />} />
        <Route path="/charte-cookies" element={<Charter />} />
        <Route path="/cards2" element={<CreateCardsEdu />} />
        <Route path="/cards1" element={<CreateCards />} />
        <Route path="/cards" element={<CreateCardsMenu />} />
        <Route path="/404" element={<Page404 />} />
        <Route
          path="/logout"
          element={
            <Suspense fallback={<Loading />}>
              <Logout />
            </Suspense>
          }
        />
        <Route path="/loading" element={<Loading />} />
        <Route path="/reset" element={<LoginModal />} /> 
        <Route path="/org/pages" element={
        <Suspense fallback={<Loading />}>
          {loading ? (
            <Loading />
          ) : me && (me.origin === 'TRAINING' || me.origin === 'BUSINESS') ? (
            <Profiles me={me} xl />
          ) : (
            <NoAccess />
          )}
        </Suspense>
        }/>
        <Route path="/profiles" element={
          <Suspense fallback={<Loading />}>
            {loading ? (
              <Loading />
            ) : me && me.origin === 'CANDIDATE' ? (
              <Profiles me={me} xl />
            ) : (
              <NoAccess />
            )}
          </Suspense>
        }/>
        <Route path="/creer-ma-page" element={
          <Suspense fallback={<Loading />}>
            {loading ? (
              <div />
            ) : me && me.origin === 'CANDIDATE' ? (
              <CreateProfile user={me} redirectUrl="/" xl />
            ) : (
              <NoAccess />
            )}
          </Suspense>
        }/>

        <Route
        path="/org/creer-ma-page"
        element={
          <Suspense fallback={<Loading />}>
            {loading ? (
              <div /> // Vous pourriez vouloir utiliser ici le même composant de chargement que pour les autres routes
            ) : me && (me.origin === 'TRAINING' || me.origin === 'BUSINESS') ? (
              <CreatePage user={me} returnUrl="/org" xl />
            ) : (
              <NoAccess />
            )}
          </Suspense>
          }
        />
        <Route
          path="/org/inscription"
          element={
            !me ? (
              <Suspense fallback={<Loading />}>
                <AuthPage hideRight mode="signup" returnUrl="/org" />
              </Suspense>
            ) : (
              <Navigate replace to="/org" />
            )
          }
        />
        <Route
          path="/inscription"
          element={
            !me ? (
              <Suspense fallback={<Loading />}>
                <AuthPage hideRight mode="signup" returnUrl="/" />
              </Suspense>
            ) : (
              <Navigate replace to="/" />
            )
          }
        />
        <Route
          path="/org/connexion"
          element={
            !me ? (
              <Suspense fallback={<Loading />}>
                <LoginModal returnUrl="/org" headline="Connexion !" />
              </Suspense>
            ) : (
              <Navigate replace to="/org" />
            )
          }
        />
        <Route
          path="/connexion"
          element={
            !me ? (
              <Suspense fallback={<Loading />}>
                <LoginModal returnUrl="/" headline="Connexion !" />
              </Suspense>
            ) : (
              <Navigate replace to="/" />
            )
          }
        />
        <Route
          path="/org/:slug/*"
          element={
            <Suspense fallback={<Loading />}>
              <CompanyProfile me={me} />
            </Suspense>
          }
        />
        <Route
          path="/:slug/*"
          element={
            <Suspense fallback={<Loading />}>
              <UserProfile me={me} />
            </Suspense>
          }
        />
        <Route
          path="chatBot"
          element={
            me ? (
              <Suspense fallback={<Loading />}>
                <ChatBot me={me}/>
              </Suspense>
            ) : (
              <NoAccess />
            )
          }
        />
        <Route
          path="/accompagnement"
          element={
            <Suspense fallback={<Loading />}>
              <Support />
            </Suspense>
          }
        />
        {/*
         *** Landing Page Organisme intermédiaire
         */}
        <Route path="/org" element={<AuthenticatedRedirect><MainOrg /></AuthenticatedRedirect>} />
        <Route path="/org/offre" element={<AuthenticatedRedirect><MainOrg /></AuthenticatedRedirect>} />
        <Route path="/org/ecosystem" element={<AuthenticatedRedirect><MainOrg /></AuthenticatedRedirect>} />
        <Route path="/org/temoignage" element={<AuthenticatedRedirect><MainOrg /></AuthenticatedRedirect>} />
        <Route path="/academy" element={<AuthenticatedRedirect><MainAcademy /></AuthenticatedRedirect>} />
        {/*
         *** Créer ma page organisation
         */}
        <Route path="/org/org-login" element={<AuthenticatedRedirect><OrgSignUp /></AuthenticatedRedirect>} />
        {/*
         *** Formulaire Demander une demo
         */}
        <Route path="/demo" element={<Demo />} />
        {/*
         *** Formulaire Rejoindre le club
         */}
        <Route path="/entreprise/rejoindreleclub" element={<Demo />} />
        {/*
         *** Landing Page Candidat
         */}
        <Route path="/candidat" element={<AuthenticatedRedirect><MainCandidat /></AuthenticatedRedirect>} />
        <Route path="/candidat/creermapage" element={<AuthenticatedRedirect><MainCandidat /></AuthenticatedRedirect>} />
        <Route path="/candidat/whirrior" element={<AuthenticatedRedirect><MainCandidat /></AuthenticatedRedirect>} />
        <Route path="/candidat/stories" element={<AuthenticatedRedirect><MainCandidat /></AuthenticatedRedirect>} />
        {/*
         *** Landing Page Employeur
         */}
        <Route path="/entreprise" element={<AuthenticatedRedirect><MainEntreprise /></AuthenticatedRedirect>} />
        <Route path="/entreprise/ecosystem" element={<AuthenticatedRedirect><MainEntreprise /></AuthenticatedRedirect>} />
        <Route path="/entreprise/club" element={<AuthenticatedRedirect><MainEntreprise /></AuthenticatedRedirect>} />
        {/*
         *** Landing Page
         */}
        <Route path="/" element={<AuthenticatedRedirect><Main /></AuthenticatedRedirect>} />
        <Route path="/.netlify" element={<NoRender />} />
        {/* <Route path="*" element={<Page404 />} /> */}
      </Routes>
      </Suspense>
      {!isAuthenticated ? <Cookies /> : null}
    </div>
    // </Router>
  )
}
// check authLink in apollo.jsx
// for localstorage authentication
const AppWithQuery = compose(
  graphql(UserQuery, {
    // name: 'UserQuery',
    options: { fetchPolicy: 'cache-and-network' },
  }),
  graphql(PublicProfileFirstQuery, {
    options: (props) => ({
      variables: {
        slug: !window.location.pathname.includes('/org/@')
          ? window.location.pathname.substring(2)
          : null,
      },
    }),
    name: 'profileBySlug',
  }),
  graphql(PageFirstQuery, {
    options: (props) => ({
      variables: {
        slug: window.location.pathname.includes('/org/@')
          ? window.location.pathname.substring(6)
          : null,
      },
    }),
    name: 'pageBySlug',
  })
)(App)
export default AppWithQuery
