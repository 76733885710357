import React, { node } from 'react'

import { DraftailEditor, BLOCK_TYPE, INLINE_STYLE } from 'draftail'

import { handleLinkStrategy, DraftLink } from 'utils/render'

// type DraftailEditorType = {
//   BottomRightComponent?: node,
//   handleBlur: () => {},
//   onSave: () => {},
//   stateSaveInterval: number,
//   rawContentState: Object,
// }

const Editor = (props) => {
  const {
    BottomRightComponent,
    handleBlur,
    onSave,
    stateSaveInterval,
    rawContentState,
    placeholder,
    removeBlockTypes,
    limitedBlockTypes,
    ...rest
  } = props

  return (
    <div style={{ position: 'relative', marginTop: '.75em' }}>
      <DraftailEditor
        {...rest}
        stateSaveInterval={stateSaveInterval || 16}
        rawContentState={rawContentState || null}
        onBlur={handleBlur}
        onSave={onSave}
        onChange={null}
        placeholder={placeholder || null}
        // eslint-disable-next-line
        blockTypes={
          limitedBlockTypes
            ? [
                { type: BLOCK_TYPE.HEADER_TWO, label: 'titre' },
                {
                  type: BLOCK_TYPE.HEADER_THREE,
                  label: 'sous-titre',
                },
                {
                  type: BLOCK_TYPE.UNORDERED_LIST_ITEM,
                  label: '-',
                },
                {
                  type: BLOCK_TYPE.ORDERED_LIST_ITEM,
                  label: '1...',
                },
              ]
            : removeBlockTypes
            ? []
            : [
                { type: BLOCK_TYPE.HEADER_TWO, label: 'titre' },
                {
                  type: BLOCK_TYPE.HEADER_THREE,
                  label: 'sous-titre',
                },
                {
                  type: BLOCK_TYPE.UNORDERED_LIST_ITEM,
                  label: '-',
                },
                {
                  type: BLOCK_TYPE.ORDERED_LIST_ITEM,
                  label: '1...',
                },
                { type: BLOCK_TYPE.CODE, label: '[Bloc Code]' },
              ]
        }
        decorators={[
          {
            strategy: handleLinkStrategy,
            component: DraftLink,
          },
        ]}
        // eslint-disable-next-line
        inlineStyles={
          limitedBlockTypes
            ? [
                { type: INLINE_STYLE.BOLD, label: 'G' },
                { type: INLINE_STYLE.ITALIC, label: 'I' },
              ]
            : removeBlockTypes
            ? []
            : [
                { type: INLINE_STYLE.BOLD, label: 'G' },
                { type: INLINE_STYLE.ITALIC, label: 'I' },
                { type: INLINE_STYLE.CODE, label: '{Code}' },
              ]
        }
      />
      {BottomRightComponent && <BottomRightComponent />}
    </div>
  )
}

Editor.defaultProps = {
  BottomRightComponent: undefined,
}

export default Editor
