import React, { useContext, useState,useEffect, createElement } from 'react'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
// import PropTypes from 'prop-types';
import redraft from 'redraft'
import Select from 'react-select'
import Lightbox from 'react-images'
import UIAvatar from 'react-ui-avatars'
import { Icon } from 'antd'

import { StoreContext } from 'contexts/Store'
import { getUser } from 'utils/handleUser'
import * as WORDING from 'utils/wording'
import VideoPlayer from 'components/VideoPlayer'
import { UikHorizontalScrollArea } from 'uikit/containers'
import { traineePage } from 'utils/regexp'

import { LegalMentionContent } from 'pages/LegalMention'
import { CguContent } from 'pages/Cgu'
import { CharterContent } from 'pages/Charter'
import { PrivacyContent } from 'pages/Privacy'
import { PrivacyAIContent } from 'pages/PrivacyAI'

import {
  UikAvatar,
  UikButton,
  UikHeadline,
  UikHeadlineDesc,
  UikTag,
  UikTagOther,
  UikSvg,
  UikDivider,
  UikWidgetContent,
  UikCheckbox,
} from 'uikit/components'
import ContentPreview from 'components/ContentPreview'
import renderers from 'utils/render'

import StoryMenu from '../StoryMenu'
import { Row, Col } from 'react-flexbox-grid'

import { SVG_URI } from 'utils/vars'

import cls from './hover.module.scss'

const Cta = ({ cta, children }) =>
  cta ? (
    <div style={{ cursor: 'pointer' }} onClick={cta}>
      {children}
    </div>
  ) : (
    <div> {children} </div>
  )

const limitCharactersOfEditorStateAndStatesOverflow = (editorState, count) => {
  const newEditorState = {
    ...editorState,
    blocks: [],
  }
  let counter = 0
  let hasOverflow = false

  editorState.blocks.map((block, i) => {
    if (counter <= count) {
      newEditorState.blocks.push(block)
      counter += block.text.length
    } else {
      hasOverflow = true
    }
    return null
  })
  return {
    hasOverflow,
    editorState: newEditorState,
  }
}

const WidgetContent = ({
  avatar,
  className,
  cta,
  editorState,
  iFrames,
  links,
  title,
  text,
  subTitles,
  tags,
  tagsInterest,
  isOwner,
  children,
  svg,
  iconStoryMenu,
  iconTitle,
  iconColor,
  launch,
  launchText,
  selectTag,
  allTags,
  storyType,
  videoUrl,
  draft,
  id,
  pages,
  readMore,
  name,
  element,
  ...rest
}) => {
  const { ownPage: { ownPage }, navigationByTag: { navigationByTag } } = useContext(StoreContext);

  const navigate = useNavigate();
  const location = useLocation()
  const params = useParams()


  const [state, setState] = useState({
    hideOverflow: true,
    images: images,
    lightboxIsOpen: false,
    currentImageIndex: 0,
  })


  useEffect(() => {
    if (window.iframely) {
      window.iframely.load()
    }
  }, [])

  useEffect(() => {
    if (images !== state.images) {
      setState((prevState) => ({
        ...prevState,
        images: images,
      }))
    }
  }, [images])

  useEffect(() => {
    if (window.iframely) {
      window.iframely.load()
    }
  }, [images])

  const { images, lightboxIsOpen, currentImageIndex, hideOverflow } = state


  // direction: -1 to goPrev | 1 to goNext
  const onLightBoxClick = (images, direction) => {
    if (images[currentImageIndex + direction]) {
      setState({
        ...state,
        currentImageIndex: currentImageIndex + direction,
      })
    }
  }
  
  const showOverflow = () => {
    setState({
      ...state,
      hideOverflow: false,
    })
  }

    let contentHasOverflow
    let editorStateTransport
    if (editorState) {
      editorStateTransport = limitCharactersOfEditorStateAndStatesOverflow(
        editorState,
        300
      )
      contentHasOverflow = editorStateTransport.hasOverflow
    }
    const appends = (type) => {
      if (type === 'WORK') {
        return 'experiences-pro'
      } else if (type === 'VOLUNTEER') {
        return 'benevolat'
      } else if (type === 'EDUCATION') {
        return 'cursus'
      } else if (type === 'PUBLICATION') {
        return 'articles'
      } else if (type === 'PROJECT') {
        return 'projets'
      } else if (type === 'MEETING') {
        return 'rencontres'
      } else if (type === 'INTEREST') {
        return 'interets'
      } else if (type === 'AWARD') {
        return 'prix'
      } else if (type === 'FAIL') {
        return 'echecs'
      } else if (type === 'FREESTORY') {
        return 'freestory'
      }
    }
    const rendered =
      editorStateTransport && hideOverflow
        ? redraft(editorStateTransport.editorState, renderers)
        : redraft(editorState, renderers)

    const noForm =
      !location.pathname.includes('/creer-') &&
      !location.pathname.includes('/edit') &&
      !location.pathname.includes('/profiles') &&
      !location.pathname.includes('/pages') &&
      !location.pathname.includes('/nouvelle/') &&
      !location.pathname.includes('/nouvelle-story')

    const isEditable =
      storyType !== 'INTRODUCTION' && noForm && !traineePage(location.pathname)

    const user = getUser()
    const userType = !!user
      ? user.origin
      : window.location.pathname.includes('/org')
      ? 'TRAINING'
      : 'CANDIDATE'
    const getRandomColor = (letter) => {
      let colorValues = '#FFC117'
      if (letter.match(/^[a-cA-C 0-9\.\,\+\-]*$/)) {
        return colorValues
      } else if (letter.match(/^[d-fD-F\.\,\+\-]*$/)) {
        return (colorValues = '#007ED9')
      } else if (letter.match(/^[g-iG-I\.\,\+\-]*$/)) {
        return (colorValues = '#ED4A5E')
      } else if (letter.match(/^[j-lJ-L\.\,\+\-]*$/)) {
        return (colorValues = '#00A2AE')
      } else if (letter.match(/^[m-oM-O\.\,\+\-]*$/)) {
        return (colorValues = '#3C26BB')
      } else if (letter.match(/^[p-rP-R\.\,\+\-]*$/)) {
        return (colorValues = '#666be2')
      } else if (letter.match(/^[s-uS-U\.\,\+\-]*$/)) {
        return (colorValues = '#ff015b')
      } else if (letter.match(/^[v-xV-X\.\,\+\-]*$/)) {
        return (colorValues = '#30ca93')
      } else if (letter.match(/^[v-xV-X\.\,\+\-]*$/)) {
        return (colorValues = '#ffd000')
      } else {
        return (colorValues = '#F56A00')
      }
    }

    return (
      <UikWidgetContent className={className} {...rest}>
        {iconStoryMenu && iconTitle && iconColor && (
          <Row type="flex" style={{ margin: '0px' }}>
            <Icon
              type={iconStoryMenu}
              style={{
                marginTop: '4px',
                marginRight: '8px',
                color: iconColor,
              }}
            />
            <UikHeadlineDesc style={{ fontWeight: 'bold' }}>
              {' '}
              {iconTitle}{' '}
            </UikHeadlineDesc>
            <UikDivider />
          </Row>
        )}
        {avatar ? (
          <Cta cta={cta}>
            {avatar.imageId ? (
              <div style={{ display: 'flex' }}>
                <UikAvatar
                  imageId={avatar.imageId}
                  imageModifier={avatar.imageModifier}
                  size="larger"
                  style={{ marginRight: '10px' }}
                />
                <p
                  style={
                    draft
                      ? {
                          whiteSpace: 'pre-wrap',
                          marginTop: '1.575rem',
                          color: 'rgba(62,63,66,0.4)',
                        }
                      : {
                          whiteSpace: 'pre-wrap',
                          marginTop: '1.575rem',
                          fontSize: '20px',
                          color: 'black',
                        }
                  }
                >
                  {name}
                </p>
              </div>
            ) : (
              <div style={{ display: 'flex' }}>
                {name ? (
                  <UIAvatar
                    style={{ marginRight: '10px' }}
                    background={getRandomColor(
                      name.charAt(0).toLowerCase()
                    )}
                    name={name}
                    color="#FFFFFF"
                    rounded
                    size="80px"
                  />
                ) : (
                  <UIAvatar
                    style={{ marginRight: '10px' }}
                    background={getRandomColor(
                      title.charAt(0).toLowerCase()
                    )}
                    name={title}
                    color="#FFFFFF"
                    rounded
                    size="80px"
                  />
                )}
                <p
                  style={
                    draft
                      ? {
                          whiteSpace: 'pre-wrap',
                          marginTop: '1.575rem',
                          color: 'rgba(62,63,66,0.4)',
                        }
                      : {
                          whiteSpace: 'pre-wrap',
                          marginTop: '1.575rem',
                          fontSize: '20px',
                          color: 'black',
                        }
                  }
                >
                  {name}
                </p>
              </div>
            )}
          </Cta>
        ) : (
          <div style={{ marginBottom: '30px' }}>
            <div className={cls.hover}>
              <div className={cls.hover__noHover}>
                <UikHeadline
                  rightEl={
                    <StoryMenu
                      isHovered={false}
                      isEditable={isEditable && ownPage}
                    />
                  }
                >
                  {title}
                </UikHeadline>
              </div>
              <div
                className={cls.hover__hover}
                onClick={() => {
                  if (isEditable && ownPage) {
                    navigate(
                      `/${params.slug}/${appends(
                        storyType
                      )}/${id}/edit`
                    )
                    window.scroll(0, 0)
                  }
                  return null
                }}
                onKeyPress={() => {
                  if (isEditable && ownPage) {
                    navigate(
                      `/${params.slug}/${appends(
                        storyType
                      )}/${id}/edit`
                    )
                  }
                }}
              >
                <UikHeadline
                  rightEl={
                    <StoryMenu
                      isHovered={true}
                      isEditable={isEditable && ownPage}
                    />
                  }
                >
                  {title}
                </UikHeadline>
              </div>
            </div>
            {subTitles && (
              <UikHeadlineDesc>
                {subTitles.props.children.map((sub, index) => {
                  if (index < 1) {
                    return sub
                  } else if (index === 1) {
                    return (
                      readMore &&
                      hideOverflow && (
                        <UikButton
                          key={index}
                          style={{
                            marginTop: '12px',
                          }}
                          onClick={showOverflow}
                        >
                          <span role="img" aria-label="read down">
                            👇
                          </span>
                          Lire plus ...
                        </UikButton>
                      )
                    )
                  }
                  if (!hideOverflow) return sub
                })}
              </UikHeadlineDesc>
            )}
          </div>
        )}
        {svg && (
          <Row
            style={{
              display: 'flexbox',
              justifyContent: 'center',
            }}
          >
            <Cta cta={cta}>
              <UikButton style={{ marginBottom: '20px' }}>
                {WORDING.PROFILES_CTA(userType)}
              </UikButton>
            </Cta>
          </Row>
        )}
        {text && (
          <p
            style={
              draft
                ? {
                    whiteSpace: 'pre-wrap',
                    marginTop: '1.575rem',
                    color: 'rgba(62,63,66,0.4)',
                  }
                : {
                    whiteSpace: 'pre-wrap',
                    marginTop: '1.575rem',
                  }
            }
          >
            {text}
          </p>
        )}
        {selectTag && <Select {...children} />}
        {allTags &&
          allTags.map((t) => <UikCheckbox label={t.label} {...children} />)}
        {pages === 'LegalMention'
          ? createElement(LegalMentionContent)
          : null}
        {pages === 'Cgu' ? createElement(CguContent) : null}
        {pages === 'Charter' ? createElement(CharterContent) : null}
        {pages === 'Privacy' ? createElement(PrivacyContent) : null}
        {pages === 'PrivacyAI' ? createElement(PrivacyAIContent) : null}
        {rendered && (
          <div style={{ marginBottom: '2em' }}>
            {rendered}
            {contentHasOverflow && hideOverflow && (
              <UikButton
                style={{
                  marginTop: '12px',
                  marginBottom: '0.5em',
                }}
                onClick={showOverflow}
              >
                <div></div>{' '}
                <span role="img" aria-label="read down">
                  👇
                </span>
                Lire plus ...
              </UikButton>
            )}
          </div>
        )}
        {iFrames &&
          iFrames.map((elem) => {
            if (elem.url) {
              return (
                <div
                  key={elem.url}
                  style={{
                    maxWidth: '500px',
                    margin: 'auto',
                    // marginTop: '30px',
                    marginBottom: '2.5em',
                  }}
                >
                  <div
                    dangerouslySetInnerHTML={{
                      __html: `<a href="${elem.url}?iframely=less" data-iframely-url></a>`,
                    }}
                  />
                </div>
              )
            }
            return null
          })}
        {images && images.length ? (
          // <UikHorizontalScrollArea>
          <div>
            <ContentPreview
              images={images}
              title={`${images.length} image${
                images.length > 1 ? 's' : ''
              }`}
              //  associé${images.length > 1 ? 'es' : ''}
              description={`Cliquer pour ${
                images.length > 1 ? 'les' : 'la'
              } voir`}
              imgUrl={images[0]}
              onClick={() =>
                setState({
                  ...state,
                  lightboxIsOpen: true,
                  currentImageIndex: 0,
                })
              }
              target="_blank"
              style={{
                marginTop: '1.5em',
                marginBottom: '3em',
              }}
            />
            <Lightbox
              currentImage={currentImageIndex}
              onClickNext={() => onLightBoxClick(images, 1)}
              onClickPrev={() => onLightBoxClick(images, -1)}
              images={images.map((e) => ({
                src: `https://ucarecdn.com/${e}/`,
              }))}
              imageCountSeparator=" sur "
              isOpen={lightboxIsOpen}
              onClose={(e) => {
                setState({
                  ...state,
                  lightboxIsOpen: false,
                })
              }}
            />
          </div>
        ) : //  </UikHorizontalScrollArea>
        null}
        {videoUrl ? (
          <div className="videoPlay" style={{ marginTop: '-2em' }}>
            <VideoPlayer
              url={
                videoUrl.includes('facebook') ||
                videoUrl.includes('vimeo') ||
                videoUrl.includes('dailymotion') ||
                videoUrl.includes('youtube') ||
                videoUrl.includes('twitter')
                  ? videoUrl
                  : `https://ucarecdn.com/${videoUrl}/`
              }
            />
          </div>
        ) : null}
        {(tags && tags.length) || (tagsInterest && tagsInterest.length) ? (
          <div
            style={{
              marginTop: images && images.length ? 'Opx' : '30px',
            }}
          >
            {
              // since prisma migration v1 to v2 there is a bug that duplicate tags when you edit a story. this filter duplicate tags
              tags.filter((v,i,a)=>a.findIndex(v2=>(v2.id===v.id))===i).map((elem, index) => {
              if (index < 12) {
                return (
                  <UikTagOther
                    key={elem.slug}
                    Component={elem.Component}
                    {...elem}
                    style={{
                      fontSize: '14px',
                      padding: '2px 16px',
                      fontFamily:
                        '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica,Arial,sans-serif',
                    }}
                  >
                    {elem.name}
                  </UikTagOther>
                )
              } else if (index === 12) {
                return (
                  hideOverflow && (
                    <div>
                      <UikButton
                        style={{
                          marginTop: '12px',
                        }}
                        onClick={showOverflow}
                      >
                        <span role="img" aria-label="read down">
                          👇
                        </span>
                        Lire plus ...
                      </UikButton>
                    </div>
                  )
                )
              }
              if (!hideOverflow) {
                return (
                  <UikTag
                    large
                    key={elem.slug || elem.name}
                    Component={elem.Component}
                    {...elem}
                  >
                    {elem.name || elem.label}
                  </UikTag>
                )
              }
            })}
            {/* {
                      window.location.href.includes("edit") || window.location.href.includes("nouvelle") ? tagsInterest.map(elem => (
                        <UikTag
                        large
                        key={elem.slug || elem.name}
                        Component={elem.Component}
                        {...elem}
                        >
                          {elem.name}
                        </UikTag>
                      )) : null
                    }                       */}
          </div>
        ) : null}
        {launch && (
          <Row
            style={{
              display: 'flexbox',
              justifyContent: 'flex-start',
            }}
          >
            <Cta cta={cta}>
              <UikButton style={{ marginBottom: '20px' }}>
                {!!launchText ? launchText : 'Démarrer'}
              </UikButton>
            </Cta>
          </Row>
        )}
      </UikWidgetContent>
    )
  }

export default WidgetContent