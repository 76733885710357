import React, { Component } from 'react'
import { string, object, arrayOf, shape, oneOf } from 'prop-types'
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'

import { SVG_URI } from 'utils/vars'

import { UikSvg, UikButton } from 'uikit/components'

import { UikKeyListener, UikForwardRef } from 'uikit/containers'

import TextWidget from 'components/TextWidget'

import Menu from 'components/Menu'

// import LoadingThread from 'components/LoadingCards/Thread';
import ThreadHeader from './ThreadHeader'
import ThreadContent from './ThreadContent'

import './thread.scss'

const formatDate = (date) => {
  if (date) {
    const shortDate = date.substr(0, 10)
    return `${shortDate.substr(8, 2)}-${shortDate.substr(
      5,
      2
    )}-${shortDate.substr(0, 4)}`
  }
  return ''
}

class Thread extends Component {
  constructor(props) {
    super(props)
    this.ref = null
  }

  render() {
    const {
      baseUrl,
      userIsOwner,
      navSections,
      transport,
      userStories,
      history,
      match: {
        params: {
          // with @ character
          0: userName,
          appends,
        },
      },
    } = this.props

    return (
      <UikKeyListener
        actions={[
          {
            keyCode: 27,
            method: this.props.onClose,
          },
        ]}
        listenerType="keydown"
      >
        <UikForwardRef style={{ paddingBottom: '60px', background: '#FBFBFD' }}>
          {({ parentRef }) => (
            <div>
              <ThreadHeader
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  maxWifth: '650px',
                }}
              >
                <div>
                  <h2>{transport.text}</h2>
                  {userIsOwner && (
                    <UikButton
                      Component={Link}
                      to={`/${baseUrl}${userName}/nouvelle/${appends}`}
                      style={{ marginTop: '6px' }}
                      xs
                    >
                      Nouvelle Story
                    </UikButton>
                  )}
                </div>
                <UikSvg
                  alt="close"
                  height="24px"
                  src={`${SVG_URI}/remove.svg`}
                  width="24px"
                  style={{ cursor: 'pointer' }}
                  onClick={this.props.onClose}
                />
              </ThreadHeader>
              <ThreadContent>
                <TextWidget
                  content={userStories
                    .sort(
                      (a, b) =>
                        new Date(b.createdAt).getTime() -
                        new Date(a.createdAt).getTime()
                    )
                    .map((elem) => ({
                      ...elem,
                      // eslint-disable-next-line
                      text:
                        elem.startDate && elem.endDate
                          ? `${formatDate(elem.startDate)} / ${formatDate(
                              elem.endDate
                            )}`
                          : // eslint-disable-next-line
                          elem.startDate && elem.endDatePresent
                          ? `${formatDate(elem.startDate)} - en cours`
                          : // eslint-disable-next-line
                          elem.startDate
                          ? `début : ${formatDate(elem.startDate)}`
                          : elem.endDate
                          ? `fin : ${formatDate(elem.endDate)}`
                          : '',
                    }))}
                />
                {navSections.filter((e) => e.text !== 'Présentation').length >
                1 ? (
                  <Menu
                    links={navSections
                      .filter((e) => e.text !== 'Présentation')
                      .filter((e) => e.appends !== appends)
                      .map((link) => ({
                        ...link,
                        key: link.text,
                        Component: 'a',
                        onClick: () => {
                          parentRef.scrollIntoView(true)
                          history.replace({
                            pathname: !window.location.href.includes('/org/')
                              ? `/${userName}/${link.appends}`
                              : `/org/${userName}/${link.appends}`,
                            state: { modal: true },
                          })
                        },
                      }))}
                    style={{
                      marginTop: '30px',
                      boxShadow: '0 3px 6px #00000029',
                    }}
                    // title="👇 Ces infos pourraient vous plaire également"
                  />
                ) : null}
              </ThreadContent>
            </div>
          )}
        </UikForwardRef>
      </UikKeyListener>
    )
  }
}

Thread.propTypes = {
  baseUrl: oneOf(['', 'org/']).isRequired,
  navSections: arrayOf(
    shape({
      title: string.isRequired,
      appends: string.isRequired,
    })
  ).isRequired,
  transport: shape({
    text: string.isRequired,
  }).isRequired,
  userStories: arrayOf(
    shape({
      title: string.isRequired,
      editorState: object.isRequired,
    })
  ).isRequired,
}

export default withRouter(Thread)
