// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@media only screen and (min-width: 120px)and (max-width: 319px){.kC4ZUtNdaPYadfXGW22v{font-size:10px}}@media only screen and (min-width: 320px)and (max-width: 360px){.kC4ZUtNdaPYadfXGW22v{font-size:13px}}.kC4ZUtNdaPYadfXGW22v{z-index:5}", "",{"version":3,"sources":["webpack://./src/components/AlgoliaAsyncInterest/algoliaAsyncInterest.module.scss"],"names":[],"mappings":"AAAA,gEACI,sBACE,cAAA,CAAA,CAIN,gEACI,sBACE,cAAA,CAAA,CAIJ,sBACE,SAAA","sourcesContent":["@media only screen and (min-width : 120px) and (max-width : 319px) {\n    .tagClass {\n      font-size: 10px;\n    }\n  }\n\n@media only screen and (min-width : 320px) and (max-width : 360px) {\n    .tagClass {\n      font-size: 13px;\n    }\n  }\n\n  .tagClass {\n    z-index: 5;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tagClass": "kC4ZUtNdaPYadfXGW22v"
};
export default ___CSS_LOADER_EXPORT___;
