import React from 'react'

import {
  InputField,
  EditorField,
  VideoField,
  FieldArray,
  ImagesArrayField,
  InputDateOrNow,
  EditorsArrayField,
  ImageField,
  PdfField,
  WebcamField,
  CreateTagField,
  SelectTagField,
  CreateInterestTagField,
  SelectInterestTagField,
  CreateWorkTagField,
  SelectWorkTagField,
  CreateAllTagField,
  SelectAllTagField,
  CreateTagOnPagesField,
  SelectTagOnPagesField,
} from './fields'

const textAddImage = (location) =>
  location.pathname.includes('/org/')
    ? 'Ajouter votre logo'
    : 'Ajouter ma photo de profil'

const textDropImage = (location) =>
  location.pathname.includes('/org/')
    ? 'Supprimer votre logo'
    : 'Supprimer ma photo'

export default (location, type, fields, getInputRef, getEditorRef) => {
  if (type === 'INPUT' || type === 'DATE') {
    return <InputField {...fields} getInputRef={getInputRef} />
  } else if (type === 'DATE_OR_NOW') {
    return <InputDateOrNow {...fields} />
  } else if (type === 'EDITOR') {
    return <EditorField {...fields} getEditorRef={getEditorRef} />
  } else if (type === 'VIDEO') {
    return <VideoField {...fields} />
  } else if (type === 'FIELD_ARRAY') {
    return <FieldArray {...fields} />
  } else if (type === 'IMAGES_ARRAY') {
    return <ImagesArrayField {...fields} />
  } else if (type === 'EDITORS_ARRAY') {
    return <EditorsArrayField {...fields} getEditorRef={getEditorRef} />
  } else if (type === 'IMAGE') {
    return (
      <ImageField
        {...fields}
        textAddImage={textAddImage(location)}
        textDropImage={textDropImage(location)}
      />
    )
  } else if (type === 'PDFFILE') {
    return <PdfField {...fields} />
  } else if (type === 'WEBCAM') {
    return <WebcamField {...fields} />
  } else if (type === 'CREATE_TAG') {
    return <CreateTagField {...fields} />
  } else if (type === 'SELECT_TAG') {
    return <SelectTagField {...fields} />
  } else if (type === 'CREATE_INTEREST_TAG') {
    return <CreateInterestTagField {...fields} />
  } else if (type === 'SELECT_INTEREST_TAG') {
    return <SelectInterestTagField {...fields} />
  } else if (type === 'CREATE_WORK_TAG') {
    return <CreateWorkTagField {...fields} />
  } else if (type === 'SELECT_WORK_TAG') {
    return <SelectWorkTagField {...fields} />
  } else if (type === 'CREATE_ALL_TAG') {
    return <CreateAllTagField {...fields} />
  } else if (type === 'SELECT_ALL_TAG') {
    return <SelectAllTagField {...fields} />
  } else if (type === 'CREATE_TAG_ON_PAGES') {
    return <CreateTagOnPagesField {...fields} />
  } else if (type === 'SELECT_TAG_ON_PAGES') {
    return <SelectTagOnPagesField {...fields} />
  }
  return <p>Field not found</p>
}
