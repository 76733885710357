import React from 'react'

import Joyride from 'react-joyride'

const options = {
  arrowColor: '#e28c42',
  primaryColor: '#e28c42',
}

export default (props) => {
  const steps = [
    {
      target: '.first-step',
      content: (
        <div>
          <p> Ecrire ma première story </p>
        </div>
      ),
    },
  ]

  return (
    <Joyride
      steps={steps}
      // continuous
      // scrollToFirstStep
      // showProgress
      // showSkipButton
      // run={run}
      styles={{ options }}
      disableBeacon={false}
      // callback={this.callback}
      locale={{ close: 'Fermer' }}
    />
  )
}
