import React, { useState, useEffect } from 'react'
import { graphql, withApollo } from '@apollo/client/react/hoc'
import { compose } from 'recompose'
import slugify from 'slugify'
import {
  Modal,
  Header,
  Footer,
  FooterItem,
  Close,
  Body,
} from '@zendeskgarden/react-modals'
import { Button } from '@zendeskgarden/react-buttons'
import { UserQuery } from 'gql/auth'

import PropTypes from 'prop-types'

import { withFormik } from 'formik'

import createProfileSections from 'modules/Form/createProfileSections'
import ProfileForm from 'modules/Form/containers/ProfileForm'

import { CreateProfileMutation } from 'gql/profile'
import { CreateTagMutation } from 'gql/tag'

import { setMainProfile } from 'utils/handleMainProfile'

import { createProfile as createIntercomProfile } from 'utils/intercom'

import clsLogin from 'containers/LoginModal/loginModal.module.scss'

// using this function with React.memo to prevent the component to re-render.
// we had a re-render problem when we have an error on the mutation request
const areEqual = (prevProps, nextProps) => true

const CreateProfile = React.memo((props) => {
  const currentForm = createProfileSections()
  const [showModal, handleModal] = useState(false)
  const [redirect, handleRedirect] = useState('')

  const formikEnhancer = withFormik({
    validationSchema: currentForm.validationSchema,
    mapPropsToValues: () => ({
      ...currentForm.initialValues,
    }),
    handleSubmit: (vals, { setSubmitting }) => {
      const cleanVals = vals
      cleanVals.title = `${vals.title}`
      cleanVals.links = vals.links
        .filter((e) => e.url.length)
        .map((link) => ({
          url: link.url,
          type: link.type,
        }))
      cleanVals.editorStates = vals.editorStates.map((elem, i) => ({
        title: elem.title,
        editorState: elem.editorState,
        storyType: 'INTRODUCTION',
        isPublished: true,
        order: i,
      }))

      vals.tags === undefined ? (vals.tags = []) : vals.tags

      const tagsnew = vals.tags && vals.tags.filter((tag) => !tag.id)
      vals.tags &&
        vals.tags
          .filter((tag) => !tag.id)
          .map((tag) => {
            console.log('SHOULD PRINT ONLY NEW TAGS', tag)
            props
              .createTag({
                variables: {
                  name: tag.name,
                  slug: slugify(tag.name),
                },
              })
              .then(({ data }) => {
                setSubmitting(false)
                console.log('tagId', data)
              })
              .catch((err) => {
                console.log(err)
                setSubmitting(false)
              })
          })

      Array.prototype.push.apply(vals.tags, vals.tagsInterest)
      Array.prototype.push.apply(vals.tags, vals.tagsWork)
      cleanVals.tags =
        vals.tags &&
        vals.tags
          .filter((tag) => tag.id)
          .map(
            (tag) =>
              console.log('SHOULD PRINT ONLY KNOWN TAGS', tag) || {
                id: tag.id,
              }
          )

      // newLy created tags cannot be connected to newlycreated profile, needs to be verified !

      cleanVals.video = vals.video || vals.videoUrl
      ;(cleanVals.isPublished = !vals.isDraft),
        props
          .createProfile({
            variables: {
              ...cleanVals,
              id: props.user.id,
            },
          })
          .then(({ data: { createProfile } }) => {
            setSubmitting(false)
            // Set New Profile to Main Profile
            setMainProfile(createProfile, 'CANDIDATE')
            const mail = props.user.email

            createIntercomProfile(createProfile, mail)
            tagsnew.length >= 1
              ? (handleModal(true), handleRedirect(`/@${createProfile.slug}`))
              : window.location.replace(`/@${createProfile.slug}`)
          })
          .catch((err) => {
            console.log(err)
            setSubmitting(false)
          })
    },
    displayName: 'ProfileForm',
  })

  const relocate = () => {
    window.location.replace(redirect)
  }

  return (
    <div style={{ paddingBottom: '60px' }}>
      {React.createElement(formikEnhancer(ProfileForm), {
        existingTags: props.existingTags,
        returnUrl: props.returnUrl,
        ...currentForm,
        ...props,
      })}
      {showModal && (
        <Modal className={clsLogin.responsiveFormModal}>
          <Header default>Information</Header>
          <Body>
            Les tags créés seront disponibles après validation par Whire.
          </Body>
          <Footer>
            <FooterItem
              style={{
                display: 'block',
                marginRight: 'auto',
                marginLeft: 'auto',
              }}
            >
              <Button
                success
                onClick={() => {
                  relocate()
                  handleModal(true)
                }}
              >
                ok
              </Button>
            </FooterItem>
          </Footer>
        </Modal>
      )}
    </div>
  )
}, areEqual)

CreateProfile.propTypes = {
  // returnUrl: PropTypes.string.isRequired,
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
}

CreateProfile.displayName = 'CreateProfile'

export default compose(
  graphql(UserQuery),
  graphql(CreateProfileMutation, { name: 'createProfile' }),
  graphql(CreateTagMutation, { name: 'createTag' })
)(withApollo(CreateProfile))
